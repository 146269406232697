/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Button, Box, Flex } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import TextFormField from '../form/TextFormField';
import DateRangeFormField from '../form/DateRangeFormField';
import CheckBoxFormField from '../form/CheckBoxFormField';
import RadioFormField from '../form/RadioFormField';
import useStore from '../../store';
import c from '../../constant';
import * as Yup from 'yup';
import moment from 'moment';
import 'moment/locale/en-gb';

const QUERY_COMPANY_REPORT_TYPE = gql`
	query get($period: String!) {
		getPublicCompanyReportTypes(period: $period) {
			id
			title
			titleKh
		}
	}
`;

const filterSchema = Yup.object().shape({
	companyName: Yup.string(),
	createdDate: Yup.string(),
	unreadOnly: Yup.boolean(),
	showSpam: Yup.boolean(),
	newReportType: Yup.string(),
});

function ComplaintFilter({
	isOpen = false,
	onClose = () => null,
	onCallback = () => null,
	companyName = '',
	fromDate = null,
	toDate = null,
	read = null,
	type = '',
	showSpam = null,
	newReportType = '',
}) {
	moment.locale('en-gb');
	const { t } = useTranslation();
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const [loadReportTypeByPeriod, { data: dataReportType }] = useLazyQuery(QUERY_COMPANY_REPORT_TYPE);
	const [tempNewReportType, setTempNewReportType] = useState(newReportType);

	useEffect(() => isOpen && type && loadReportTypeByPeriod({ variables: { period: type } }), [isOpen, type]);

	let defaultRange = '';
	if (fromDate && toDate) {
		const defaultFrom = JSON.parse(fromDate);

		const defaultTo = JSON.parse(toDate);
		defaultRange = {
			from: defaultFrom,
			to: defaultTo,
		};
	}

	function getTypeData(data) {
		let res = [];
		data?.forEach((item) => {
			res.push({
				label: lang === 'kh' ? item?.titleKh : item?.title,
				value: item?.id,
			});
		});
		return res;
	}

	return (
		<>
			<Modal borderRadius={c.borderRadius} isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius}>
					<ModalHeader>{t('Filter')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Formik
							initialValues={{
								companyName: companyName ? companyName : '',
								createdDate: defaultRange ? JSON.stringify(defaultRange) : '',
								unreadOnly: read ? read : '',
								showSpam: showSpam ? showSpam : false,
								newReportType: newReportType ? newReportType : '',
							}}
							validationSchema={filterSchema}
							onSubmit={(values) => {
								let url = `?type=${type?.toLowerCase()}&page=1`;
								if (values.companyName) {
									url += `&companyName=${values.companyName}`;
								}
								if (values.createdDate) {
									const tmpDate = JSON.parse(values.createdDate);
									if (tmpDate?.from && tmpDate?.to) {
										const dateFrom = JSON.stringify(tmpDate.from);
										const dateTo = JSON.stringify(tmpDate.to);
										url += `&fromDate=${dateFrom}&toDate=${dateTo}`;
									}
								}
								if (values?.newReportType) {
									url += `&newReportType=${tempNewReportType}`;
								}
								if (values.unreadOnly) {
									url += `&read=false`;
								}
								if (values.showSpam) {
									url += `&showSpam=true`;
								}
								onCallback(url);
								onClose();
							}}
						>
							{(props) => (
								<Box pb="4">
									<Form>
										<TextFormField {...props} label="Company/Institution Name" name="companyName" />
										<center>
											<DateRangeFormField {...props} label="Created Date" name="createdDate" initialDate={{ fromDate, toDate }} />
										</center>
										<Box display="none">
											{dataReportType?.getPublicCompanyReportTypes?.length > 0 && (
												<RadioFormField
													label="Report Type"
													name="newReportType"
													radios={getTypeData(dataReportType?.getPublicCompanyReportTypes)}
													initValue={newReportType}
													callbackValueChange={(e) => setTempNewReportType(e)}
												/>
											)}
										</Box>
										<Box marginTop="4">
											<CheckBoxFormField {...props} label="Show unread only" name="unreadOnly" />
										</Box>
										<Box marginTop="4">
											<CheckBoxFormField {...props} label="Show spam" name="showSpam" />
										</Box>
										<Flex mt="32px" justifyContent="flex-end">
											<Button
												id="login-btn"
												variant="solid"
												size="md"
												h="42px"
												w="128px"
												type="submit"
												borderRadius={c.borderRadius}
												color="#3498db"
											>
												{t('Apply')}
											</Button>
											<Button
												size="md"
												h="42px"
												ml={4}
												w="128px"
												onClick={() => {
													onCallback(`?type=${type?.toLowerCase()}&newReportType=${newReportType}&page=1`);
													onClose();
												}}
												variant="ghost"
											>
												{t('Clear')}
											</Button>
										</Flex>
									</Form>
								</Box>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default ComplaintFilter;
