import React from 'react';
import { RadioGroup, Stack, Radio, FormControl, Box, FormLabel, FormHelperText } from '@chakra-ui/react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

const RadioValueFormField = ({ label = null, name = null, radios = [], radiosDirection = 'column', required = false }) => {
	const { t } = useTranslation();

	return (
		<Field name={name}>
			{({ field, meta, form }) => {
				return (
					<Box mb="4" mt="4">
						<FormControl id={name}>
							{label && (
								<FormLabel>
									{t(label)}
									{required && <span style={{ color: 'red' }}>*</span>}
								</FormLabel>
							)}
							<RadioGroup onChange={(e) => form.setFieldValue(name, e)} value={field?.value}>
								<Stack direction={radiosDirection} ml={radiosDirection === 'column' ? 4 : 0}>
									{radios?.map((item, index) => (
										<Radio key={`radio-item-${index}`} value={item?.value}>
											{t(item?.label)}
											{t(item?.secondLabel ? item?.secondLabel : '')}
										</Radio>
									))}
								</Stack>
							</RadioGroup>
							{meta.touched && meta.error && (
								<FormHelperText id="error-message-password" color="red.400" fontSize="12px">
									{t(label)} {t('is')} {t(meta.error)}
								</FormHelperText>
							)}
						</FormControl>
					</Box>
				);
			}}
		</Field>
	);
};

export default RadioValueFormField;
