import { EWSTypeCurrency, sumOrError } from './ews';
import { isNumeric } from './market-development';

const sfpNumColumn = 2;
const readyOnlys = [0, 24, 25, 26, 36, 37, 38, 54, 55, 56];
const sumOnlys = [24, 36, 54, 56];

function cleanSFPCells(labels) {
	const cells = labels?.map((label, i) => {
		if (readyOnlys?.includes(i)) {
			if (sumOnlys?.includes(i)) {
				return [
					{ value: '0', readOnly: true },
					{ value: '0', readOnly: true },
				];
			} else {
				return [
					{ value: '', readOnly: true },
					{ value: '', readOnly: true },
				];
			}
		} else {
			return [
				{ value: '', readOnly: false },
				{ value: '', readOnly: false },
			];
		}
	});
	return cells;
}

function detailSFPCells({ code = null, labels = [], detailLabels = [], readOnly = false }) {
	const localCells = labels?.map((label, i) => {
		if (readyOnlys?.includes(i)) {
			if (sumOnlys?.includes(i)) {
				return [
					{ value: '0', readOnly: true },
					{ value: '0', readOnly: true },
				];
			} else {
				return [
					{ value: '', readOnly: true },
					{ value: '', readOnly: true },
				];
			}
		} else {
			const detailLabel = detailLabels?.find((el) => el?.templateLabel?.code === label?.code);
			if (detailLabel) {
				return [
					{ value: detailLabel?.values?.find((value) => value?.currency === EWSTypeCurrency.USD)?.value || 0, readOnly },
					{ value: detailLabel?.values?.find((value) => value?.currency === EWSTypeCurrency.KHR)?.value || 0, readOnly },
				];
			} else {
				return [
					{ value: '', readOnly },
					{ value: '', readOnly },
				];
			}
		}
	});

	const { cells } = calculateSFPCells({ code, cells: localCells, numLabel: labels?.length, readOnly });

	return cells;
}

function calculateSFPCells({ code, cells, numLabel, readOnly = false }) {
	// Total assets
	let taSums = Array.from({ length: 23 }, (item, i) => i + 1);
	let taUSD = 0;
	let taKHR = 0;

	// Total equity
	let teSums = Array.from({ length: 9 }, (item, i) => i + 27);
	let teUSD = 0;
	let teKHR = 0;

	// Total liabilities
	let tlSums = Array.from({ length: 15 }, (item, i) => i + 39);
	let tlUSD = 0;
	let tlKHR = 0;

	let localCells = cells?.map((cols, i) => {
		if (readyOnlys?.includes(i)) {
			if (i === 24) {
				return [
					{ value: sumOrError([taUSD]), readOnly: true },
					{ value: sumOrError([taKHR]), readOnly: true },
				];
			} else if (i === 36) {
				return [
					{ value: sumOrError([teUSD]), readOnly: true },
					{ value: sumOrError([teKHR]), readOnly: true },
				];
			} else if (i === 54) {
				return [
					{ value: sumOrError([tlUSD]), readOnly: true },
					{ value: sumOrError([tlKHR]), readOnly: true },
				];
			} else if (i === 56) {
				return [
					{
						value: sumOrError([teUSD, tlUSD]),
						readOnly: true,
					},
					{
						value: sumOrError([teKHR, tlKHR]),
						readOnly: true,
					},
				];
			} else {
				return [
					{ value: '', readOnly: true },
					{ value: '', readOnly: true },
				];
			}
		} else {
			if (taSums?.includes(i)) {
				taUSD = taUSD + parseFloat(cols[0]?.value || 0);
				taKHR = taKHR + parseFloat(cols[1]?.value || 0);
			} else if (teSums?.includes(i)) {
				teUSD = teUSD + parseFloat(cols[0]?.value || 0);
				teKHR = teKHR + parseFloat(cols[1]?.value || 0);
			} else if (tlSums?.includes(i)) {
				tlUSD = tlUSD + parseFloat(cols[0]?.value || 0);
				tlKHR = tlKHR + parseFloat(cols[1]?.value || 0);
			}
			return [
				{ value: cols[0]?.value, readOnly },
				{ value: cols[1]?.value, readOnly },
			];
		}
	});
	localCells = localCells.slice(0, numLabel);
	return { code, cells: localCells };
}

function validateSFPCells({ cells }) {
	const localCells = cells?.reduce((iPre, iCur) => {
		let localIPre = iPre;
		localIPre.push(
			...iCur?.reduce((jPre, jCur) => {
				let localJPre = jPre;
				if (!jCur?.readOnly && !isNumeric(jCur?.value)) {
					localJPre.push(jCur);
				}
				return localJPre;
			}, [])
		);
		return localIPre;
	}, []);
	return localCells?.length === 0;
}

export { sfpNumColumn, cleanSFPCells, detailSFPCells, calculateSFPCells, validateSFPCells };
