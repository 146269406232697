/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { Box, Flex, Radio, RadioGroup, Stack, IconButton, Tooltip as Tip, Text, useToast, Center } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { Chart as ChartJS, CategoryScale, Tooltip, Title, Legend } from 'chart.js';
import { DownloadIcon } from '@chakra-ui/icons';
import { chartBackgroundColor } from '../../Miscellaneous/plugins-chart';
import { formatCurrency } from '../../../../utils/format';
import * as ChartGeo from 'chartjs-chart-geo';
import Chart from 'react-chartjs-2';
import Card from '../../../Card/Card';
import khm from '../../Miscellaneous/khm.topo.json';
import DataLoading from '../../../DataLoading';

const Q_DATASET = gql`
	query get($type: MDDataPolicyGrossPremiumEnumType!, $companyType: InsuranceInstitutionsType, $companyId: String, $range: MDDataDateRangeInput) {
		getDistributionChannelGrossPremiumNumPolicyByProvinceDataset(type: $type, companyType: $companyType, companyId: $companyId, range: $range)
	}
`;

ChartJS.register(Title, Tooltip, Legend, CategoryScale, ChartGeo.ChoroplethController, ChartGeo.ProjectionScale, ChartGeo.ColorScale, ChartGeo.GeoFeature);

function Graph({ companyType = null, companyId = null, range = null }) {
	const chartRef = useRef(null);
	const toast = useToast();
	const { t } = useTranslation();
	const [features, setFeatures] = useState(null);
	const [type, setType] = useState('Policy');
	const [getDataset, { data, error, loading }] = useLazyQuery(Q_DATASET, { fetchPolicy: 'no-cache' });

	useEffect(load, [companyType, companyId, range, type]);

	useEffect(() => {
		if (data) {
			let localFeatures = ChartGeo.topojson.feature(khm, khm.objects.khm).features || [];
			localFeatures = localFeatures?.reduce((iPre, iCur) => {
				let localIPre = iPre;
				let localICur = iCur;
				let localProperties = localICur?.properties;
				let province = data?.getDistributionChannelGrossPremiumNumPolicyByProvinceDataset?.find((el) => el?.code === localProperties?.code);
				if (province) {
					localProperties = { ...localProperties, nameKh: province?.name, sum: province?.sum, percentage: province?.percentage };
					localICur = { ...localICur, properties: localProperties };
					localIPre.push(localICur);
				}
				return localIPre;
			}, []);
			setFeatures(localFeatures?.length > 0 ? localFeatures : null);
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors?.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	function load() {
		const variables = { type, companyType, companyId, range: range?.from && range?.to ? range : null };
		getDataset({ variables });
	}

	return (
		<Box className={loading ? 'disableFormClear' : ''}>
			<Card>
				<Flex justifyContent="center" alignItems="center" flexDirection="column" p={4}>
					<Text fontSize="lg" fontWeight="bold" color="gray.600" noOfLines={2} textAlign={'center'} pl={4} pr={4}>
						{t('Gross Premium and Number of Policies by Province')}
					</Text>
				</Flex>
				<DataLoading loading={loading} loadedData={features}>
					{({ loadedData }) => (
						<>
							<Flex justifyContent="center" alignItems="center" flexDirection="column">
								<RadioGroup onChange={setType} value={type}>
									<Stack direction="row">
										<Text fontSize="sm">{t('Show')}</Text>
										<Radio size="sm" value="Policy">
											{t('Policy')}
										</Radio>
										<Radio size="sm" value="GrossPremium">
											{t('Gross Premium')}
										</Radio>
									</Stack>
								</RadioGroup>
							</Flex>
							<Box width="100%" p={4} pt={0}>
								<Center>
									<Chart
										ref={chartRef}
										type="choropleth"
										plugins={[chartBackgroundColor]}
										data={{
											labels: loadedData?.map((d) => d.properties.nameKh),
											datasets: [
												{
													outline: loadedData,
													label: 'Province/City',
													data: loadedData?.map((el) => ({
														feature: { ...el },
														value: el?.properties?.sum,
													})),
												},
											],
										}}
										options={{
											showOutline: false,
											showGraticule: false,
											canvasBackgroundColor: {
												color: '#FFFFFF',
											},
											layout: {
												padding: {
													top: 32,
													right: 16,
													bottom: 16,
													left: 8,
												},
											},
											plugins: {
												tooltip: {
													callbacks: {
														label: (context) => {
															return context.element.feature.properties.nameKh;
														},
														footer: (toolTipItems) => {
															let percent = 0;
															let number = 0;
															toolTipItems?.forEach((el) => {
																percent =
																	percent +
																	parseFloat(el?.dataset?.data[el?.dataIndex]?.feature?.properties?.percentage || 0);
																number = number + parseFloat(el?.dataset?.data[el?.dataIndex]?.feature?.properties?.sum || 0);
															});
															const titlePercent = t('Percentage') + ': ' + percent?.toFixed(2) + '%';
															const titlePolicy = t('Policy') + ': ' + number?.toFixed(0);
															const titleGrossPremium = t('Gross Premium') + ': ' + formatCurrency(number?.toFixed(2));
															return [titlePercent, type === 'Policy' ? titlePolicy : titleGrossPremium];
														},
													},
												},
												legend: {
													display: false,
												},
											},
											scales: {
												xy: {
													projection: 'mercator',
												},
												color: {
													interpolate: 'greens',
													quantize: 5,
													legend: {
														align: 'right',
														position: 'left',
													},
													ticks: {
														callback: function (value, index, values) {
															return type === 'Policy' ? value : formatCurrency(value);
														},
													},
												},
											},
										}}
									/>
								</Center>
							</Box>
							<Flex className="hidden-print" justifyContent="flex-end" alignItems="center" p={4}>
								<Tip label={t('Download Image')}>
									<IconButton
										size="sm"
										icon={<DownloadIcon w={3} h={3} />}
										onMouseDown={() => {
											const link = document.createElement('a');
											link.download = `graph-distribution-gross-premium-claim-paid-by-province.jpg`;
											link.href = chartRef?.current?.toBase64Image('image/jpeg', 1);
											link.click();
										}}
									/>
								</Tip>
							</Flex>
						</>
					)}
				</DataLoading>
			</Card>
		</Box>
	);
}

export default Graph;
