const distributionType = {
	numberPolicies: 'NumberPolicies',
	sumInsured: 'SumInsured',
	grossPremium: 'GrossPremium',
	numberRisks: 'NumberRisks',
	claimIncurred: 'ClaimIncurred',
	claimPaid: 'ClaimPaid',
	claimDenies: 'ClaimDenies',
	outstandingClaims: 'OutstandingClaims',
	surrenderPolicies: 'SurrenderPolicies',
};

const distributionValueType = {
	brokers: 'Brokers',
	individualAgent: 'IndividualAgent',
	corporateAgent: 'CorporateAgent',
	bancassurance: 'Bancassurance',
	digitalSale: 'DigitalSale',
	saleStaff: 'SaleStaff',
	numberRisks: 'NumberRisks',
	number: 'Number',
	amount: 'Amount',
};

export { distributionType, distributionValueType };
