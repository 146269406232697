/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Badge, Box, Button, Center, Container, Flex, TableContainer, Text, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faPrint } from '@fortawesome/free-solid-svg-icons';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import PrintLayout from '../../components/Print/PrintLayout';
import ReactPaginate from 'react-paginate';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import Filter from './components/filter';
import CompanyName from '../../components/CompanyName';
import LabelDate from '../../components/LabelDate';
import ReprotLayout from '../../components/Report/ReportLayout';
import c from '../../constant';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import useStore from '../../store';

const Q_STATEMENTS = gql`
	query get(
		$pagerInput: PagerInput!
		$companyId: String
		$companyType: InsuranceInstitutionsType
		$group: EarlyWarningGroupTypeStatement
		$type: EarlyWarningTypeStatement
		$year: Float
		$isEditing: Boolean
	) {
		getEarlyWarningStatements(
			pagerInput: $pagerInput
			companyId: $companyId
			companyType: $companyType
			group: $group
			type: $type
			year: $year
			isEditing: $isEditing
		) {
			list {
				id
				company {
					id
					name
					nameKhmer
					type
					logo
				}
				year
				type
				createdAt
				isEditing
				editingUpdatedAt
				editingUpdatedBy
				editingDueDate
				editingDescription
				notification {
					read
				}
			}
			totalRows
			totalPages
		}
	}
`;

const group = {
	quarterly_financial_statements: 'QuarterlyFinancialStatement',
	annually_unaudited_financial_statements: 'AnnuallyUnauditedFinancialStatements',
	annually_audited_financial_statements: 'AnnuallyAuditedFinancialStatements',
};

function EarlyWarningList() {
	const toast = useToast();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const page = urlParams.get('page') || 1;
	const companyId = urlParams.get('companyId') || null;
	const companyType = urlParams.get('companyType') || null;
	const type = urlParams.get('type') || null;
	const year = urlParams.get('year') || null;
	const editing = urlParams.has('editing', true);
	const period = urlParams.get('period') || null;
	const reportId = urlParams.get('reportId') || null;
	const { push } = useHistory();
	const { t } = useTranslation();
	const [fetchList, { loading: loadingGet, data: dataGet, error: errorGet }] = useLazyQuery(Q_STATEMENTS);
	const readReport = useStore((state) => state.readReport);

	useEffect(() => {
		load();
	}, [page, companyId, companyType, type, year, editing, period, reportId]);

	useEffect(() => {
		if (errorGet) {
			if (errorGet?.graphQLErrors?.length > 0) {
				errorGet?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorGet?.message ? errorGet?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorGet]);

	function load() {
		const limit = 10;
		const offset = parseInt(page ? (page - 1) * limit : 0) || 0;
		const variables = {
			pagerInput: { limit, offset },
			companyId,
			companyType,
			group: group[reportId],
			type: type,
			year: parseInt(year),
			isEditing: editing ? true : null,
		};
		fetchList({ variables });
	}

	function handlePageChanged({ selected }) {
		let url = `?page=${selected + 1}`;

		if (companyId !== null && companyId !== undefined) {
			url += `&companyId=${companyId}`;
		}

		if (companyType !== null && companyType !== undefined) {
			url += `&companyType=${companyType}`;
		}

		if (type !== null && type !== undefined) {
			url += `&type=${type}`;
		}

		if (year !== null && year !== undefined) {
			url += `&year=${year}`;
		}

		if (editing !== null && editing !== undefined) {
			url += `&editing=${editing}`;
		}

		push(`/early-warning-system${url}`);
	}

	return (
		<PrintLayout>
			<>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'Company Report',
								path: '/company-submission-report',
							},
							{
								name: period,
								path: '#',
							},
						]}
					/>
					<Center className="show-only-printing">
						<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
							{t('Early Warning System')}
						</Text>
					</Center>
					<Center mt="5">
						<ReprotLayout activeTab={period?.toLocaleLowerCase()} reportId={reportId}>
							<Box
								w="100%"
								bg="white"
								borderRadius={c.borderRadius}
								border="1px solid #dbdbdb"
								boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
								pb="32px"
							>
								<Flex
									className="hidden-print responsive-header-for-table"
									bg="#FAFAFA"
									p="16px"
									pt="8px"
									pb="8px"
									mb="16px"
									alignItems="center"
									justifyContent="space-between"
									borderBottom="1px solid #dbdbdb"
								>
									<Text fontSize="x-large" fontWeight="bold">
										{t('List')}
									</Text>
									<Flex>
										<Button
											mr="2"
											onClick={() => window?.print()}
											leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
											colorScheme="gray"
											variant="solid"
											size="sm"
											borderRadius={c.borderRadius}
											border="1px solid #bdc3c7"
										>
											{t('Print')}
										</Button>
										<Filter
											period={period}
											reportId={reportId}
											companyType={companyType}
											companyId={companyId}
											type={type}
											year={year}
											editing={editing}
											onCallback={(url) => push(`/early-warning-system${url}`)}
										/>
									</Flex>
								</Flex>
								<Box p="16px" pt="0">
									{loadingGet && (
										<Flex justifyContent="center" alignItems="center">
											<Text>{t('Loading')}</Text>
										</Flex>
									)}
									{!loadingGet && dataGet?.getEarlyWarningStatements?.list?.length <= 0 && (
										<Flex direction="column" alignItems="center" justifyContent="center" pt={16} pl={4} pr={4} pb={16}>
											<FontAwesomeIcon icon={faFileAlt} style={{ fontSize: 56, color: '#A0AEC0' }} />
											<Text fontSize="lg" fontWeight="bold" color="gray.600" mt={6}>
												{t('No Data')}
											</Text>
										</Flex>
									)}
									{!loadingGet && dataGet?.getEarlyWarningStatements?.list?.length > 0 && (
										<>
											<TableContainer>
												<Table variant="simple" className="table-company-proposal-list responsive-table-container">
													<Thead>
														<Tr className="td-as-th">
															<Th align="left">{t('COMPANY')}</Th>
															{period?.toLowerCase() === 'quarterly' && <Th>{t('Type')}</Th>}
															<Th align="left">{t('Year')}</Th>
															<Th align="left">{t('Created At')}</Th>
															<Th align="right">{t('Status')}</Th>
														</Tr>
													</Thead>
													<Tbody>
														{dataGet?.getEarlyWarningStatements?.list?.map((e, i) => {
															return (
																<Tr
																	key={`report-item-${i}`}
																	className={e?.notification?.read === false ? 'new' : 'read'}
																	onMouseDown={() => {
																		if (!e?.notification?.read) {
																			readReport(reportId);
																		}
																		push(
																			'/early-warning-system/detail?id=' +
																				e?.id +
																				'&period=' +
																				period +
																				'&reportId=' +
																				reportId
																		);
																	}}
																>
																	<Td align="left">
																		<CompanyName company={e?.company} />
																	</Td>
																	{period?.toLowerCase() === 'quarterly' && (
																		<Td align="left">
																			<Badge colorScheme="gray">{t(e?.type)}</Badge>
																		</Td>
																	)}
																	<Td align="left">
																		<LabelDate input={parseInt(e?.year)} inputFormat="Y" outputFormat="YYYY" />
																	</Td>
																	<Td align="left">
																		<LabelDate input={e?.createdAt} outputFormat={c.dateTimeFormat} />
																	</Td>
																	<Td align="right">
																		{e?.isEditing ? (
																			<Badge colorScheme="orange" variant="solid">
																				{t('Editing')}
																			</Badge>
																		) : (
																			<>-</>
																		)}
																	</Td>
																</Tr>
															);
														})}
													</Tbody>
													<tfoot className="responsive-footer hidden-print">
														<Tr className="td-as-th">
															<Th align="left">{t('Company')}</Th>
															{period?.toLowerCase() === 'quarterly' && <Th>{t('Type')}</Th>}
															<Th align="left">{t('Year')}</Th>
															<Th align="left">{t('Created At')}</Th>
															<Th align="right">{t('Status')}</Th>
														</Tr>
													</tfoot>
												</Table>
											</TableContainer>
											<Flex className="hidden-print" alignItems="flex-end" direction="column" mt={2} pl={6} pr={6}>
												<Text fontSize="sm" fontWeight="semibold" color="gray.600">
													{`${t('Total Rows')}: ${dataGet?.getEarlyWarningStatements?.totalRows}`}
												</Text>
											</Flex>
											{dataGet?.getEarlyWarningStatements?.totalPages > 1 && (
												<Flex className="hidden-print" justifyContent="flex-end" mt={8} pl={0} pr={0}>
													<ReactPaginate
														previousLabel={<ChevronLeftIcon fontSize="20px" />}
														nextLabel={<ChevronRightIcon fontSize="20px" />}
														breakLabel={'...'}
														breakClassName={'break-me'}
														disableInitialCallback={true}
														pageCount={dataGet?.getEarlyWarningStatements?.totalPages}
														marginPagesDisplayed={2}
														pageRangeDisplayed={3}
														onPageChange={handlePageChanged}
														containerClassName={'pagination'}
														activeClassName={'active'}
														initialPage={(page - 1) | 0}
													/>
												</Flex>
											)}
										</>
									)}
								</Box>
							</Box>
						</ReprotLayout>
					</Center>
				</Container>
			</>
		</PrintLayout>
	);
}

export default EarlyWarningList;
