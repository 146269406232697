import { useEffect, useState } from 'react';
import { Box, Flex, Tooltip, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { checkSelectedActiveColumn } from '../../../utils/market-development';
import { calculateIRCells } from '../../../utils/ews-ir-utils';
import Spreadsheet from 'react-spreadsheet';
import EWSLabels from './ews-template-labels';

export default function EWSSOCE({ readOnly = false, code = null, labels = [], cells = [], onChange = ({ code, cells }) => null }) {
	const { t } = useTranslation();
	const [dataCells, setDataCells] = useState([]);
	const [active, setActive] = useState(null);

	useEffect(() => setDataCells(cells), [cells]);

	return (
		<>
			<Box>
				<Text fontSize="large" fontWeight="bold" mb={4}>
					{t('Investment Report')}
				</Text>
				<Box className="ews-container">
					<div className="ews-2-row-head" />
					<table className="spreadsheet_custom_header">
						<thead>
							<tr style={{ height: '34px' }}>
								<td colSpan="3" className="ews-background-light-silver">
									{t('(In Million KHR)')}
								</td>
								<td rowSpan="2" className={checkSelectedActiveColumn(active, 3) ? 'active-column' : 'ews-background-light-silver'}>
									{t('USD')}
								</td>
								<Tooltip label={<>{t('Percentage of Invested Asset')}</>}>
									<td
										rowSpan="2"
										className={checkSelectedActiveColumn(active, 4) ? 'active-column singleLine' : 'ews-background-light-silver singleLine'}
									>
										{t('Percentage of Invested Asset')}
									</td>
								</Tooltip>
							</tr>
							<tr style={{ height: '34px' }}>
								<td className={checkSelectedActiveColumn(active, 0) ? 'active-column' : 'ews-background-light-silver'}>{t('KHR')}</td>
								<Tooltip label={<>{t('Foreign Currency')}</>}>
									<td
										className={checkSelectedActiveColumn(active, 1) ? 'active-column singleLine' : 'ews-background-light-silver singleLine'}
									>
										{t('Foreign Currency')}
									</td>
								</Tooltip>
								<td className={checkSelectedActiveColumn(active, 2) ? 'active-column' : 'ews-background-light-silver'}>{t('Total')}</td>
							</tr>
						</thead>
					</table>
					<div>
						<EWSLabels type="EWS" labels={labels} active={active} />
						<Box>
							<Flex alignItems="center" mt="0px" h="0px" borderRight="1px solid hsl(2deg, 0%, 91%)" pl={2} pr={2} />
							<Spreadsheet
								data={dataCells}
								onChange={(dataCells) => {
									if (readOnly) {
										onChange({
											code,
											cells: cells?.reduce((pre, cur, i) => {
												let localPre = pre;
												localPre.push([
													{ value: cur[0]?.value, readOnly: true },
													{ value: cur[1]?.value, readOnly: true },
													{ value: cur[2]?.value, readOnly: true },
													{ value: cur[3]?.value, readOnly: true },
													{ value: cur[4]?.value, readOnly: true },
												]);
												return localPre;
											}, []),
										});
									} else {
										onChange(calculateIRCells({ code, cells: dataCells, numLabel: labels?.length }));
									}
								}}
								onActivate={(e) => setActive({ ...e, t: 'EWS' })}
								hideRowIndicators
								hideColumnIndicators
							/>
						</Box>
					</div>
				</Box>
			</Box>
		</>
	);
}
