/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { fileName, excelSale } from '../../Miscellaneous/export';
import ReactExport from 'react-export-excel';
import c from '../../../../constant';
import useStore from '../../../../store';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ExportExcel({ month = null, year = null, numColumns = 0, saleItems = [], ...props }) {
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const { currentLang } = useStore((state) => state.currentLang);

	useEffect(() => {
		setData(
			saleItems.reduce((pre, cur) => {
				let localPre = pre;
				let localCur = excelData(cur?.saleReportType, cur?.saleProducts);
				localPre.push(localCur);
				return localPre;
			}, [])
		);
	}, [saleItems]);

	function excelData(saleReportType = null, saleProducts = []) {
		const inforces = excelSale({
			title: t('Renewal Policy'),
			saleProducts: saleProducts?.filter((el) => (el?.productLine?.type === 'Life') & (el?.businessType === 'Inforce')) || [],
			numColumns,
			total: t('Total'),
			lang: currentLang,
		});
		const news = excelSale({
			title: t('New Policy'),
			saleProducts: saleProducts?.filter((el) => (el?.productLine?.type === 'Life') & (el?.businessType === 'New')) || [],
			numColumns,
			total: t('Total'),
			lang: currentLang,
		});
		return { name: saleReportType, data: [...inforces, ...news] };
	}

	return (
		<>
			<Box {...props}>
				<ExcelFile
					filename={fileName('Sale', 'Life', month, year)}
					element={
						<>
							<Button
								isLoading={false}
								isDisabled={false}
								onClick={() => null}
								leftIcon={<FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 16 }} />}
								colorScheme="gray"
								variant="solid"
								size="sm"
								borderRadius={c.borderRadius}
								border="1px solid #bdc3c7"
							>
								{t('Export')}
							</Button>
						</>
					}
				>
					{data?.map((el, i) => {
						return (
							<ExcelSheet key={i} data={el?.data} name={t(el?.name)}>
								<ExcelColumn label={t('Ordinal')} value="0" />
								<ExcelColumn label={t('Product Line')} value="1" />
								<ExcelColumn
									label={
										currentLang === 'kh'
											? '> $5,000 (ចំនួនបណ្ណសន្យារ៉ាប់រង_បុព្វលាភបង់ផ្តាច់)'
											: '> $5000 (Number of Policies_Single Premium)'
									}
									value="2"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh'
											? '> $5,000 (ចំនួនបណ្ណសន្យារ៉ាប់រង_បុព្វលាភប្រចាំគ្រា)'
											: '> $5000 (Number of Policies_Regular Premium)'
									}
									value="3"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh'
											? '≤ $5,000 (ចំនួនបណ្ណសន្យារ៉ាប់រង_បុព្វលាភបង់ផ្តាច់)'
											: '≤ $5000 (Number of Policies_Single Premium)'
									}
									value="4"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh'
											? '≤ $5,000 (ចំនួនបណ្ណសន្យារ៉ាប់រង_បុព្វលាភប្រចាំគ្រា)'
											: '≤ $5000 (Number of Policies_Regular Premium)'
									}
									value="5"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? '(ចំនួនដែលត្រូវបានធានា_ស្រី > $5000)' : '(Number of Insured_Female > $5000)'}
									value="6"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? '(ចំនួនដែលត្រូវបានធានា_ប្រុស > $5000)' : '(Number of Insured_Male > $5000)'}
									value="7"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? '(ចំនួនដែលត្រូវបានធានា_ស្រី ≤ $5000)' : '(Number of Insured_Female ≤ $5000)'}
									value="8"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? '(ចំនួនដែលត្រូវបានធានា_ប្រុស ≤ $5000)' : '(Number of Insured_Male ≤ $5000)'}
									value="9"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? '(ទឹកប្រាក់ធានារ៉ាប់រង_បុព្វលាភបង់ផ្តាច់ > $5000)' : '(Sum Insured_Single Premium > $5000)'}
									value="10"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? '(ទឹកប្រាក់ធានារ៉ាប់រង_បុព្វលាភប្រចាំគ្រា > $5000)' : '(Sum Insured_Regular Premium > $5000)'}
									value="11"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? '(ទឹកប្រាក់ធានារ៉ាប់រង_បុព្វលាភបង់ផ្តាច់ ≤ $5000)' : '(Sum Insured_Single Premium ≤ $5000)'}
									value="12"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? '(ទឹកប្រាក់ធានារ៉ាប់រង_បុព្វលាភប្រចាំគ្រា ≤ $5000)' : '(Sum Insured_Regular Premium ≤ $5000)'}
									value="13"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh' ? '(បុព្វលាភធានារ៉ាប់រងដុល_បុព្វលាភបង់ផ្តាច់ > $5000)' : '(Gross Premium_Single Premium > $5000)'
									}
									value="14"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh' ? '(បុព្វលាភធានារ៉ាប់រងដុល_បុព្វលាភប្រចាំគ្រា > $5000)' : '(Gross Premium_Regular Premium > $5000)'
									}
									value="15"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh' ? '(បុព្វលាភធានារ៉ាប់រងដុល_បុព្វលាភបង់ផ្តាច់ ≤ $5000)' : '(Gross Premium_Single Premium ≤ $5000)'
									}
									value="16"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh' ? '(បុព្វលាភធានារ៉ាប់រងដុល_បុព្វលាភប្រចាំគ្រា ≤ $5000)' : '(Gross Premium_Regular Premium ≤ $5000)'
									}
									value="17"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh' ? '(បណ្ណសន្យារ៉ាប់រងបញ្ចប់ក្នុងរយ:ពេលសាកល្បង_ចំនួន > $5000)' : '(Flat Cancellation_Number > $5000)'
									}
									value="18"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh' ? '(បណ្ណសន្យារ៉ាប់រងបញ្ចប់ក្នុងរយ:ពេលសាកល្បង_ចំនួន ≤ $5000)' : '(Flat Cancellation_Number ≤ $5000)'
									}
									value="19"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh'
											? '(បណ្ណសន្យារ៉ាប់រងបញ្ចប់ក្នុងរយ:ពេលសាកល្បង_បុព្វលាភបង្វិលជូនអតិថិជន > $5000)'
											: '(Flat Cancellation_Refunded Gross Premium > $5000)'
									}
									value="20"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh'
											? '(បណ្ណសន្យារ៉ាប់រងបញ្ចប់ក្នុងរយ:ពេលសាកល្បង_បុព្វលាភបង្វិលជូនអតិថិជន ≤ $5000)'
											: '(Flat Cancellation_Refunded Gross Premium ≤ $5000)'
									}
									value="21"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? '(ចំនួនបណ្ណសន្យារ៉ាប់រងបោះបង់_ចំនួន > $5000)' : '(Surrender Policies_Number > $5000)'}
									value="22"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? '(ចំនួនបណ្ណសន្យារ៉ាប់រងបោះបង់_ចំនួន ≤ $5000)' : '(Surrender Policies_Number ≤ $5000)'}
									value="23"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh'
											? '(ចំនួនបណ្ណសន្យារ៉ាប់រងបោះបង់_បុព្វលាភបង្វិលជូនអតិថិជន > $5000)'
											: '(Surrender Policies_Refunded Gross Premium > $5000)'
									}
									value="24"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh'
											? '(ចំនួនបណ្ណសន្យារ៉ាប់រងបោះបង់_បុព្វលាភបង្វិលជូនអតិថិជន ≤ $5000)'
											: '(Surrender Policies_Refunded Gross Premium ≤ $5000)'
									}
									value="25"
								/>
							</ExcelSheet>
						);
					})}
				</ExcelFile>
			</Box>
		</>
	);
}
