/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Flex, SimpleGrid, Text, useToast, useMediaQuery, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { formatNumber, formatCurrency } from '../../../../utils/format';
import DataLoading from '../../../DataLoading';

const Q_DASHBOARD = gql`
	query get($companyType: InsuranceInstitutionsType, $companyId: String, $range: MDDataDateRangeInput) {
		getSaleDashboard(companyType: $companyType, companyId: $companyId, range: $range)
	}
`;

function Sidebar({ companyType = null, companyId = null, range = null }) {
	const toast = useToast();
	const { t } = useTranslation();
	const [getDashboard, { loading, data, error }] = useLazyQuery(Q_DASHBOARD, { fetchPolicy: 'no-cache' });
	const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)');
	const [isSmallerThan700] = useMediaQuery('(max-width: 700px)');

	useEffect(load, [companyType, companyId, range]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	function load() {
		const variables = { companyType, companyId, range: range?.from && range?.to ? range : null };
		getDashboard({ variables });
	}

	return (
		<>
			<DataLoading loading={loading} loadedData={data?.getSaleDashboard}>
				{({ loadedData }) => (
					<SimpleGrid w="100%" overflowX="scroll" columns={[1, 1, 2, 5]} spacing={5}>
						<Flex width="100%" direction="column">
							<Tooltip label={<>{t('Gross Premium Amount (New)')}</>}>
								<Text fontSize={['sm', '10px', 'sm']} fontWeight="semibold" textAlign={['center', 'center', 'left']} textColor="gray.500">
									{t('Gross Premium Amount (New)')}
								</Text>
							</Tooltip>
							<Text
								fontSize={['2xl', '2xl', '3xl']}
								fontWeight="bold"
								textAlign={['center', 'center', 'left']}
								color={loadedData?.newGrossPremiumAmount?.color}
							>
								{formatCurrency(loadedData?.newGrossPremiumAmount?.value)}
							</Text>
						</Flex>
						<Flex width="100%" direction="column">
							<Tooltip label={<>{t('Gross Premium Amount (Renewal)')}</>}>
								<Text fontSize={['sm', '10px', 'sm']} fontWeight="semibold" textAlign={['center', 'center', 'left']} textColor="gray.500">
									{t('Gross Premium Amount (Renewal)')}
								</Text>
							</Tooltip>
							<Text
								fontSize={['2xl', '2xl', '3xl']}
								fontWeight="bold"
								textAlign={['center', 'center', 'left']}
								color={loadedData?.renewalGrossPremiumAmount?.color}
							>
								{formatCurrency(loadedData?.renewalGrossPremiumAmount?.value)}
							</Text>
						</Flex>
						<Flex width="100%" direction="column">
							<Tooltip label={<>{t('Sum Insured Amount (New)')}</>}>
								<Text fontSize={['sm', '10px', 'sm']} fontWeight="semibold" textAlign={['center', 'center', 'left']} textColor="gray.500">
									{t('Sum Insured Amount (New)')}
								</Text>
							</Tooltip>
							<Text
								fontSize={['2xl', '2xl', '3xl']}
								fontWeight="bold"
								textAlign={['center', 'center', 'left']}
								color={loadedData?.newSumInsuredAmount?.color}
							>
								{formatCurrency(loadedData?.newSumInsuredAmount?.value)}
							</Text>
						</Flex>
						<Flex width="100%" direction="column">
							<Tooltip label={<>{t('Sum Insured Amount (Renewal)')}</>}>
								<Text fontSize={['sm', '10px', 'sm']} fontWeight="semibold" textAlign={['center', 'center', 'left']} textColor="gray.500">
									{t('Sum Insured Amount (Renewal)')}
								</Text>
							</Tooltip>
							<Text
								fontSize={['2xl', '2xl', '3xl']}
								fontWeight="bold"
								textAlign={['center', 'center', 'left']}
								color={loadedData?.renewalSumInsuredAmount?.color}
							>
								{formatCurrency(loadedData?.renewalSumInsuredAmount?.value)}
							</Text>
						</Flex>
						<Flex width={isLargerThan1280 ? '70%' : isSmallerThan700 ? '100%' : '100%'} direction="column">
							<Tooltip label={<>{t('Number of Policies (New)')}</>}>
								<Text fontSize={['sm', '10px', 'sm']} fontWeight="semibold" textAlign={['center', 'center', 'left']} textColor="gray.500">
									{t('Number of Policies (New)')}
								</Text>
							</Tooltip>
							<Text
								fontSize={['2xl', '2xl', '3xl']}
								fontWeight="bold"
								textAlign={['center', 'center', 'left']}
								color={loadedData?.newNumberOfPolicies?.color}
							>
								{formatNumber(loadedData?.newNumberOfPolicies?.value)}
							</Text>
						</Flex>
						<Flex width="100%" direction="column">
							<Tooltip label={<>{t('Number of Policies (Renewal)')}</>}>
								<Text fontSize={['sm', '10px', 'sm']} fontWeight="semibold" textAlign={['center', 'center', 'left']} textColor="gray.500">
									{t('Number of Policies (Renewal)')}
								</Text>
							</Tooltip>
							<Text
								fontSize={['2xl', '2xl', '3xl']}
								fontWeight="bold"
								textAlign={['center', 'center', 'left']}
								color={loadedData?.renewalNumberOfPolicies?.color}
							>
								{formatNumber(loadedData?.renewalNumberOfPolicies?.value)}
							</Text>
						</Flex>
						<Flex width="100%" direction="column">
							<Tooltip label={<>{t('Number of Insured Person (Female)')}</>}>
								<Text fontSize={['sm', '10px', 'sm']} fontWeight="semibold" textAlign={['center', 'center', 'left']} textColor="gray.500">
									{t('Number of Insured Person (Female)')}
								</Text>
							</Tooltip>
							<Text
								fontSize={['2xl', '2xl', '3xl']}
								fontWeight="bold"
								textAlign={['center', 'center', 'left']}
								color={loadedData?.numberOfInsuredPersonFemale?.color}
							>
								{formatNumber(loadedData?.numberOfInsuredPersonFemale?.value)}
							</Text>
						</Flex>
						<Flex width="100%" direction="column">
							<Tooltip label={<>{t('Number of Insured Person (Male)')}</>}>
								<Text fontSize={['sm', '10px', 'sm']} fontWeight="semibold" textAlign={['center', 'center', 'left']} textColor="gray.500">
									{t('Number of Insured Person (Male)')}
								</Text>
							</Tooltip>
							<Text
								fontSize={['2xl', '2xl', '3xl']}
								fontWeight="bold"
								textAlign={['center', 'center', 'left']}
								color={loadedData?.numberOfInsuredPersonMale?.color}
							>
								{formatNumber(loadedData?.numberOfInsuredPersonMale?.value)}
							</Text>
						</Flex>
						<Flex width={isLargerThan1280 ? '90%' : isSmallerThan700 ? '100%' : '100%'} direction="column">
							<Tooltip label={<>{t('Number of Cancellation (Life)')}</>}>
								<Text
									fontSize={['sm', '10px', 'sm']}
									noOfLines={2}
									fontWeight="semibold"
									textAlign={['center', 'center', 'left']}
									textColor="gray.500"
								>
									{t('Number of Cancellation (Life)')}
								</Text>
							</Tooltip>
							<Text
								fontSize={['2xl', '2xl', '3xl']}
								fontWeight="bold"
								textAlign={['center', 'center', 'left']}
								color={loadedData?.numberOfCancellationFlat?.color}
							>
								{formatNumber(loadedData?.numberOfCancellationFlat?.value)}
							</Text>
						</Flex>
						<Flex width={isLargerThan1280 ? '75%' : isSmallerThan700 ? '100%' : ''} direction="column">
							<Tooltip label={<>{t('Surrender Policies')}</>}>
								<Text fontSize={['sm', '10px', 'sm']} fontWeight="semibold" textAlign={['center', 'center', 'left']} textColor="gray.500">
									{t('Surrender Policies')}
								</Text>
							</Tooltip>
							<Text
								fontSize={['2xl', '2xl', '3xl']}
								fontWeight="bold"
								textAlign={['center', 'center', 'left']}
								color={loadedData?.numberOfCancellationSurrender?.color}
							>
								{formatNumber(loadedData?.numberOfCancellationSurrender?.value)}
							</Text>
						</Flex>
					</SimpleGrid>
				)}
			</DataLoading>
		</>
	);
}

export default Sidebar;
