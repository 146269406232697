import { Text, Flex } from '@chakra-ui/react';
import { isNumeric } from '../../../utils/market-development';
import { useTranslation } from 'react-i18next';

export default function SpreadSheetTotal({ data = [], numColumn = 0 }) {
	const { t } = useTranslation();

	function totalByIndex(tmpData, x) {
		let count = 0;
		let total = null;
		let isError = false;
		tmpData?.forEach((item) => {
			if (item[x]?.value) {
				if (isNumeric(item[x]?.value)) {
					total = parseFloat(total || 0) + parseFloat(item[x]?.value || 0);
					count = count + 1;
				} else {
					isError = true;
				}
			} else if (item[x]?.value === 0 || item[x]?.value === '0') {
				count = count + 1;
			}
		});
		if (isError) {
			return (
				<Flex alignItems="center" justifyContent="flex-end" w="100%" h="30px">
					<Text mr="4px" mt="0px" color="#c0392b" fontSize="18px">
						!Error
					</Text>
				</Flex>
			);
		} else {
			return (
				<Flex alignItems="center" h="30px" justifyContent="flex-end">
					<Text color="#27ae60" mr="4px" mt="0px" noOfLines={1}>
						{total || (count === tmpData?.length ? '0' : '-')}
					</Text>
				</Flex>
			);
		}
	}

	return (
		<>
			<div className="total-cell">
				<Text ml="4px" color="#27ae60" pl={2} pr={2}>
					{t('Total')}
				</Text>
			</div>
			<table className="footer-table">
				<tfoot>
					<tr>
						{[...Array(numColumn).keys()].map((item) => (
							<td style={{ textAlign: 'right' }} key={`${item}-tt`}>
								{totalByIndex(data, item)}
							</td>
						))}
					</tr>
				</tfoot>
			</table>
		</>
	);
}
