const formatNumber = (value) => {
	if (value) {
		return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
	}
	return '0';
};

const formatCurrency = (value, currency = '$') => {
	if (value) {
		return currency + value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
	}
	return currency + '0';
};

const formatPercentage = (value) => {
	if (value) {
		return value.toString() + '%';
	}
	return null;
};

export { formatNumber, formatCurrency, formatPercentage };
