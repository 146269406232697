import React from 'react';
import { FormControl, Box, FormLabel, FormHelperText, Select } from '@chakra-ui/react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import c from '../../constant';

const SelectValueFormField = ({ disabled = false, name = null, label = null, options = [], required = false, placeholder = null }) => {
	const { t } = useTranslation('common');

	return (
		<Field name={name}>
			{({ field, meta, form }) => {
				return (
					<Box mb="4" overflow="hidden">
						<FormControl id={name}>
							{label && (
								<FormLabel>
									{t(label)}
									{required && <span style={{ color: 'red' }}>*</span>}
								</FormLabel>
							)}
							<Select
								h={12}
								value={field?.value}
								onChange={(e) => form.setFieldValue(name, e.target.value)}
								placeholder={placeholder}
								borderRadius={c.borderRadius}
								disabled={disabled}
							>
								{options?.map((item, index) => {
									return (
										<option key={`option-item-${index}`} value={item?.value}>
											{t(item?.label)}
										</option>
									);
								})}
							</Select>
							{meta.touched && meta.error && (
								<FormHelperText id="error-message-password" color="red.400" fontSize="12px">
									{t(label)} {t('is')} {t(meta.error)}
								</FormHelperText>
							)}
						</FormControl>
					</Box>
				);
			}}
		</Field>
	);
};

export default SelectValueFormField;
