import React from 'react';
import { Badge, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function CompanyBadge({ type }) {
	const { t } = useTranslation();
	switch (type) {
		case 'ACTUARIAL_FIRM':
			return <Badge colorScheme="teal">{t('INSURANCE ACTUARIAL FIRM')}</Badge>;
		case 'INSURANCE_CLAIM_MANAGEMENT':
			return (
				<Tooltip label={t(type)}>
					<Badge colorScheme="blue">{t(type)}</Badge>
				</Tooltip>
			);
		case 'AUDIT_SERVICE':
			return (
				<Tooltip label={t(type)}>
					<Badge colorScheme="cyan">{t(type)}</Badge>
				</Tooltip>
			);
		case 'ASSOCIATION':
			return (
				<Tooltip label={t(type)}>
					<Badge colorScheme="messenger">{t(type)}</Badge>
				</Tooltip>
			);
		case 'GENERAL_INSURANCE':
			return (
				<Tooltip label={t(type)}>
					<Badge colorScheme="orange">{t(type)}</Badge>
				</Tooltip>
			);
		case 'LIFE_INSURANCE':
			return (
				<Tooltip label={t(type)}>
					<Badge colorScheme="green">{t(type)}</Badge>
				</Tooltip>
			);
		case 'MICRO_INSURANCE':
			return (
				<Tooltip label={t(type)}>
					<Badge colorScheme="yellow">{t(type)}</Badge>
				</Tooltip>
			);
		case 'REINSURANCE':
			return (
				<Tooltip label={t(type)}>
					<Badge colorScheme="teal">{t(type)}</Badge>
				</Tooltip>
			);
		case 'INSURANCE_BROKER':
			return (
				<Tooltip label={t(type)}>
					<Badge colorScheme="purple">{t(type)}</Badge>
				</Tooltip>
			);
		case 'INSURANCE_AGENT':
			return (
				<Tooltip label={t(type)}>
					<Badge colorScheme="gray">{t(type)}</Badge>
				</Tooltip>
			);
		case 'INSURANCE_LOSS_ADJUSTER':
			return (
				<Tooltip label={t(type)}>
					<Badge colorScheme="red">{t(type)}</Badge>
				</Tooltip>
			);
		default:
			return null;
	}
}
export default CompanyBadge;
