/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, Flex, Text, Table, Tbody, Tr, Th, Td, Tfoot, Badge, Center } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { SectionHeader } from '../../Other';
import useStore from '../../../store';
import moment from 'moment';
import c from '../../../constant';

function ReportPrint({ data, ...props }) {
	const { t } = useTranslation();
	const { currentLang } = useStore((state) => state.currentLang);

	function getCompanyName(item) {
		let displayName = item?.companyName;
		if (item?.company?.id) {
			if (currentLang === 'kh') {
				displayName = item?.company?.nameKhmer;
			} else {
				displayName = item?.company?.name;
			}
		}
		return displayName;
	}

	function reportTypeOfReports(type, newTypeDetail) {
		switch (type) {
			case 'FINANCIAL_REPORT':
				return <Badge colorScheme="green">{t('Financial Report')}</Badge>;
			case 'COMPLAINT_SETTLEMENT_REPORT':
				return <Badge colorScheme="yellow">{t('Complaint Settlement Report')}</Badge>;
			case 'INSURANCE_AGENT_REPORT':
				return <Badge colorScheme="purple">{t('Insurance Agent Report')}</Badge>;
			case 'NEW_TYPE':
				return <Badge colorScheme="gray">{currentLang === 'kh' ? newTypeDetail?.titleKh : newTypeDetail?.title}</Badge>;
			default:
				break;
		}
	}

	function reportTypeBadge(type) {
		switch (type) {
			case 'MONTHLY':
				return <Badge colorScheme="green">{t('MONTHLY')}</Badge>;
			case 'QUARTERLY':
				return <Badge colorScheme="yellow">{t('QUARTERLY')}</Badge>;
			case 'SEMESTERLY':
				return <Badge colorScheme="purple">{t('SEMESTERLY')}</Badge>;
			case 'ANNUALLY':
				return <Badge colorScheme="red">{t('ANNUALLY')}</Badge>;
			default:
				return null;
		}
	}

	return (
		<>
			<Flex className="show-only-printing" mt={4} {...props}>
				<Box width="100%">
					<Center>
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb={2}
						>
							<Flex
								h="48px"
								bg="#FAFAFA"
								pl="16px"
								pr="16px"
								mb="16px"
								alignItems="center"
								justifyContent="space-between"
								borderBottom="1px solid #dbdbdb"
							>
								<SectionHeader title={t('Report')} fontSize="lg" />
							</Flex>
							<Box p="16px">
								<Table variant="simple">
									<Tbody>
										<Tr className="td-as-th">
											<Th>{t('Company Name')}</Th>
											<Th>{t('Created At')}</Th>
											<Th isNumeric>{t('Report Type')}</Th>
											<Th isNumeric>{t('Type')}</Th>
										</Tr>
										{data?.getCompanySubmissionReportsByCompanyId?.companySubmissionReports.length <= 0 && (
											<Tr>
												<Td>
													<Text>{t('No Data')}</Text>
												</Td>
											</Tr>
										)}
										{data?.getCompanySubmissionReportsByCompanyId?.companySubmissionReports?.map((item, index) => {
											return (
												<Tr
													cursor="pointer"
													_hover={{ bg: 'gray.200' }}
													key={`item-${index}`}
													bg={item?.notification?.read === false ? 'gray.100' : 'white'}
												>
													<Td fontWeight="500">
														<Text maxW="400px" noOfLines={4}>
															{getCompanyName(item)}
														</Text>
													</Td>
													<Td>{moment(item.createdAt).format('LLLL')}</Td>
													<Td isNumeric>{reportTypeOfReports(item.reportType, item?.newTypeDetail)}</Td>
													<Td isNumeric>
														{reportTypeBadge(item.type)} {item.spam === true && <Badge colorScheme="red">{t('Spam')}</Badge>}
													</Td>
												</Tr>
											);
										})}
									</Tbody>
								</Table>
							</Box>
						</Box>
					</Center>
				</Box>
			</Flex>
		</>
	);
}

export default ReportPrint;
