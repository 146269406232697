/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Container,
	Box,
	Flex,
	Text,
	Center,
	Button,
	useDisclosure,
	AlertDialog,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogFooter,
	AlertDialogOverlay,
	AlertDialogBody,
	useToast,
} from '@chakra-ui/react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent/BreadcrumbComponent';
import NewProductLine from '../../../../components/MarketDevelopment/ProductLine/new';
import EditProductLine from '../../../../components/MarketDevelopment/ProductLine/edit';
import LoadingResponsive from '../../../../components/Table/LoadingResponsive';
import useMoment from '../../../../hooks/useMoment';
import c from '../../../../constant';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const Q_PRODUCT_LINES = gql`
	query get($pagerInput: PagerInput!) {
		getProductLines(pagerInput: $pagerInput) {
			list {
				id
				nameKh
				nameEn
				createdAt
				type
				hint
				hideForMicro
			}
			totalPages
			totalRows
		}
	}
`;

const M_DELETE = gql`
	mutation delete($id: String!) {
		deleteProductLine(id: $id)
	}
`;

let workingId = null;

function ProductLine() {
	const toast = useToast();
	const { t } = useTranslation();
	const { toDateString } = useMoment();
	const { isOpen: isCreating, onOpen: openCreating, onClose: closeCreating } = useDisclosure();
	const { isOpen: isEditing, onOpen: openEditing, onClose: closeEditing } = useDisclosure();
	const { isOpen: isDeleting, onOpen: openDeleting, onClose: closeDeleting } = useDisclosure();
	const [getProductLines, { data: dataGet, loading: loadingGet }] = useLazyQuery(Q_PRODUCT_LINES);
	const [deleteProductLine, { data: dataDelete, loading: loadingDelete }] = useMutation(M_DELETE);

	useEffect(() => {
		getProductLines({ variables: { pagerInput: { offset: 0, limit: 300 } } });
	}, []);

	useEffect(() => {
		if (dataDelete) {
			toast({
				title: t('All tasks are completed'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			closeDeleting();
			getProductLines({ variables: { pagerInput: { offset: 0, limit: 300 } } });
		}
	}, [dataDelete]);

	const onDelete = () => {
		const variables = { id: workingId };
		deleteProductLine({ variables });
	};

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'Settings',
							path: '/settings',
						},
						{
							name: 'Market Development Data',
							path: '/settings/market-development-data',
						},
						{
							name: 'Product Line',
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex
							alignItems="center"
							justifyContent="space-between"
							bg="#FAFAFA"
							p="16px"
							pt="8px"
							pb="8px"
							mb="16px"
							borderBottom="1px solid #dbdbdb"
						>
							<Flex justifyContent="center" alignItems="center">
								<Text fontSize="x-large" fontWeight="bold" mr="4">
									{t('Product Line')}
								</Text>
							</Flex>
							<Flex className="header-menu" justifyContent="flex-end" alignItems="center">
								<Button
									mr="0"
									onClick={openCreating}
									leftIcon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: 16 }} />}
									colorScheme="gray"
									variant="solid"
									size="sm"
									borderRadius={c.borderRadius}
									border="1px solid #bdc3c7"
								>
									{t('Add')}
								</Button>
							</Flex>
						</Flex>
						<Box p="16px" pt="0">
							<Table variant="simple" className="table-company-proposal-list responsive-table-container">
								<Thead>
									<Tr className="td-as-th">
										<Th align="left">{t('Khmer Name')}</Th>
										<Th align="left">{t('English Name')}</Th>
										<Th align="left">{t('Created At')}</Th>
										<Th align="left">{t('Insurance Business Type')}</Th>
										<Th align="left">{t('Hint')}</Th>
										<Th align="left">{t('Hide For Micro')}</Th>
										<Th align="right">{t('Actions')}</Th>
									</Tr>
								</Thead>
								<Tbody>
									{loadingGet && (
										<>
											<LoadingResponsive column={5} row={5} />
										</>
									)}
									{!loadingGet && dataGet?.getProductLines?.list?.length <= 0 && (
										<>
											<Tr>
												<Td>
													<Text>{t('No Data')}</Text>
												</Td>
												<Td />
												<Td />
												<Td />
											</Tr>
										</>
									)}
									{!loadingGet && dataGet?.getProductLines?.list?.length > 0 && (
										<>
											{dataGet?.getProductLines?.list?.map((e, i) => (
												<Tr key={`row-item-${i}`}>
													<Td align="left">
														<Text>{e?.nameKh}</Text>
													</Td>
													<Td align="left">
														<Text>{e?.nameEn}</Text>
													</Td>
													<Td align="left">
														<Text>{toDateString(e?.createdAt, c.longDateFormat)}</Text>
													</Td>
													<Td align="left">
														<Text>{t(e?.type)}</Text>
													</Td>
													<Td align="left">
														<Text>{e?.hint || 'N/A'}</Text>
													</Td>
													<Td align="left">
														<Text fontWeight="semibold">{e?.hideForMicro ? '1' : '0'}</Text>
													</Td>
													<Td align="right">
														<Flex justifyContent="flex-end">
															<Flex
																_hover={{ bg: 'gray.300' }}
																justifyContent="center"
																alignItems="center"
																w={6}
																h={6}
																mr={2}
																bg="gray.100"
																borderRadius={8}
																onClick={() => {
																	workingId = e?.id;
																	openEditing();
																}}
															>
																<FontAwesomeIcon
																	icon={faEdit}
																	style={{
																		fontSize: 10,
																		color: '#3182CE',
																	}}
																/>
															</Flex>
															<Flex
																_hover={{ bg: 'gray.300' }}
																justifyContent="center"
																alignItems="center"
																w={6}
																h={6}
																mr={0}
																bg="gray.100"
																borderRadius={8}
																onClick={() => {
																	workingId = e?.id;
																	openDeleting();
																}}
															>
																<FontAwesomeIcon
																	icon={faTrash}
																	style={{
																		fontSize: 10,
																		color: '#E53E3E',
																	}}
																/>
															</Flex>
														</Flex>
													</Td>
												</Tr>
											))}
										</>
									)}
								</Tbody>
								<tfoot className="responsive-footer">
									<Tr className="td-as-th">
										<Th align="left">{t('Khmer Name')}</Th>
										<Th align="left">{t('English Name')}</Th>
										<Th align="left">{t('Created At')}</Th>
										<Th align="left">{t('Insurance Business Type')}</Th>
										<Th align="left">{t('Hint')}</Th>
										<Th align="left">{t('Hide For Micro')}</Th>
										<Th align="right">{t('Actions')}</Th>
									</Tr>
								</tfoot>
							</Table>
						</Box>
					</Box>
				</Center>
			</Container>
			<AlertDialog isOpen={isDeleting} onClose={closeDeleting}>
				<AlertDialogOverlay>
					<AlertDialogContent borderRadius={c.borderRadius}>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Delete Product Line')}
						</AlertDialogHeader>
						<AlertDialogBody>{t("Are you sure? You can't undo this action afterwards")}</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={3} isLoading={loadingDelete} borderRadius={c.borderRadius} colorScheme="gray" onClick={closeDeleting}>
								{t('Cancel')}
							</Button>
							<Button mr={0} isLoading={loadingDelete} borderRadius={c.borderRadius} colorScheme="red" onClick={onDelete}>
								{t('Delete')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			{isCreating && (
				<>
					<NewProductLine
						isOpen={isCreating}
						onClose={closeCreating}
						callback={() => getProductLines({ variables: { pagerInput: { offset: 0, limit: 300 } } })}
					/>
				</>
			)}
			{isEditing && (
				<>
					<EditProductLine
						id={workingId}
						isOpen={isEditing}
						onClose={closeEditing}
						callback={() => getProductLines({ variables: { pagerInput: { offset: 0, limit: 300 } } })}
					/>
				</>
			)}
		</>
	);
}

export default ProductLine;
