import { EWSSoceTypeColumn, EWSTypeCurrency, sumOrError } from './ews';
import { isNumeric } from './market-development';

const soceNumColumn = 14;

function cleanSOCECells(labels) {
	const cells = labels?.map((label, i) => {
		if (i === 7) {
			return [
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
			];
		} else {
			return [
				{ value: '', readOnly: false },
				{ value: '', readOnly: false },
				{ value: '', readOnly: false },
				{ value: '', readOnly: false },
				{ value: '', readOnly: false },
				{ value: '', readOnly: false },
				{ value: '', readOnly: false },
				{ value: '', readOnly: false },
				{ value: '', readOnly: false },
				{ value: '', readOnly: false },
				{ value: '', readOnly: false },
				{ value: '', readOnly: false },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
			];
		}
	});
	return cells;
}

function detailSOCECells({ code = null, labels = [], detailLabels = [], readOnly = false }) {
	const localCells = labels?.map((label, i) => {
		if (i === 7) {
			return [
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
			];
		} else {
			const detailLabel = detailLabels?.find((el) => el?.templateLabel?.code === label?.code);
			if (detailLabel) {
				return [
					{
						value:
							detailLabel?.values?.find((value) => value?.currency === EWSTypeCurrency.USD && value?.soceCol === EWSSoceTypeColumn.ShareCapital)
								?.value || 0,
						readOnly,
					},
					{
						value:
							detailLabel?.values?.find((value) => value?.currency === EWSTypeCurrency.KHR && value?.soceCol === EWSSoceTypeColumn.ShareCapital)
								?.value || 0,
						readOnly,
					},
					{
						value:
							detailLabel?.values?.find((value) => value?.currency === EWSTypeCurrency.USD && value?.soceCol === EWSSoceTypeColumn.SharePremium)
								?.value || 0,
						readOnly,
					},
					{
						value:
							detailLabel?.values?.find((value) => value?.currency === EWSTypeCurrency.KHR && value?.soceCol === EWSSoceTypeColumn.SharePremium)
								?.value || 0,
						readOnly,
					},
					{
						value:
							detailLabel?.values?.find(
								(value) => value?.currency === EWSTypeCurrency.USD && value?.soceCol === EWSSoceTypeColumn.DepositsForFuterShareSubscription
							)?.value || 0,
						readOnly,
					},
					{
						value:
							detailLabel?.values?.find(
								(value) => value?.currency === EWSTypeCurrency.KHR && value?.soceCol === EWSSoceTypeColumn.DepositsForFuterShareSubscription
							)?.value || 0,
						readOnly,
					},
					{
						value:
							detailLabel?.values?.find(
								(value) => value?.currency === EWSTypeCurrency.USD && value?.soceCol === EWSSoceTypeColumn.RevaluationReserves
							)?.value || 0,
						readOnly,
					},
					{
						value:
							detailLabel?.values?.find(
								(value) => value?.currency === EWSTypeCurrency.KHR && value?.soceCol === EWSSoceTypeColumn.RevaluationReserves
							)?.value || 0,
						readOnly,
					},
					{
						value:
							detailLabel?.values?.find(
								(value) => value?.currency === EWSTypeCurrency.USD && value?.soceCol === EWSSoceTypeColumn.RetainedEarningsAccumulatedLoses
							)?.value || 0,
						readOnly,
					},
					{
						value:
							detailLabel?.values?.find(
								(value) => value?.currency === EWSTypeCurrency.KHR && value?.soceCol === EWSSoceTypeColumn.RetainedEarningsAccumulatedLoses
							)?.value || 0,
						readOnly,
					},
					{
						value:
							detailLabel?.values?.find(
								(value) => value?.currency === EWSTypeCurrency.USD && value?.soceCol === EWSSoceTypeColumn.OtherComponentsOfEquity
							)?.value || 0,
						readOnly,
					},
					{
						value:
							detailLabel?.values?.find(
								(value) => value?.currency === EWSTypeCurrency.KHR && value?.soceCol === EWSSoceTypeColumn.OtherComponentsOfEquity
							)?.value || 0,
						readOnly,
					},
					{ value: '0', readOnly: true },
					{ value: '0', readOnly: true },
				];
			} else {
				return [
					{ value: '', readOnly },
					{ value: '', readOnly },
					{ value: '', readOnly },
					{ value: '', readOnly },
					{ value: '', readOnly },
					{ value: '', readOnly },
					{ value: '', readOnly },
					{ value: '', readOnly },
					{ value: '', readOnly },
					{ value: '', readOnly },
					{ value: '', readOnly },
					{ value: '', readOnly },
					{ value: '0', readOnly: true },
					{ value: '0', readOnly: true },
				];
			}
		}
	});

	const { cells } = calculateSOCECells({ code, cells: localCells, numLabel: labels?.length, readOnly });

	return cells;
}

function calculateSOCECells({ code, cells, numLabel, readOnly = false }) {
	let tebescUSD = 0;
	let tebescKHR = 0;
	let tebespUSD = 0;
	let tebespKHR = 0;
	let tebedffsUSD = 0;
	let tebedffsKHR = 0;
	let teberrUSD = 0;
	let teberrKHR = 0;
	let teberealUSD = 0;
	let teberealKHR = 0;
	let tebeoceUSD = 0;
	let tebeoceKHR = 0;

	let localCells = cells?.map((cols, i) => {
		if (i === 7) {
			return [
				{ value: sumOrError([tebescUSD]), readOnly: true },
				{ value: sumOrError([tebescKHR]), readOnly: true },
				{ value: sumOrError([tebespUSD]), readOnly: true },
				{ value: sumOrError([tebespKHR]), readOnly: true },
				{ value: sumOrError([tebedffsUSD]), readOnly: true },
				{ value: sumOrError([tebedffsKHR]), readOnly: true },
				{ value: sumOrError([teberrUSD]), readOnly: true },
				{ value: sumOrError([teberrKHR]), readOnly: true },
				{ value: sumOrError([teberealUSD]), readOnly: true },
				{ value: sumOrError([teberealKHR]), readOnly: true },
				{ value: sumOrError([tebeoceUSD]), readOnly: true },
				{ value: sumOrError([tebeoceKHR]), readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
			];
		} else {
			tebescUSD = tebescUSD + parseFloat(cols[0]?.value || 0);
			tebescKHR = tebescKHR + parseFloat(cols[1]?.value || 0);
			tebespUSD = tebespUSD + parseFloat(cols[2]?.value || 0);
			tebespKHR = tebespKHR + parseFloat(cols[3]?.value || 0);
			tebedffsUSD = tebedffsUSD + parseFloat(cols[4]?.value || 0);
			tebedffsKHR = tebedffsKHR + parseFloat(cols[5]?.value || 0);
			teberrUSD = teberrUSD + parseFloat(cols[6]?.value || 0);
			teberrKHR = teberrKHR + parseFloat(cols[7]?.value || 0);
			teberealUSD = teberealUSD + parseFloat(cols[8]?.value || 0);
			teberealKHR = teberealKHR + parseFloat(cols[9]?.value || 0);
			tebeoceUSD = tebeoceUSD + parseFloat(cols[10]?.value || 0);
			tebeoceKHR = tebeoceKHR + parseFloat(cols[11]?.value || 0);

			return [
				{ value: cols[0]?.value, readOnly },
				{ value: cols[1]?.value, readOnly },
				{ value: cols[2]?.value, readOnly },
				{ value: cols[3]?.value, readOnly },
				{ value: cols[4]?.value, readOnly },
				{ value: cols[5]?.value, readOnly },
				{ value: cols[6]?.value, readOnly },
				{ value: cols[7]?.value, readOnly },
				{ value: cols[8]?.value, readOnly },
				{ value: cols[9]?.value, readOnly },
				{ value: cols[10]?.value, readOnly },
				{ value: cols[11]?.value, readOnly },
				{ value: '0', readOnly },
				{ value: '0', readOnly },
			];
		}
	});

	let tebettUSD = 0;
	let tebettKHR = 0;

	localCells = localCells?.map((cols, i) => {
		if (i === 7) {
			return [
				cols[0],
				cols[1],
				cols[2],
				cols[3],
				cols[4],
				cols[5],
				cols[6],
				cols[7],
				cols[8],
				cols[9],
				cols[10],
				cols[11],
				{ value: sumOrError([tebettUSD]), readOnly: true },
				{ value: sumOrError([tebettKHR]), readOnly: true },
			];
		} else {
			const totalUSD =
				parseFloat(cols[0]?.value || 0) +
				parseFloat(cols[2]?.value || 0) +
				parseFloat(cols[4]?.value || 0) +
				parseFloat(cols[6]?.value || 0) +
				parseFloat(cols[8]?.value || 0) +
				parseFloat(cols[10]?.value || 0);
			const totalKHR =
				parseFloat(cols[1]?.value || 0) +
				parseFloat(cols[3]?.value || 0) +
				parseFloat(cols[5]?.value || 0) +
				parseFloat(cols[7]?.value || 0) +
				parseFloat(cols[9]?.value || 0) +
				parseFloat(cols[11]?.value || 0);

			tebettUSD = tebettUSD + totalUSD;
			tebettKHR = tebettKHR + totalKHR;

			return [
				cols[0],
				cols[1],
				cols[2],
				cols[3],
				cols[4],
				cols[5],
				cols[6],
				cols[7],
				cols[8],
				cols[9],
				cols[10],
				cols[11],
				{ value: sumOrError([totalUSD]), readOnly: true },
				{ value: sumOrError([totalKHR]), readOnly: true },
			];
		}
	});

	localCells = localCells?.slice(0, numLabel);
	return { code, cells: localCells };
}

function validateSOCECells({ cells }) {
	const localCells = cells?.reduce((iPre, iCur) => {
		let localIPre = iPre;
		localIPre.push(
			...iCur?.reduce((jPre, jCur) => {
				let localJPre = jPre;
				if (!jCur?.readOnly && !isNumeric(jCur?.value)) {
					localJPre.push(jCur);
				}
				return localJPre;
			}, [])
		);
		return localIPre;
	}, []);
	return localCells?.length === 0;
}

export { soceNumColumn, cleanSOCECells, detailSOCECells, calculateSOCECells, validateSOCECells };
