/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import { Box, Flex, Text, IconButton, Tooltip, useToast, Center } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { DownloadIcon } from '@chakra-ui/icons';
import { chartBackgroundColor } from '../../Miscellaneous/plugins-chart';
import { formatCurrency } from '../../../../utils/format';
import Chart from 'react-chartjs-2';
import Card from '../../../Card/Card';
import chartDataLabels from 'chartjs-plugin-datalabels';
import DataLoading from '../../../DataLoading';

const Q_DATASET = gql`
	query get($companyType: InsuranceInstitutionsType, $companyId: String, $range: MDDataDateRangeInput) {
		getDistributionChannelGrossPremiumLobByChannelDataset(companyType: $companyType, companyId: $companyId, range: $range)
	}
`;

function Graph({ companyType = null, companyId = null, range = null }) {
	const chartRef = useRef(null);
	const toast = useToast();
	const { t } = useTranslation();
	const [getDataset, { data, error, loading }] = useLazyQuery(Q_DATASET, { fetchPolicy: 'no-cache' });

	useEffect(load, [companyType, companyId, range]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	function load() {
		const variables = { companyType, companyId, range: range?.from && range?.to ? range : null };
		getDataset({ variables });
	}

	return (
		<Box className={loading ? 'disableFormClear' : ''}>
			<Card>
				<Flex justifyContent="center" alignItems="center" flexDirection="column" p={4}>
					<Text fontSize="lg" fontWeight="bold" color="gray.600" noOfLines={2} textAlign={'center'} pl={4} pr={4}>
						{t('Gross Premium of LOB by Channel')}
					</Text>
				</Flex>
				<DataLoading loading={loading} loadedData={data?.getDistributionChannelGrossPremiumLobByChannelDataset}>
					{({ loadedData }) => (
						<>
							<Box w="100%" p={4} pt={0}>
								<Center>
									<Chart
										ref={chartRef}
										type="bar"
										height="300px"
										plugins={[chartDataLabels, chartBackgroundColor]}
										options={{
											indexAxis: 'y',
											maintainAspectRatio: false,
											canvasBackgroundColor: {
												color: '#FFFFFF',
											},
											layout: {
												padding: {
													top: 32,
													right: 16,
													bottom: 16,
													left: 8,
												},
											},
											plugins: {
												title: { display: false },
												legend: {
													display: true,
													labels: {
														font: {
															family: 'Rajdhani, Hanuman, sans-serif, Moul',
															weight: 'normal',
															size: 12,
														},
														generateLabels(chart) {
															return chart.data.datasets.map((el, i) => {
																const meta = chart.getDatasetMeta(i);
																const style = meta.controller.getStyle(i);
																return {
																	text: t(el?.label),
																	fillStyle: style.backgroundColor,
																	strokeStyle: style.borderColor,
																	lineWidth: style.borderWidth / 4,
																	pointStyle: chart.legend.options.labels.pointStyle,
																	hidden: !chart.getDataVisibility(i),
																	index: i,
																};
															});
														},
														usePointStyle: true,
														pointStyle: 'rect',
													},
													position: 'bottom',
												},
												datalabels: {
													color: 'white',
													display: function (context) {
														return context?.dataset?.maxValue / context?.dataset?.data[context?.dataIndex] < 5.6;
													},
													formatter: function (value, context) {
														return formatCurrency(value, '$');
													},
												},
												tooltip: {
													callbacks: {
														label: (toolTipItem) => {
															return t(toolTipItem.dataset.label) + ': ' + formatCurrency(toolTipItem.formattedValue, '$');
														},
													},
												},
											},
											scales: {
												x: {
													stacked: true,
													ticks: {
														callback: function (value, index, ticks) {
															return formatCurrency(value);
														},
													},
												},
												y: {
													stacked: true,
												},
											},
										}}
										data={loadedData}
									/>
								</Center>
							</Box>
							<Flex className="hidden-print" justifyContent="flex-end" alignItems="center" p={4}>
								<Tooltip label={t('Download Image')}>
									<IconButton
										size="sm"
										icon={<DownloadIcon w={3} h={3} />}
										onMouseDown={() => {
											const link = document.createElement('a');
											link.download = `graph-distribution-gross-premium-lob-by-channel.jpg`;
											link.href = chartRef?.current?.toBase64Image('image/jpeg', 1);
											link.click();
										}}
									/>
								</Tooltip>
							</Flex>
						</>
					)}
				</DataLoading>
			</Card>
		</Box>
	);
}

export default Graph;
