import { Badge } from '@chakra-ui/react';
import useStore from '../../store';
import { useTranslation } from 'react-i18next';

function NewReportType({ type = null, newTypeDetail = null }) {
	const { t } = useTranslation();
	const { currentLang: lang } = useStore((state) => state?.currentLang);

	return (
		<>
			{type === 'FINANCIAL_REPORT' && (
				<>
					<Badge colorScheme="green">{t('Financial Report')}</Badge>
				</>
			)}
			{type === 'COMPLAINT_SETTLEMENT_REPORT' && (
				<>
					<Badge colorScheme="yellow">{t('Complaint Settlement Report')}</Badge>
				</>
			)}
			{type === 'INSURANCE_AGENT_REPORT' && (
				<>
					<Badge colorScheme="purple">{t('Insurance Agent Report')}</Badge>
				</>
			)}
			{type === 'NEW_TYPE' && (
				<>
					<Badge colorScheme="gray">{lang === 'kh' ? newTypeDetail?.titleKh : newTypeDetail?.title}</Badge>
				</>
			)}
		</>
	);
}

export default NewReportType;
