/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Box, Flex, Button, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { gql, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import DatetimeFormField from '../../components/form/DatetimeFormField';
import RadioValueFormField from '../../components/form/RadioValueFormField';
import TextAreaFormField from '../../components/form/TextAreaFormField';
import moment from 'moment';
import c from '../../constant';
import * as Yup from 'yup';

const M_UPDATE = gql`
	mutation ($editableDescription: String, $editableDueDate: DateTime, $isEditable: Boolean!, $type: MDDataReportEnumType!, $id: String!) {
		setMonthlyReportEditableStatus(
			editableDescription: $editableDescription
			editableDueDate: $editableDueDate
			isEditable: $isEditable
			type: $type
			id: $id
		)
	}
`;

const validationSchema = Yup.object().shape({
	isEditable: Yup.bool().required('required'),
	editableDueDate: Yup.mixed().when('isEditable', {
		is: (isEditable) => isEditable === true,
		then: Yup.string().required('required'),
		otherwise: Yup.string().nullable(),
	}),
	editableDescription: Yup.string(),
});

export default function EditMonthlyReportModal({
	id = null,
	type = null,
	isEditable = '0',
	editableDueDate = '',
	editableDescription = '',
	onClose = () => null,
	onCallback = () => null,
}) {
	const toast = useToast();
	const { t } = useTranslation('common');
	const [setStatus, { loading, data, error }] = useMutation(M_UPDATE);

	useEffect(() => {
		if (data?.setMonthlyReportEditableStatus) {
			onClose();
			onCallback();
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	return (
		<>
			<Modal isOpen={true} onClose={onClose} closeOnEsc={loading ? false : true} closeOnOverlayClick={loading ? false : true}>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius}>
					<ModalHeader>{t('Allow Editing')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Box p={4}>
							<Formik
								initialValues={{
									isEditable: isEditable === true ? '1' : '0',
									editableDueDate: editableDueDate || moment().add(3, 'days').endOf('days'),
									editableDescription: editableDescription || '',
								}}
								validationSchema={validationSchema}
								onSubmit={(values) => {
									if (values?.isEditable === '1') {
										const variables = {
											id,
											type,
											isEditable: true,
											editableDueDate: values?.editableDueDate,
											editableDescription: values?.editableDescription || null,
										};
										setStatus({ variables });
									} else {
										const variables = {
											id,
											type,
											isEditable: false,
											editableDueDate: null,
											editableDescription: null,
										};
										setStatus({ variables });
									}
								}}
							>
								{({ values, ...props }) => {
									return (
										<Form>
											<Flex pl={4} pr={4} border="2px dashed #c0392b" direction="column">
												<RadioValueFormField
													{...props}
													label="Permission"
													name="isEditable"
													radios={[
														{ label: 'Open', value: '1' },
														{ label: 'Close', value: '0' },
													]}
													required
												/>
												{values?.isEditable === '1' && (
													<>
														<DatetimeFormField {...props} label="Due Date" name="editableDueDate" required />
														<TextAreaFormField {...props} label="Description" name="editableDescription" />
													</>
												)}
											</Flex>
											<Flex mt="16px">
												<Button
													id="login-btn"
													variant="solid"
													size="md"
													h="42px"
													w="128px"
													type="submit"
													borderRadius={c.borderRadius}
													color="#3498db"
													isDisabled={loading}
													isLoading={loading}
												>
													{t('Update')}
												</Button>
											</Flex>
										</Form>
									);
								}}
							</Formik>
						</Box>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}
