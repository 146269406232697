/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { Box, Button, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faPrint } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import ClaimPaidVsClaimIncurredGraph from './ClaimPaidVsClaimIncurredGraph';
import ClaimDeniedVsClaimIncurredGraph from './ClaimDeniedVsClaimIncurredGraph';
import ClaimPaidVsClaimDeniedGraph from './ClaimPaidVsClaimDeniedGraph';
import ClaimByProductGraph from './ClaimByProductGraph';
import ClamAverageAmountPerPolicyGraph from './ClamAverageAmountPerPolicyGraph';
import ClaimPaidNumPoliciesGrossPremium from './ClaimNumPoliciesGrossPremium';
import ClaimPaidGrossPremiumGrowthGraph from './ClaimGrowthGraph';
import ClaimDeniedNumPoliciesGrossPremium from './ClaimNumPoliciesGrossPremium';
import ClaimDeniedGrossPremiumGrowthGraph from './ClaimGrowthGraph';
import ClaimOutstandingNumPoliciesGrossPremium from './ClaimNumPoliciesGrossPremium';
import ClaimOutstandingGrossPremiumGrowthGraph from './ClaimGrowthGraph';
import ClaimIncurredNumPoliciesGrossPremium from './ClaimNumPoliciesGrossPremium';
import ClaimIncurredGrossPremiumGrowthGraph from './ClaimGrowthGraph';
import ClaimSidebar from './ClaimSidebar';
import Filter from '../../Miscellaneous/filter-dashboard';
import c from '../../../../constant';

function MonthlyClaimDashboard() {
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const period = urlParams.get('period') || null;
	const reportId = urlParams.get('reportId') || null;
	const { push } = useHistory();
	const { t } = useTranslation();
	const [filter, setFilter] = useState(null);

	return (
		<Box>
			<Flex justifyContent="center" alignItems="center" className="show-only-printing" borderBottomColor="gray.200" borderBottomWidth={1}>
				<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
					{t('Claim')}
				</Text>
			</Flex>
			<Flex mb={4} direction={['column', 'column', 'row']} justifyContent="flex-end" alignItems="center">
				<Button
					className="hidden-print"
					isLoading={false}
					isDisabled={false}
					leftIcon={<FontAwesomeIcon icon={faList} style={{ fontSize: 16 }} />}
					size="sm"
					borderRadius={c.borderRadius}
					border="1px solid #bdc3c7"
					mr={[0, 0, 2]}
					width={['100%', '100%', 'auto']}
					colorScheme="gray"
					variant="solid"
					mt={[2, 2, 0]}
					onMouseDown={() => push('/market-development-data/monthly-claim-report?period=' + period + '&reportId=' + reportId + '&page=1')}
				>
					{t('List')}
				</Button>
				<Filter
					companyType={filter?.companyType}
					companyId={filter?.companyId}
					rangeOption={filter?.rangeOption}
					rangeValue={filter?.rangeValue}
					onCallback={setFilter}
				/>
				<Button
					className="hidden-print"
					isLoading={false}
					isDisabled={false}
					onClick={() => window?.print()}
					leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
					size="sm"
					borderRadius={c.borderRadius}
					border="1px solid #bdc3c7"
					ml={[0, 0, 2]}
					width={['100%', '100%', 'auto']}
					colorScheme="gray"
					variant="solid"
					mt={[2, 2, 0]}
				>
					{t('Print')}
				</Button>
			</Flex>
			<Box display={['', '', 'flex']}>
				<Box width="100%" height="auto" mt={[4, 4, 0]}>
					<Box height="auto" mt={[0, 0, 0]} border="1px dotted #bdc3c7" pl={4} pr={4} pt={4} pb={4} mb={4}>
						<ClaimSidebar companyType={filter?.companyType} companyId={filter?.companyId} fromDate={filter?.fromDate} toDate={filter?.toDate} />
					</Box>
					<SimpleGrid w="100%" columns={1} spacing={4}>
						<ClaimByProductGraph companyType={filter?.companyType} companyId={filter?.companyId} range={filter?.rangeValue} />
						<ClamAverageAmountPerPolicyGraph companyType={filter?.companyType} companyId={filter?.companyId} range={filter?.rangeValue} />
						<ClaimPaidNumPoliciesGrossPremium
							title="Claim Paid"
							claimType="ClaimPaid"
							companyType={filter?.companyType}
							companyId={filter?.companyId}
							range={filter?.rangeValue}
						/>
						<ClaimPaidGrossPremiumGrowthGraph
							title="Growth of Claim Paid"
							claimType="ClaimPaid"
							companyType={filter?.companyType}
							companyId={filter?.companyId}
							range={filter?.rangeValue}
						/>
						<ClaimDeniedNumPoliciesGrossPremium
							title="Claim Denied"
							claimType="ClaimDenied"
							companyType={filter?.companyType}
							companyId={filter?.companyId}
							range={filter?.rangeValue}
						/>
						<ClaimDeniedGrossPremiumGrowthGraph
							title="Growth of Claim Denied"
							claimType="ClaimDenied"
							companyType={filter?.companyType}
							companyId={filter?.companyId}
							range={filter?.rangeValue}
						/>
						<ClaimOutstandingNumPoliciesGrossPremium
							title="Outstanding Claims"
							claimType="OutstandingClaim"
							companyType={filter?.companyType}
							companyId={filter?.companyId}
							range={filter?.rangeValue}
						/>
						<ClaimOutstandingGrossPremiumGrowthGraph
							title="Growth of Outstanding Claims"
							claimType="OutstandingClaim"
							companyType={filter?.companyType}
							companyId={filter?.companyId}
							range={filter?.rangeValue}
						/>
						<ClaimIncurredNumPoliciesGrossPremium
							title="Claim Incurred"
							claimType="ClaimIncurred"
							companyType={filter?.companyType}
							companyId={filter?.companyId}
							range={filter?.rangeValue}
						/>
						<ClaimIncurredGrossPremiumGrowthGraph
							title="Growth of Claim Incurred"
							claimType="ClaimIncurred"
							companyType={filter?.companyType}
							companyId={filter?.companyId}
							range={filter?.rangeValue}
						/>
						<ClaimPaidVsClaimIncurredGraph companyType={filter?.companyType} companyId={filter?.companyId} range={filter?.rangeValue} />
						<ClaimDeniedVsClaimIncurredGraph companyType={filter?.companyType} companyId={filter?.companyId} range={filter?.rangeValue} />
						<ClaimPaidVsClaimDeniedGraph companyType={filter?.companyType} companyId={filter?.companyId} range={filter?.rangeValue} />
					</SimpleGrid>
				</Box>
			</Box>
		</Box>
	);
}

export default MonthlyClaimDashboard;
