import { EWSTypeStatement } from './utils/ews';

const env = "production"
// const env = 'development';
// const env = "staging"

const onesignalAppId = env === 'production' ? '25022ad5-5401-4a24-93c3-b30635f8440a' : 'e74edf0a-ebee-44a6-bd2f-aff18ad9fc1f';
const baseGQL =
	env === 'production'
		? 'https://gateway.irc.gov.kh/graphql'
		: env === 'staging'
		? 'https://api.staging.irc.gov.kh/graphql'
		: 'http://localhost:3000/graphql';
const baseFilePath = env === 'production' ? 'https://object.irc.gov.kh/irc-public/' : 'https://object.irc.gov.kh/irc-public-dev/';
const adminCamDigiKeyRedirectUrlLogin = env === 'production' ? 'https://irc.gov.kh/redirect/adminLogin' : 'http://localhost:3009/redirect/adminLogin';
const adminCamDigiKeyRedirectUrlLink =
	env === 'production' ? 'https://irc.gov.kh/redirect/adminLinkAccount' : 'http://localhost:3009/redirect/adminLinkAccount';
const skeletonData = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

const roles = [
	{
		role: 'admin',
		label: 'Admin',
	},
	{
		role: 'noRole',
		label: 'No Role',
	},
	{
		role: 'websiteEditor',
		label: 'Website Editor',
	},
	{
		role: 'complaint',
		label: 'Complaint',
	},
	{
		role: 'companySubmissionReport',
		label: 'Company Report',
	},
	{
		role: 'companySubmissionProposal',
		label: 'Proposal',
	},
	{
		role: 'companyList',
		label: 'Company',
	},
	{
		role: 'requestLeave',
		label: 'Request Leave',
	},
	{
		role: 'agent',
		label: 'Agent',
	},
	{
		role: 'employee',
		label: 'Official Profile',
	},
	{
		role: 'payment',
		label: 'Payment',
	},
	{
		role: 'issuedLetter',
		label: 'Issued Letter',
	},
	{
		role: 'auditCompany',
		label: 'Audit Company',
	},
	{
		role: 'manageAgentPartner',
		label: 'Manage Agent Partner',
	},
	{
		role: 'mdd',
		label: 'Market Development Data',
	},
	{
		role: 'insuranceCompanySaleStaff',
		label: 'Insurance Sale Staff',
	},
	{
		role: 'ews',
		label: 'Early Warning System',
	},
];

const companyReportTypes = [
	{
		label: 'Financial Report',
		value: 'FINANCIAL_REPORT',
	},
	{
		label: 'Complaint Settlement Report',
		value: 'COMPLAINT_SETTLEMENT_REPORT',
	},
	{
		label: 'Insurance Agent Report',
		value: 'INSURANCE_AGENT_REPORT',
	},
	{
		label: 'New Type',
		value: 'NEW_TYPE',
	},
];

const financialReportTypes = [
	{
		label: 'Monthly',
		value: 'MONTHLY',
	},
	{
		label: 'Quarterly',
		value: 'QUARTERLY',
	},
	{
		label: 'Annually',
		value: 'ANNUALLY',
	},
];

const departments = [
	{
		label: 'License and Legal Affairs Department',
		labelKh: 'នាយកដ្ឋានគ្រប់គ្រងអាជ្ញាបណ្ណ និងកិច្ចការគតិយុត្ត',
		value: 'D_L_L_A',
		offices: [
			{
				value: 'legal_and_mediation',
				label: 'Legal and Mediation',
				labelKh: 'នីតិកម្ម និងផ្សះផ្សាវិវាទ',
			},
			{ value: 'licensing', label: 'Licensing', labelKh: 'គ្រប់គ្រងអាជ្ញាបណ្ណ' },
			{
				value: 'intermediary_registration',
				label: 'Intermediary Registration',
				labelKh: 'ចុះបញ្ជីអន្តរការី',
			},
		],
	},
	{
		label: 'Market Development Department',
		labelKh: 'នាយកដ្ឋានអភិវឌ្ឍន៍ទីផ្សារ',
		value: 'D_M_D',
		offices: [
			{
				value: 'life_insurance_product',
				label: 'Life Insurance Product',
				labelKh: 'ផលិតផលធានារ៉ាប់រងអាយុជីវិត',
			},
			{
				value: 'general_insurance_product',
				label: 'General Insurance Product',
				labelKh: 'ផលិតផលធានារ៉ាប់រងទូទៅ',
			},
			{
				value: 'micro_insurance_product',
				label: 'Micro Insurance Product',
				labelKh: 'ផលិតផលធានារ៉ាប់រងខ្នាតតូច',
			},
			{
				value: 'market_conduct_supervision',
				label: 'Market Conduct Supervision',
				labelKh: 'ត្រួតពិនិត្យប្រតិបត្តិការទីផ្សារ',
			},
		],
	},
	{
		label: 'General Affairs Department',
		labelKh: 'នាយកដ្ឋានកិច្ចការទូទៅ',
		value: 'D_G_A',
		offices: [
			{ value: 'administrative', label: 'Administrative', labelKh: 'រដ្ឋបាល' },
			{ value: 'human_resource', label: 'Human Resource', labelKh: 'ធនធានមនុស្ស' },
			{ value: 'accounting', label: 'Accounting', labelKh: 'គណនេយ្យ' },
			{ value: 'public_relation', label: 'Public Relation', labelKh: 'ទំនាក់ទំនងសាធារណៈ' },
		],
	},
	{
		label: 'Research, Training and Cooperation Department',
		labelKh: 'នាយកដ្ឋានស្រាវជ្រាវ បណ្តុះបណ្តាល និងសហប្រតិបត្តិការ',
		value: 'D_R_T_C',
		offices: [
			{ value: 'research', label: 'Research', labelKh: 'ស្រាវជ្រាវ' },
			{ value: 'training', label: 'Training', labelKh: 'បណ្តុះបណ្តាល' },
			{ value: 'cooperation', label: 'Cooperation', labelKh: 'សហប្រតិបត្តិការ' },
			{ value: 'insurtech', label: 'InsurTech', labelKh: 'បច្ចេកវិទ្យាធានារ៉ាប់រង' },
		],
	},
	{
		label: 'Supervision Department',
		labelKh: 'នាយកដ្ឋានត្រួតពិនិត្យ',
		value: 'D_S',
		offices: [
			{
				value: 'life_insurer',
				label: 'Life Insurer',
				labelKh: 'ត្រួតពិនិត្យក្រុមហ៊ុនធានារ៉ាប់រងអាយុជីវិត',
			},
			{
				value: 'general_insurer',
				label: 'General Insurer',
				labelKh: 'ត្រួតពិនិត្យក្រុមហ៊ុនធានារ៉ាប់រងទូទៅ',
			},
			{
				value: 'micro_insurer',
				label: 'Micro Insurer',
				labelKh: 'ត្រួតពិនិត្យក្រុមហ៊ុនធានារ៉ាប់រងខ្នាតតូច និងអន្តរការី',
			},
			{ value: 'prudential', label: 'Prudential', labelKh: 'បទប្បញ្ញត្តិប្រុងប្រយ័ត្ន' },
		],
	},
];

const position = [
	{ value: 1, label: 'Director General', labelKh: 'អគ្គនាយក' },
	{ value: 2, label: 'Deputy Director General', labelKh: 'អគ្គនាយករង' },
	{ value: 3, label: 'Head of Department', labelKh: 'ប្រធាននាយកដ្ឋាន' },
	{ value: 4, label: 'Deputy Head of Department', labelKh: 'អនុប្រធាននាយកដ្ឋាន' },
	{ value: 5, label: 'Head of Office', labelKh: 'ប្រធានការិយាល័យ' },
	{ value: 6, label: 'Deputy Head of Office', labelKh: 'អនុប្រធានការិយាល័យ' },
	{ value: 7, label: 'Statute Officer', labelKh: 'មន្រ្តីលក្ខន្តិកៈ' },
	{ value: 8, label: 'Internship Officer', labelKh: 'មន្រ្តីកម្មសិក្សា' },
];

const insuranceCompanyType = {
	association: { label: 'ASSOCIATION', value: 'ASSOCIATION' },
	general: { label: 'GENERAL INSURANCE', value: 'GENERAL_INSURANCE' },
	life: { label: 'LIFE INSURANCE', value: 'LIFE_INSURANCE' },
	micro: { label: 'MICRO INSURANCE', value: 'MICRO_INSURANCE' },
	re: { label: 'REINSURANCE', value: 'REINSURANCE' },
	broker: { label: 'INSURANCE BROKER', value: 'INSURANCE_BROKER' },
	agent: { label: 'Insurance Agent Co Ltd', value: 'INSURANCE_AGENT' },
	lossAdjuster: { label: 'Insurance Loss Adjuster Co Ltd', value: 'INSURANCE_LOSS_ADJUSTER' },
	auditService: { label: 'INSURANCE AUDIT SERVICE', value: 'AUDIT_SERVICE' },
	claimManagement: { label: 'INSURANCE CLAIM MANAGEMENT', value: 'INSURANCE_CLAIM_MANAGEMENT' },
	actuarialFirm: { label: 'INSURANCE ACTUARIAL FIRM', value: 'ACTUARIAL_FIRM' },
};

const insuranceCompanyTypes = [
	{ label: 'ASSOCIATION', value: 'ASSOCIATION' },
	{ label: 'GENERAL INSURANCE', value: 'GENERAL_INSURANCE' },
	{ label: 'LIFE INSURANCE', value: 'LIFE_INSURANCE' },
	{ label: 'MICRO INSURANCE', value: 'MICRO_INSURANCE' },
	{ label: 'REINSURANCE', value: 'REINSURANCE' },
	{ label: 'INSURANCE BROKER', value: 'INSURANCE_BROKER' },
	{ label: 'Insurance Agent Co Ltd', value: 'INSURANCE_AGENT' },
	{ label: 'Insurance Loss Adjuster Co Ltd', value: 'INSURANCE_LOSS_ADJUSTER' },
	{ label: 'INSURANCE AUDIT SERVICE', value: 'AUDIT_SERVICE' },
	{ label: 'INSURANCE CLAIM MANAGEMENT', value: 'INSURANCE_CLAIM_MANAGEMENT' },
	{ label: 'INSURANCE ACTUARIAL FIRM', value: 'ACTUARIAL_FIRM' },
];

const insuranceCompanyTypesExcludeAssociation = [
	{ label: 'GENERAL INSURANCE', value: 'GENERAL_INSURANCE' },
	{ label: 'LIFE INSURANCE', value: 'LIFE_INSURANCE' },
	{ label: 'MICRO INSURANCE', value: 'MICRO_INSURANCE' },
	{ label: 'REINSURANCE', value: 'REINSURANCE' },
	{ label: 'INSURANCE BROKER', value: 'INSURANCE_BROKER' },
	{ label: 'Insurance Agent Co Ltd', value: 'INSURANCE_AGENT' },
	{ label: 'Insurance Loss Adjuster Co Ltd', value: 'INSURANCE_LOSS_ADJUSTER' },
	{ label: 'INSURANCE AUDIT SERVICE', value: 'AUDIT_SERVICE' },
	{ label: 'INSURANCE CLAIM MANAGEMENT', value: 'INSURANCE_CLAIM_MANAGEMENT' },
	{ label: 'INSURANCE ACTUARIAL FIRM', value: 'ACTUARIAL_FIRM' },
];

const insuranceSeperatedLicenseType = {
	brokerGeneral: { label: 'Broker General Insurance', value: 'BROKER_GENERAL_INSURANCE' },
	brokerLife: { label: 'Broker Life Insurance', value: 'BROKER_LIFE_INSURANCE' },
	brokerRe: { label: 'Broker Reinsurance', value: 'BROKER_REINSURANCE' },
};

const governanceCeo = {
	ceo: 'ceo',
	auditCommission: 'auditCommission',
	investmentCommission: 'investmentCommission',
};

const reportTitles = {
	monthly: 'Monthly Report',
	quarterly: 'Quarterly Report',
	semesterly: 'Semesterly Report',
	annually: 'Annually Report',
};

const monthly_sale_report = 'monthly_sale_report';
const monthly_claim_report = 'monthly_claim_report';
const monthly_distribution_channel_report = 'monthly_distribution_channel_report';
const quarterly_financial_statements = 'quarterly_financial_statements';
const annually_unaudited_financial_statements = 'annually_unaudited_financial_statements';
const annually_audited_financial_statements = 'annually_audited_financial_statements';
const reportIds = {
	quarterly_financial_statements: [
		{ label: 'Quarter1', value: EWSTypeStatement.q1 },
		{ label: 'Quarter2', value: EWSTypeStatement.q2 },
		{ label: 'Quarter3', value: EWSTypeStatement.q3 },
		{ label: 'Quarter4', value: EWSTypeStatement.q4 },
	],
	annually_unaudited_financial_statements: [
		{
			label: 'AnnualNonAudited',
			value: EWSTypeStatement.an,
		},
	],
	annually_audited_financial_statements: [{ label: 'AnnualAudited', value: EWSTypeStatement.aa }],
};

const mtplGSale = 'GSALE';

const mtplGClaim = 'GCLAIM';

const mtplTypeTemplates = [mtplGSale, mtplGClaim];

const constants = {
	baseGQL,
	baseFilePath,
	skeletonData,
	borderRadius: '2px',
	roles,
	onesignalAppId,
	companyReportTypes,
	departments,
	financialReportTypes,
	maxFileSize: 104857600,
	env,
	longDateFormat: 'LLLL',
	defaultDateFormat: 'DD MMMM YYYY',
	dateFormats: ['DD-MM-YYYY', 'DD/MM/YYYY'],
	dateFormat: 'DD-MM-YYYY',
	dateOnlyFormat: 'DD/MM/YYYY',
	dateTextOnlyFormat: 'dddd, DD MMMM YYYY',
	dateTimeFormat: 'D MMMM YYYY, h:mm a',
	excelDateOnlyFormat: 'MM/DD/YYYY',
	excelDateOnlyTextFormat: 'DD MMMM YYYY',
	excelDateTimeFormat: 'MM/DD/YYYY H:mm:ss',
	loggingDateTimeFormat: 'D MMMM YYYY, h:mm:ss a',
	fileNameDateFormat: 'DD MM YYYY hh mm ss',
	position,
	adminCamDigiKeyRedirectUrlLogin,
	adminCamDigiKeyRedirectUrlLink,
	auditServiceNewLicenseType: 'request_audit_service_license',
	auditServiceRenewLicenseType: 'request_audit_service_license_renewal',
	auditServicePending: 'PENDING',
	auditServiceInprogress: 'INPROGRESS',
	auditServiceComplete: 'COMPLETE',
	localeKm: 'km',
	localeEn: 'en-gb',
	minCalendar: { year: 1970, month: 1, day: 1 },
	maxCalendar: { year: 2070, month: 12, day: 31 },
	waiting: 'waiting',
	approved: 'approved',
	rejected: 'rejected',
	stepOffice: 'OFFICE',
	stepDepartment: 'DEPARTMENT',
	stepDirectorGeneral: 'DIRECTORGENERAL',
	stepOfficeRoles: ['5', '6', '7'],
	stepDepartmentRoles: ['3', '4'],
	stepDirectorGeneralRoles: ['1'],
	insuranceCompanyType,
	insuranceCompanyTypes,
	insuranceCompanyTypesExcludeAssociation,
	insuranceSeperatedLicenseType,
	governanceCeo,
	reportTitles,
	monthly_sale_report,
	monthly_claim_report,
	monthly_distribution_channel_report,
	quarterly_financial_statements,
	annually_unaudited_financial_statements,
	annually_audited_financial_statements,
	reportIds,
	mtplGSale,
	mtplGClaim,
	mtplTypeTemplates,
};

export default constants;
