/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Center, Container, Flex, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import MonthlyClaimDashboard from '../../components/MarketDevelopment/MonthlyClaim/Dashboard/MonthlyClaimDashboard';
import MonthlyDistributionDashboard from '../../components/MarketDevelopment/MonthlyDistributionChannel/Dashboard/MonthlyDistributionDashboard';
import MonthlySaleDashboard from '../../components/MarketDevelopment/MonthlySale/Dashboard/MonthlySaleDashboard';
import PrintLayout from '../../components/Print/PrintLayout';
import ReprotLayout from '../../components/Report/ReportLayout';
import c from '../../constant';

function Dashboard() {
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const reportId = urlParams.get('reportId') || null;
	const period = urlParams.get('period') || null;
	const { t } = useTranslation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	function getTitle(key) {
		switch (key) {
			case 'monthly_sale_report':
				return 'Insurance Sales Report';
			case 'monthly_claim_report':
				return 'Receiving and Settling Claim Report';
			case 'monthly_distribution_channel_report':
				return 'Distribution Channels and Geographical Report';
			default:
				return '';
		}
	}

	return (
		<PrintLayout>
			<>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'Company Report',
								path: '/company-submission-report',
							},
							{
								name: period,
								path: '/company-submission-report',
							},
						]}
					/>
					<Center mt="5">
						<ReprotLayout activeTab="monthly" reportId={reportId}>
							<Box
								w="100%"
								bg="white"
								borderRadius={c.borderRadius}
								border="1px solid #dbdbdb"
								boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
								pb="32px"
							>
								<Flex
									className="hidden-print"
									justifyContent="space-between"
									alignItems="center"
									bg="#FAFAFA"
									p="16px"
									pt="8px"
									pb="8px"
									mb="16px"
									borderBottom="1px solid #dbdbdb"
								>
									<Text fontSize="lg" fontWeight="bold">
										{t(getTitle(reportId))}
									</Text>
									<Flex />
								</Flex>
								<Box p="16px" pt="0">
									{reportId === c.monthly_sale_report && <MonthlySaleDashboard />}
									{reportId === c.monthly_claim_report && <MonthlyClaimDashboard />}
									{reportId === c.monthly_distribution_channel_report && <MonthlyDistributionDashboard />}
								</Box>
							</Box>
						</ReprotLayout>
					</Center>
				</Container>
			</>
		</PrintLayout>
	);
}

export default Dashboard;
