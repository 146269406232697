/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { Badge, Flex, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportMenuItem from './ReportMenuItem';
import useStore from '../../store';
import c from '../../constant';

const mdd = 'mdd';
const mddCanViewGeneralMonthlyReport = 'mddgmr';
const mddCanViewLifeMonthlyReport = 'mddlmr';
const mddCanViewMicroMonthlyReport = 'mddmmr';

const ews = 'ews';
const ewsCanViewStatementGeneralInsurance = 'ewsCanViewStatementGeneralInsurance';
const ewsCanViewStatementLifeInsurance = 'ewsCanViewStatementLifeInsurance';
const ewsCanViewStatementMicroInsurance = 'ewsCanViewStatementMicroInsurance';

const periods = ['Monthly', 'Quarterly', 'Semesterly', 'Annually'];

const QUERY_COMPANY_REPORT_TYPE = gql`
	query get($period: String!) {
		getPublicCompanyReportTypes(period: $period) {
			id
			title
			titleKh
			period
			departments
		}
	}
`;

export default function ReprotLayout({ children, activeTab, reportId }) {
	const { t } = useTranslation('common');
	const [loadReportTypeByPeriod, { data, loading }] = useLazyQuery(QUERY_COMPANY_REPORT_TYPE);
	const { unreadReportCount } = useStore((state) => state.unreadReportCount);
	const setUnreadReportCount = useStore((state) => state.setUnreadReportCount);
	const { currentUser: user } = useStore((state) => state.currentUser);
	const [initialReportTypes, setInitialReportTypes] = useState([]);
	const [reportTypes, setReportTypes] = useState(null);

	useEffect(() => {
		if (user) {
			const localInitialReportTypes = [];
			const roles = user?.role.split(',') || [];
			if (
				roles?.includes('admin') ||
				(roles?.includes(mdd) &&
					(user?.mdd?.includes(mddCanViewGeneralMonthlyReport) ||
						user?.mdd?.includes(mddCanViewLifeMonthlyReport) ||
						user?.mdd?.includes(mddCanViewMicroMonthlyReport)))
			) {
				localInitialReportTypes.push({
					id: c.monthly_sale_report,
					title: 'Insurance Sales Report',
					titleKh: 'របាយការណ៍អំពីអាជីវកម្ម',
					period: 'MONTHLY',
					path: '/market-development-data?period=monthly&reportId=' + c.monthly_sale_report,
					hint: getHint(user?.mdd),
				});
				localInitialReportTypes.push({
					id: c.monthly_claim_report,
					title: 'Receiving and Settling Claim Report',
					titleKh: 'របាយការណ៍អំពីការទទួល និងដោះស្រាយសំណងធានារ៉ាប់រង',
					period: 'MONTHLY',
					path: '/market-development-data?period=monthly&reportId=' + c.monthly_claim_report,
					hint: getHint(user?.mdd),
				});
				localInitialReportTypes.push({
					id: c.monthly_distribution_channel_report,
					title: 'Distribution Channels and Geographical Report',
					titleKh: 'របាយការណ៍លក់តាមប្រព័ន្ធចែកចាយ និងតាមភូមិសាស្ត្រ',
					period: 'MONTHLY',
					path: '/market-development-data?period=monthly&reportId=' + c.monthly_distribution_channel_report,
					hint: getHint(user?.mdd),
				});
			}
			/*
			if (roles?.includes('admin') || roles?.includes(ews)) {
				localInitialReportTypes.push({
					id: c.quarterly_financial_statements,
					title: 'Financial Statements',
					titleKh: 'របាយការណ៍ហិរញ្ញវត្ថុ',
					period: 'QUARTERLY',
					path: '/early-warning-system?period=quarterly&reportId=' + c.quarterly_financial_statements + '&page=1',
				});
				localInitialReportTypes.push({
					id: c.annually_unaudited_financial_statements,
					title: 'Unaudited Financial Statements',
					titleKh: 'របាយការណ៍ហិរញ្ញវត្ថុប្រចាំឆ្នាំដែលមិនទាន់រងសវនកម្ម',
					period: 'ANNUALLY',
					path: '/early-warning-system?period=annually&reportId=' + c.annually_unaudited_financial_statements + '&page=1',
				});
				localInitialReportTypes.push({
					id: c.annually_audited_financial_statements,
					title: 'Audited Financial Statements',
					titleKh: 'របាយការណ៍ហិរញ្ញវត្ថុប្រចាំឆ្នាំដែលបានរងសវនកម្ម',
					period: 'ANNUALLY',
					path: '/early-warning-system?period=annually&reportId=' + c.annually_audited_financial_statements + '&page=1',
				});
			}
            */
			setInitialReportTypes(localInitialReportTypes);
		}
	}, [user]);

	useEffect(() => loadReportTypeByPeriod({ variables: { period: '' } }), [initialReportTypes]);

	useEffect(() => {
		if (data?.getPublicCompanyReportTypes) {
			const tempReport = [...initialReportTypes];
			data?.getPublicCompanyReportTypes?.forEach((item, index) => {
				const roles = user?.role.split(',') || [];
				const reportDepartments = item?.departments?.split(',') || [];
				const userDepartments = user?.department?.split(',') || [];
				const status = userDepartments.some((department) => reportDepartments.includes(department));
				if (roles?.includes('admin') || status) {
					tempReport.push({
						id: item?.id,
						title: item?.title,
						titleKh: item?.titleKh,
						period: item?.period,
						path: `/company-submission-report/report?type=${item?.period?.toLowerCase()}&page=1&newReportType=${item?.id}`,
					});
				}
			});
			setReportTypes(tempReport);
		}
	}, [data]);

	function reportList(reportTypes = [], period = null) {
		const localReportTypes = reportTypes?.filter((el) => el?.period?.toLowerCase() === period?.toLowerCase());
		if (localReportTypes?.length > 0) {
			return (
				<>
					{localReportTypes?.map((el, i) => (
						<ReportMenuItem
							key={i}
							item={el}
							index={i}
							reportId={reportId}
							callbackCountUnread={(e) => callbackCountUnread({ count: e, reportId: el?.id, period })}
						/>
					))}
				</>
			);
		} else {
			return (
				<Flex
					width="100%"
					alignItems="center"
					bg="gray.100"
					borderRadius={c.borderRadius}
					borderWidth={1}
					borderStyle="solid"
					borderColor="gray.300"
					boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
					p="2"
					cursor="pointer"
					mb="2"
				>
					<Text fontWeight="bold" color="gray.400">
						{t('No Data')}
					</Text>
				</Flex>
			);
		}
	}

	function callbackCountUnread({ count, reportId, period }) {
		const temp = { ...unreadReportCount, [reportId]: { count, period } };
		setUnreadReportCount(temp);
	}

	function getCountUnread(listCount, item) {
		let count = 0;
		for (var k in listCount) {
			if (listCount.hasOwnProperty(k)) {
				if (listCount[k]?.period === item) {
					count = count + listCount[k]?.count;
				}
			}
		}
		return count;
	}

	function getDefaultIndex(key) {
		switch (key) {
			case 'monthly':
				return 0;
			case 'quarterly':
				return 1;
			case 'semesterly':
				return 2;
			case 'annually':
				return 3;
			default:
				return 0;
		}
	}

	function getHint(permissions) {
		let hints = [];
		let roles = user?.role.split(',') || [];
		if (roles?.includes('admin')) {
			hints.push('GI');
			hints.push('LI');
			hints.push('MI');
		} else {
			if (permissions?.includes('mddgmr')) {
				hints.push('GI');
			}
			if (permissions?.includes('mddlmr')) {
				hints.push('LI');
			}
			if (permissions?.includes('mddmmr')) {
				hints.push('MI');
			}
		}
		return '(' + [hints?.slice(0, -1)?.join(' '), hints?.slice(-1)[0]].join(hints?.length < 2 ? '' : ' & ') + ')';
	}

	return (
		<>
			<Flex w="100%" overflowX="scroll">
				<Tabs
					className="hidden-print"
					defaultIndex={getDefaultIndex(activeTab)}
					bg="#ECECEF"
					variant="unstyled"
					w="440px"
					minW="440px"
					minH="400px"
					borderRadius={c.borderRadius}
					border="1px solid #dbdbdb"
					boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
					pb="32px"
					mr="1"
				>
					<TabList>
						{periods?.map((item, index) => (
							<Tab
								key={index}
								pl="0"
								fontWeight="bold"
								pr="0"
								w="100%"
								_selected={{ color: 'white', bg: 'blue.500' }}
								borderRadius="0 !important"
							>
								<Tooltip label={t(item)}>
									<Text color="inherit" noOfLines={1}>
										{t(item)}{' '}
									</Text>
								</Tooltip>
								{getCountUnread(unreadReportCount, item) > 0 && (
									<Badge fontSize="12px" ml="1" borderRadius={0} colorScheme="red" left={0}>
										{getCountUnread(unreadReportCount, item)}
									</Badge>
								)}
							</Tab>
						))}
					</TabList>
					<TabPanels mt="3px">
						{periods?.map((item, index) => {
							return (
								<TabPanel p="2" key={index}>
									{loading ? (
										<center>
											<Spinner mt="4" size="md" thickness={2} color="gray.400" />
										</center>
									) : (
										<>{reportTypes && reportList(reportTypes, item)}</>
									)}
								</TabPanel>
							);
						})}
					</TabPanels>
				</Tabs>
				{children}
			</Flex>
		</>
	);
}
