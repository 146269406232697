/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, Table, Tbody, Tfoot, Tr, Th, Td, Badge, Checkbox, Button, useDisclosure } from '@chakra-ui/react';
import { faSlidersH, faPrint, faRetweet, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatAgentCode } from '../../utils/index';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import ReactPaginate from 'react-paginate';
import LoadingTable from '../../components/Table/Loading';
import AgentFilter from '../../components/Filter/AgentFilter';
import LeaveReportModal from '../../components/Modal/LeaveReportModal';
import PrintLayout from '../../components/Print/PrintLayout';
import ExcelExportModalActiveAgent from '../../components/Modal/ExcelExportModalActiveAgent';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import useStore from '../../store';
import moment from 'moment';
import c from '../../constant';
import './style.css';

const QUERY_DATA = gql`
	query get(
		$limit: Float
		$offset: Float
		$status: String
		$code: String
		$name: String
		$idCardNumber: String
		$payment: AgentPaymentStatus
		$working: AgentWorkingStatus
		$read: Boolean
	) {
		getAgents(
			pagerInput: { limit: $limit, offset: $offset }
			status: $status
			code: $code
			name: $name
			idCardNumber: $idCardNumber
			payment: $payment
			working: $working
			read: $read
		) {
			totalRows
			totalPages
			agents {
				id
				code
				name
				latinName
				validFrom
				expireAt
				phoneNumber
				idCardNumber
				status
				paymentStatus
				notification {
					read
				}
			}
		}
	}
`;

const QUERY_COUNT = gql`
	query {
		countUnreadLeaveRequest
	}
`;

const MUTATION_READ_NOTIFICATION = gql`
	mutation read($id: String!) {
		readNotificationById(id: $id)
	}
`;

function Report() {
	const { t } = useTranslation();
	const { currentLang } = useStore((state) => state.currentLang);
	const { currentUser } = useStore((state) => state.currentUser);
	const { countUnreadNotifications } = useStore((state) => state.countUnreadNotifications);
	const setCountUnreadNotifications = useStore((state) => state.setCountUnreadNotifications);
	const location = useLocation();
	const history = useHistory();
	const urlParams = new URLSearchParams(location.search);
	const currentPage = urlParams.get('page') ? urlParams.get('page') : 1;
	const code = urlParams.get('code') ? urlParams.get('code') : null;
	const name = urlParams.get('name') ? urlParams.get('name') : null;
	const idCardNumber = urlParams.get('idCardNumber') ? urlParams.get('idCardNumber') : null;
	const payment = urlParams.get('payment') ? urlParams.get('payment') : null;
	const working = urlParams.get('working') ? urlParams.get('working') : null;
	const read = urlParams.get('read') ? urlParams.get('read') : null;
	const status = urlParams.get('status') ? urlParams.get('status') : null;
	const [readNotification] = useMutation(MUTATION_READ_NOTIFICATION);
	const [openExportActiveAgent, setOpenExportActiveAgent] = useState(false);
	const [rowLimit, setRowLimit] = useState(10);
	const [openLeaveReportModal, setOpenLeaveReportModal] = useState(false);
	const [loadCount] = useLazyQuery(QUERY_COUNT);
	const [loadData, { loading, data }] = useLazyQuery(QUERY_DATA);
	const { isOpen, onOpen, onClose } = useDisclosure();

	moment.locale(currentLang === 'kh' ? 'km' : 'en-gb');

	useEffect(() => {
		window.scrollTo(0, 0);
		const variables = {
			offset: currentPage ? (currentPage - 1) * rowLimit : 0,
			limit: rowLimit,
			code,
			name,
			idCardNumber,
			payment,
			working,
			read,
			status: status ? status : 'in-progress',
		};
		loadData({ variables });
		loadCount();
	}, []);

	function handlePageClick(data) {
		let selected = data.selected;
		const page = selected + 1;
		let url = `?page=${page}`;
		if (status) {
			url += `&status=${status}`;
		}
		if (code) {
			url += `&code=${code}`;
		}
		if (name) {
			url += `&name=${name}`;
		}
		if (idCardNumber) {
			url += `&idCardNumber=${idCardNumber}`;
		}
		if (payment) {
			url += `&payment=${payment}`;
		}
		if (working) {
			url += `&working=${working}`;
		}
		if (read !== null) {
			const unreadOnly = read ? read : '';
			if (unreadOnly) {
				url += `&read=false`;
			}
		}
		history.push(`/agent${url}`);
	}

	useEffect(() => {
		if (currentPage) {
			window.scrollTo(0, 0);
			const variables = {
				offset: currentPage ? (currentPage - 1) * rowLimit : 0,
				limit: rowLimit,
				code: code,
				name,
				idCardNumber,
				payment,
				working,
				read: read === 'false' ? false : read === 'true' ? true : null,
				status: status ? status : 'in-progress',
			};
			loadData({ variables });
		}
	}, [rowLimit, currentPage, code, name, idCardNumber, payment, working, read, status]);

	function getStatus(status) {
		let bgColor = 'gray';
		switch (status) {
			case 'IN PROGRESS':
				bgColor = 'yellow';
				break;
			case 'ACTIVE':
				bgColor = 'green';
				break;
			case 'REJECTED':
				bgColor = 'red';
				break;
			case 'DEACTIVATED':
				bgColor = 'red';
				break;
			default:
				break;
		}
		return status === 'BLACKLISTED' ? (
			<Badge bg="#c0392b">{t(status)}</Badge>
		) : (
			<Badge colorScheme={bgColor}>{t(status === 'ACTIVE' ? 'AGENT ACTIVE' : status)}</Badge>
		);
	}

	function getExpireStatus() {
		let bgColor = 'red';
		return <Badge colorScheme={bgColor}>{t('Expired')}</Badge>;
	}

	function checkReadRow(item) {
		let read = true;
		read = item?.notification?.read;
		return read;
	}

	function handleStatusChange(statusValue) {
		const page = 1;

		let url = `?page=${page}`;

		if (statusValue) {
			url += `&status=${statusValue}`;
		}
		if (code) {
			url += `&code=${code}`;
		}
		if (name) {
			url += `&name=${name}`;
		}
		if (idCardNumber) {
			url += `&idCardNumber=${idCardNumber}`;
		}
		if (payment) {
			url += `&payment=${payment}`;
		}
		if (working) {
			url += `&working=${working}`;
		}
		if (read !== null) {
			const unreadOnly = read ? read : '';
			if (unreadOnly) {
				url += `&read=false`;
			}
		}
		history.push(`/agent${url}`);
	}

	function panelTabs({ name, value }) {
		let active = status ? status : 'in-progress';

		return (
			<Flex
				userSelect="none"
				minW="100px"
				onClick={() => {
					handleStatusChange(value);
				}}
				cursor="pointer"
				mb={'-2px'}
				_hover={{ boxShadow: `inset 0 -2px 0 0 ${value === active ? '#2980b9' : '#bdc3c7'}` }}
				boxShadow={value === active ? 'inset 0 -2px 0 0 #2980b9' : ''}
				pt="16px"
				pb="16px"
				pl="12px"
				pr="12px"
				alignItems="center"
				justifyContent="center"
			>
				<Text>{t(name)}</Text>
			</Flex>
		);
	}

	function readTheNotification(id) {
		readNotification({
			variables: {
				id,
			},
		});
		setCountUnreadNotifications(countUnreadNotifications - 1 < 0 ? 0 : countUnreadNotifications - 1);
	}

	return (
		<PrintLayout>
			<>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'Insurance Agent Registration',
								path: '#',
							},
						]}
					/>
					<Center className="show-only-printing">
						<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
							ភ្នាក់ងារ
						</Text>
					</Center>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="32px"
						>
							<Flex
								className="hidden-print"
								bg="#FAFAFA"
								p="16px"
								pt="0"
								pb="0"
								mb="16px"
								alignItems="center"
								justifyContent="space-between"
								borderBottom="1px solid #dbdbdb"
							>
								<Flex pb="2px">
									{panelTabs({ name: 'In Progress', value: 'in-progress' })}
									{panelTabs({ name: 'Rejected', value: 'rejected' })}
									{panelTabs({ name: 'Approve For Agent', value: 'active' })}
									{panelTabs({ name: 'Expired', value: 'expired' })}
									{panelTabs({ name: 'Agent Deactivated', value: 'deactivated' })}
									{panelTabs({ name: 'Blacklisted', value: 'blacklisted' })}
									{panelTabs({ name: 'All', value: 'all' })}
								</Flex>
								<Flex alignItems="center">
									<Button
										mr="2"
										onClick={() => history.push('/agent-renewal?page=1&status=inprogress')}
										leftIcon={<FontAwesomeIcon icon={faRetweet} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t('Renewal List')}
									</Button>
									{currentUser?.role === 'admin' && (
										<Button
											mr="2"
											onClick={() => setOpenExportActiveAgent(true)}
											leftIcon={<FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 16 }} />}
											colorScheme="gray"
											variant="solid"
											size="sm"
											borderRadius={c.borderRadius}
											border="1px solid #bdc3c7"
										>
											{t('Export')}
										</Button>
									)}
									<Button
										mr="2"
										onClick={() => window?.print()}
										leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t('Print')}
									</Button>
									<Button
										onClick={onOpen}
										leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
										colorScheme={`${code || name || read || idCardNumber || payment || working ? 'yellow' : 'gray'}`}
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7}"
									>
										{t('Filter')}
									</Button>
								</Flex>
							</Flex>

							<Box p="16px">
								<Table variant="simple">
									<Tbody>
										<Tr className="td-as-th">
											<Th>{t('Registration number')}</Th>
											<Th>{t('Name')}</Th>
											<Th>{t('Latin name')}</Th>
											<Th>{t('Phone')}</Th>
											<Th>{t('ID card')}</Th>
											<Th>{t('validFrom')}</Th>
											<Th>{t('Expired At')}</Th>
											<Th isNumeric>{t('Agent status')}</Th>
										</Tr>
										{loading && <LoadingTable column={8} />}
										{data?.getAgents?.agents.length <= 0 && (
											<Tr>
												<Td isNumeric>
													<Text>{t('No Data')}</Text>
												</Td>
											</Tr>
										)}
										{data?.getAgents?.agents?.map((item, index) => {
											return (
												<Tr
													cursor="pointer"
													_hover={{ bg: 'gray.200' }}
													onClick={() => {
														if (item?.notification?.read === false) {
															readTheNotification(item?.notification?.id);
														}
														history.push(`/agent/detail?id=${item.id}`);
													}}
													key={`item-${index}`}
													bg={checkReadRow(item) === false ? 'gray.100' : 'white'}
												>
													<Td fontWeight={item?.code ? 'bold' : 400} color={item?.code ? '#3181CE' : 'gray.800'}>
														{item?.code ? formatAgentCode(item?.code) : 'N/A'}
													</Td>
													<Td>{item?.name}</Td>
													<Td>{item?.latinName}</Td>
													<Td>{item?.phoneNumber}</Td>
													<Td
														color={item.status === 'BLACKLISTED' ? '#c0392b' : 'gray.800'}
														fontWeight={item.status === 'BLACKLISTED' ? 'bold' : '400'}
													>
														{item?.idCardNumber}
													</Td>
													<Td>{item?.validFrom ? moment(item.validFrom).format('DD MMMM YYYY') : 'N/A'}</Td>
													<Td
														color={
															item.status === 'ACTIVE' && item.expireAt && moment(item.expireAt) < moment() ? 'red' : 'gray.800'
														}
													>
														{item.expireAt ? moment(item.expireAt).format('DD MMMM YYYY') : 'N/A'}
													</Td>
													<Td isNumeric>
														{item?.paymentStatus === 'PENDING' && <Badge colorScheme={'yellow'}>{t('Payment Pending')}</Badge>}
														{item.expireAt && moment(item.expireAt) < moment() && item.status === 'ACTIVE'
															? getExpireStatus()
															: getStatus(item?.status)}
													</Td>
												</Tr>
											);
										})}
									</Tbody>
									<Tfoot className="hidden-print">
										<Tr>
											<Th>{t('Registration number')}</Th>
											<Th>{t('Name')}</Th>
											<Th>{t('Latin name')}</Th>
											<Th>{t('Phone')}</Th>
											<Th>{t('ID card')}</Th>
											<Th>{t('validFrom')}</Th>
											<Th>{t('Expired At')}</Th>
											<Th isNumeric>{t('Agent status')}</Th>
										</Tr>
										<Tr>
											<Th />
											<Th />
											<Th />
											<Th />
											<Th />
											<Th />
											<Th />
											<Th isNumeric>
												{t('Total Rows')}: {data?.getAgents?.totalRows}
											</Th>
										</Tr>
									</Tfoot>
								</Table>
								<Flex justifyContent="flex-end" w="100%" mt="4">
									{data?.getAgents?.totalPages > 1 && (
										<ReactPaginate
											previousLabel={<ChevronLeftIcon fontSize="20px" />}
											nextLabel={<ChevronRightIcon fontSize="20px" />}
											breakLabel={'...'}
											breakClassName={'break-me'}
											pageCount={data?.getAgents?.totalPages}
											marginPagesDisplayed={2}
											pageRangeDisplayed={3}
											onPageChange={handlePageClick}
											containerClassName={'pagination'}
											activeClassName={'active'}
											initialPage={(currentPage - 1) | 0}
										/>
									)}
								</Flex>
								<Box justifyContent="flex-end" className="show-only-printing">
									<Text ml="2" mt="4">
										{t('Total Rows')}: <b>{data?.getAgents?.totalRows}</b>
									</Text>
								</Box>
								<Flex className="hidden-print" justifyContent="flex-end" mt="6">
									<Text mr="2">{t('Show more rows')}</Text>
									<Checkbox
										isChecked={rowLimit > 10}
										onChange={() => {
											handlePageClick({ selected: 0 });
											if (rowLimit > 10) {
												setRowLimit(10);
											} else {
												setRowLimit(300);
											}
										}}
									></Checkbox>
								</Flex>
							</Box>
						</Box>
					</Center>
				</Container>
				<AgentFilter
					callback={(e) => history.push(`/agent${e}`)}
					isOpen={isOpen}
					onClose={onClose}
					code={code}
					name={name}
					idCardNumber={idCardNumber}
					payment={payment}
					working={working}
					read={read}
					status={status}
				/>
				{openLeaveReportModal && <LeaveReportModal isOpen={true} onClose={() => setOpenLeaveReportModal(false)} />}
				{openExportActiveAgent && <ExcelExportModalActiveAgent onClose={() => setOpenExportActiveAgent(false)} />}
			</>
		</PrintLayout>
	);
}

export default Report;
