import { EWSTypeCurrency } from './ews';
import { isNumeric } from './market-development';

const ordNumColumn = 2;

function cleanORDCells(labels) {
	const cells = labels?.map((label, i) => [
		{ value: '', readOnly: false },
		{ value: '', readOnly: false },
	]);
	return cells;
}

function detailORDCells({ code = null, labels = [], detailLabels = [], readOnly = false }) {
	const localCells = labels?.map((label, i) => {
		const detailLabel = detailLabels?.find((el) => el?.templateLabel?.code === label?.code);
		if (detailLabel) {
			return [
				{ value: detailLabel?.values?.find((value) => value?.currency === EWSTypeCurrency.USD)?.value || 0, readOnly },
				{ value: detailLabel?.values?.find((value) => value?.currency === EWSTypeCurrency.KHR)?.value || 0, readOnly },
			];
		} else {
			return [
				{ value: '', readOnly },
				{ value: '', readOnly },
			];
		}
	});

	const { cells } = calculateORDCells({ code, cells: localCells, numLabel: labels?.length, readOnly });

	return cells;
}

function calculateORDCells({ code, cells, numLabel, readOnly = false }) {
	let localCells = cells?.map((cols, i) => {
		return [
			{ value: cols[0]?.value, readOnly },
			{ value: cols[1]?.value, readOnly },
		];
	});
	localCells = localCells?.slice(0, numLabel);
	return { code, cells: localCells };
}

function validateORDCells({ cells }) {
	const localCells = cells?.reduce((iPre, iCur) => {
		let localIPre = iPre;
		localIPre.push(
			...iCur?.reduce((jPre, jCur) => {
				let localJPre = jPre;
				if (!jCur?.readOnly && !isNumeric(jCur?.value)) {
					localJPre.push(jCur);
				}
				return localJPre;
			}, [])
		);
		return localIPre;
	}, []);
	return localCells?.length === 0;
}

export { ordNumColumn, cleanORDCells, detailORDCells, calculateORDCells, validateORDCells };
