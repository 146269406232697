import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Loading from './Loading';

const DataLoading = ({ loading = false, loadedData = null, children = null }) => {
	const { t } = useTranslation();

	return (
		<>
			{loading && <Loading />}
			{!loading && !loadedData && (
				<Flex w="100%" justifyContent="center" alignItems="center" direction="column" pt={24} pb={24} pl={0} pr={0}>
					<Text color="gray.400" mt={4}>
						{t('No Data')}
					</Text>
				</Flex>
			)}
			{!loading && loadedData && children({ loadedData })}
		</>
	);
};

export default DataLoading;
