/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { fileName, excelSale } from '../../Miscellaneous/export';
import ReactExport from 'react-export-excel';
import c from '../../../../constant';
import useStore from '../../../../store';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ExportExcel({ month = null, year = null, numColumns = 0, saleItems = [], ...props }) {
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const { currentLang } = useStore((state) => state.currentLang);

	useEffect(() => {
		setData(
			saleItems.reduce((pre, cur) => {
				let localPre = pre;
				let localCur = excelData(cur?.saleReportType, cur?.saleProducts);
				localPre.push(localCur);
				return localPre;
			}, [])
		);
	}, [saleItems]);

	function excelData(saleReportType = null, saleProducts = []) {
		const news = excelSale({
			title: t('New Policy'),
			saleProducts: saleProducts?.filter((el) => el?.policyType === 'New') || [],
			numColumns,
			total: t('Total'),
			lang: currentLang,
		});
		const renewals = excelSale({
			title: t('Renewal Policy'),
			saleProducts: saleProducts?.filter((el) => el?.policyType === 'Renewal') || [],
			numColumns,
			total: t('Total'),
			lang: currentLang,
		});
		return { name: saleReportType, data: [...news, ...renewals] };
	}

	return (
		<>
			<Box {...props}>
				<ExcelFile
					filename={fileName('Sale', 'General', month, year)}
					element={
						<>
							<Button
								isLoading={false}
								isDisabled={false}
								onClick={() => null}
								leftIcon={<FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 16 }} />}
								colorScheme="gray"
								variant="solid"
								size="sm"
								borderRadius={c.borderRadius}
								border="1px solid #bdc3c7"
							>
								{t('Export')}
							</Button>
						</>
					}
				>
					{data?.map((el, i) => {
						// const data = el?.labels?.map((label, j) => {
						// 	return {
						// 		title: currentLang === 'kh' ? el?.titleKhmer : el?.titleEnglish,
						// 	};
						// });
						return (
							<ExcelSheet key={i} data={el?.data} name={t(el?.name)}>
								<ExcelColumn label={t('Ordinal')} value="0" />
								<ExcelColumn label={t('Product Line')} value="1" />
								<ExcelColumn
									label={currentLang === 'kh' ? '> $5,000 (បណ្ណសន្យារ៉ាប់រង_បុព្វលាភធានារ៉ាប់រងដុល)' : '> $5000 (Policy_Gross Premium)'}
									value="2"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? '≤ $5,000 (បណ្ណសន្យារ៉ាប់រង_បុព្វលាភធានារ៉ាប់រងដុល)' : '≤ $5000 (Policy_Gross Premium)'}
									value="3"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? '> $5,000 (បណ្ណសន្យារ៉ាប់រង_ទឹកប្រាក់ធានារ៉ាប់រង)' : '> $5000 (Policy_Sum insured)'}
									value="4"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? '≤ $5,000 (បណ្ណសន្យារ៉ាប់រង_ទឹកប្រាក់ធានារ៉ាប់រង)' : '> $5000 (Policy_Sum insured)'}
									value="5"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? '> $5,000 (បណ្ណសន្យារ៉ាប់រង_ចំនួនបណ្ណសន្យារ៉ាប់រង)' : '> $5000 (Policy_Number of Policies)'}
									value="6"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? '≤ $5,000 (បណ្ណសន្យារ៉ាប់រង_ចំនួនបណ្ណសន្យារ៉ាប់រង)' : '> $5000 (Policy_Number of Policies)'}
									value="7"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh'
											? '> $5,000 ប្រុស (បណ្ណសន្យារ៉ាប់រង_ចំនួនដែលត្រូវបានធានា)'
											: '> $5000 Male (Policy_Number of Insured)'
									}
									value="8"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh'
											? '> $5,000 ស្រី (បណ្ណសន្យារ៉ាប់រង_ចំនួនដែលត្រូវបានធានា)'
											: '> $5000 Female (Policy_Number of Insured)'
									}
									value="9"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh'
											? '> $5,000 ឯកតាផ្សេង (បណ្ណសន្យារ៉ាប់រង_ចំនួនដែលត្រូវបានធានា)'
											: '> $5000 Other Unit (Policy_Number of Insured)'
									}
									value="10"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh'
											? '≤ $5,000 ប្រុស (បណ្ណសន្យារ៉ាប់រង_ចំនួនដែលត្រូវបានធានា)'
											: '≤ $5000 Male (Policy_Number of Insured)'
									}
									value="11"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh'
											? '≤ $5,000 ស្រី (បណ្ណសន្យារ៉ាប់រង_ចំនួនដែលត្រូវបានធានា)'
											: '≤ $5000 Female (Policy_Number of Insured)'
									}
									value="12"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh'
											? '≤ $5,000 ឯកតាផ្សេង (បណ្ណសន្យារ៉ាប់រង_ចំនួនដែលត្រូវបានធានា)'
											: '≤ $5000 Other Unit (Policy_Number of Insured)'
									}
									value="13"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh'
											? '> $5,000 (បណ្ណសន្យារ៉ាប់រងលុបចោល/បញ្ចប់_ចំនួនបណ្ណសន្យារ៉ាប់រងបញ្ចប់ក្នុងរយៈពេលសាកល្បង)'
											: '> $5000 (Cancelled Policies_Number of Cancellation)'
									}
									value="14"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh'
											? '≤ $5,000 (បណ្ណសន្យារ៉ាប់រងលុបចោល/បញ្ចប់_ចំនួនបណ្ណសន្យារ៉ាប់រងបញ្ចប់ក្នុងរយៈពេលសាកល្បង)'
											: '≤ $5000 (Cancelled Policies_Number of Cancellation)'
									}
									value="15"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh'
											? '> $5,000 (បណ្ណសន្យារ៉ាប់រងលុបចោល/បញ្ចប់_បុព្វលាភធានារ៉ាប់រងដុល)'
											: '> $5000 (Cancelled Policies_Gross Premium)'
									}
									value="16"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh'
											? '≤ $5,000 (បណ្ណសន្យារ៉ាប់រងលុបចោល/បញ្ចប់_បុព្វលាភធានារ៉ាប់រងដុល)'
											: '≤ $5000 (Cancelled Policies_Gross Premium)'
									}
									value="17"
								/>
							</ExcelSheet>
						);
					})}
				</ExcelFile>
			</Box>
		</>
	);
}
