/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text, Table, Tbody, Tfoot, Tr, Th, Td, Badge, Tooltip, Checkbox, useToast, Center } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { gql, useLazyQuery } from '@apollo/client';
import './style.css';
import c from '../../constant';
import useStore from '../../store';
import ReactPaginate from 'react-paginate';
import LoadingTable from '../../components/Table/Loading';
import useMoment from '../../hooks/useMoment';
import PrintLayout from '../Print/PrintLayout';
import LeaveUse from './LeaveUse';

const Q_GET_EMPLOYEE_LEAVES = gql`
	query GetLeaveRequestsByEmployeeId($employeeId: String!, $limit: Float!, $offset: Float!, $status: LeaveStatusType) {
		getLeaveRequestsByEmployeeId(employeeId: $employeeId, pagerInput: { limit: $limit, offset: $offset }, status: $status) {
			totalPages
			totalRows
			leaves {
				id
				no
				from
				to
				comeback
				purpose
				amount
				createdBy
				createdAt
				from
				to
				approveByDepartment
				approveByDepartmentBy
				approveByDepartmentAt
				approveByDirectorGeneral
				approveByDirectorGeneralBy
				approveByDirectorGeneralAt
				user {
					username
					fullName
					fullNameKhmer
					avatar
					department
				}
				userDepartment {
					username
					fullName
					fullNameKhmer
				}
				userDG {
					username
					fullName
					fullNameKhmer
				}
				notification {
					read
				}
			}
		}
	}
`;

let minLimit = 10;
let maxLimit = 300;
let offset = 0;
let status = 'APPROVED';

function LeaveRequest({ employeeId }) {
	const history = useHistory();
	const toast = useToast();
	const { t } = useTranslation();
	const { toDateString } = useMoment();
	const { currentLang } = useStore((state) => state.currentLang);
	const [isShowingMore, setShowingMore] = useState(false);

	const [loadUserLeaveRequests, { error, data, loading }] = useLazyQuery(Q_GET_EMPLOYEE_LEAVES);

	const employeeChangeCallback = useCallback(() => {
		offset = 0;
	}, [employeeId]);

	const loadUserLeaveRequestsCallback = useCallback(() => {
		const variables = {
			employeeId,
			limit: isShowingMore ? maxLimit : minLimit,
			offset,
			status,
		};
		loadUserLeaveRequests({ variables });
	}, [employeeId, isShowingMore]);

	useEffect(() => {
		employeeChangeCallback();
	}, [employeeChangeCallback]);

	useEffect(() => {
		loadUserLeaveRequestsCallback();
	}, [loadUserLeaveRequestsCallback]);

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	function getApproveStatus(approve, date, user) {
		let res = null;
		if (approve === null) {
			res = <Badge colorScheme="yellow">{t('In progress')}</Badge>;
		} else if (approve === true) {
			res = <Badge colorScheme="green">{t('Approved Leave')}</Badge>;
		} else {
			res = <Badge colorScheme="red">{t('Rejected Leave')}</Badge>;
		}
		if (date) {
			res = <Tooltip label={`${toDateString(date, c.longDateFormat)} ${t('By')} ${getDisplayName(user)}`}>{res}</Tooltip>;
		}
		return res;
	}

	function checkReadRow(item) {
		let read = true;
		read = item?.notification?.read;
		return read;
	}

	function getDisplayName(user) {
		let displayName = user?.username;
		if (currentLang === 'kh') {
			displayName = user?.fullNameKhmer ? user?.fullNameKhmer : user?.fullName ? user?.fullName : user?.username;
		} else {
			displayName = user?.fullName ? user?.fullName : user?.fullNameKhmer ? user?.fullNameKhmer : user?.username;
		}
		return displayName;
	}

	function checkIfDeputyDirector(department) {
		const dp = department?.split(',');
		if (dp?.length > 1) {
			return true;
		} else {
			return false;
		}
	}

	function onShowingMore() {
		offset = 0;
		setShowingMore(!isShowingMore);
	}

	function onPageChange(value) {
		offset = value * (isShowingMore ? maxLimit : minLimit);
		loadUserLeaveRequestsCallback();
	}

	return (
		<PrintLayout>
			<Center className="show-only-printing">
				<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
					របាយការណ៍សុំច្បាប់
				</Text>
			</Center>
			<LeaveUse employeeId={employeeId} />
			<Center className="content-detail-leave">
				<Box w="100%" bg="white" borderRadius={c.borderRadius} border="1px solid #dbdbdb" boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)" pb={4}>
					<Flex
						className="hidden-print"
						h="48px"
						p={4}
						pt={0}
						pb={0}
						mb={4}
						bg="#FAFAFA"
						alignItems="center"
						justifyContent="space-between"
						borderBottom="1px solid #dbdbdb"
					/>
					<Box p="16px">
						<Table variant="simple">
							<Tbody>
								<Tr className="td-as-th">
									<Th>{t('Number')}</Th>
									<Th>{t('Name')}</Th>
									<Th>{t('Purpose')}</Th>
									<Th isNumeric>{t('Leave amount (Days)')}</Th>
									<Th>{t('Leave from date')}</Th>
									<Th>{t('Leave to date')}</Th>
									<Th>{t('Created At')}</Th>
									<Th isNumeric>{t('Approved Leave By Department')}</Th>
									<Th isNumeric>{t('Approved Leave By DG')}</Th>
								</Tr>
								{loading && <LoadingTable column={9} row={5} />}
								{data?.getLeaveRequestsByEmployeeId?.leaves.length <= 0 && (
									<Tr>
										<Td>
											<Text>{t('No Data')}</Text>
										</Td>
									</Tr>
								)}
								{data?.getLeaveRequestsByEmployeeId?.leaves?.map((item, index) => {
									return (
										<Tr
											_hover={{ bg: 'gray.200' }}
											cursor="pointer"
											onClick={() => history.push(`/request-leave/detail?id=${item.id}`)}
											key={`item-${index}`}
											bg={checkReadRow(item) === false ? 'gray.100' : 'white'}
										>
											<Td>{item?.no}</Td>
											<Td>{getDisplayName(item?.user)}</Td>
											<Td maxW={350}>
												<Text noOfLines={3}>{item?.purpose}</Text>
											</Td>
											<Td isNumeric>{item.amount}</Td>
											<Td>{toDateString(item.from, c.longDateFormat)}</Td>
											<Td>{toDateString(item.to, c.longDateFormat)}</Td>
											<Td>{toDateString(item.createdAt, c.longDateFormat)}</Td>
											<Td isNumeric>
												{checkIfDeputyDirector(item?.user?.department)
													? null
													: getApproveStatus(item?.approveByDepartment, item?.approveByDepartmentAt, item?.userDepartment)}
											</Td>
											<Td isNumeric>
												{getApproveStatus(item?.approveByDirectorGeneral, item?.approveByDirectorGeneralAt, item?.userDG)}
											</Td>
										</Tr>
									);
								})}
							</Tbody>
							<Tfoot className="hidden-print">
								<Tr>
									<Th>{t('Number')}</Th>
									<Th>{t('Name')}</Th>
									<Th>{t('Purpose')}</Th>
									<Th isNumeric>{t('Leave amount (Days)')}</Th>
									<Th>{t('Leave from date')}</Th>
									<Th>{t('Leave to date')}</Th>
									<Th>{t('Created At')}</Th>
									<Th isNumeric>{t('Approved Leave By Department')}</Th>
									<Th isNumeric>{t('Approved Leave By DG')}</Th>
								</Tr>
								<Tr>
									<Th />
									<Th />
									<Th />
									<Th />
									<Th />
									<Th />
									<Th />
									<Th />
									<Th isNumeric>
										{t('Total Rows')}: {data?.getLeaveRequestsByEmployeeId?.totalRows}
									</Th>
								</Tr>
							</Tfoot>
						</Table>
						<Flex justifyContent="flex-end" w="100%" mt="4">
							{data?.getLeaveRequestsByEmployeeId?.totalPages > 1 && (
								<ReactPaginate
									previousLabel={<ChevronLeftIcon fontSize="20px" />}
									nextLabel={<ChevronRightIcon fontSize="20px" />}
									breakLabel={'...'}
									breakClassName={'break-me'}
									pageCount={data?.getLeaveRequestsByEmployeeId?.totalPages}
									marginPagesDisplayed={2}
									pageRangeDisplayed={3}
									disableInitialCallback={true}
									onPageChange={({ selected }) => onPageChange(selected)}
									containerClassName={'pagination'}
									activeClassName={'active'}
									initialPage={offset / (isShowingMore ? maxLimit : minLimit)}
								/>
							)}
						</Flex>
						<Box justifyContent="flex-end" className="show-only-printing">
							<Text ml="2" mt="4">
								{t('Total Rows')}: <b>{data?.getLeaveRequestsByEmployeeId?.totalRows}</b>
							</Text>
						</Box>
						<Flex className="hidden-print" justifyContent="flex-end" mt="6">
							<Text mr="2">{t('Show more rows')}</Text>
							<Checkbox isChecked={isShowingMore} onChange={onShowingMore} />
						</Flex>
					</Box>
				</Box>
			</Center>
		</PrintLayout>
	);
}

export default LeaveRequest;
