/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Container,
	Box,
	Flex,
	Text,
	Center,
	Button,
	Tooltip,
	useDisclosure,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	useToast,
	Spinner,
} from '@chakra-ui/react';
import { useLocation, useHistory } from 'react-router-dom';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import LoadingOverlayText from '../../components/LoadingOverlay/LoadingOverlayText';
import DownloadAndViewButton from '../../components/Files/DownloadAndViewButton';
import useStore from '../../store';
import getDepartmentLabel from '../../utils/getDepartmentLabel';
import PrintLayout from '../../components/Print/PrintLayout';
import AddDocumentNumberFileModal from '../../components/Modal/AddDocumentNumberFileModal';
import ProposalDetailModal from '../CompanySubmissionProposal/detailModal';
import moment from 'moment';
import 'moment/locale/en-gb';
import c from '../../constant';

const QUERY_DATA = gql`
	query get($id: String!) {
		getDocumentNumberById(id: $id) {
			id
			subject
			attachmentFile
			createdBy
			createdAt
			type
			language
			department
			companyName
			updateLogs
			number
			year
			displayNumber
			proposalNumber
			company {
				id
				name
				nameKhmer
			}
			writeDate
			references {
				documentNumberTypeReferenceValue
				documentNumberTypeReference {
					id
					enTitle
					khTitle
				}
			}
		}
	}
`;

const M_DELETE_DATA = gql`
	mutation delete($id: String!) {
		deleteDocumentNumber(id: $id)
	}
`;

const Q_PROPOSAL_ID = gql`
	query get($proposalNumber: Float!) {
		getCompanySubmissionsProposalIdByProposalNumber(proposalNumber: $proposalNumber)
	}
`;

const leftLabelWidth = '160px';
const paddingTopRow = 1;
const paddingBottomRow = 1;
function AddCompanyProposalSuccess() {
	const toast = useToast();
	const history = useHistory();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const { t } = useTranslation();
	const id = urlParams.get('id') || null;
	const typeId = urlParams.get('typeId') || null;
	const typeLabel = urlParams.get('typeLabel') || null;
	const [loadData, { loading, data }] = useLazyQuery(QUERY_DATA);
	const { currentLang } = useStore((state) => state.currentLang);
	const { currentUser: user } = useStore((state) => state.currentUser);
	moment.locale(currentLang === 'kh' ? 'km' : 'en-gb');
	const [viewingId, setViewingId] = useState(null);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenConfirm, onClose: onCloseConfirm, onOpen: onOpenConfirm } = useDisclosure();
	const [deleteData, { loading: loadingDelete, error: errorDelete, data: dataDelete }] = useMutation(M_DELETE_DATA);

	useEffect(() => {
		loadData({
			variables: {
				id,
			},
		});
	}, []);

	useEffect(() => {
		if (errorDelete) {
			if (errorDelete?.graphQLErrors?.length > 0) {
				errorDelete?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorDelete?.message ? errorDelete?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorDelete]);

	useEffect(() => {
		if (dataDelete?.deleteDocumentNumber) {
			toast({
				title: t('Document number deleted successfully'),
				status: 'success',
				duration: 5000,
				isClosable: true,
				position: 'bottom-right',
			});
			history.push(`/issued-letter/list?page=1&typeId=${typeId}&typeLabel=${typeLabel}`);
		}
	}, [dataDelete]);

	function row(label, value, isFile = false) {
		return (
			<Flex style={{ pageBreakInside: 'avoid' }} pt={paddingTopRow} pb={paddingBottomRow}>
				<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
					{t(label)}
				</Text>
				<Box>
					{value?.file ? (
						<Box>
							<DownloadAndViewButton url={value?.file} size={value?.fileSize} />
						</Box>
					) : (
						<>
							{isFile ? (
								<>
									{user?.canUpdateIssuedLetter ? (
										<>
											<Box ml={4}>
												<Tooltip label={t('Add Attachment File')}>
													<Flex
														userSelect="none"
														onClick={onOpen}
														cursor="pointer"
														alignItems="center"
														justifyContent="center"
														w="120px"
														h="32px"
														borderRadius={c.borderRadius}
														border="1px solid #dbdbdb"
														boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
													>
														<Text>{t('Add Attachment File')}</Text>
													</Flex>
												</Tooltip>
											</Box>
										</>
									) : (
										<>
											<Text ml="4" fontWeight="bold">
												{'N/A'}
											</Text>
										</>
									)}
								</>
							) : (
								<Text ml="4" fontWeight="bold">
									{value ? t(value) : 'N/A'}
								</Text>
							)}
						</>
					)}
				</Box>
			</Flex>
		);
	}

	function getDisplayCompanyName(item) {
		let displayName = item?.companyName;
		if (item?.company?.id) {
			if (currentLang === 'kh') {
				displayName = item?.company?.nameKhmer;
			} else {
				displayName = item?.company?.name;
			}
		}
		return displayName;
	}

	function NumberReference({ id = null, item = null }) {
		const [getProposalId, { data, loading }] = useLazyQuery(Q_PROPOSAL_ID);

		useEffect(() => {
			if (item) {
				const variables = { proposalNumber: parseInt(item?.numDp) };
				getProposalId({ variables });
			}
		}, [item]);

		return (
			<Flex
				key={`k-num-${id}`}
				style={{ pageBreakInside: 'avoid' }}
				pt={paddingTopRow}
				pb={paddingBottomRow}
				_hover={{ cursor: data?.getCompanySubmissionsProposalIdByProposalNumber ? 'zoom-in' : 'auto' }}
				onMouseDown={() => {
					if (data?.getCompanySubmissionsProposalIdByProposalNumber) {
						setViewingId(data?.getCompanySubmissionsProposalIdByProposalNumber);
					}
				}}
			>
				<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
					{currentLang === 'kh' ? item?.khTitle : item?.enTitle}
				</Text>
				<Flex justifyContent="center" alignItems="center">
					<Text ml="4" fontWeight="bold">
						{item?.numDp}
					</Text>
					{loading && <Spinner ml={1} size="xs" color="blue.300" />}
				</Flex>
			</Flex>
		);
	}

	function displayNumberRef(data) {
		let numTypes = [];
		if (data?.length > 0) {
			data?.forEach((item) => {
				let pushTo = true;
				numTypes?.forEach((nT) => {
					if (nT?.id === item?.documentNumberTypeReference?.id) {
						pushTo = false;
					}
				});
				if (pushTo) {
					numTypes.push(item?.documentNumberTypeReference);
				}
			});
		}
		let displayData = [];
		numTypes?.forEach((nT) => {
			let numDp = '';
			data?.forEach((item) => {
				if (item?.documentNumberTypeReference?.id === nT?.id) {
					numDp ? (numDp += `, ${item?.documentNumberTypeReferenceValue}`) : (numDp = item?.documentNumberTypeReferenceValue);
				}
			});
			displayData.push({ ...nT, numDp });
		});

		return (
			<>
				{displayData?.map((item, index) => (
					<NumberReference id={index} item={item} />
				))}
			</>
		);
	}

	return (
		<PrintLayout>
			<>
				{loading && <LoadingOverlayText />}
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'ISSUED LETTER',
								path: '/issued-letter?page=1&count=300',
							},
							{
								name: typeLabel || '#',
								path: `/issued-letter/list?page=1&typeId=${typeId}&typeLabel=${typeLabel}`,
							},
							{
								name: 'Detail',
								path: '#',
							},
						]}
					/>
					<Center className="show-only-printing">
						<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
							លេខ​ឯកសារ
						</Text>
					</Center>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="32px"
						>
							<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
								<Text fontSize="x-large" fontWeight="bold">
									{t('Detail')}
								</Text>
								<Flex>
									{user?.canUpdateIssuedLetter && (
										<>
											<Link to={`/issued-letter/edit?typeId=${typeId}&typeLabel=${typeLabel}&id=${id}`}>
												<Button
													mr="2"
													leftIcon={<FontAwesomeIcon icon={faEdit} style={{ fontSize: 16 }} />}
													colorScheme="gray"
													variant="solid"
													size="sm"
													borderRadius={c.borderRadius}
													border="1px solid #bdc3c7"
												>
													{t('Edit')}
												</Button>
											</Link>
										</>
									)}
									{user?.canDeleteIssuedLetter && (
										<>
											<Button
												mr="0"
												leftIcon={<FontAwesomeIcon icon={faTrash} style={{ fontSize: 16 }} />}
												colorScheme="red"
												variant="solid"
												size="sm"
												borderRadius={c.borderRadius}
												border="1px solid #bdc3c7"
												onClick={onOpenConfirm}
											>
												{t('Delete')}
											</Button>
										</>
									)}
								</Flex>
							</Flex>

							{data?.getDocumentNumberById && (
								<Box p="16px" pt="0" className="leave-detail-container">
									{row('Number', data?.getDocumentNumberById?.displayNumber)}
									{row('Subject', data?.getDocumentNumberById?.subject)}
									{row('Date', moment(data?.getDocumentNumberById?.writeDate).format(c.dateOnlyFormat))}
									{row('Company', getDisplayCompanyName(data?.getDocumentNumberById))}
									{row(
										'Department',
										data?.getDocumentNumberById?.department ? getDepartmentLabel(data?.getDocumentNumberById?.department) : 'N/A'
									)}
									{row('year', data?.getDocumentNumberById?.year)}
									<>{displayNumberRef(data?.getDocumentNumberById?.references)}</>
									{row('File', data?.getDocumentNumberById?.attachmentFile, true)}
								</Box>
							)}
						</Box>
					</Center>
				</Container>
				{isOpen && (
					<AddDocumentNumberFileModal
						id={id}
						isOpen={true}
						onClose={onClose}
						onCallback={() =>
							loadData({
								variables: {
									id,
								},
							})
						}
					/>
				)}
			</>
			<AlertDialog
				isOpen={isOpenConfirm}
				onClose={onCloseConfirm}
				closeOnEsc={loadingDelete ? false : true}
				closeOnOverlayClick={loadingDelete ? false : true}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Delete')}
						</AlertDialogHeader>
						<AlertDialogBody>{t("Are you sure? You can't undo this action afterwards")}</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={4} isLoading={loadingDelete} onClick={onCloseConfirm}>
								{t('Cancel')}
							</Button>
							<Button
								isLoading={loadingDelete}
								colorScheme="red"
								onClick={() => {
									const variables = { id };
									deleteData({ variables });
								}}
							>
								{t('Delete')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			{viewingId && <ProposalDetailModal id={viewingId} onCloseModal={() => setViewingId(null)} />}
		</PrintLayout>
	);
}

export default AddCompanyProposalSuccess;
