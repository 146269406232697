/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Flex, SimpleGrid, Text, Tooltip, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { formatCurrency } from '../../../../utils/format';
import DataLoading from '../../../DataLoading';

const Q_DASHBOARD = gql`
	query get($companyType: InsuranceInstitutionsType, $companyId: String, $range: MDDataDateRangeInput) {
		getClaimDashboard(companyType: $companyType, companyId: $companyId, range: $range)
	}
`;

function Sidebar({ companyType = null, companyId = null, range = null }) {
	const toast = useToast();
	const { t } = useTranslation();
	const [getDashboard, { data, loading, error }] = useLazyQuery(Q_DASHBOARD, { fetchPolicy: 'no-cache' });

	useEffect(load, [companyType, companyId, range]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	function load() {
		const variables = { companyType, companyId, range: range?.from && range?.to ? range : null };
		getDashboard({ variables });
	}

	return (
		<>
			<DataLoading loading={loading} loadedData={data?.getClaimDashboard}>
				{({ loadedData }) => (
					<SimpleGrid w="100%" columns={[1, 1, 2, 2]} spacing={[4]}>
						<Flex width="100%" direction="column" justifyContent={'center'} alignItems={'center'}>
							<Tooltip label={<>{t('Claim Incurred Amount')}</>}>
								<Text fontSize={['sm', '10px', 'sm']} fontWeight="semibold" textAlign={['center', 'center', 'left']} textColor="gray.500">
									{t('Claim Incurred Amount')}
								</Text>
							</Tooltip>
							<Text
								fontSize={['2xl', '2xl', '3xl']}
								fontWeight="bold"
								textAlign={['center', 'center', 'left']}
								color={loadedData?.claimIncurredAmount?.color}
							>
								{formatCurrency(loadedData?.claimIncurredAmount?.value)}
							</Text>
						</Flex>
						<Flex width="100%" direction="column" justifyContent={'center'} alignItems={'center'}>
							<Tooltip label={<>{t('Claim Paid Amount')}</>}>
								<Text fontSize={['sm', '10px', 'sm']} fontWeight="semibold" textAlign={['center', 'center', 'left']} textColor="gray.500">
									{t('Claim Paid Amount')}
								</Text>
							</Tooltip>
							<Text
								fontSize={['2xl', '2xl', '3xl']}
								fontWeight="bold"
								textAlign={['center', 'center', 'left']}
								color={loadedData?.claimPaidAmount?.color}
							>
								{formatCurrency(loadedData?.claimPaidAmount?.value)}
							</Text>
						</Flex>
						<Flex width="100%" direction="column" justifyContent={'center'} alignItems={'center'}>
							<Tooltip label={<>{t('Claim Denied Amount')}</>}>
								<Text fontSize={['sm', '10px', 'sm']} fontWeight="semibold" textAlign={['center', 'center', 'left']} textColor="gray.500">
									{t('Claim Denied Amount')}
								</Text>
							</Tooltip>
							<Text
								fontSize={['2xl', '2xl', '3xl']}
								fontWeight="bold"
								textAlign={['center', 'center', 'left']}
								color={loadedData?.claimDeniedAmount?.color}
							>
								{formatCurrency(loadedData?.claimDeniedAmount?.value)}
							</Text>
						</Flex>
						<Flex width="100%" direction="column" justifyContent={'center'} alignItems={'center'}>
							<Tooltip label={<>{t('Claim Outstanding Amount')}</>}>
								<Text fontSize={['sm', '10px', 'sm']} fontWeight="semibold" textAlign={['center', 'center', 'left']} textColor="gray.500">
									{t('Claim Outstanding Amount')}
								</Text>
							</Tooltip>
							<Text
								fontSize={['2xl', '2xl', '3xl']}
								fontWeight="bold"
								textAlign={['center', 'center', 'left']}
								color={loadedData?.claimOutstandingAmount?.color}
							>
								{formatCurrency(loadedData?.claimOutstandingAmount?.value)}
							</Text>
						</Flex>
					</SimpleGrid>
				)}
			</DataLoading>
		</>
	);
}

export default Sidebar;
