/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, Input, Button, useToast, InputRightElement, InputGroup, FormLabel } from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import CheckListFormField from '../../components/form/CheckListFormFieldNested';
import TextFormField from '../../components/form/TextFormField';
import RadioFormField from '../../components/form/RadioFormField';
import SelectFormField from '../../components/form/SelectFormField';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import useStore from '../../store';
import c from '../../constant';
import * as Yup from 'yup';

const CONVERT_USER_TO_EMPLOYEE = gql`
	mutation convert($username: String!) {
		convertUserToEmployeeByUsername(username: $username)
	}
`;

const QUERY_USER = gql`
	query user($id: String!) {
		getUserById(id: $id) {
			id
			username
			fullName
			fullNameKhmer
			createdAt
			role
			companyReportType
			department
			office
			canAssignUserToCompanyProposal
			canAssignUserToCompanyReport
			canUploadDoneFileToCompanyProposal
			canViewListReportToCompanyProposal
			canAutoreassignProposal
			financialReportTypes
			canUpdateCompanyLicensing
			canUpdateCompanyGovernance
			canUpdateCompanyContact
			canApproveRejectLeaveRequest
			canViewLeaveRequestReport
			canNotApplyForLeave
			canReviewAgentRequest
			canApproveRejectDeactivateAgent
			canFinalApproveRejectAgent
			canUploadDoneFileToComplaint
			canAssignUserToComplaint
			position
			paymentAgentRegistration
			paymentPublicService
			paymentLicense
			canUpdateLicense
			paymentFine
			canUpdateFine
			canCreateIssuedLetter
			canUpdateIssuedLetter
			canDeleteIssuedLetter
			mdd
			ews
		}
	}
`;

const UPDATE_USER_MUTATION = gql`
	mutation updateUserByAdmin(
		$id: String!
		$fullName: String
		$fullNameKhmer: String
		$newPassword: String
		$role: String
		$companyReportType: String
		$department: String
		$office: String
		$active: Boolean
		$canAssignUserToCompanyProposal: Boolean
		$canAssignUserToCompanyReport: Boolean
		$canUploadDoneFileToCompanyProposal: Boolean
		$canViewListReportToCompanyProposal: Boolean
		$canAutoreassignProposal: Boolean
		$financialReportTypes: String
		$canUpdateCompanyLicensing: Boolean
		$canUpdateCompanyGovernance: Boolean
		$canUpdateCompanyContact: Boolean
		$canApproveRejectLeaveRequest: Boolean
		$canViewLeaveRequestReport: Boolean
		$canNotApplyForLeave: Boolean
		$canReviewAgentRequest: Boolean
		$canApproveRejectDeactivateAgent: Boolean
		$canFinalApproveRejectAgent: Boolean
		$canUploadDoneFileToComplaint: Boolean
		$canAssignUserToComplaint: Boolean
		$position: String
		$paymentAgentRegistration: Boolean
		$paymentPublicService: Boolean
		$paymentLicense: Boolean
		$canUpdateLicense: Boolean
		$paymentFine: Boolean
		$canUpdateFine: Boolean
		$canCreateIssuedLetter: Boolean
		$canUpdateIssuedLetter: Boolean
		$canDeleteIssuedLetter: Boolean
		$mdd: JSON
		$ews: JSON
	) {
		updateUserByAdmin(
			id: $id
			fullName: $fullName
			fullNameKhmer: $fullNameKhmer
			newPassword: $newPassword
			role: $role
			active: $active
			companyReportType: $companyReportType
			department: $department
			office: $office
			canAssignUserToCompanyProposal: $canAssignUserToCompanyProposal
			canAssignUserToCompanyReport: $canAssignUserToCompanyReport
			canUploadDoneFileToCompanyProposal: $canUploadDoneFileToCompanyProposal
			canViewListReportToCompanyProposal: $canViewListReportToCompanyProposal
			canAutoreassignProposal: $canAutoreassignProposal
			financialReportTypes: $financialReportTypes
			canUpdateCompanyLicensing: $canUpdateCompanyLicensing
			canUpdateCompanyGovernance: $canUpdateCompanyGovernance
			canUpdateCompanyContact: $canUpdateCompanyContact
			canApproveRejectLeaveRequest: $canApproveRejectLeaveRequest
			canViewLeaveRequestReport: $canViewLeaveRequestReport
			canNotApplyForLeave: $canNotApplyForLeave
			canReviewAgentRequest: $canReviewAgentRequest
			canApproveRejectDeactivateAgent: $canApproveRejectDeactivateAgent
			canFinalApproveRejectAgent: $canFinalApproveRejectAgent
			canUploadDoneFileToComplaint: $canUploadDoneFileToComplaint
			canAssignUserToComplaint: $canAssignUserToComplaint
			position: $position
			paymentAgentRegistration: $paymentAgentRegistration
			paymentPublicService: $paymentPublicService
			paymentLicense: $paymentLicense
			canUpdateLicense: $canUpdateLicense
			paymentFine: $paymentFine
			canUpdateFine: $canUpdateFine
			canCreateIssuedLetter: $canCreateIssuedLetter
			canUpdateIssuedLetter: $canUpdateIssuedLetter
			canDeleteIssuedLetter: $canDeleteIssuedLetter
			mdd: $mdd
			ews: $ews
		)
	}
`;

const signUpSchema = Yup.object().shape({
	username: Yup.string().required('Required'),
	fullName: Yup.string(),
	fullNameKhmer: Yup.string(),
	password: Yup.string().min(8),
	role: Yup.array().min(1, 'Required').required('Required'),
	companyReportType: Yup.array(),
	companyProposalPermission: Yup.array(),
	complaintPermission: Yup.array(),
	companyReportPermission: Yup.array(),
	companyPermission: Yup.array(),
	department: Yup.array(),
	office: Yup.string(),
	financialReportTypes: Yup.array(),
	requestLeavePermission: Yup.array(),
	agentPermission: Yup.array(),
	position: Yup.string(),
	paymentPermission: Yup.array(),
	issuedLetter: Yup.array(),
	mdd: Yup.array().nullable(),
	ews: Yup.array().nullable(),
});

function Update() {
	const history = useHistory();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const [tmpRole, setTmpRole] = useState([]);
	const [tmpReportType, setTmpReportType] = useState([]);
	const [departmentCallback, setDepartmentCallback] = useState(null);
	const toast = useToast();
	const { t } = useTranslation();
	const [loadUsers, { loading: loadingUser, data: dataUser }] = useLazyQuery(QUERY_USER);
	const [updateUserByAdmin, { loading, error, data }] = useMutation(UPDATE_USER_MUTATION);
	const [username, setUsername] = useState('');
	const [convertUserToEmployee] = useMutation(CONVERT_USER_TO_EMPLOYEE);
	const { currentLang } = useStore((state) => state.currentLang);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		loadUsers({ variables: { id } });
	}, []);

	function updateUser(value) {
		setUsername(value.username);
		updateUserByAdmin({
			variables: {
				id: id,
				fullName: value.fullName,
				fullNameKhmer: value.fullNameKhmer,
				newPassword: value.password,
				role: arrayRoleToString(value.role),
				active: true,
				companyReportType: arrayRoleToString(value.companyReportType),
				department: arrayRoleToString(value?.department),
				office: value?.office,
				canAssignUserToCompanyProposal: value.companyProposalPermission?.includes('canAssignUser') ? true : false,
				canAssignUserToCompanyReport: value.companyReportPermission?.includes('canAssignUser') ? true : false,
				canUploadDoneFileToCompanyProposal: value.companyProposalPermission?.includes('canUploadDoneFile') ? true : false,
				canViewListReportToCompanyProposal: value.companyProposalPermission?.includes('canViewListReport') ? true : false,
				canAutoreassignProposal: value.companyProposalPermission?.includes('canAutoreassignProposal') ? true : false,

				canUpdateCompanyLicensing: arrayRoleToString(value.companyPermission).includes('canUpdateLicensing') ? true : false,
				canUpdateCompanyGovernance: arrayRoleToString(value.companyPermission).includes('canUpdateGovernance') ? true : false,
				canUpdateCompanyContact: arrayRoleToString(value.companyPermission).includes('canUpdateContact') ? true : false,

				financialReportTypes: arrayRoleToString(value.financialReportTypes),
				canApproveRejectLeaveRequest: value.requestLeavePermission?.includes('canApproveRejectLeaveRequest') ? true : false,
				canViewLeaveRequestReport: value.requestLeavePermission?.includes('canViewLeaveRequestReport') ? true : false,
				canNotApplyForLeave: value.requestLeavePermission?.includes('canNotApplyForLeave') ? true : false,

				canReviewAgentRequest: value.agentPermission?.includes('canReviewAgentRequest') ? true : false,
				canApproveRejectDeactivateAgent: value.agentPermission?.includes('canApproveRejectDeactivateAgent') ? true : false,
				canFinalApproveRejectAgent: value.agentPermission?.includes('canFinalApproveRejectAgent') ? true : false,

				canUploadDoneFileToComplaint: value.complaintPermission?.includes('canUploadDoneFile') ? true : false,
				canAssignUserToComplaint: value.complaintPermission?.includes('canAssignComplaint') ? true : false,
				position: value?.position ? value?.position : '',
				paymentAgentRegistration: value.paymentPermission?.includes('paymentAgentRegistration') ? true : false,
				paymentPublicService: value.paymentPermission?.includes('paymentPublicService') ? true : false,

				paymentLicense: value.paymentPermission?.includes('paymentLicense') ? true : false,
				canUpdateLicense: value.paymentPermission?.includes('canUpdateLicense') ? true : false,
				paymentFine: value.paymentPermission?.includes('paymentFine') ? true : false,
				canUpdateFine: value.paymentPermission?.includes('canUpdateFine') ? true : false,

				canCreateIssuedLetter: value?.issuedLetter?.includes('canCreateIssuedLetter'),
				canUpdateIssuedLetter: value?.issuedLetter?.includes('canUpdateIssuedLetter'),
				canDeleteIssuedLetter: value?.issuedLetter?.includes('canDeleteIssuedLetter'),

				mdd: value?.role?.includes('mdd') ? value?.mdd || [] : [],

				ews: value?.role?.includes('ews') ? value?.ews || [] : [],
			},
		});
	}

	function arrayRoleToString(role) {
		let result = '';
		if (role) {
			role?.forEach((item, index) => {
				if (index === 0) {
					result = item;
				} else {
					result += `,${item}`;
				}
			});
		}
		return result;
	}

	function stringToArray(role) {
		return role ? role.split(',') : [];
	}

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: message,
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	useEffect(() => {
		if (data) {
			if (data?.updateUserByAdmin) {
				convertUserToEmployee({
					variables: { username },
				});
				toast({
					title: t('User update successfully'),
					status: 'success',
					isClosable: true,
					position: 'top-right',
				});
				history.push('/users?page=1');
			}
		}
	}, [data]);

	function getRadioRoles() {
		const roles = [];
		c?.roles?.forEach((r) => {
			roles.push({ label: r.label, value: r.role });
		});
		return roles;
	}

	function checkForRole(role) {
		let res = false;
		tmpRole?.forEach((item) => {
			if (item === role) {
				res = true;
			}
		});

		return res;
	}

	function checkForReportType(type) {
		let res = false;
		tmpReportType?.forEach((item) => {
			if (item === type) {
				res = true;
			}
		});

		return res;
	}

	function getCompanySubmissionInitValue(getUserById) {
		const res = [];
		if (getUserById?.canAssignUserToCompanyProposal === true) {
			res.push('canAssignUser');
		}
		if (getUserById?.canUploadDoneFileToCompanyProposal === true) {
			res.push('canUploadDoneFile');
		}
		if (getUserById?.canViewListReportToCompanyProposal === true) {
			res.push('canViewListReport');
		}
		if (getUserById?.canAutoreassignProposal === true) {
			res.push('canAutoreassignProposal');
		}
		return res;
	}

	function getComplaintInitValue(getUserById) {
		const res = [];
		if (getUserById?.canUploadDoneFileToComplaint === true) {
			res.push('canUploadDoneFile');
		}
		if (getUserById?.canAssignUserToComplaint === true) {
			res.push('canAssignComplaint');
		}
		return res;
	}

	function getIssuedLetterInitValue(getUserById) {
		const res = [];
		if (getUserById?.canCreateIssuedLetter === true) {
			res.push('canCreateIssuedLetter');
		}
		if (getUserById?.canUpdateIssuedLetter === true) {
			res.push('canUpdateIssuedLetter');
		}
		if (getUserById?.canDeleteIssuedLetter === true) {
			res.push('canDeleteIssuedLetter');
		}
		return res;
	}

	function getLeaveRequestPermissionInitValue(getUserById) {
		const res = [];

		if (getUserById?.canApproveRejectLeaveRequest === true) {
			res.push('canApproveRejectLeaveRequest');
		}
		if (getUserById?.canViewLeaveRequestReport === true) {
			res.push('canViewLeaveRequestReport');
		}

		if (getUserById?.canNotApplyForLeave === true) {
			res.push('canNotApplyForLeave');
		}

		return res;
	}

	function getPaymentPermissionInitValue(getUserById) {
		const res = [];

		if (getUserById?.paymentAgentRegistration === true) {
			res.push('paymentAgentRegistration');
		}
		if (getUserById?.paymentPublicService === true) {
			res.push('paymentPublicService');
		}
		if (getUserById?.paymentLicense === true) {
			res.push('paymentLicense');
		}

		if (getUserById?.canUpdateLicense === true) {
			res.push('canUpdateLicense');
		}

		if (getUserById?.paymentFine === true) {
			res.push('paymentFine');
		}

		if (getUserById?.canUpdateFine === true) {
			res.push('canUpdateFine');
		}

		return res;
	}

	function getAgentPermissionInitValue(getUserById) {
		const res = [];

		if (getUserById?.canReviewAgentRequest === true) {
			res.push('canReviewAgentRequest');
		}
		if (getUserById?.canApproveRejectDeactivateAgent === true) {
			res.push('canApproveRejectDeactivateAgent');
		}
		if (getUserById?.canFinalApproveRejectAgent === true) {
			res.push('canFinalApproveRejectAgent');
		}
		return res;
	}

	function getCompanySubmissionReportInitValue(getUserById) {
		const res = [];
		if (getUserById?.canAssignUserToCompanyReport === true) {
			res.push('canAssignUser');
		}
		return res;
	}

	function getInitCompanyPermission(userData) {
		const permission = [];
		if (userData.canUpdateCompanyLicensing) {
			permission.push('canUpdateLicensing');
		}
		if (userData.canUpdateCompanyGovernance) {
			permission.push('canUpdateGovernance');
		}
		if (userData.canUpdateCompanyContact) {
			permission.push('canUpdateContact');
		}
		return permission;
	}

	function getOfficeDataFromDepartment(departmentData) {
		if (departmentData?.length === 1) {
			const dp = departmentData?.length > 0 ? departmentData[0] : null;
			let res = c.departments.filter((e) => e.value === dp);
			if (res?.length > 0) {
				return res[0]?.offices;
			} else {
				return [];
			}
		} else {
			return [];
		}
	}

	function getPositionOptions(data) {
		let res = [];
		data?.forEach((item) => {
			res.push({ value: item?.value?.toString(), label: currentLang === 'en' ? item?.label : item?.labelKh });
		});
		return res;
	}

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'USERS',
							path: '/users?page=1',
						},
						{
							name: 'UPDATE USER',
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
							<Text fontSize="x-large" fontWeight="bold">
								{t('Update User')}
							</Text>
						</Flex>

						<Box p="16px" maxW="480px">
							{loadingUser && <Text>{t('Loading')}</Text>}
							{dataUser?.getUserById?.id && (
								<Formik
									initialValues={{
										username: dataUser?.getUserById?.username ? dataUser?.getUserById?.username : '',
										fullName: dataUser?.getUserById?.fullName ? dataUser?.getUserById?.fullName : '',
										fullNameKhmer: dataUser?.getUserById?.fullNameKhmer ? dataUser?.getUserById?.fullNameKhmer : '',
										password: '',
										role: dataUser?.getUserById?.role ? stringToArray(dataUser?.getUserById?.role) : '',
										companyReportType: dataUser?.getUserById?.companyReportType
											? stringToArray(dataUser?.getUserById?.companyReportType)
											: '',
										department: dataUser?.getUserById?.department ? stringToArray(dataUser?.getUserById?.department) : '',
										office: dataUser?.getUserById?.office ? dataUser?.getUserById?.office : '',
										companyProposalPermission: getCompanySubmissionInitValue(dataUser?.getUserById),
										complaintPermission: getComplaintInitValue(dataUser?.getUserById),
										companyReportPermission: getCompanySubmissionReportInitValue(dataUser?.getUserById),
										companyPermission: dataUser?.getUserById ? getInitCompanyPermission(dataUser?.getUserById) : [],
										financialReportTypes: dataUser?.getUserById?.financialReportTypes
											? stringToArray(dataUser?.getUserById?.financialReportTypes)
											: '',
										requestLeavePermission: getLeaveRequestPermissionInitValue(dataUser?.getUserById),
										agentPermission: getAgentPermissionInitValue(dataUser?.getUserById),
										position: dataUser?.getUserById?.position ? dataUser?.getUserById?.position : '',
										paymentPermission: getPaymentPermissionInitValue(dataUser?.getUserById),
										issuedLetter: getIssuedLetterInitValue(dataUser?.getUserById),
										mdd: dataUser?.getUserById?.mdd,
										ews: dataUser?.getUserById?.ews,
									}}
									validationSchema={signUpSchema}
									onSubmit={(values) => {
										updateUser(values);
									}}
								>
									{(props) => (
										<Form>
											<Box>
												<Field name="username">
													{({ field, meta }) => (
														<>
															<FormLabel>{t('Username')}</FormLabel>
															<Input
																mb="3"
																{...field}
																id="username"
																size="lg"
																placeholder={t(`Username`)}
																fontWeight="500"
																autoComplete="off"
																disabled
															/>
															{meta.touched && meta.error && (
																<Text id="error-message-username" color="red.400" fontSize="12px">
																	{t('Username')} {t('is')} {t(meta.error)}
																</Text>
															)}
														</>
													)}
												</Field>

												<TextFormField {...props} label="Full Name In Latin" name="fullName" />

												<TextFormField {...props} label="Full Name In Khmer" name="fullNameKhmer" />

												<Field name="password">
													{({ field, meta, form }) => (
														<>
															<FormLabel>{t('Password')}</FormLabel>
															<InputGroup size="md" mt="3">
																<Input {...field} id="password" size="lg" fontWeight="500" autoComplete="off" />
																<InputRightElement width="4.5rem">
																	<Button
																		mt="8px"
																		mr="6px"
																		h="100%"
																		size="sm"
																		onClick={() => {
																			form.setFieldValue('password', generatePassword(), true);
																		}}
																	>
																		{t('Generate')}
																	</Button>
																</InputRightElement>
															</InputGroup>

															{meta.touched && meta.error && (
																<Text id="error-message-password" color="red.400" fontSize="12px">
																	{t('Password')} {t('is')} {t(meta.error)}
																</Text>
															)}
														</>
													)}
												</Field>

												<CheckListFormField
													label="Department"
													name="department"
													initValue={stringToArray(dataUser?.getUserById?.department)}
													list={c.departments}
													callBack={(e) => {
														setDepartmentCallback(e);
													}}
													initialValues={dataUser?.getUserById?.position ? dataUser?.getUserById?.position : ''}
												/>

												<RadioFormField
													{...props}
													label="Office"
													name="office"
													radios={getOfficeDataFromDepartment(departmentCallback)}
													initValue={dataUser?.getUserById?.office ? dataUser?.getUserById?.office : ''}
												/>

												<SelectFormField
													{...props}
													name="position"
													label="Position"
													placeholder="Position"
													options={getPositionOptions(c?.position)}
													t={t}
													initialValue={dataUser?.getUserById?.position ? dataUser?.getUserById?.position : ''}
												/>

												<CheckListFormField
													label="Role"
													name="role"
													initValue={stringToArray(dataUser?.getUserById?.role)}
													list={getRadioRoles()}
													callBack={(e) => setTmpRole(e)}
													required
													nested={[
														{
															index: 3,
															child: (
																<>
																	{checkForRole('complaint') && (
																		<CheckListFormField
																			label="Complaint Permission"
																			name="complaintPermission"
																			list={[
																				{
																					label: 'Can Assign',
																					value: 'canAssignComplaint',
																				},
																				{
																					label: 'Can upload done file',
																					value: 'canUploadDoneFile',
																				},
																			]}
																			initValue={getComplaintInitValue(dataUser?.getUserById)}
																		/>
																	)}
																</>
															),
														},
														{
															index: 4,
															child: (
																<>
																	{checkForRole('companySubmissionReport') && (
																		<CheckListFormField
																			label="Company Report Types"
																			name="companyReportType"
																			initValue={stringToArray(dataUser?.getUserById?.companyReportType)}
																			list={c.companyReportTypes}
																			callBack={(e) => setTmpReportType(e)}
																			nested={[
																				{
																					index: 0,
																					child: (
																						<>
																							{checkForReportType('FINANCIAL_REPORT') && (
																								<CheckListFormField
																									label="Financial Report Types"
																									name="financialReportTypes"
																									initValue={stringToArray(
																										dataUser?.getUserById?.financialReportTypes
																									)}
																									list={c.financialReportTypes}
																								/>
																							)}
																						</>
																					),
																				},
																			]}
																		/>
																	)}
																</>
															),
														},
														{
															index: 4,
															child: (
																<>
																	{checkForRole('companySubmissionReport') && (
																		<CheckListFormField
																			label="Company Report Permission"
																			name="companyReportPermission"
																			list={[
																				{
																					label: 'Can Assign User',
																					value: 'canAssignUser',
																				},
																			]}
																			initValue={getCompanySubmissionReportInitValue(dataUser?.getUserById)}
																		/>
																	)}
																</>
															),
														},
														{
															index: 5,
															child: (
																<>
																	{checkForRole('companySubmissionProposal') && (
																		<CheckListFormField
																			label="Proposal Permission"
																			name="companyProposalPermission"
																			list={[
																				{
																					label: 'Can Assign User',
																					value: 'canAssignUser',
																				},
																				{
																					label: 'Can upload done file',
																					value: 'canUploadDoneFile',
																				},
																				{
																					label: 'Can View List Report',
																					value: 'canViewListReport',
																				},
																				{
																					label: 'Can Auto-reassign Proposals',
																					value: 'canAutoreassignProposal',
																				},
																			]}
																			initValue={getCompanySubmissionInitValue(dataUser?.getUserById)}
																		/>
																	)}
																</>
															),
														},
														{
															index: 6,
															child: (
																<>
																	{checkForRole('companyList') && (
																		<CheckListFormField
																			label="Company Permission"
																			name="companyPermission"
																			list={[
																				{
																					label: 'Can Update Company Licensing',
																					value: 'canUpdateLicensing',
																				},
																				{
																					label: 'Can Update Company Governance',
																					value: 'canUpdateGovernance',
																				},
																				{
																					label: 'Can Update Company Contact',
																					value: 'canUpdateContact',
																				},
																			]}
																			initValue={
																				dataUser?.getUserById ? getInitCompanyPermission(dataUser?.getUserById) : []
																			}
																		/>
																	)}
																</>
															),
														},
														{
															index: 7,
															child: (
																<>
																	{checkForRole('requestLeave') && (
																		<CheckListFormField
																			label="Request Leave Permission"
																			name="requestLeavePermission"
																			list={[
																				{
																					label: 'Can Approve/Reject',
																					value: 'canApproveRejectLeaveRequest',
																				},
																				{
																					label: 'Can View Report',
																					value: 'canViewLeaveRequestReport',
																				},
																				{
																					label: 'Can not apply for leave',
																					value: 'canNotApplyForLeave',
																				},
																			]}
																			initValue={getLeaveRequestPermissionInitValue(dataUser?.getUserById)}
																		/>
																	)}
																</>
															),
														},
														{
															index: 8,
															child: (
																<>
																	{checkForRole('agent') && (
																		<CheckListFormField
																			label="Agent Permission"
																			name="agentPermission"
																			list={[
																				{
																					label: 'Can Review Request',
																					value: 'canReviewAgentRequest',
																				},
																				{
																					label: 'Can Approve/Reject/Deactivate',
																					value: 'canApproveRejectDeactivateAgent',
																				},
																				{
																					label: 'Can Final Approve/Reject/Deactivate',
																					value: 'canFinalApproveRejectAgent',
																				},
																			]}
																			initValue={getAgentPermissionInitValue(dataUser?.getUserById)}
																		/>
																	)}
																</>
															),
														},
														{
															index: 10,
															child: (
																<>
																	{checkForRole('payment') && (
																		<CheckListFormField
																			label="Payment Permission"
																			name="paymentPermission"
																			list={[
																				{
																					label: 'Agent Registration',
																					value: 'paymentAgentRegistration',
																				},
																				{
																					label: 'Public Service',
																					value: 'paymentPublicService',
																				},
																				{
																					label: 'View License',
																					value: 'paymentLicense',
																				},
																				{
																					label: 'Can Update License',
																					value: 'canUpdateLicense',
																				},
																				{
																					label: 'View Fines',
																					value: 'paymentFine',
																				},
																				{
																					label: 'Can Update Fines',
																					value: 'canUpdateFine',
																				},
																			]}
																			initValue={getPaymentPermissionInitValue(dataUser?.getUserById)}
																		/>
																	)}
																</>
															),
														},
														{
															index: 11,
															child: (
																<>
																	{checkForRole('issuedLetter') && (
																		<CheckListFormField
																			label="Permission"
																			name="issuedLetter"
																			list={[
																				{
																					label: 'Create',
																					value: 'canCreateIssuedLetter',
																				},
																				{
																					label: 'Edit',
																					value: 'canUpdateIssuedLetter',
																				},
																				{
																					label: 'Delete',
																					value: 'canDeleteIssuedLetter',
																				},
																			]}
																			initValue={getIssuedLetterInitValue(dataUser?.getUserById)}
																		/>
																	)}
																</>
															),
														},
														{
															index: 14,
															child: (
																<>
																	{checkForRole('mdd') && (
																		<CheckListFormField
																			label="Permission"
																			name="mdd"
																			list={[
																				{
																					label: 'General Monthly Report',
																					value: 'mddgmr',
																				},
																				{
																					label: 'Life Monthly Report',
																					value: 'mddlmr',
																				},
																				{
																					label: 'Micro Monthly Report',
																					value: 'mddmmr',
																				},
																			]}
																			initValue={dataUser?.getUserById?.mdd}
																		/>
																	)}
																</>
															),
														},
														{
															index: 16,
															child: (
																<>
																	{checkForRole('ews') && (
																		<CheckListFormField
																			label="Permission"
																			name="ews"
																			list={[
																				{
																					label: 'Can view statement of general insurance',
																					value: 'ewsCanViewStatementGeneralInsurance',
																				},
																				{
																					label: 'Can view statement of life insurance',
																					value: 'ewsCanViewStatementLifeInsurance',
																				},
																				{
																					label: 'Can view statement of micro insurance',
																					value: 'ewsCanViewStatementMicroInsurance',
																				},
																				{
																					label: 'Can Assign User',
																					value: 'ewsCanAssignUser',
																				},
																			]}
																			initValue={dataUser?.getUserById?.ews}
																		/>
																	)}
																</>
															),
														},
													]}
												/>

												<Flex textAlign="right" mt="6" justifyContent="space-between" alignItems="center">
													<Button
														id="login-btn"
														variant="solid"
														size="md"
														h="42px"
														w="128px"
														bg="primary.dark"
														_hover={{ bg: 'primary.dark' }}
														color="white"
														type="submit"
														isLoading={loading}
														loadingText={t('CREATE')}
														spinnerPlacement="start"
													>
														{t(`UPDATE`)}
													</Button>
												</Flex>
											</Box>
										</Form>
									)}
								</Formik>
							)}
						</Box>
					</Box>
				</Center>
			</Container>
		</>
	);
}

export default Update;

const generatePassword = (length = 8, wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$') =>
	Array.from(crypto.getRandomValues(new Uint32Array(length)))
		.map((x) => wishlist[x % wishlist.length])
		.join('');
