import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Container,
	Box,
	Flex,
	Text,
	Center,
	Button,
	useToast,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
} from '@chakra-ui/react';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import c from '../../constant';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import DateRangeFormField from '../../components/form/DateRangeFormField';
import moment from 'moment';
import 'moment/locale/en-gb';
import DatetimeFormField from '../../components/form/DatetimeFormField';
import RadioFormField from '../../components/form/RadioFormField';
import axios from 'axios';

const UPDATE_STATUS = gql`
	mutation update($data: String!) {
		updateMaintenanceStatus(data: $data)
	}
`;

const filterSchema = Yup.object().shape({
	webDate: Yup.string().required('required'),
	adminDate: Yup.string().required('required'),
	webStatus: Yup.string().required('required'),
	adminStatus: Yup.string().required('required'),
});

function Create() {
	moment.locale('en-gb');
	const history = useHistory();
	const toast = useToast();
	const { t } = useTranslation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const [isOpen, setIsOpen] = React.useState(false);
	const onClose = () => setIsOpen(false);
	const cancelRef = React.useRef();

	const [webStatus, setWebStatus] = useState(null);
	const [adminStatus, setAdminStatus] = useState(null);
	const [statusWebDate, setWebStatusDate] = useState(null);
	const [statusAdminDate, setAdminStatusDate] = useState(null);

	console.log('webStatus:', webStatus);
	console.log('adminStatus:', adminStatus);
	console.log('statusWebDate:', statusWebDate);
	console.log('statusAdminDate:', statusAdminDate);

	const [updateStatus, { error, loading, data }] = useMutation(UPDATE_STATUS);

	console.log('aaaa', statusWebDate);

	useEffect(() => {
		checkMaintenanceStatus();
	}, []);

	function checkMaintenanceStatus() {
		axios.get(c.baseFilePath + `status/status.json?timestamp=${new Date().getTime()}`).then(function (response) {
			setAdminStatus((response?.data?.status).toString());
			setAdminStatusDate(response?.data?.date);
		});

		axios.get(c.baseFilePath + `status/status-web.json?timestamp=${new Date().getTime()}`).then(function (response) {
			setWebStatus((response?.data?.status).toString());
			setWebStatusDate(response?.data?.date);
		});
	}

	useEffect(() => {
		if (data) {
			toast({
				title: t('Notifications deleted successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			onClose();
			history.push('/settings');
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: t('Settings'),
							path: '/settings',
						},
						{
							name: t('Maintenance Status'),
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
							<Text fontSize="x-large" fontWeight="bold">
								{t('Maintenance Status')}
							</Text>
						</Flex>

						<Box p="16px" maxW="480px">
							{statusWebDate && statusAdminDate && (
								<Formik
									initialValues={{
										webDate: statusWebDate,
										webStatus: webStatus,
										adminDate: statusAdminDate,
										adminStatus: adminStatus,
									}}
									validationSchema={filterSchema}
									onSubmit={(values) => {
										console.log('values:', values);
										updateStatus({
											variables: {
												data: JSON.stringify(values),
											},
										});
									}}
								>
									{(props) => (
										<Box pb="4">
											<Form>
												<Flex p="4" border="2px dashed #c0392b" justifyContent="space-between">
													<DatetimeFormField {...props} label="Web date" name="webDate" required />
													<RadioFormField
														{...props}
														name="webStatus"
														label="Status"
														radios={[
															{ label: '1', value: '1' },
															{ label: '0', value: '0' },
														]}
														required
														initValue={webStatus}
													/>
												</Flex>

												<Flex p="4" border="2px dashed #2980b9" mt="4" justifyContent="space-between">
													<DatetimeFormField {...props} label="Admin date" name="adminDate" required />
													<RadioFormField
														{...props}
														name="adminStatus"
														label="Status"
														radios={[
															{ label: '1', value: '1' },
															{ label: '0', value: '0' },
														]}
														required
														initValue={adminStatus}
													/>
												</Flex>

												<Flex mt="16px">
													<Button
														id="login-btn"
														variant="solid"
														size="md"
														h="42px"
														w="128px"
														type="submit"
														borderRadius={c.borderRadius}
														color="#3498db"
													>
														{t('Update')}
													</Button>
												</Flex>
											</Form>
										</Box>
									)}
								</Formik>
							)}
						</Box>
					</Box>
				</Center>
			</Container>
		</>
	);
}

export default Create;
