/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { Flex, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import c from '../../constant';
import useStore from '../../store';
import CountBadge from './CountBadge';

const QUERY_REPORTS = gql`
	query qCountFileReport(
		$pagerInput: PagerInput!
		$fromDate: DateTime
		$toDate: DateTime
		$companyName: String
		$type: CompanySubmissionReportTypes
		$reportType: CompanySubmissionReportTypesOfReport
		$newReportType: String
		$read: Boolean
		$spam: Boolean
	) {
		getCompanySubmissionReports(
			pagerInput: $pagerInput
			fromDate: $fromDate
			toDate: $toDate
			companyName: $companyName
			type: $type
			reportType: $reportType
			newReportType: $newReportType
			read: $read
			spam: $spam
		) {
			companySubmissionReports {
				id
			}
			countUnreadCompanySubmissionReports
		}
	}
`;

const QUERY_EWS_COUNT = gql`
	query countEWS($type: String) {
		getUnreadEarlyWarningStatementCount(type: $type)
	}
`;

const QUERY_MD_COUNT = gql`
	query countMD($reportId: String) {
		getCountUnreadNotifications(reportId: $reportId)
	}
`;

export default function ReportMenuItem({ item, index, reportId, callbackCountUnread }) {
	const { push } = useHistory();
	const { currentLang } = useStore((state) => state.currentLang);
	const [loadReports, { data }] = useLazyQuery(QUERY_REPORTS);
	const [loadEWSCount, { data: dataEWS }] = useLazyQuery(QUERY_EWS_COUNT);
	const [loadMDCount, { data: dataMD }] = useLazyQuery(QUERY_MD_COUNT);

	const { unreadReportCount } = useStore((state) => state.unreadReportCount);

	useEffect(() => {
		if (data?.getCompanySubmissionReports) {
			callbackCountUnread(data?.getCompanySubmissionReports?.countUnreadCompanySubmissionReports);
		}
	}, [data]);

	useEffect(() => {
		if (dataEWS?.getUnreadEarlyWarningStatementCount) {
			callbackCountUnread(dataEWS?.getUnreadEarlyWarningStatementCount?.unreadEarlyWarningStatementCount);
		}
	}, [dataEWS]);

	useEffect(() => {
		if (dataMD) {
			callbackCountUnread(dataMD?.getCountUnreadNotifications);
		}
	}, [dataMD]);

	useEffect(() => {
		if (!unreadReportCount?.[item?.id]?.period) {
			if (item?.id && item?.path?.includes('company-submission-report/report')) {
				const limit = 1;
				const offset = 0;
				const pagerInput = { limit, offset };
				loadReports({
					variables: {
						pagerInput,
						read: false,
						newReportType: item?.id,
						spam: null,
					},
				});
			}

			if (
				item?.id === c.quarterly_financial_statements ||
				item?.id === c.annually_unaudited_financial_statements ||
				item?.id === c.annually_audited_financial_statements
			) {
				loadEWSCount({
					variables: {
						type: item?.id,
					},
				});
			}

			if (item?.id && item?.path?.includes('/market-development-data?')) {
				loadMDCount({
					variables: {
						type: 'COMPANY_SUBMISSION_MDD_REPORT',
						reportId: item?.id,
					},
				});
			}
		}
	}, [item]);

	function getCount(reportId, list) {
		console.log('here');
		let count = 0;
		if (list?.length > 0) {
			console.log('get count', list[reportId]);
		}
		return count;
	}

	function reportItem(item, index) {
		return (
			<Flex
				_hover={{ backgroundColor: `${reportId === item?.id ? 'rgba(52, 152, 219,.1)' : 'gray.50'}` }}
				key={index}
				onMouseDown={() => push(item?.path)}
				width="100%"
				alignItems="center"
				bg={`${reportId === item?.id ? 'rgba(52, 152, 219,.1)' : 'white'}`}
				borderRadius={c.borderRadius}
				border={`1px solid ${reportId === item?.id ? '#3498db' : '#dbdbdb'}`}
				boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
				p="2"
				cursor="pointer"
				mb="2"
			>
				<Text w="100%" fontWeight="bold" color="black.500">
					{currentLang === 'kh' ? item?.titleKh : item?.title}
					{item?.hint && <span style={{ marginLeft: '4px', color: '#3182ce', fontWeight: 'bold' }}>{item?.hint}</span>}
				</Text>
				<CountBadge reportId={item?.id} />
			</Flex>
		);
	}

	return reportItem(item, index);
}
