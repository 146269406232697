/* eslint-disable react-hooks/exhaustive-deps */
import { Badge } from '@chakra-ui/react';
import useStore from '../../store';

export default function CountBadge({ reportId }) {

	const { unreadReportCount } = useStore((state) => state.unreadReportCount);
	function getCount(list) {
		let count = 0
		if (list) {
			count = list[reportId]?.count || 0
		}
		return count
	}

	return (
		getCount(unreadReportCount) ? <Badge colorScheme='red'>
			{getCount(unreadReportCount)}
		</Badge> : null
	);
}
