/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Button, Box, Flex, useToast } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import TextFormField from '../../form/TextFormField';
import CheckFormField from '../../form/CheckFormField';
import RadioValueFormField from '../../form/RadioValueFormField';
import c from '../../../constant';
import * as Yup from 'yup';

const Q_GET = gql`
	query get($id: String!) {
		getProductLine(id: $id) {
			id
			nameKh
			nameEn
			order
			type
			hint
			hideForMicro
		}
	}
`;

const M_EDIT = gql`
	mutation edit(
		$id: String!
		$nameKh: String!
		$nameEn: String!
		$order: Float!
		$type: MDDataProductLineEnumType!
		$hint: String
		$hideForMicro: Boolean = false
	) {
		editProductLine(id: $id, nameKh: $nameKh, nameEn: $nameEn, order: $order, type: $type, hint: $hint, hideForMicro: $hideForMicro)
	}
`;

const validationSchema = Yup.object().shape({
	nameKh: Yup.string().required('Required'),
	nameEn: Yup.string().required('Required'),
	order: Yup.number().required('Required'),
	type: Yup.string().required('Required'),
	hint: Yup.string().nullable(),
	hideForMicro: Yup.boolean(),
});

function EditProductLine({ id = null, isOpen = false, onClose = () => null, callback = () => null }) {
	const toast = useToast();
	const { t } = useTranslation();
	const [getProductLine, { data: dataGet }] = useLazyQuery(Q_GET);
	const [editProductLine, { loading, data: dataEdit, error }] = useMutation(M_EDIT);

	useEffect(() => {
		if (id) {
			const variables = { id };
			getProductLine({ variables });
		}
	}, [id]);

	useEffect(() => {
		if (dataEdit) {
			toast({
				title: t('All tasks are completed'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			callback();
			onClose();
		}
	}, [dataEdit]);

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	const onSubmit = (values) => {
		const variables = {
			id,
			nameKh: values?.nameKh,
			nameEn: values?.nameEn,
			order: values?.order,
			type: values?.type,
			hint: values?.hint,
			hideForMicro: values?.hideForMicro,
		};
		editProductLine({ variables });
	};

	return (
		<>
			<Modal
				closeOnEsc={loading ? false : true}
				closeOnOverlayClick={loading ? false : true}
				borderRadius={c.borderRadius}
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius}>
					<ModalHeader>{t('Edit Product Line')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{dataGet?.getProductLine && (
							<>
								<Formik
									initialValues={{
										nameKh: dataGet?.getProductLine?.nameKh,
										nameEn: dataGet?.getProductLine?.nameEn,
										order: dataGet?.getProductLine?.order,
										type: dataGet?.getProductLine?.type,
										hint: dataGet?.getProductLine?.hint,
										hideForMicro: dataGet?.getProductLine?.hideForMicro,
									}}
									validationSchema={validationSchema}
									onSubmit={onSubmit}
								>
									{(props) => {
										return (
											<Box pb="4">
												<Form>
													<TextFormField {...props} label="Khmer Name" name="nameKh" required />
													<TextFormField {...props} label="English Name" name="nameEn" required />
													<TextFormField {...props} label="Order" name="order" type="number" required />
													<RadioValueFormField
														{...props}
														label="Insurance Business Type"
														name="type"
														radios={[
															{ label: 'General', value: 'General' },
															{ label: 'Life', value: 'Life' },
														]}
														required
													/>
													<TextFormField {...props} label="Hint" name="hint" />
													<CheckFormField label="Hide For Micro" name="hideForMicro" />
													<Flex mt="32px" justifyContent="flex-end">
														<Button
															id="login-btn"
															variant="solid"
															size="md"
															h="42px"
															w="128px"
															type="submit"
															borderRadius={c.borderRadius}
															isLoading={loading}
															isDisabled={loading}
															color="#3498db"
														>
															{t('Edit')}
														</Button>
													</Flex>
												</Form>
											</Box>
										);
									}}
								</Formik>
							</>
						)}
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default EditProductLine;
