import { Flex, Spinner, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function Loading({ label = 'Loading' }) {
	const { t } = useTranslation();

	return (
		<>
			<Flex direction="column" justifyContent="center" alignItems="center" pt={24} pb={24}>
				<Spinner size="lg" thickness={4} color="gray.400" />
				<Text fontSize="sm" fontWeight="md" color="gray.600" mt={3}>
					{t(label)}
				</Text>
			</Flex>
		</>
	);
}

export default Loading;
