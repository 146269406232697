/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Box, Center, Container, Flex, SimpleGrid, Text, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { DragHandleIcon } from '@chakra-ui/icons';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import LabelDetail from '../../../components/LabelDetail';
import useMoment from '../../../hooks/useMoment';
import arrayMove from 'array-move';
import c from '../../../constant';

const Q_TEMPLATE = gql`
	query get($templateId: String!) {
		getEarlyWarningTemplateById(templateId: $templateId) {
			id
			code
			titleKhmer
			titleEnglish
			order
			createdAt
			createdBy
			labels {
				id
				code
				titleKhmer
				titleEnglish
				level
				order
				readOnly
			}
		}
	}
`;

const M_ORDERS = gql`
	mutation update($orders: JSON!) {
		updateEarlyWarningTemplateLabelOrder(orders: $orders)
	}
`;

function EarlyWarningTemplateDetail() {
	const toast = useToast();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const templateId = urlParams.get('id') || null;
	const { toDateString } = useMoment();
	const { t } = useTranslation();
	const [detail, setDetail] = useState(null);
	const [fetchData, { data, error }] = useLazyQuery(Q_TEMPLATE);
	const [updateData] = useMutation(M_ORDERS);

	useEffect(() => {
		fetchDetail();
	}, [templateId]);

	useEffect(() => {
		data && setDetail(data?.getEarlyWarningTemplateById);
	}, [data]);

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	function fetchDetail() {
		templateId && fetchData({ variables: { templateId } });
	}

	function onSortEnd({ oldIndex, newIndex }) {
		const localList = arrayMove(detail?.labels, oldIndex, newIndex);
		const variables = { orders: localList.map(({ id }, order) => ({ id, order })) };
		console.log(variables);
		updateData({ variables });
		setDetail({ ...detail, labels: localList });
	}

	const SortableIcon = SortableHandle(() => <DragHandleIcon fontSize={8} ml={2} mr={2} />);

	const SortableItem = SortableElement(({ item }) => {
		return (
			<Flex
				mb="1"
				ml={item?.level * 4}
				alignItems="center"
				bg="white"
				h={8}
				borderRadius={c.borderRadius}
				border="1px solid #dbdbdb"
				boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
			>
				<SortableIcon />
				<Flex _hover={{ textDecoration: 'underline' }} cursor="pointer">
					<Text color="black.400" fontWeight="600">
						{item?.code}&nbsp;
					</Text>
					<Text color="black.400">
						{item?.titleKhmer}
						{item?.titleEnglish && <>&nbsp;({item?.titleEnglish})</>}
					</Text>
				</Flex>
			</Flex>
		);
	});

	const SortableList = SortableContainer(({ items }) => {
		return (
			<Box w="100%">
				{items.map((item, index) => (
					<SortableItem key={`item-${item?.id}`} disabled={true} index={index} item={item} />
				))}
			</Box>
		);
	});

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'Settings',
							path: '/settings',
						},
						{
							name: 'Early Warning System Statement Template',
							path: '/settings/early-warning-template',
						},
						{
							name: 'Detail',
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="16px"
					>
						<Flex
							alignItems="center"
							justifyContent="space-between"
							bg="#FAFAFA"
							p="16px"
							pt="8px"
							pb="8px"
							mb="16px"
							borderBottom="1px solid #dbdbdb"
						>
							<Flex justifyContent="center" alignItems="center">
								<Text fontSize="x-large" fontWeight="bold" mr="4">
									{t('Detail')}
								</Text>
							</Flex>
							<Flex className="header-menu" justifyContent="flex-end" alignItems="center">
								<></>
							</Flex>
						</Flex>
						{detail && (
							<Box p="16px" pt="0">
								<SimpleGrid columns={[1, null, 2]} spacingX={8}>
									<LabelDetail labelWidth="200px" label="Khmer Title" titleDetail={t(detail?.titleKhmer)} />
									<LabelDetail labelWidth="200px" label="English Title" titleDetail={t(detail?.titleEnglish)} />
									<LabelDetail labelWidth="200px" label="Created At" titleDetail={toDateString(detail?.createdAt, c.longDateFormat)} />
									<LabelDetail
										labelWidth="200px"
										label="Code"
										childDetail={
											<>
												<Text fontWeight="600">{detail?.code}</Text>
											</>
										}
									/>
								</SimpleGrid>
								<Box w="100%" mt={4}>
									<SortableList useDragHandle items={detail?.labels} onSortEnd={onSortEnd} />
								</Box>
							</Box>
						)}
					</Box>
				</Center>
			</Container>
		</>
	);
}

export default EarlyWarningTemplateDetail;
