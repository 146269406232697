import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export default function SpreadSheetKeyValuePair({ label = null, value = null }) {
	const { t } = useTranslation();

	return (
		<>
			<Flex mb={1} justifyContent="flex-start" alignItems="center">
				<Flex>
					<Text fontWeight="semibold">{t(label)}</Text>
					<Text>:</Text>
					<Flex direction="column" ml={2} justifyContent="center" alignItems="flex-start">
						{value}
					</Flex>
				</Flex>
			</Flex>
		</>
	);
}
