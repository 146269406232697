import { Flex, Image, Text, Tooltip } from '@chakra-ui/react';
import PlaceHolder from '../assets/images/logo-placeholder.png';
import useStore from '../store';
import c from '../constant';

function CompanyName({ companyName = null, company = null, color = '#303030' }) {
	const { currentLang: lang } = useStore((state) => state.currentLang);

	return (
		<>
			{company ? (
				<Tooltip
					label={
						<Flex justifyContent="left" alignItems="center">
							<Image src={company?.logo ? c.baseFilePath + company?.logo : ''} w="100%" h="auto" maxW="16px" fallbackSrc={PlaceHolder} mr={2} />
							<Text color="white">{lang === 'kh' ? company?.nameKhmer : company?.name}</Text>
						</Flex>
					}
				>
					<Flex justifyContent="left" alignItems="center">
						<Image src={company?.logo ? c.baseFilePath + company?.logo : ''} w="auto" minH="20px" h="20px" mr={2} />
						<Text color={color}>
							{lang === 'kh' ? company?.nameKhmer : company?.name}
						</Text>
					</Flex>
				</Tooltip>
			) : (
				<Tooltip label={<Text color="white">{companyName}</Text>}>
					<Text color={color}>{companyName}</Text>
				</Tooltip>
			)}
		</>
	);
}

export default CompanyName;
