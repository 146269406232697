import { EWSTypeCurrency, percent, sumOrError } from './ews';
import { isNumeric } from './market-development';

const irNumColumn = 5;
const sumOnlys = [0, 4, 5, 9, 10, 18, 19, 24, 25, 31, 32, 40, 43, 46, 49, 52, 55, 57, 60, 61, 62, 70, 71, 79, 82, 85, 90];

function cleanIRCells(labels) {
	const cells = labels?.map((label, i) => {
		if (sumOnlys?.includes(i)) {
			return [
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '%', readOnly: true },
			];
		} else {
			return [
				{ value: '', readOnly: false },
				{ value: '', readOnly: false },
				{ value: '0', readOnly: true },
				{ value: '', readOnly: false },
				{ value: '%', readOnly: true },
			];
		}
	});
	return cells;
}

function detailIRCells({ code = null, labels = [], detailLabels = [], readOnly = false }) {
	const localCells = labels?.map((label, i) => {
		if (sumOnlys?.includes(i)) {
			return [
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '%', readOnly: true },
			];
		} else {
			const detailLabel = detailLabels?.find((el) => el?.templateLabel?.code === label?.code);
			if (detailLabel) {
				return [
					{
						value:
							detailLabel?.values?.find(
								(value) => value?.currency === EWSTypeCurrency.KHR && value?.isMillion === true && value?.isForeignCurrency === false
							)?.value || 0,
						readOnly,
					},
					{
						value:
							detailLabel?.values?.find(
								(value) => value?.currency === EWSTypeCurrency.KHR && value?.isMillion === true && value?.isForeignCurrency === true
							)?.value || 0,
						readOnly,
					},
					{ value: '0', readOnly: true },
					{ value: detailLabel?.values?.find((value) => value?.currency === EWSTypeCurrency.USD)?.value || 0, readOnly },
					{ value: '%', readOnly: true },
				];
			} else {
				return [
					{ value: '', readOnly },
					{ value: '', readOnly },
					{ value: '0', readOnly: true },
					{ value: '', readOnly },
					{ value: '%', readOnly: true },
				];
			}
		}
	});

	const { cells } = calculateIRCells({ code, cells: localCells, numLabel: labels?.length, readOnly });

	return cells;
}

function calculateIRCells({ code, cells, numLabel, readOnly = false }) {
	let record00Sums = [...Array.from({ length: 3 }, (item, i) => i + 1)];
	let record00KHR = 0;
	let record00FrgnKHR = 0;
	let record00TotKHR = 0;
	let record00USD = 0;

	let record04Sums = [...Array.from({ length: 1 }, (item, i) => i + 8)];
	let record04KHR = 0;
	let record04FrgnKHR = 0;
	let record04TotKHR = 0;
	let record04USD = 0;

	let record05Sums = [...Array.from({ length: 2 }, (item, i) => i + 6)];
	let record05KHR = 0;
	let record05FrgnKHR = 0;
	let record05TotKHR = 0;
	let record05USD = 0;

	let record09Sums = [...Array.from({ length: 1 }, (item, i) => i + 17)];
	let record09KHR = 0;
	let record09FrgnKHR = 0;
	let record09TotKHR = 0;
	let record09USD = 0;

	let record10Sums = [...Array.from({ length: 6 }, (item, i) => i + 11)];
	let record10KHR = 0;
	let record10FrgnKHR = 0;
	let record10TotKHR = 0;
	let record10USD = 0;

	let record18Sums = [...Array.from({ length: 1 }, (item, i) => i + 23)];
	let record18KHR = 0;
	let record18FrgnKHR = 0;
	let record18TotKHR = 0;
	let record18USD = 0;

	let record19Sums = [...Array.from({ length: 3 }, (item, i) => i + 20)];
	let record19KHR = 0;
	let record19FrgnKHR = 0;
	let record19TotKHR = 0;
	let record19USD = 0;

	let record24Sums = [...Array.from({ length: 1 }, (item, i) => i + 30)];
	let record24KHR = 0;
	let record24FrgnKHR = 0;
	let record24TotKHR = 0;
	let record24USD = 0;

	let record25Sums = [...Array.from({ length: 4 }, (item, i) => i + 26)];
	let record25KHR = 0;
	let record25FrgnKHR = 0;
	let record25TotKHR = 0;
	let record25USD = 0;

	let record31Sums = [...Array.from({ length: 1 }, (item, i) => i + 39)];
	let record31KHR = 0;
	let record31FrgnKHR = 0;
	let record31TotKHR = 0;
	let record31USD = 0;

	let record32Sums = [...Array.from({ length: 6 }, (item, i) => i + 33)];
	let record32KHR = 0;
	let record32FrgnKHR = 0;
	let record32TotKHR = 0;
	let record32USD = 0;

	let record40Sums = [...Array.from({ length: 2 }, (item, i) => i + 41)];
	let record40KHR = 0;
	let record40FrgnKHR = 0;
	let record40TotKHR = 0;
	let record40USD = 0;

	let record43Sums = [...Array.from({ length: 2 }, (item, i) => i + 44)];
	let record43KHR = 0;
	let record43FrgnKHR = 0;
	let record43TotKHR = 0;
	let record43USD = 0;

	let record46Sums = [...Array.from({ length: 2 }, (item, i) => i + 47)];
	let record46KHR = 0;
	let record46FrgnKHR = 0;
	let record46TotKHR = 0;
	let record46USD = 0;

	let record49Sums = [...Array.from({ length: 2 }, (item, i) => i + 50)];
	let record49KHR = 0;
	let record49FrgnKHR = 0;
	let record49TotKHR = 0;
	let record49USD = 0;

	let record52Sums = [...Array.from({ length: 2 }, (item, i) => i + 53)];
	let record52KHR = 0;
	let record52FrgnKHR = 0;
	let record52TotKHR = 0;
	let record52USD = 0;

	let record55Sums = [...Array.from({ length: 1 }, (item, i) => i + 56)];
	let record55KHR = 0;
	let record55FrgnKHR = 0;
	let record55TotKHR = 0;
	let record55USD = 0;

	let record57Sums = [...Array.from({ length: 2 }, (item, i) => i + 58)];
	let record57KHR = 0;
	let record57FrgnKHR = 0;
	let record57TotKHR = 0;
	let record57USD = 0;

	let record61Sums = [...Array.from({ length: 1 }, (item, i) => i + 69)];
	let record61KHR = 0;
	let record61FrgnKHR = 0;
	let record61TotKHR = 0;
	let record61USD = 0;

	let record62Sums = [...Array.from({ length: 6 }, (item, i) => i + 63)];
	let record62KHR = 0;
	let record62FrgnKHR = 0;
	let record62TotKHR = 0;
	let record62USD = 0;

	let record70Sums = [...Array.from({ length: 1 }, (item, i) => i + 78)];
	let record70KHR = 0;
	let record70FrgnKHR = 0;
	let record70TotKHR = 0;
	let record70USD = 0;

	let record71Sums = [...Array.from({ length: 6 }, (item, i) => i + 72)];
	let record71KHR = 0;
	let record71FrgnKHR = 0;
	let record71TotKHR = 0;
	let record71USD = 0;

	let record79Sums = [...Array.from({ length: 2 }, (item, i) => i + 80)];
	let record79KHR = 0;
	let record79FrgnKHR = 0;
	let record79TotKHR = 0;
	let record79USD = 0;

	let record82Sums = [...Array.from({ length: 2 }, (item, i) => i + 83)];
	let record82KHR = 0;
	let record82FrgnKHR = 0;
	let record82TotKHR = 0;
	let record82USD = 0;

	let record85Sums = [...Array.from({ length: 4 }, (item, i) => i + 86)];
	let record85KHR = 0;
	let record85FrgnKHR = 0;
	let record85TotKHR = 0;
	let record85USD = 0;

	let localCells = cells?.map((cols, i) => {
		if (sumOnlys?.includes(i)) {
			return [
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '0', readOnly: true },
				{ value: '%', readOnly: true },
			];
		} else {
			if (record00Sums?.includes(i)) {
				record00KHR = record00KHR + parseFloat(cols[0]?.value || 0);
				record00FrgnKHR = record00FrgnKHR + parseFloat(cols[1]?.value || 0);
				record00TotKHR = record00TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record00USD = record00USD + parseFloat(cols[3]?.value || 0);
			} else if (record04Sums?.includes(i)) {
				record04KHR = record04KHR + parseFloat(cols[0]?.value || 0);
				record04FrgnKHR = record04FrgnKHR + parseFloat(cols[1]?.value || 0);
				record04TotKHR = record04TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record04USD = record04USD + parseFloat(cols[3]?.value || 0);
			} else if (record05Sums?.includes(i)) {
				record05KHR = record05KHR + parseFloat(cols[0]?.value || 0);
				record05FrgnKHR = record05FrgnKHR + parseFloat(cols[1]?.value || 0);
				record05TotKHR = record05TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record05USD = record05USD + parseFloat(cols[3]?.value || 0);
			} else if (record09Sums?.includes(i)) {
				record09KHR = record09KHR + parseFloat(cols[0]?.value || 0);
				record09FrgnKHR = record09FrgnKHR + parseFloat(cols[1]?.value || 0);
				record09TotKHR = record09TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record09USD = record09USD + parseFloat(cols[3]?.value || 0);
			} else if (record10Sums?.includes(i)) {
				record10KHR = record10KHR + parseFloat(cols[0]?.value || 0);
				record10FrgnKHR = record10FrgnKHR + parseFloat(cols[1]?.value || 0);
				record10TotKHR = record10TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record10USD = record10USD + parseFloat(cols[3]?.value || 0);
			} else if (record18Sums?.includes(i)) {
				record18KHR = record18KHR + parseFloat(cols[0]?.value || 0);
				record18FrgnKHR = record18FrgnKHR + parseFloat(cols[1]?.value || 0);
				record18TotKHR = record18TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record18USD = record18USD + parseFloat(cols[3]?.value || 0);
			} else if (record19Sums?.includes(i)) {
				record19KHR = record19KHR + parseFloat(cols[0]?.value || 0);
				record19FrgnKHR = record19FrgnKHR + parseFloat(cols[1]?.value || 0);
				record19TotKHR = record19TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record19USD = record19USD + parseFloat(cols[3]?.value || 0);
			} else if (record24Sums?.includes(i)) {
				record24KHR = record24KHR + parseFloat(cols[0]?.value || 0);
				record24FrgnKHR = record24FrgnKHR + parseFloat(cols[1]?.value || 0);
				record24TotKHR = record24TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record24USD = record24USD + parseFloat(cols[3]?.value || 0);
			} else if (record25Sums?.includes(i)) {
				record25KHR = record25KHR + parseFloat(cols[0]?.value || 0);
				record25FrgnKHR = record25FrgnKHR + parseFloat(cols[1]?.value || 0);
				record25TotKHR = record25TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record25USD = record25USD + parseFloat(cols[3]?.value || 0);
			} else if (record31Sums?.includes(i)) {
				record31KHR = record31KHR + parseFloat(cols[0]?.value || 0);
				record31FrgnKHR = record31FrgnKHR + parseFloat(cols[1]?.value || 0);
				record31TotKHR = record31TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record31USD = record31USD + parseFloat(cols[3]?.value || 0);
			} else if (record32Sums?.includes(i)) {
				record32KHR = record32KHR + parseFloat(cols[0]?.value || 0);
				record32FrgnKHR = record32FrgnKHR + parseFloat(cols[1]?.value || 0);
				record32TotKHR = record32TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record32USD = record32USD + parseFloat(cols[3]?.value || 0);
			} else if (record40Sums?.includes(i)) {
				record40KHR = record40KHR + parseFloat(cols[0]?.value || 0);
				record40FrgnKHR = record40FrgnKHR + parseFloat(cols[1]?.value || 0);
				record40TotKHR = record40TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record40USD = record40USD + parseFloat(cols[3]?.value || 0);
			} else if (record43Sums?.includes(i)) {
				record43KHR = record43KHR + parseFloat(cols[0]?.value || 0);
				record43FrgnKHR = record43FrgnKHR + parseFloat(cols[1]?.value || 0);
				record43TotKHR = record43TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record43USD = record43USD + parseFloat(cols[3]?.value || 0);
			} else if (record46Sums?.includes(i)) {
				record46KHR = record46KHR + parseFloat(cols[0]?.value || 0);
				record46FrgnKHR = record46FrgnKHR + parseFloat(cols[1]?.value || 0);
				record46TotKHR = record46TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record46USD = record46USD + parseFloat(cols[3]?.value || 0);
			} else if (record49Sums?.includes(i)) {
				record49KHR = record49KHR + parseFloat(cols[0]?.value || 0);
				record49FrgnKHR = record49FrgnKHR + parseFloat(cols[1]?.value || 0);
				record49TotKHR = record49TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record49USD = record49USD + parseFloat(cols[3]?.value || 0);
			} else if (record52Sums?.includes(i)) {
				record52KHR = record52KHR + parseFloat(cols[0]?.value || 0);
				record52FrgnKHR = record52FrgnKHR + parseFloat(cols[1]?.value || 0);
				record52TotKHR = record52TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record52USD = record52USD + parseFloat(cols[3]?.value || 0);
			} else if (record55Sums?.includes(i)) {
				record55KHR = record55KHR + parseFloat(cols[0]?.value || 0);
				record55FrgnKHR = record55FrgnKHR + parseFloat(cols[1]?.value || 0);
				record55TotKHR = record55TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record55USD = record55USD + parseFloat(cols[3]?.value || 0);
			} else if (record57Sums?.includes(i)) {
				record57KHR = record57KHR + parseFloat(cols[0]?.value || 0);
				record57FrgnKHR = record57FrgnKHR + parseFloat(cols[1]?.value || 0);
				record57TotKHR = record57TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record57USD = record57USD + parseFloat(cols[3]?.value || 0);
			} else if (record61Sums?.includes(i)) {
				record61KHR = record61KHR + parseFloat(cols[0]?.value || 0);
				record61FrgnKHR = record61FrgnKHR + parseFloat(cols[1]?.value || 0);
				record61TotKHR = record61TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record61USD = record61USD + parseFloat(cols[3]?.value || 0);
			} else if (record62Sums?.includes(i)) {
				record62KHR = record62KHR + parseFloat(cols[0]?.value || 0);
				record62FrgnKHR = record62FrgnKHR + parseFloat(cols[1]?.value || 0);
				record62TotKHR = record62TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record62USD = record62USD + parseFloat(cols[3]?.value || 0);
			} else if (record70Sums?.includes(i)) {
				record70KHR = record70KHR + parseFloat(cols[0]?.value || 0);
				record70FrgnKHR = record70FrgnKHR + parseFloat(cols[1]?.value || 0);
				record70TotKHR = record70TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record70USD = record70USD + parseFloat(cols[3]?.value || 0);
			} else if (record71Sums?.includes(i)) {
				record71KHR = record71KHR + parseFloat(cols[0]?.value || 0);
				record71FrgnKHR = record71FrgnKHR + parseFloat(cols[1]?.value || 0);
				record71TotKHR = record71TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record71USD = record71USD + parseFloat(cols[3]?.value || 0);
			} else if (record79Sums?.includes(i)) {
				record79KHR = record79KHR + parseFloat(cols[0]?.value || 0);
				record79FrgnKHR = record79FrgnKHR + parseFloat(cols[1]?.value || 0);
				record79TotKHR = record79TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record79USD = record79USD + parseFloat(cols[3]?.value || 0);
			} else if (record82Sums?.includes(i)) {
				record82KHR = record82KHR + parseFloat(cols[0]?.value || 0);
				record82FrgnKHR = record82FrgnKHR + parseFloat(cols[1]?.value || 0);
				record82TotKHR = record82TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record82USD = record82USD + parseFloat(cols[3]?.value || 0);
			} else if (record85Sums?.includes(i)) {
				record85KHR = record85KHR + parseFloat(cols[0]?.value || 0);
				record85FrgnKHR = record85FrgnKHR + parseFloat(cols[1]?.value || 0);
				record85TotKHR = record85TotKHR + parseFloat(cols[0]?.value || 0) + parseFloat(cols[1]?.value || 0);
				record85USD = record85USD + parseFloat(cols[3]?.value || 0);
			}

			const co0 = cols[0]?.value;
			const co1 = cols[1]?.value;
			const co3 = cols[3]?.value;
			return [
				{ value: co0, readOnly: false },
				{ value: co1, readOnly: false },
				{ value: '0', readOnly: true },
				{ value: co3, readOnly: false },
				{ value: '%', readOnly: true },
			];
		}
	});

	localCells = cells?.map((cols, i) => {
		if (sumOnlys?.includes(i)) {
			if (i === 0) {
				return [
					{
						value: sumOrError([
							record00KHR,
							record04KHR,
							record05KHR,
							record09KHR,
							record10KHR,
							record18KHR,
							record19KHR,
							record24KHR,
							record25KHR,
							record31KHR,
							record32KHR,
							record40KHR,
							record43KHR,
							record46KHR,
							record49KHR,
							record52KHR,
						]),
						readOnly: true,
					},
					{
						value: sumOrError([
							record00FrgnKHR,
							record04FrgnKHR,
							record05FrgnKHR,
							record09FrgnKHR,
							record10FrgnKHR,
							record18FrgnKHR,
							record19FrgnKHR,
							record24FrgnKHR,
							record25FrgnKHR,
							record31FrgnKHR,
							record32FrgnKHR,
							record40FrgnKHR,
							record43FrgnKHR,
							record46FrgnKHR,
							record49FrgnKHR,
							record52FrgnKHR,
						]),
						readOnly: true,
					},
					{
						value: sumOrError([
							record00TotKHR,
							record04TotKHR,
							record05TotKHR,
							record09TotKHR,
							record10TotKHR,
							record18TotKHR,
							record19TotKHR,
							record24TotKHR,
							record25TotKHR,
							record31TotKHR,
							record32TotKHR,
							record40TotKHR,
							record43TotKHR,
							record46TotKHR,
							record49TotKHR,
							record52TotKHR,
						]),
						readOnly: true,
					},
					{
						value: sumOrError([
							record00USD,
							record04USD,
							record05USD,
							record09USD,
							record10USD,
							record18USD,
							record19USD,
							record24USD,
							record25USD,
							record31USD,
							record32USD,
							record40USD,
							record43USD,
							record46USD,
							record49USD,
							record52USD,
						]),
						readOnly: true,
					},
					{
						value: percent(
							sumOrError([
								record00USD,
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
								record46USD,
								record49USD,
								record52USD,
							]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 4) {
				return [
					{
						value: sumOrError([
							record04KHR,
							record05KHR,
							record09KHR,
							record10KHR,
							record18KHR,
							record19KHR,
							record24KHR,
							record25KHR,
							record31KHR,
							record32KHR,
							record40KHR,
							record43KHR,
						]),
						readOnly: true,
					},
					{
						value: sumOrError([
							record04FrgnKHR,
							record05FrgnKHR,
							record09FrgnKHR,
							record10FrgnKHR,
							record18FrgnKHR,
							record19FrgnKHR,
							record24FrgnKHR,
							record25FrgnKHR,
							record31FrgnKHR,
							record32FrgnKHR,
							record40FrgnKHR,
							record43FrgnKHR,
						]),
						readOnly: true,
					},
					{
						value: sumOrError([
							record04TotKHR,
							record05TotKHR,
							record09TotKHR,
							record10TotKHR,
							record18TotKHR,
							record19TotKHR,
							record24TotKHR,
							record25TotKHR,
							record31TotKHR,
							record32TotKHR,
							record40TotKHR,
							record43TotKHR,
						]),
						readOnly: true,
					},
					{
						value: sumOrError([
							record04USD,
							record05USD,
							record09USD,
							record10USD,
							record18USD,
							record19USD,
							record24USD,
							record25USD,
							record31USD,
							record32USD,
							record40USD,
							record43USD,
						]),
						readOnly: true,
					},
					{
						value: percent(
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 5) {
				return [
					{ value: sumOrError([record05KHR]), readOnly: true },
					{ value: sumOrError([record05FrgnKHR]), readOnly: true },
					{ value: sumOrError([record05TotKHR]), readOnly: true },
					{ value: sumOrError([record05USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record05USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 9) {
				return [
					{ value: sumOrError([record09KHR, record10KHR]), readOnly: true },
					{ value: sumOrError([record09FrgnKHR, record10FrgnKHR]), readOnly: true },
					{ value: sumOrError([record09TotKHR, record10TotKHR]), readOnly: true },
					{ value: sumOrError([record09USD, record10USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record09USD, record10USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 10) {
				return [
					{ value: sumOrError([record10KHR]), readOnly: true },
					{ value: sumOrError([record10FrgnKHR]), readOnly: true },
					{ value: sumOrError([record10TotKHR]), readOnly: true },
					{ value: sumOrError([record10USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record10USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 18) {
				return [
					{ value: sumOrError([record18KHR, record19KHR]), readOnly: true },
					{ value: sumOrError([record18FrgnKHR, record19FrgnKHR]), readOnly: true },
					{ value: sumOrError([record18TotKHR, record19TotKHR]), readOnly: true },
					{ value: sumOrError([record18USD, record19USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record18USD, record19USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 19) {
				return [
					{ value: sumOrError([record19KHR]), readOnly: true },
					{ value: sumOrError([record19FrgnKHR]), readOnly: true },
					{ value: sumOrError([record19TotKHR]), readOnly: true },
					{ value: sumOrError([record19USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record19USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 24) {
				return [
					{ value: sumOrError([record24KHR, record25KHR]), readOnly: true },
					{ value: sumOrError([record24FrgnKHR, record25FrgnKHR]), readOnly: true },
					{ value: sumOrError([record24TotKHR, record25TotKHR]), readOnly: true },
					{ value: sumOrError([record24USD, record25USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record24USD, record25USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 25) {
				return [
					{ value: sumOrError([record25KHR]), readOnly: true },
					{ value: sumOrError([record25FrgnKHR]), readOnly: true },
					{ value: sumOrError([record25TotKHR]), readOnly: true },
					{ value: sumOrError([record25USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record25USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 31) {
				return [
					{ value: sumOrError([record31KHR, record32KHR]), readOnly: true },
					{ value: sumOrError([record31FrgnKHR, record32FrgnKHR]), readOnly: true },
					{ value: sumOrError([record31TotKHR, record32TotKHR]), readOnly: true },
					{ value: sumOrError([record31USD, record32USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record31USD, record32USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 32) {
				return [
					{ value: sumOrError([record32KHR]), readOnly: true },
					{ value: sumOrError([record32FrgnKHR]), readOnly: true },
					{ value: sumOrError([record32TotKHR]), readOnly: true },
					{ value: sumOrError([record32USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record32USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 40) {
				return [
					{ value: sumOrError([record40KHR]), readOnly: true },
					{ value: sumOrError([record40FrgnKHR]), readOnly: true },
					{ value: sumOrError([record40TotKHR]), readOnly: true },
					{ value: sumOrError([record40USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record40USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 43) {
				return [
					{ value: sumOrError([record43KHR]), readOnly: true },
					{ value: sumOrError([record43FrgnKHR]), readOnly: true },
					{ value: sumOrError([record43TotKHR]), readOnly: true },
					{ value: sumOrError([record43USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record43USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 46) {
				return [
					{ value: sumOrError([record46KHR]), readOnly: true },
					{ value: sumOrError([record46FrgnKHR]), readOnly: true },
					{ value: sumOrError([record46TotKHR]), readOnly: true },
					{ value: sumOrError([record46USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record46USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 49) {
				return [
					{ value: sumOrError([record49KHR]), readOnly: true },
					{ value: sumOrError([record49FrgnKHR]), readOnly: true },
					{ value: sumOrError([record49TotKHR]), readOnly: true },
					{ value: sumOrError([record49USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record49USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 52) {
				return [
					{ value: sumOrError([record52KHR]), readOnly: true },
					{ value: sumOrError([record52FrgnKHR]), readOnly: true },
					{ value: sumOrError([record52TotKHR]), readOnly: true },
					{ value: sumOrError([record52USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record52USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 55) {
				return [
					{
						value: sumOrError([record55KHR, record57KHR, record61KHR, record62KHR, record70KHR, record71KHR, record79KHR, record82KHR]),
						readOnly: true,
					},
					{
						value: sumOrError([
							record55FrgnKHR,
							record57FrgnKHR,
							record61FrgnKHR,
							record62FrgnKHR,
							record70FrgnKHR,
							record71FrgnKHR,
							record79FrgnKHR,
							record82FrgnKHR,
						]),
						readOnly: true,
					},
					{
						value: sumOrError([
							record55TotKHR,
							record57TotKHR,
							record61TotKHR,
							record62TotKHR,
							record70TotKHR,
							record71TotKHR,
							record79TotKHR,
							record82TotKHR,
						]),
						readOnly: true,
					},
					{
						value: sumOrError([record55USD, record57USD, record61USD, record62USD, record70USD, record71USD, record79USD, record82USD]),
						readOnly: true,
					},
					{
						value: percent(
							sumOrError([record55USD, record57USD, record61USD, record62USD, record70USD, record71USD, record79USD, record82USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 57) {
				return [
					{ value: sumOrError([record57KHR]), readOnly: true },
					{ value: sumOrError([record57FrgnKHR]), readOnly: true },
					{ value: sumOrError([record57TotKHR]), readOnly: true },
					{ value: sumOrError([record57USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record57USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 60) {
				return [
					{ value: sumOrError([record61KHR, record62KHR, record70KHR, record71KHR, record79KHR]), readOnly: true },
					{ value: sumOrError([record61FrgnKHR, record62FrgnKHR, record70FrgnKHR, record71FrgnKHR, record79FrgnKHR]), readOnly: true },
					{ value: sumOrError([record61TotKHR, record62TotKHR, record70TotKHR, record71TotKHR, record79TotKHR]), readOnly: true },
					{ value: sumOrError([record61USD, record62USD, record70USD, record71USD, record79USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record61USD, record62USD, record70USD, record71USD, record79USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 61) {
				return [
					{ value: sumOrError([record61KHR, record62KHR]), readOnly: true },
					{ value: sumOrError([record61FrgnKHR, record62FrgnKHR]), readOnly: true },
					{ value: sumOrError([record61TotKHR, record62TotKHR]), readOnly: true },
					{ value: sumOrError([record61USD, record62USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record61USD, record62USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 62) {
				return [
					{ value: sumOrError([record62KHR]), readOnly: true },
					{ value: sumOrError([record62FrgnKHR]), readOnly: true },
					{ value: sumOrError([record62TotKHR]), readOnly: true },
					{ value: sumOrError([record62USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record62USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 70) {
				return [
					{ value: sumOrError([record70KHR, record71KHR]), readOnly: true },
					{ value: sumOrError([record70FrgnKHR, record71FrgnKHR]), readOnly: true },
					{ value: sumOrError([record70TotKHR, record71TotKHR]), readOnly: true },
					{ value: sumOrError([record70USD, record71USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record70USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 71) {
				return [
					{ value: sumOrError([record71KHR]), readOnly: true },
					{ value: sumOrError([record71FrgnKHR]), readOnly: true },
					{ value: sumOrError([record71TotKHR]), readOnly: true },
					{ value: sumOrError([record71USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record71USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 79) {
				return [
					{ value: sumOrError([record79KHR]), readOnly: true },
					{ value: sumOrError([record79FrgnKHR]), readOnly: true },
					{ value: sumOrError([record79TotKHR]), readOnly: true },
					{ value: sumOrError([record79USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record79USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 82) {
				return [
					{ value: sumOrError([record82KHR]), readOnly: true },
					{ value: sumOrError([record82FrgnKHR]), readOnly: true },
					{ value: sumOrError([record82TotKHR]), readOnly: true },
					{ value: sumOrError([record82USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record82USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 85) {
				return [
					{ value: sumOrError([record85KHR]), readOnly: true },
					{ value: sumOrError([record85FrgnKHR]), readOnly: true },
					{ value: sumOrError([record85TotKHR]), readOnly: true },
					{ value: sumOrError([record85USD]), readOnly: true },
					{
						value: percent(
							sumOrError([record85USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else if (i === 90) {
				return [
					{
						value: sumOrError([
							record55KHR,
							record57KHR,
							record61KHR,
							record62KHR,
							record70USD,
							record85KHR,
							record71KHR,
							record79KHR,
							record82KHR,
						]),
						readOnly: true,
					},
					{
						value: sumOrError([
							record55FrgnKHR,
							record57FrgnKHR,
							record61FrgnKHR,
							record62FrgnKHR,
							record70FrgnKHR,
							record85FrgnKHR,
							record71FrgnKHR,
							record79FrgnKHR,
							record82FrgnKHR,
						]),
						readOnly: true,
					},
					{
						value: sumOrError([
							record55TotKHR,
							record57TotKHR,
							record61TotKHR,
							record62TotKHR,
							record70TotKHR,
							record85TotKHR,
							record71TotKHR,
							record79TotKHR,
							record82TotKHR,
						]),
						readOnly: true,
					},
					{
						value: sumOrError([
							record55USD,
							record57USD,
							record61USD,
							record62USD,
							record70USD,
							record85USD,
							record71USD,
							record79USD,
							record82USD,
						]),
						readOnly: true,
					},
					{
						value: percent(
							sumOrError([record55USD, record57USD, record61USD, record62USD, record70USD, record85USD, record71USD, record79USD, record82USD]),
							sumOrError([
								record04USD,
								record05USD,
								record09USD,
								record10USD,
								record18USD,
								record19USD,
								record24USD,
								record25USD,
								record31USD,
								record32USD,
								record40USD,
								record43USD,
							])
						),
						readOnly: true,
					},
				];
			} else {
				return [
					{ value: '0', readOnly: true },
					{ value: '0', readOnly: true },
					{ value: '0', readOnly: true },
					{ value: '0', readOnly: true },
					{ value: '%', readOnly: true },
				];
			}
		} else {
			const co0 = cols[0]?.value;
			const co1 = cols[1]?.value;
			const co3 = cols[3]?.value;
			const v0 = parseFloat(co0 || 0);
			const v1 = parseFloat(co1 || 0);
			const v3 = parseFloat(co3 || 0);
			return [
				{ value: co0, readOnly },
				{ value: co1, readOnly },
				{ value: sumOrError([v0, v1]), readOnly: true },
				{ value: co3, readOnly },
				{
					value: percent(
						v3,
						sumOrError([
							record04USD,
							record05USD,
							record09USD,
							record10USD,
							record18USD,
							record19USD,
							record24USD,
							record25USD,
							record31USD,
							record32USD,
							record40USD,
							record43USD,
						])
					),
					readOnly: true,
				},
			];
		}
	});

	localCells = localCells.slice(0, numLabel);
	return { code, cells: localCells };
}

function validateIRCells({ cells }) {
	const localCells = cells?.reduce((iPre, iCur) => {
		let localIPre = iPre;
		localIPre.push(
			...iCur?.reduce((jPre, jCur) => {
				let localJPre = jPre;
				if (!jCur?.readOnly && !isNumeric(jCur?.value)) {
					localJPre.push(jCur);
				}
				return localJPre;
			}, [])
		);
		return localIPre;
	}, []);
	return localCells?.length === 0;
}

export { irNumColumn, cleanIRCells, detailIRCells, calculateIRCells, validateIRCells };
