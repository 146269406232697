import { Checkbox, Box, FormControl, FormHelperText } from '@chakra-ui/react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

const CheckMarkFormField = ({ name = null, label = null }) => {
	const { t } = useTranslation();

	return (
		<Field name={name}>
			{({ field, meta, form }) => {
				return (
					<Box mb="4">
						<FormControl id={name}>
							<Checkbox {...field} isChecked={field?.value} onChange={(e) => form.setFieldValue(name, e?.target?.checked)}>
								{t(label)}
							</Checkbox>
							<>
								{meta.touched && meta.error && (
									<FormHelperText id={`error-message-${name}`} color="red.400" fontSize="12px">
										{t(name)} {t('is')} {t(meta.error)}
									</FormHelperText>
								)}
							</>
						</FormControl>
					</Box>
				);
			}}
		</Field>
	);
};

export default CheckMarkFormField;
