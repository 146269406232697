import { isNumeric } from './market-development';

const EWSTypeStatement = {
	q1: 'Quarter1',
	q2: 'Quarter2',
	q3: 'Quarter3',
	q4: 'Quarter4',
	aa: 'AnnualAudited',
	an: 'AnnualNonAudited',
};

const EWSTypeTemplate = {
	SFP: 'SFP',
	SPLOCI: 'SPLOCI',
	SCF: 'SCF',
	SOCE: 'SOCE',
	ORD: 'ORD',
	IR: 'IR',
};

const EWSTypeCurrency = {
	KHR: 'KHR',
	USD: 'USD',
};

const EWSSoceTypeColumn = {
	ShareCapital: 'scap',
	SharePremium: 'sprm',
	DepositsForFuterShareSubscription: 'dfss',
	RevaluationReserves: 'rrsv',
	RetainedEarningsAccumulatedLoses: 'real',
	OtherComponentsOfEquity: 'oceq',
};

function sumOrError(values) {
	if (values?.map((value) => isNumeric(value))?.includes(false)) {
		return 'Error';
	} else {
		return values?.reduce((pre, cur, i) => pre + cur, 0);
	}
}

function percent(a, b) {
	if (isNumeric(a) && isNumeric(b) && parseFloat(b) !== 0) {
		return Math.round(parseFloat(a / b) * 100) + '%';
	} else {
		return '%';
	}
}

export { EWSTypeStatement, EWSTypeCurrency, EWSTypeTemplate, EWSSoceTypeColumn, sumOrError, percent };
