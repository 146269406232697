import React, { useEffect, useState } from 'react';
import { FormControl, Box, FormLabel, FormHelperText, Select } from '@chakra-ui/react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import useStore from '../../../../store';
import c from '../../../../constant';

const MdCompanyTypeFormField = ({ name = null, companyFormName = null, label = null, placeholder = null, required = false }) => {
	const { t } = useTranslation('common');
	const { currentUser: user } = useStore((state) => state?.currentUser);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		if (user) {
			let localOptions = [];
			const roles = user?.role.split(',') || [];
			if (roles?.includes('admin')) {
				localOptions = [
					{ label: 'GENERAL INSURANCE', value: 'GENERAL_INSURANCE' },
					{ label: 'LIFE INSURANCE', value: 'LIFE_INSURANCE' },
					{ label: 'MICRO INSURANCE', value: 'MICRO_INSURANCE' },
				];
			} else if (roles?.includes('mdd')) {
				if (user?.mdd?.includes('mddgmr')) {
					localOptions = [...localOptions, { label: 'GENERAL INSURANCE', value: 'GENERAL_INSURANCE' }];
				}
				if (user?.mdd?.includes('mddlmr')) {
					localOptions = [...localOptions, { label: 'LIFE INSURANCE', value: 'LIFE_INSURANCE' }];
				}
				if (user?.mdd?.includes('mddmmr')) {
					localOptions = [...localOptions, { label: 'MICRO INSURANCE', value: 'MICRO_INSURANCE' }];
				}
			}
			setOptions(localOptions);
		}
	}, [user]);

	return (
		<Field name={name}>
			{({ field, meta, form }) => {
				return (
					<Box mb="4" overflow="hidden">
						<FormControl id={name}>
							{label && (
								<FormLabel>
									{t(label)}
									{required && <span style={{ color: 'red' }}>*</span>}
								</FormLabel>
							)}
							<Select
								h={12}
								value={field?.value}
								onChange={(e) => {
									form?.setFieldValue(name, e.target.value);
									form?.setFieldValue(companyFormName, null);
								}}
								placeholder={placeholder}
								borderRadius={c.borderRadius}
							>
								{options?.map((item, index) => {
									return (
										<option key={`option-item-${index}`} value={item?.value}>
											{t(item?.label)}
										</option>
									);
								})}
							</Select>
							{meta.touched && meta.error && (
								<FormHelperText id="error-message-password" color="red.400" fontSize="12px">
									{t(label)} {t('is')} {t(meta.error)}
								</FormHelperText>
							)}
						</FormControl>
					</Box>
				);
			}}
		</Field>
	);
};

export default MdCompanyTypeFormField;
