import React from 'react';
import { Badge, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function SaleReportBadge({ type }) {
	const { t } = useTranslation();
	switch (type) {
		case 'Individual':
			return (
				<Tooltip label={t(type)}>
					<Badge colorScheme="gray">{t(type)}</Badge>
				</Tooltip>
			);
		case 'Group':
			return (
				<Tooltip label={t(type)}>
					<Badge colorScheme="gray">{t(type)}</Badge>
				</Tooltip>
			);
		case 'Investment':
			return (
				<Tooltip label={t(type)}>
					<Badge colorScheme="gray">{t(type)}</Badge>
				</Tooltip>
			);
		case 'MTPL':
			return (
				<Tooltip label={t(type)}>
					<Badge colorScheme="gray">{t(type)}</Badge>
				</Tooltip>
			);
		default:
			return null;
	}
}
export default SaleReportBadge;
