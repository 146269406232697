/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Badge } from '@chakra-ui/react';
import { gql, useLazyQuery } from '@apollo/client';

const QUERY_COUNT_UNREAD_REPORT = gql`
	query {
		getCompanySubmissionReports(pagerInput: { offset: 0, limit: 1 }) {
			countUnreadCompanySubmissionReports
		}
	}
`;

const QUERY_COUNT_UNREAD_STATEMENT = gql`
	query {
		getUnreadEarlyWarningStatementCount
	}
`;



const QUERY_MD_COUNT = gql`
	query {
		getCountUnreadNotifications(
			openType: "COMPANY_SUBMISSION_MDD_REPORT"
		)
	}
`;


function CompanySubmissionReportBadge() {
	const [count, setCount] = useState(0);
	const [loadCountUnreadReports, { data: dataUnreadReport }] = useLazyQuery(QUERY_COUNT_UNREAD_REPORT);
	const [loadCountUnreadStatements, { data: dataUnreadStatement }] = useLazyQuery(QUERY_COUNT_UNREAD_STATEMENT);
	const [loadMDCount, { data: dataUnreadMDCount }] = useLazyQuery(QUERY_MD_COUNT);


	
	useEffect(load, []);

	useEffect(sumCount, [dataUnreadReport, dataUnreadStatement, dataUnreadMDCount]);

	function load() {
		loadCountUnreadReports();
		loadCountUnreadStatements();
		loadMDCount({
			variables: {
				type: 'COMPANY_SUBMISSION_MDD_REPORT',
			}
		});
	}

	function sumCount() {
		const unreadReportCount = parseInt(dataUnreadReport?.getCompanySubmissionReports?.countUnreadCompanySubmissionReports || 0);
		const unreadStatementCount = parseInt(dataUnreadStatement?.getUnreadEarlyWarningStatementCount?.unreadEarlyWarningStatementCount || 0);
		const unreadMDCount = parseInt(dataUnreadMDCount?.getCountUnreadNotifications || 0);
		setCount(unreadReportCount + unreadStatementCount + unreadMDCount);
	}

	return (
		<>
			{parseInt(count) > 0 && (
				<>
					<Badge fontSize="x-large" zIndex="99" top="2px" right="2px" position="absolute" colorScheme="red">
						{parseInt(count)}
					</Badge>
				</>
			)}
		</>
	);
}

export default CompanySubmissionReportBadge;
