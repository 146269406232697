import React from 'react';

import { Tr, Td, Skeleton, Flex } from '@chakra-ui/react';
function LoadingTable(props) {
	return (
		<>
			{Array(props?.row ? props.row : 10)
				.fill(0)
				.map((ei, i) => {
					return (
						<Tr key={`table-row-item-${i}`}>
							{Array(props?.column ? props.column : 0)
								.fill(0)
								.map((ej, j) => (
									<Td key={`table-data-item-${j}`}>
										{
											<Skeleton ml={1} mr={1}>
												<Flex flex="1" minH="28px"></Flex>
											</Skeleton>
										}
									</Td>
								))}
						</Tr>
					);
				})}
		</>
	);
}

export default LoadingTable;
