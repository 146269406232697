/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormLabel, FormHelperText, RadioGroup, Radio, SimpleGrid } from '@chakra-ui/react';
import { Field } from 'formik';
import { Calendar } from '@amir04lm26/react-modern-calendar-date-picker';
import { mappingKhmerNumber } from '../../../../utils/market-development';
import useStore from '../../../../store';
import moment from 'moment';
import 'moment/locale/en-gb';
import '@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css';

const MdDateOptionFormField = ({ name = null, label = null, required = false }) => {
	moment.locale('en-gb');
	const { t } = useTranslation();
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const [year] = useState(moment().year());

	return (
		<Field name={name}>
			{({ field, meta, form }) => (
				<Box mb="4">
					<FormControl id={name}>
						<FormLabel>
							{t(label)}
							{required && <span style={{ color: 'red' }}>*</span>}
						</FormLabel>
						<RadioGroup
							value={field?.value}
							onChange={(e) => {
								form?.setFieldValue('rangeValue', { from: null, to: null });
								form?.setFieldValue(name, e);
							}}
						>
							<SimpleGrid columns={2} spacing={4}>
								{[
									{ label: 'Quarter 1', value: 'q1' },
									{ label: 'Quarter 2', value: 'q2' },
									{ label: 'Quarter 3', value: 'q3' },
									{ label: 'Quarter 4', value: 'q4' },
									{ label: 'Semester 1', value: 's1' },
									{ label: 'Semester 2', value: 's2' },
									{ label: 'Year', value: 'year' },
									{ label: 'Select', value: 'select' },
								]?.map((el, i) => {
									if (el?.value === 'select') {
										return (
											<Radio key={`radio-key-${i}`} value={el?.value}>
												{t(el?.label)}
											</Radio>
										);
									} else {
										return (
											<Radio key={`radio-key-${i}`} value={el?.value}>
												{t(el?.label)} {lang === 'kh' ? mappingKhmerNumber(year) : year}
											</Radio>
										);
									}
								})}
							</SimpleGrid>
						</RadioGroup>
						{field?.value === 'select' && (
							<Box mt={6}>
								<Calendar
									value={form?.values['rangeValue']}
									onChange={(e) => form?.setFieldValue('rangeValue', e)}
									shouldHighlightWeekends
									locale={lang === 'kh' ? myCustomLocale : 'en'}
								/>
								{form?.touched['rangeValue'] && form?.errors['rangeValue'] && (
									<FormHelperText id="error-message-password" color="red.400" fontSize="12px">
										{t('Date')} {t('is')} {t(form?.errors['rangeValue'])}
									</FormHelperText>
								)}
							</Box>
						)}
						{meta?.touched && meta?.error && (
							<FormHelperText id="error-message-password" color="red.400" fontSize="12px">
								{t('Date')} {t('is')} {t(meta?.error)}
							</FormHelperText>
						)}
					</FormControl>
				</Box>
			)}
		</Field>
	);
};

export default MdDateOptionFormField;

const myCustomLocale = {
	months: ['មករា', 'ខែកុម្ភៈ', 'មីនា', 'មេសា', 'ឧសភា', 'មិថុនា', 'កក្កដា', 'សីហា', 'កញ្ញា', 'តុលា', 'វិច្ឆិកា', 'ធ្នូ'],
	weekDays: [
		{
			name: 'អាទិត្យ',
			short: 'អាទិត្យ',
			isWeekend: true,
		},
		{
			name: 'ច័ន្ទ',
			short: 'ច័ន្ទ',
		},
		{
			name: 'អង្គារ',
			short: 'អង្គារ',
		},
		{
			name: 'ពុធ',
			short: 'ពុធ',
		},
		{
			name: 'ព្រហស្បតិ៍',
			short: 'ព្រហស្បតិ៍',
		},
		{
			name: 'សុក្រ',
			short: 'សុក្រ',
		},
		{
			name: 'សៅរ៍',
			short: 'សៅរ៍',
			isWeekend: true,
		},
	],
	weekStartingIndex: 0,
	getToday(gregorainTodayObject) {
		return gregorainTodayObject;
	},
	toNativeDate(date) {
		return new Date(date.year, date.month - 1, date.day);
	},
	getMonthLength(date) {
		return new Date(date.year, date.month, 0).getDate();
	},
	transformDigit(digit) {
		return digit;
	},
	nextMonth: 'Next Month',
	previousMonth: 'Previous Month',
	openMonthSelector: 'Open Month Selector',
	openYearSelector: 'Open Year Selector',
	closeMonthSelector: 'Close Month Selector',
	closeYearSelector: 'Close Year Selector',
	defaultPlaceholder: 'Select...',
	from: 'from',
	to: 'to',
	digitSeparator: ',',
	yearLetterSkip: 0,
	isRtl: false,
};
