/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@chakra-ui/react';
import { checkSelectedActiveColumn } from '../../../../utils/market-development';
import Spreadsheet from 'react-spreadsheet';
import SpreadSheetProductList from '../../Miscellaneous/spreadsheet-product-list';

const Table = ({ claimProducts = null, numColumn = 10 }) => {
	const { t } = useTranslation();
	const [generalData, setGeneralData] = useState(
		claimProducts?.filter((el) => el?.productLine?.type === 'General')?.map((el) => el?.productValues?.map((el) => ({ value: el?.value, readOnly: true })))
	);
	const [lifeData, setLifeData] = useState(
		claimProducts?.filter((el) => el?.productLine?.type === 'Life')?.map((el) => el?.productValues?.map((el) => ({ value: el?.value, readOnly: true })))
	);
	const [active, setActive] = useState(null);

	return (
		<Box className="spreadsheet_container">
			<div className="product-title-cell">{t('Product Line')}</div>
			<table className="spreadsheet_custom_header">
				<thead>
					<tr style={{ height: '82px' }}>
						<td colspan="2" className="background-light-silver">
							{t('Claim Paid')}
						</td>
						<td colspan="2" className="background-light-silver">
							{t('Claim Denied')}
						</td>
						<td colspan="2" className="background-light-silver">
							{t('Outstanding Claims')}
						</td>
						<td colspan="2" className="background-light-silver">
							{t('Change in Outstanding Claims')}
						</td>
						<td colspan="2" className="background-light-silver">
							{t('Claim Incurred')}
						</td>
					</tr>
					<tr style={{ height: '34px' }}>
						<td colspan="2" className="background-light-silver">
							({t('1')})
						</td>
						<td colspan="2" className="background-light-silver">
							({t('2')})
						</td>
						<td colspan="2" className="background-light-silver">
							({t('3')})
						</td>
						<td colspan="2" className="background-light-silver">
							({t('4')})
						</td>
						<td colspan="2" className="background-light-silver">
							({t('1')}+{t('2')}+{t('4')})
						</td>
					</tr>
					<tr style={{ height: '34px' }}>
						<td className={checkSelectedActiveColumn(active, 0) ? 'active-column' : 'background-light-silver'}>{t('number')}</td>
						<td className={checkSelectedActiveColumn(active, 1) ? 'active-column' : 'background-light-silver'}>{t('amount1')}</td>
						<td className={checkSelectedActiveColumn(active, 2) ? 'active-column' : 'background-light-silver'}>{t('number')}</td>
						<td className={checkSelectedActiveColumn(active, 3) ? 'active-column' : 'background-light-silver'}>{t('amount1')}</td>
						<td className={checkSelectedActiveColumn(active, 4) ? 'active-column' : 'background-light-silver'}>{t('number')}</td>
						<td className={checkSelectedActiveColumn(active, 5) ? 'active-column' : 'background-light-silver'}>{t('amount1')}</td>
						<td className={checkSelectedActiveColumn(active, 6) ? 'active-column' : 'background-light-silver'}>{t('number')}</td>
						<td className={checkSelectedActiveColumn(active, 7) ? 'active-column' : 'background-light-silver'}>{t('amount1')}</td>
						<td className={checkSelectedActiveColumn(active, 8) ? 'active-column' : 'background-light-silver'}>{t('number')}</td>
						<td className={checkSelectedActiveColumn(active, 9) ? 'active-column' : 'background-light-silver'}>{t('amount1')}</td>
					</tr>
				</thead>
			</table>
			<div>
				<Flex>
					<SpreadSheetProductList
						type="General"
						label="General Micro Insurance Business"
						labelMarginTop="0px"
						products={claimProducts?.filter((el) => el?.productLine?.type === 'General')?.map((e) => e?.productLine)}
						active={active}
					/>
					<Box>
						<Flex alignItems="center" mt="0px" h="30px" borderRight="1px solid hsl(2deg, 0%, 91%)" pl={2} pr={2} />
						<Spreadsheet
							data={generalData}
							onChange={(e) => {
								let localData = generalData?.reduce((p, c) => {
									let lp = p;
									let lc = c?.slice(0, numColumn);
									lp.push(lc);
									return lp;
								}, []);
								setGeneralData(
									localData?.slice(0, claimProducts?.filter((el) => el?.productLine?.type === 'General')?.map((e) => e?.productLine)?.length)
								);
							}}
							onActivate={(e) => setActive({ ...e, t: 'General' })}
							hideRowIndicators
							hideColumnIndicators
						/>
					</Box>
				</Flex>
			</div>
			<div>
				<Flex>
					<SpreadSheetProductList
						type="Life"
						label="Life Micro Insurance Business"
						labelMarginTop="-8px"
						products={claimProducts?.filter((el) => el?.productLine?.type === 'Life')?.map((e) => e?.productLine)}
						active={active}
					/>
					<Box>
						<Flex alignItems="center" mt="-8px" h="30px" borderRight="1px solid hsl(2deg, 0%, 91%)" pl={2} pr={2} />
						<Spreadsheet
							data={lifeData}
							onChange={(e) => {
								let localData = lifeData?.reduce((p, c) => {
									let lp = p;
									let lc = c?.slice(0, numColumn);
									lp.push(lc);
									return lp;
								}, []);
								setLifeData(
									localData?.slice(0, claimProducts?.filter((el) => el?.productLine?.type === 'Life')?.map((e) => e?.productLine)?.length)
								);
							}}
							onActivate={(e) => setActive({ ...e, t: 'Life' })}
							hideRowIndicators
							hideColumnIndicators
						/>
					</Box>
				</Flex>
			</div>
		</Box>
	);
};

export default Table;
