import { Text, Tooltip } from '@chakra-ui/react';
import useStore from '../store';
import moment from 'moment';

function LabelDate({ input = null, inputFormat = null, outputFormat = null, color = '#303030' }) {
	const { currentLang: lang } = useStore((state) => state.currentLang);

	moment.locale(lang === 'kh' ? 'km' : 'en-gb');

	return (
		<Tooltip
			label={
				<>
					{inputFormat ? (
						<Text fontWeight="medium" color="white">
							{moment(input, inputFormat).format(outputFormat)}
						</Text>
					) : (
						<Text fontWeight="medium" color="white">
							{moment(input).format(outputFormat)}
						</Text>
					)}
				</>
			}
		>
			{inputFormat ? (
				<Text fontWeight="medium" color={color}>
					{moment(input, inputFormat).format(outputFormat)}
				</Text>
			) : (
				<Text fontWeight="medium" color={color}>
					{moment(input).format(outputFormat)}
				</Text>
			)}
		</Tooltip>
	);
}

export default LabelDate;
