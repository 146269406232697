import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, AspectRatio, Flex, Text, SimpleGrid, IconButton, ButtonGroup, Box } from '@chakra-ui/react';
import Card from '../../components/Card/Card';
import {
	faAtlas,
	faUsers,
	faPersonBooth,
	faChartBar,
	faFileAlt,
	faBuilding,
	faCalendarMinus,
	faMoneyBillWave,
	faAddressCard,
	faListOl,
	faFileContract,
	faHandshake,
	faUserTag,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { DragHandleIcon, HamburgerIcon } from '@chakra-ui/icons';
import c from '../../constant';
import useStore from '../../store';
import ComplaintBadge from '../../components/HomeBadge/ComplaintBadge';
import CompanySubmissionReportBadge from '../../components/HomeBadge/CompanySubmissionReportBadge';
import CompanySubmissionProposalBadge from '../../components/HomeBadge/CompanySubmissionProposalBadge';
import LeaveRequestBadge from '../../components/HomeBadge/LeaveRequestBadge';
import AgentRegistrationBadge from '../../components/HomeBadge/AgentRegistrationBadge';
import ManageAgentPartnerBadge from '../../components/ManageAgentPartner/Badge';
import InsuranceInstitutionBadge from '../../components/HomeBadge/InsuranceInstitutionBadge';
import Announcement from '../../components/Announcement';
import ProposalAnnouncement from '../../components/ProposalAnnouncement';
import InsuranceCompanySaleStaffBadge from '../../components/HomeBadge/InsuranceCompanySaleStaffBadge';

import _ from 'lodash';
import './style.css';

const CHANGE_MENU_STYLE_MUTATION = gql`
	mutation change($menuStyle: String!) {
		changeHomeMenuStyle(menuStyle: $menuStyle)
	}
`;

function Home() {
	const history = useHistory();
	const { t } = useTranslation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const setCurrentUser = useStore((state) => state.setCurrentUser);

	const [chnageMenuStyle, {}] = useMutation(CHANGE_MENU_STYLE_MUTATION);

	const { currentUser } = useStore((state) => state.currentUser);

	const [menuStyle, setMenuStyle] = useState(currentUser?.homeMenu === 'list' ? 'list' : 'grid');
	useEffect(() => {
		if (currentUser?.homeMenu) {
			setMenuStyle(currentUser?.homeMenu === 'list' ? 'list' : 'grid');
		}
	}, [currentUser]);

	function changeMenu(data) {
		chnageMenuStyle({
			variables: {
				menuStyle: data || null,
			},
		});
		setCurrentUser({ ...currentUser, homeMenu: data });
	}

	function item({ m, index }) {
		let pass = false;
		m.roles.forEach((r) => {
			if (currentUser?.role) {
				const tmpUserRole = currentUser?.role ? currentUser?.role.split(',') : [];
				tmpUserRole.forEach((rUser) => {
					if (r === rUser) {
						pass = true;
					}
				});
			}
		});
		if (!pass) {
			return null;
		}
		const BADGE = m.badge;
		return (
			<AspectRatio key={'menu-' + index} cursor="pointer" maxW="100%" ratio={1}>
				<Card>
					{BADGE}
					<Flex
						id={m.name.toLowerCase()}
						w="100%"
						h="100%"
						p={4}
						onMouseDown={() => {
							history.push(m.path);
						}}
						_hover={{
							transition: 'all 0.07s ease-in',
							border: '2px solid #303030',
							borderRadius: c.borderRadius,
						}}
						direction="column"
						justifyContent="center"
						alignItems="center"
					>
						<FontAwesomeIcon icon={m.icon} color="#303030" p="2" style={{ width: '25%', height: '25%' }} />
						<Text mt=".7rem" color="text.main" fontSize="xl" fontWeight="600" textAlign="center">
							{t(m.name)}
						</Text>
					</Flex>
				</Card>
			</AspectRatio>
		);
	}

	function getMenuListData() {
		let tmpFilteredMenu = [];
		menuList.map((m) => {
			let pass = false;
			m.roles.forEach((r) => {
				if (currentUser?.role) {
					const tmpUserRole = currentUser?.role ? currentUser?.role.split(',') : [];
					tmpUserRole.forEach((rUser) => {
						if (r === rUser) {
							pass = true;
						}
					});
				}
			});
			if (pass) {
				tmpFilteredMenu.push(m);
			}
		});
		let groupMenu = [];
		tmpFilteredMenu.map((m) => groupMenu.push(m.group));
		groupMenu = _.uniq(groupMenu);
		const groupMenuOrder = [];
		groupOrder.map((o) => {
			const check = _.find(groupMenu, function (g) {
				return g === o;
			});
			if (check) {
				groupMenuOrder.push(check);
			}
		});
		const res = [];
		groupMenuOrder.map((g) => {
			const tmp = _.filter(tmpFilteredMenu, function (o) {
				return o.group === g;
			});
			res.push({
				group: g,
				list: tmp,
			});
		});
		return res;
	}

	function listMenu() {
		const menuData = getMenuListData();
		return (
			<Box mt="2">
				{menuData?.map((menu, i) => {
					return (
						<Flex w="100%" key={`mm-${i}`} direction="column">
							<Text fontWeight="bold" color="rgba(52, 73, 94,.7)">
								{t(menu.group)}
							</Text>
							<SimpleGrid mt="2" w="100%" mb="24px" columns={[1, null, 3]} spacing={['1.5rem', null, '1.5rem']}>
								{menu.list.map((m, index) => {
									const BADGE = m.badge;
									return (
										<AspectRatio className="btn-3" key={'menu-' + index} cursor="pointer" maxW="100%" ratio={10 / 2.2}>
											<Card>
												{BADGE}
												<Flex
													id={m.name.toLowerCase()}
													w="100%"
													h="100%"
													p={4}
													onMouseDown={() => {
														history.push(m.path);
													}}
													borderLeft="2px solid rgba(0,0,0,0)"
													_hover={{
														transition: 'all 0.07s ease-in',
														borderLeft: '2px solid rgba(52, 152, 219,1.0)',
														borderRadius: c.borderRadius,
													}}
													// justifyContent="center"
													alignItems="center"
												>
													<FontAwesomeIcon icon={m.icon} color="#303030" style={{ fontSize: '1rem' }} />
													<Flex flex="1" ml="3">
														<Text color="text.main" fontSize="1rem" fontWeight="600" textAlign="center">
															{t(m.name)}
														</Text>
													</Flex>
												</Flex>
											</Card>
										</AspectRatio>
									);
								})}
							</SimpleGrid>
						</Flex>
					);
				})}
			</Box>
		);
	}

	return (
		<Container maxW="container.xl" mt="16px" mb="16px">
			{currentUser?.announcingItem && (
				<>
					<Announcement id={currentUser?.announcingItem} />
				</>
			)}
			<ProposalAnnouncement auditServiceOnly={true} />
			{menuStyle === 'grid' && (
				<>
					<Text id="home-title" mt="8" fontSize="2xl" fontWeight="600" color="#14213D">
						{t('IRC Management Dashboard')}
					</Text>
					<SimpleGrid mt="6" mb="64px" columns={[2, null, 5]} spacing={['1.5rem', null, '1.5rem']}>
						{menuList.map((m, index) => {
							return item({ m, index });
						})}
					</SimpleGrid>
				</>
			)}
			{menuStyle === 'list' && <>{listMenu()}</>}
			<Flex justifyContent="flex-end">
				<ButtonGroup size="sm" isAttached variant="outline">
					<IconButton
						onMouseDown={() => {
							setMenuStyle('list');
							changeMenu('list');
							window.scrollTo(0, 0);
						}}
						icon={<HamburgerIcon />}
					/>
					<IconButton
						onMouseDown={() => {
							setMenuStyle('grid');
							changeMenu('grid');
							window.scrollTo(0, 0);
						}}
						icon={<DragHandleIcon />}
					/>
				</ButtonGroup>
			</Flex>
		</Container>
	);
}

export default Home;

const groupOrder = [
	'adminSystem',
	'humanResourceSystem',
	'budgetSystem',
	'marketDataSystem',
	'insuranceInstitutionDataSystem',
	'agentManagementSystem',
	'receiveSolveComplaintSystem',
];
const menuList = [
	{
		name: 'WEBSITE',
		icon: faAtlas,
		path: '/website',
		roles: ['websiteEditor', 'admin'],
		group: 'adminSystem',
	},
	{
		name: 'COMPLAINT',
		icon: faPersonBooth,
		path: '/complaint-type',
		roles: ['admin', 'complaint'],
		badge: <ComplaintBadge />,
		group: 'receiveSolveComplaintSystem',
	},
	{
		name: 'COMPANY REPORT',
		icon: faChartBar,
		path: '/company-submission-report',
		roles: ['admin', 'companySubmissionReport', 'mdd', 'ews'],
		badge: <CompanySubmissionReportBadge />,
		group: 'marketDataSystem',
	},
	{
		name: 'PROPOSAL',
		icon: faFileAlt,
		path: '/company-submission-proposal?page=1&status=in progress',
		roles: ['admin', 'companySubmissionProposal'],
		badge: <CompanySubmissionProposalBadge />,
		group: 'adminSystem',
	},
	{
		name: 'Insurance Agent Registration',
		icon: faUsers,
		path: '/agent?page=1',
		roles: ['admin', 'agent'],
		badge: <AgentRegistrationBadge />,
		group: 'agentManagementSystem',
	},
	{
		name: 'Manage Agent Partner',
		icon: faHandshake,
		path: '/manage-agent-partner',
		roles: ['admin', 'manageAgentPartner'],
		badge: <ManageAgentPartnerBadge />,
		group: 'agentManagementSystem',
	},
	{
		name: 'Audit Company',
		icon: faFileContract,
		path: '/audit-company?page=1&status=in progress',
		roles: ['admin', 'auditCompany'],
		badge: <CompanySubmissionProposalBadge auditServiceOnly={true} />,
		group: 'insuranceInstitutionDataSystem',
	},
	{
		name: 'Insurance Institution',
		icon: faBuilding,
		path: '/company?page=1',
		roles: ['admin', 'companyList'],
		badge: <InsuranceInstitutionBadge />,
		group: 'insuranceInstitutionDataSystem',
	},
	{
		name: 'Insurance Sale Staff',
		icon: faUserTag,
		path: '/company-sale-professional/menu',
		roles: ['admin', 'insuranceCompanySaleStaff'],
		badge: <InsuranceCompanySaleStaffBadge />,
		group: 'insuranceInstitutionDataSystem',
	},
	{
		name: 'Request Leave',
		icon: faCalendarMinus,
		path: '/request-leave',
		roles: ['admin', 'requestLeave'],
		badge: <LeaveRequestBadge />,
		group: 'humanResourceSystem',
	},
	{
		name: 'Revenue',
		icon: faMoneyBillWave,
		path: '/payment?page=1',
		roles: ['admin', 'payment'],
		group: 'budgetSystem',
	},
	{
		name: 'Official Profile',
		icon: faAddressCard,
		path: '/employee/list?page=1',
		roles: ['admin', 'employee'],
		group: 'humanResourceSystem',
	},
	{
		name: 'Issued Letter',
		icon: faListOl,
		path: '/issued-letter?page=1&count=300',
		roles: ['admin', 'issuedLetter'],
		group: 'adminSystem',
	},
];
