/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button } from '@chakra-ui/react';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactExport from 'react-export-excel';
import { useTranslation } from 'react-i18next';
import c from '../../../constant';
import { fileName } from '../Miscellaneous/export';
import { EWSTypeTemplate } from '../../../utils/ews';
import useStore from '../../../store';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ExportExcelGeneral({ month = null, year = null, objects = [], ...props }) {
	const { t } = useTranslation();
	const { currentLang } = useStore((state) => state.currentLang);

	return (
		<>
			<Box {...props}>
				<ExcelFile
					filename={fileName('Financial Statement', month, year)}
					element={
						<>
							<Button
								isLoading={false}
								isDisabled={false}
								onClick={() => null}
								leftIcon={<FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 16 }} />}
								colorScheme="gray"
								variant="solid"
								size="sm"
								borderRadius={c.borderRadius}
								border="1px solid #bdc3c7"
							>
								{t('Export')}
							</Button>
						</>
					}
				>
					{objects?.map((object, i) => {
						if (
							object?.code === EWSTypeTemplate.SFP ||
							object?.code === EWSTypeTemplate.SPLOCI ||
							object?.code === EWSTypeTemplate.SCF ||
							object?.code === EWSTypeTemplate.ORD
						) {
							const data = object?.labels?.map((label, j) => {
								return {
									title: currentLang === 'kh' ? label?.titleKhmer : label?.titleEnglish,
									valueUSD: object?.cells[j][0]?.value || '',
									valueKHR: object?.cells[j][1]?.value || '',
								};
							});
							return (
								<ExcelSheet key={i} data={data} name={object?.code}>
									<ExcelColumn label={t('Label')} value="title" />
									<ExcelColumn label={t('USD')} value="valueUSD" />
									<ExcelColumn label={t('KHR')} value="valueKHR" />
								</ExcelSheet>
							);
						} else if (object?.code === EWSTypeTemplate.IR) {
							const data = object?.labels?.map((label, j) => {
								return {
									title: currentLang === 'kh' ? label?.titleKhmer : label?.titleEnglish,
									valueKHRIMR: object?.cells[j][0]?.value || '',
									valueFRIMR: object?.cells[j][1]?.value || '',
									valueTotalIMR: object?.cells[j][2]?.value || '',
									valueUSD: object?.cells[j][3]?.value || '',
									valuePercentage: object?.cells[j][4]?.value || '',
								};
							});
							return (
								<ExcelSheet key={i} data={data} name={object?.code}>
									<ExcelColumn label={t('Label')} value="title" />
									<ExcelColumn label={currentLang === 'kh' ? 'ប្រាក់រៀល (គិតជា លានរៀល)' : 'KHR (In Million KHR)'} value="valueKHRIMR" />
									<ExcelColumn
										label={currentLang === 'kh' ? 'រូបិយវត្ថុអន្តរជាតិ (គិតជា លានរៀល)' : 'Foreign Currency (In Million KHR)'}
										value="valueFRIMR"
									/>
									<ExcelColumn label={currentLang === 'kh' ? 'សរុប (គិតជា លានរៀល)' : 'Total (In Million KHR)'} value="valueTotalIMR" />
									<ExcelColumn label={t('USD')} value="valueUSD" />
									<ExcelColumn label={t('Percentage of Invested Asset')} value="valuePercentage" />
								</ExcelSheet>
							);
						} else if (object?.code === EWSTypeTemplate.SOCE) {
							const data = object?.labels?.map((label, j) => {
								return {
									title: currentLang === 'kh' ? label?.titleKhmer : label?.titleEnglish,
									valueUSDSC: object?.cells[j][0]?.value || '',
									valueKHRSC: object?.cells[j][1]?.value || '',
									valueUSDSP: object?.cells[j][2]?.value || '',
									valueKHRSP: object?.cells[j][3]?.value || '',
									valueUSDDFSS: object?.cells[j][4]?.value || '',
									valueKHRDFSS: object?.cells[j][5]?.value || '',
									valueUSDRR: object?.cells[j][6]?.value || '',
									valueKHRRR: object?.cells[j][7]?.value || '',
									valueUSDRA: object?.cells[j][8]?.value || '',
									valueKHRRA: object?.cells[j][9]?.value || '',
									valueUSDOCE: object?.cells[j][10]?.value || '',
									valueKHROCE: object?.cells[j][11]?.value || '',
									valueUSDTOTAL: object?.cells[j][12]?.value || '',
									valueKHRTOTAL: object?.cells[j][13]?.value || '',
								};
							});
							return (
								<ExcelSheet key={i} data={data} name={object?.code}>
									<ExcelColumn label={t('Label')} value="title" />
									<ExcelColumn label={currentLang === 'kh' ? 'ដុល្លារ​អាមេរិក (ដើមទុន)' : 'USD (Share Capital)'} value="valueUSDSC" />
									<ExcelColumn label={currentLang === 'kh' ? 'ប្រាក់រៀល (ដើមទុន)' : 'KHR (Share Capital)'} value="valueKHRSC" />
									<ExcelColumn
										label={currentLang === 'kh' ? 'ដុល្លារ​អាមេរិក (បុព្វលាភភាគហ៊ុន)' : 'USD (Share Premium)'}
										value="valueUSDSP"
									/>
									<ExcelColumn label={currentLang === 'kh' ? 'ប្រាក់រៀល (បុព្វលាភភាគហ៊ុន)' : 'KHR (Share Premium)'} value="valueKHRSP" />
									<ExcelColumn
										label={
											currentLang === 'kh'
												? 'ដុល្លារ​អាមេរិក (ប្រាក់កក់សម្រាប់ទិញភាគហ៊ុនពេលអនាគត	)'
												: 'USD (Deposits for future share subscription	)'
										}
										value="valueUSDDFSS"
									/>
									<ExcelColumn
										label={
											currentLang === 'kh'
												? 'ប្រាក់រៀល (ប្រាក់កក់សម្រាប់ទិញភាគហ៊ុនពេលអនាគត	)'
												: 'KHR (Deposits for future share subscription	)'
										}
										value="valueKHRDFSS"
									/>
									<ExcelColumn
										label={currentLang === 'kh' ? 'ដុល្លារ​អាមេរិក (ប្រាក់បម្រុងដែលត្រូវបានវាយតម្លៃឡើងវិញ)' : 'USD (Revaluation Reserves)'}
										value="valueUSDRR"
									/>
									<ExcelColumn
										label={currentLang === 'kh' ? 'ប្រាក់រៀល (ប្រាក់បម្រុងដែលត្រូវបានវាយតម្លៃឡើងវិញ)' : 'KHR (Revaluation Reserves)'}
										value="valueKHRRR"
									/>
									<ExcelColumn
										label={currentLang === 'kh' ? 'ដុល្លារ​អាមេរិក (ចំណេញរក្សាទុក/ខាតបង្គរ)' : 'USD (Retained earnings/accumulated losses)'}
										value="valueUSDRA"
									/>
									<ExcelColumn
										label={currentLang === 'kh' ? 'ប្រាក់រៀល (ចំណេញរក្សាទុក/ខាតបង្គរ)' : 'KHR (Retained earnings/accumulated losses)'}
										value="valueKHRRA"
									/>
									<ExcelColumn
										label={currentLang === 'kh' ? 'ដុល្លារ​អាមេរិក (សមាសធាតុផ្សេងទៀតនៃមូលធន)' : 'USD (Other components of equity)'}
										value="valueUSDOCE"
									/>
									<ExcelColumn
										label={currentLang === 'kh' ? 'ប្រាក់រៀល (សមាសធាតុផ្សេងទៀតនៃមូលធន)' : 'KHR (Other components of equity)'}
										value="valueKHROCE"
									/>
									<ExcelColumn label={currentLang === 'kh' ? 'ដុល្លារ​អាមេរិក (សរុប)' : 'USD (Total)'} value="valueUSDTOTAL" />
									<ExcelColumn label={currentLang === 'kh' ? 'ប្រាក់រៀល (សរុប)' : 'KHR (Total)'} value="valueKHRTOTAL" />
								</ExcelSheet>
							);
						}
					})}
				</ExcelFile>
			</Box>
		</>
	);
}
