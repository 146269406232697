import {
	Box,
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	useDisclosure,
	Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { Form, Formik } from 'formik';
import { mappingKhmerNumber } from '../../../utils/market-development';
import MdCompanyTypeFormField from '../../../components/MarketDevelopment/Miscellaneous/Forms/MdCompanyTypeFormField';
import MdCompanyFormField from '../../../components/MarketDevelopment/Miscellaneous/Forms/MdCompanyFormField';
import SelectValueFormField from '../../../components/form/SelectValueFormField';
import CheckMarkFormField from '../../../components/form/CheckMarkFormField';
import useStore from '../../../store';
import c from '../../../constant';
import * as Yup from 'yup';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/km';

const validationSchema = Yup.object().shape({
	companyType: Yup.string().nullable(),
	companyId: Yup.string().nullable(),
	type: Yup.string().nullable(),
	year: Yup.string().nullable(),
	editing: Yup.boolean().nullable(),
});

export default function MonthlySaleFilterSubmit({
	period = null,
	reportId = null,
	companyType = null,
	companyId = null,
	type = null,
	year = null,
	editing = false,
	onCallback = () => null,
}) {
	const { t } = useTranslation();
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const { isOpen, onOpen, onClose } = useDisclosure();

	function getType(reportId) {
		if (reportId === c.annually_unaudited_financial_statements) {
			return c.reportIds[c.annually_unaudited_financial_statements][0]?.value;
		} else if (reportId === c.annually_audited_financial_statements) {
			return c.reportIds[c.annually_audited_financial_statements][0]?.value;
		} else {
			return type || '';
		}
	}

	return (
		<>
			<Button
				isLoading={false}
				isDisabled={false}
				onClick={onOpen}
				leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
				colorScheme={companyType || companyId || type || year || editing ? 'yellow' : 'gray'}
				variant="solid"
				size="sm"
				borderRadius={c.borderRadius}
				border="1px solid #bdc3c7"
			>
				<Text fontSize="sm" fontWeight="semibold">
					{t('Filter')}
				</Text>
			</Button>
			<Modal borderRadius={c.borderRadius} isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius}>
					<ModalHeader>{t('Filter')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Formik
							validationSchema={validationSchema}
							initialValues={{
								companyType,
								companyId,
								type: getType(reportId),
								year,
								editing,
							}}
							onSubmit={(values) => {
								let url = '?period=' + period + '&reportId=' + reportId + '&page=1';
								if (values?.companyType) {
									url = url + `&companyType=${values?.companyType}`;
								}
								if (values?.companyId) {
									url = url + `&companyId=${values?.companyId}`;
								}
								if (values?.type) {
									url = url + `&type=${values?.type}`;
								}
								if (values?.year) {
									url = url + `&year=${values?.year}`;
								}
								if (values?.editing) {
									url = url + `&editing=${values?.editing}`;
								}
								onCallback(url);
								onClose();
							}}
						>
							{({ values, setFieldValue, ...props }) => {
								return (
									<Box pb="4">
										<Form>
											<SimpleGrid columns={1} spacing={4}>
												<MdCompanyTypeFormField
													{...props}
													name="companyType"
													companyFormName="companyId"
													label="Company Type"
													placeholder={t('Company Type')}
												/>
												<MdCompanyFormField {...props} name="companyId" companyTypeFormName="companyType" label="Company" />
												<SelectValueFormField
													{...props}
													disabled={
														reportId === c.annually_unaudited_financial_statements ||
														reportId === c.annually_audited_financial_statements
													}
													placeholder={t('Type')}
													name="type"
													label="Type"
													options={c.reportIds[reportId]}
												/>
												<SelectValueFormField
													{...props}
													placeholder={t('Year')}
													name="year"
													label="Year"
													options={[0].reduce((p, c) => {
														let lp = p;
														let i = 2021;
														while (i <= moment().year()) {
															lp.push({ label: lang === 'kh' ? mappingKhmerNumber(i) : i, value: i });
															i = i + 1;
														}
														return lp?.reverse();
													}, [])}
												/>
												<CheckMarkFormField {...props} name="editing" label="Editing" />
												<Flex mt={2} justifyContent="flex-end">
													<Button
														mr={2}
														colorScheme="gray"
														variant="solid"
														size="md"
														h="42px"
														w="128px"
														borderRadius={c.borderRadius}
														onClick={() => {
															onCallback('?period=' + period + '&reportId=' + reportId + '&page=1');
															onClose();
														}}
													>
														{t('Clear')}
													</Button>
													<Button
														ml={2}
														colorScheme="blue"
														variant="solid"
														size="md"
														h="42px"
														w="128px"
														type="submit"
														borderRadius={c.borderRadius}
													>
														{t('Apply')}
													</Button>
												</Flex>
											</SimpleGrid>
										</Form>
									</Box>
								);
							}}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}
