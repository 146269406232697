/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { Box, Button, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faPrint } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import DisNumPolicyGrossPremiumByChannelGraph from './DisNumPolicyGrossPremiumByChannelGraph';
import DisGrossPremiumLobByChannelGraph from './DisGrossPremiumLobByChannelGraph';
import DisNumPolicyGrossPremiumByProvinceGraph from './DisNumPolicyGrossPremiumByProvinceGraph';
import Filter from '../../Miscellaneous/filter-dashboard';
import c from '../../../../constant';

function MonthlyDistributionDashboard() {
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const period = urlParams.get('period') || null;
	const reportId = urlParams.get('reportId') || null;
	const { push } = useHistory();
	const { t } = useTranslation();
	const [filter, setFilter] = useState(null);

	return (
		<Box>
			<Flex justifyContent="center" alignItems="center" className="show-only-printing" borderBottomColor="gray.200" borderBottomWidth={1}>
				<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
					{t('Distribution Channel')}
				</Text>
			</Flex>
			<Flex mb={4} direction={['column', 'column', 'row']} justifyContent="flex-end" alignItems="center">
				<Button
					className="hidden-print"
					isLoading={false}
					isDisabled={false}
					leftIcon={<FontAwesomeIcon icon={faList} style={{ fontSize: 16 }} />}
					size="sm"
					borderRadius={c.borderRadius}
					border="1px solid #bdc3c7"
					mr={[0, 0, 2]}
					width={['100%', '100%', 'auto']}
					colorScheme="gray"
					variant="solid"
					mt={[2, 2, 0]}
					onMouseDown={() =>
						push('/market-development-data/monthly-distribution-channel-report?period=' + period + '&reportId=' + reportId + '&page=1')
					}
				>
					{t('List')}
				</Button>
				<Filter
					companyType={filter?.companyType}
					companyId={filter?.companyId}
					rangeOption={filter?.rangeOption}
					rangeValue={filter?.rangeValue}
					onCallback={setFilter}
				/>
				<Button
					className="hidden-print"
					isLoading={false}
					isDisabled={false}
					onClick={() => window?.print()}
					leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
					size="sm"
					borderRadius={c.borderRadius}
					border="1px solid #bdc3c7"
					ml={[0, 0, 2]}
					width={['100%', '100%', 'auto']}
					colorScheme="gray"
					variant="solid"
					mt={[2, 2, 0]}
				>
					{t('Print')}
				</Button>
			</Flex>
			<Box display={['', '', 'flex']}>
				<Box width="100%" height="auto" mt={[4, 4, 0]}>
					<SimpleGrid w="100%" columns={1} spacing={4}>
						<DisNumPolicyGrossPremiumByChannelGraph companyType={filter?.companyType} companyId={filter?.companyId} range={filter?.rangeValue} />
						<DisGrossPremiumLobByChannelGraph companyType={filter?.companyType} companyId={filter?.companyId} range={filter?.rangeValue} />
						<DisNumPolicyGrossPremiumByProvinceGraph companyType={filter?.companyType} companyId={filter?.companyId} range={filter?.rangeValue} />
					</SimpleGrid>
				</Box>
			</Box>
		</Box>
	);
}

export default MonthlyDistributionDashboard;
