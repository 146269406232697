import { EWSTypeCurrency, sumOrError } from './ews';
import { isNumeric } from './market-development';

const scfNumColumn = 2;
const readyOnlys = [0, 1, 3, 4, 12, 13, 14, 23, 24, 28, 29, 30, 31, 32, 48, 49, 50, 51, 52, 58, 59, 60, 61, 63];
const sumOnlys = [12, 29, 49, 59, 61, 63];

function cleanSCFCells(labels) {
	const cells = labels?.map((label, i) => {
		if (readyOnlys?.includes(i)) {
			if (sumOnlys?.includes(i)) {
				return [
					{ value: '0', readOnly: true },
					{ value: '0', readOnly: true },
				];
			} else {
				return [
					{ value: '', readOnly: true },
					{ value: '', readOnly: true },
				];
			}
		} else {
			return [
				{ value: '', readOnly: false },
				{ value: '', readOnly: false },
			];
		}
	});
	return cells;
}

function detailSCFCells({ code = null, labels = [], detailLabels = [], readOnly = false }) {
	const localCells = labels?.map((label, i) => {
		if (readyOnlys?.includes(i)) {
			if (sumOnlys?.includes(i)) {
				return [
					{ value: '0', readOnly: true },
					{ value: '0', readOnly: true },
				];
			} else {
				return [
					{ value: '', readOnly: true },
					{ value: '', readOnly: true },
				];
			}
		} else {
			const detailLabel = detailLabels?.find((el) => el?.templateLabel?.code === label?.code);
			if (detailLabel) {
				return [
					{ value: detailLabel?.values?.find((value) => value?.currency === EWSTypeCurrency.USD)?.value || 0, readOnly },
					{ value: detailLabel?.values?.find((value) => value?.currency === EWSTypeCurrency.KHR)?.value || 0, readOnly },
				];
			} else {
				return [
					{ value: '', readOnly },
					{ value: '', readOnly },
				];
			}
		}
	});

	const { cells } = calculateSCFCells({ code, cells: localCells, numLabel: labels?.length, readOnly });

	return cells;
}

function calculateSCFCells({ code, cells, numLabel, readOnly = false }) {
	// Operating profit loss before changes
	let oplbcSums = [2, ...Array.from({ length: 7 }, (item, i) => i + 5)];
	let oplbcUSD = 0;
	let oplbcKHR = 0;

	// Net cash used in operating activities
	let ncuoaSums = [...Array.from({ length: 8 }, (item, i) => i + 15), ...Array.from({ length: 3 }, (item, i) => i + 25)];
	let ncuoaUSD = 0;
	let ncuoaKHR = 0;

	// Net cash used in investing activities
	let ncuiaSums = [...Array.from({ length: 15 }, (item, i) => i + 33)];
	let ncuiaUSD = 0;
	let ncuiaKHR = 0;

	// Net cash used in financing activities
	let ncufaSums = [...Array.from({ length: 5 }, (item, i) => i + 53)];
	let ncufaUSD = 0;
	let ncufaKHR = 0;

	// Cash and cash equivalent at the end of the period
	let cceSums = [...Array.from({ length: 1 }, (item, i) => i + 62)];
	let cceUSD = 0;
	let cceKHR = 0;

	let localCells = cells?.map((cols, i) => {
		if (readyOnlys?.includes(i)) {
			if (i === 12) {
				return [
					{ value: sumOrError([oplbcUSD]), readOnly: true },
					{ value: sumOrError([oplbcKHR]), readOnly: true },
				];
			} else if (i === 29) {
				return [
					{ value: sumOrError([ncuoaUSD, oplbcUSD]), readOnly: true },
					{ value: sumOrError([ncuoaKHR, oplbcKHR]), readOnly: true },
				];
			} else if (i === 49) {
				return [
					{ value: sumOrError([ncuiaUSD]), readOnly: true },
					{ value: sumOrError([ncuiaKHR]), readOnly: true },
				];
			} else if (i === 59) {
				return [
					{ value: sumOrError([ncufaUSD]), readOnly: true },
					{ value: sumOrError([ncufaKHR]), readOnly: true },
				];
			} else if (i === 61) {
				return [
					{ value: sumOrError([ncuoaUSD, oplbcUSD, ncuiaUSD, ncufaUSD]), readOnly: true },
					{ value: sumOrError([ncuoaKHR, oplbcKHR, ncuiaKHR, ncufaKHR]), readOnly: true },
				];
			} else if (i === 63) {
				return [
					{ value: sumOrError([ncuoaUSD, oplbcUSD, ncuiaUSD, ncufaUSD, cceUSD]), readOnly: true },
					{ value: sumOrError([ncuoaKHR, oplbcKHR, ncuiaKHR, ncufaKHR, cceKHR]), readOnly: true },
				];
			} else {
				return [
					{ value: '', readOnly: true },
					{ value: '', readOnly: true },
				];
			}
		} else {
			if (oplbcSums?.includes(i)) {
				oplbcUSD = oplbcUSD + parseFloat(cols[0]?.value || 0);
				oplbcKHR = oplbcKHR + parseFloat(cols[1]?.value || 0);
			} else if (ncuoaSums?.includes(i)) {
				ncuoaUSD = ncuoaUSD + parseFloat(cols[0]?.value || 0);
				ncuoaKHR = ncuoaKHR + parseFloat(cols[1]?.value || 0);
			} else if (ncuiaSums?.includes(i)) {
				ncuiaUSD = ncuiaUSD + parseFloat(cols[0]?.value || 0);
				ncuiaKHR = ncuiaKHR + parseFloat(cols[1]?.value || 0);
			} else if (ncufaSums?.includes(i)) {
				ncufaUSD = ncufaUSD + parseFloat(cols[0]?.value || 0);
				ncufaKHR = ncufaKHR + parseFloat(cols[1]?.value || 0);
			} else if (cceSums?.includes(i)) {
				cceUSD = cceUSD + parseFloat(cols[0]?.value || 0);
				cceKHR = cceKHR + parseFloat(cols[1]?.value || 0);
			}
			return [
				{ value: cols[0]?.value, readOnly },
				{ value: cols[1]?.value, readOnly },
			];
		}
	});
	localCells = localCells.slice(0, numLabel);
	return { code, cells: localCells };
}

function validateSCFCells({ cells }) {
	const localCells = cells?.reduce((iPre, iCur) => {
		let localIPre = iPre;
		localIPre.push(
			...iCur?.reduce((jPre, jCur) => {
				let localJPre = jPre;
				if (!jCur?.readOnly && !isNumeric(jCur?.value)) {
					localJPre.push(jCur);
				}
				return localJPre;
			}, [])
		);
		return localIPre;
	}, []);
	return localCells?.length === 0;
}

export { scfNumColumn, cleanSCFCells, detailSCFCells, calculateSCFCells, validateSCFCells };
