/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@chakra-ui/react';
import { checkSelectedActiveColumn } from '../../../../utils/market-development';
import Spreadsheet from 'react-spreadsheet';
import SpreadSheetProductList from '../../Miscellaneous/spreadsheet-product-list';
import SpreadSheetTotal from '../../Miscellaneous/spreadsheet-total';

const Table = ({ saleProducts = null, numColumn = 16 }) => {
	const { t } = useTranslation();
	const [newData, setNewData] = useState(
		saleProducts
			?.filter((el) => el?.productLine?.type === 'General' && el?.policyType === 'New')
			?.map((el) => el?.productValues?.map((el) => ({ value: el?.value, readOnly: true })))
	);
	const [renewalData, setRenewalData] = useState(
		saleProducts
			?.filter((el) => el?.productLine?.type === 'General' && el?.policyType === 'Renewal')
			?.map((el) => el?.productValues?.map((el) => ({ value: el?.value, readOnly: true })))
	);
	const [active, setActive] = useState(null);

	return (
		<Box className="spreadsheet_container">
			<div className="product-title-cell">{t('Product Line')}</div>
			<table className="spreadsheet_custom_header">
				<thead>
					<tr style={{ height: '40px' }}>
						<td colSpan="12" className="background-light-green">
							{t('Policies')}
						</td>
						<td colSpan="4" className="background-light-orange">
							{t('Cancelled Policies')}
						</td>
					</tr>
					<tr style={{ height: '40px' }}>
						<td colSpan="2" className="background-light-green">
							{t('Gross Premium')}
						</td>
						<td colSpan="2" className="background-light-green">
							{t('Sum Insured')}
						</td>
						<td colSpan="2" className="background-light-green">
							{t('Number of Policies')}
						</td>
						<td colSpan="6" className="background-light-green">
							{t('Number of Insured')}
						</td>
						<td colSpan="2" className="background-light-orange">
							{t('Number of Policies')}
						</td>
						<td colSpan="2" className="background-light-orange">
							{t('Gross Premium')}
						</td>
					</tr>
					<tr style={{ height: '35px' }}>
						<td rowSpan="2" className={checkSelectedActiveColumn(active, 0) ? 'active-column' : 'background-light-green'}>
							&gt; 5,000USD
						</td>
						<td rowSpan="2" className={checkSelectedActiveColumn(active, 1) ? 'active-column' : 'background-light-green'}>
							≤ 5,000USD
						</td>
						<td rowSpan="2" className={checkSelectedActiveColumn(active, 2) ? 'active-column' : 'background-light-green'}>
							&gt; 5,000USD
						</td>
						<td rowSpan="2" className={checkSelectedActiveColumn(active, 3) ? 'active-column' : 'background-light-green'}>
							≤ 5,000USD
						</td>
						<td rowSpan="2" className={checkSelectedActiveColumn(active, 4) ? 'active-column' : 'background-light-green'}>
							&gt; 5,000USD
						</td>
						<td rowSpan="2" className={checkSelectedActiveColumn(active, 5) ? 'active-column' : 'background-light-green'}>
							≤ 5,000USD
						</td>
						<td colSpan="3" className="background-light-green">
							&gt; 5,000USD
						</td>
						<td colSpan="3" className="background-light-green">
							≤ 5,000USD
						</td>
						<td rowSpan="2" className={checkSelectedActiveColumn(active, 12) ? 'active-column' : 'background-light-orange'}>
							&gt; 5,000USD
						</td>
						<td rowSpan="2" className={checkSelectedActiveColumn(active, 13) ? 'active-column' : 'background-light-orange'}>
							≤ 5,000USD
						</td>
						<td rowSpan="2" className={checkSelectedActiveColumn(active, 14) ? 'active-column' : 'background-light-orange'}>
							&gt; 5,000USD
						</td>
						<td rowSpan="2" className={checkSelectedActiveColumn(active, 15) ? 'active-column' : 'background-light-orange'}>
							≤ 5,000USD
						</td>
					</tr>
					<tr style={{ height: '35px' }}>
						<td className={checkSelectedActiveColumn(active, 6) ? 'active-column' : 'background-light-green'}>{t('Male')}</td>
						<td className={checkSelectedActiveColumn(active, 7) ? 'active-column' : 'background-light-green'}>{t('Female')}</td>
						<td className={checkSelectedActiveColumn(active, 8) ? 'active-column' : 'background-light-green'}>{t('Other Unit')}</td>
						<td className={checkSelectedActiveColumn(active, 9) ? 'active-column' : 'background-light-green'}>{t('Male')}</td>
						<td className={checkSelectedActiveColumn(active, 10) ? 'active-column' : 'background-light-green'}>{t('Female')}</td>
						<td className={checkSelectedActiveColumn(active, 11) ? 'active-column' : 'background-light-green'}>{t('Other Unit')}</td>
					</tr>
				</thead>
			</table>
			<div>
				<Flex>
					<SpreadSheetProductList
						type="t1"
						label="New Policy"
						labelMarginTop="0px"
						products={saleProducts?.filter((el) => el?.productLine?.type === 'General' && el?.policyType === 'New')?.map((e) => e?.productLine)}
						active={active}
					/>
					<Box>
						<Flex alignItems="center" mt="0px" h="30px" borderRight="1px solid hsl(2deg, 0%, 91%)" pl={2} pr={2} />
						<Spreadsheet
							data={newData}
							onChange={(e) => {
								let localData = newData?.reduce((p, c) => {
									let lp = p;
									let lc = c?.slice(0, numColumn);
									lp.push(lc);
									return lp;
								}, []);
								setNewData(localData?.slice(0, newData?.length));
							}}
							onActivate={(e) => setActive({ ...e, t: 't1' })}
							hideRowIndicators
							hideColumnIndicators
						/>
					</Box>
				</Flex>
				<SpreadSheetTotal data={newData} numColumn={numColumn} />
			</div>
			<div>
				<Flex>
					<SpreadSheetProductList
						type="t2"
						label="Renewal Policy"
						labelMarginTop="0px"
						products={saleProducts?.filter((el) => el?.productLine?.type === 'General' && el?.policyType === 'Renewal')?.map((e) => e?.productLine)}
						active={active}
					/>
					<Box>
						<Flex alignItems="center" mt="0px" h="30px" borderRight="1px solid hsl(2deg, 0%, 91%)" pl={2} pr={2} />
						<Spreadsheet
							data={renewalData}
							onChange={(e) => {
								let localData = renewalData?.reduce((p, c) => {
									let lp = p;
									let lc = c?.slice(0, numColumn);
									lp.push(lc);
									return lp;
								}, []);
								setRenewalData(localData?.slice(0, renewalData?.length));
							}}
							onActivate={(e) => setActive({ ...e, t: 't2' })}
							hideRowIndicators
							hideColumnIndicators
						/>
					</Box>
				</Flex>
				<SpreadSheetTotal data={renewalData} numColumn={numColumn} />
			</div>
		</Box>
	);
};

export default Table;
