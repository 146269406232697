/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Center, Container, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import AnimationFile from '../../assets/lotties/report-animation.json';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import ReprotLayout from '../../components/Report/ReportLayout';
import Lottie from 'lottie-react';
import c from '../../constant';

function Report() {
	const { t } = useTranslation();

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'Company Report',
							path: '#',
						},
					]}
				/>
				<Box pb="32px" w="100%" mt="5">
					<ReprotLayout>
						<Box w="100%">
							<Center className="show-only-printing">
								<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
									{t('Company Report')}
								</Text>
							</Center>
							<Center w="100%">
								<Box
									w="100%"
									bg="white"
									borderRadius={c.borderRadius}
									border="1px solid #dbdbdb"
									boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
									pb="32px"
									minH="450px"
								>
									<Flex
										className="hidden-print"
										justifyContent="space-between"
										alignItems="center"
										bg="#FAFAFA"
										p="16px"
										pt="8px"
										pb="8px"
										mb="8px"
										borderBottom="1px solid #dbdbdb"
									>
										<Flex justifyContent="center" alignItems="center">
											<Text fontSize="lg" fontWeight="bold">
												{t('Report')}
											</Text>
										</Flex>
										<Flex justifyContent="flex-start" alignItems="center"></Flex>
									</Flex>
									<Box overflow="hidden">
										<center>
											<Flex w="250px" flex={1} alignItems="center" justifyContent="center" mt="64px" h="250px">
												<Lottie loop={true} animationData={AnimationFile} />
											</Flex>
										</center>
									</Box>
								</Box>
							</Center>
						</Box>
					</ReprotLayout>
				</Box>
			</Container>
		</>
	);
}

export default Report;
