/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { DragHandleIcon } from '@chakra-ui/icons';
import { Box, Center, Container, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import arrayMove from 'array-move';
import c from '../../../constant';

const Q_TEMPLATES = gql`
	query get {
		getEarlyWarningTemplates {
			id
			code
			titleKhmer
			titleEnglish
			order
			createdAt
			createdBy
		}
	}
`;

const M_ORDERS = gql`
	mutation update($orders: JSON!) {
		updateEarlyWarningTemplateOrder(orders: $orders)
	}
`;

function EarlyWarningTemplate() {
	const { push } = useHistory();
	const { t } = useTranslation();
	const [templates, setTemplates] = useState([]);
	const [fetchData, { data }] = useLazyQuery(Q_TEMPLATES);
	const [updateData] = useMutation(M_ORDERS);

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		data && setTemplates(data?.getEarlyWarningTemplates);
	}, [data]);

	function onSortEnd({ oldIndex, newIndex }) {
		const localList = arrayMove(templates, oldIndex, newIndex);
		const variables = { orders: localList.map(({ id }, order) => ({ id, order })) };
		updateData({ variables });
		setTemplates(localList);
	}

	const SortableIcon = SortableHandle(() => <DragHandleIcon mr={4} />);

	const SortableItem = SortableElement(({ item }) => {
		return (
			<Flex
				w="100%"
				mb="2"
				minH="32px"
				alignItems="center"
				bg="white"
				borderRadius={c.borderRadius}
				border="1px solid #dbdbdb"
				boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
				p="16px"
			>
				<SortableIcon />
				<Flex
					_hover={{ textDecoration: 'underline' }}
					flex={1}
					direction="column"
					w="100%"
					h="100%"
					cursor="pointer"
					onMouseDown={() => push('/settings/early-warning-template/detail?id=' + item?.id)}
				>
					<Text fontSize="md" color="black.500" fontWeight="600">
						{item?.code}
					</Text>
					<Text fontSize="sm" color="gray.600" fontWeight="400">
						{item?.titleKhmer}
					</Text>
					<Text fontSize="sm" color="gray.600" fontWeight="400">
						{item?.titleEnglish}
					</Text>
				</Flex>
			</Flex>
		);
	});

	const SortableList = SortableContainer(({ items }) => {
		return (
			<Box w="100%">
				{items.map((item, index) => (
					<SortableItem key={`item-${item?.id}`} disabled={true} index={index} item={item} />
				))}
			</Box>
		);
	});

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'Settings',
							path: '/settings',
						},
						{
							name: 'Early Warning System Statement Template',
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex
							alignItems="center"
							justifyContent="space-between"
							bg="#FAFAFA"
							p="16px"
							pt="8px"
							pb="8px"
							mb="16px"
							borderBottom="1px solid #dbdbdb"
						>
							<Flex justifyContent="center" alignItems="center">
								<Text fontSize="x-large" fontWeight="bold" mr="4">
									{t('List')}
								</Text>
							</Flex>
							<Flex className="header-menu" justifyContent="flex-end" alignItems="center">
								<></>
							</Flex>
						</Flex>
						<Box w="100%" p={4}>
							<SortableList useDragHandle items={templates} onSortEnd={onSortEnd} />
						</Box>
					</Box>
				</Center>
			</Container>
		</>
	);
}

export default EarlyWarningTemplate;
