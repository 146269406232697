/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Image, Box, Center, Text, Input, Button, Flex, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import ChangeLanguage from '../../components/ChangeLanguage';
import OneSignal from 'react-onesignal';
import Logo from '../../assets/images/logo.png';
import CamdigiKeyLogo from '../../assets/images/camdigikey-no-bg.png';
import useStore from '../../store';
import c from '../../constant';
import * as Yup from 'yup';

const LOGIN_MUTATION = gql`
	mutation signIn($username: String!, $password: String!, $onesignalUserId: String) {
		signIn(userInput: { username: $username, password: $password }, onesignalUserId: $onesignalUserId) {
			accessToken
		}
	}
`;

const loginSchema = Yup.object().shape({
	username: Yup.string().strict(true).trim('not allowed to have leading and trailing spaces').required('Required'),
	password: Yup.string().min(8).required('Required'),
});

function Login() {
	const toast = useToast();
	const history = useHistory();
	const { t } = useTranslation();
	const { currentLang } = useStore((state) => state.currentLang);
	const [loadingCamdigikey, setLoadingCamdigikey] = useState(false);
	const [onesignalUserId, setOneSignalUserId] = useState('');
	const [loginUser, { loading, error, data }] = useMutation(LOGIN_MUTATION);

	useEffect(() => {
		OneSignal.getUserId().then((res) => {
			setOneSignalUserId(res);
		});
	}, []);

	function signIn({ username, password }) {
		loginUser({
			variables: {
				username: username?.trim()?.toLowerCase(),
				password: password,
				onesignalUserId: onesignalUserId ? onesignalUserId : '',
			},
		});
	}

	useEffect(() => {
		if (data) {
			if (data.signIn) {
				sessionStorage.setItem('accessToken', data?.signIn?.accessToken);
				history.push('/');
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			warningMessage(error.message);
		}
	}, [error]);

	function warningMessage(message) {
		toast({
			title: t(message),
			status: 'warning',
			isClosable: true,
			position: 'top-right',
		});
	}

	function loginWithCamDigiKey() {
		setLoadingCamdigikey(true);
		window.location.href = c.adminCamDigiKeyRedirectUrlLogin;
	}

	return (
		<Box style={styles.container}>
			{/* <iframe style={{width:200,height:200}} src="http://localhost:3009/redirect/adminLogin" title="W3Schools Free Online Web Tutorials"></iframe> */}

			<Flex
				borderRadius={c.borderRadius}
				position="absolute"
				top="16px"
				right="16px"
				bg="primary.dark"
				w="110px"
				h="32px"
				justifyContent="center"
				alignItems="center"
			>
				<Box ml="8px" pt="2px">
					<ChangeLanguage />
				</Box>
			</Flex>
			<Box p="16px" pt="32px" pb="32px" maxW="550px" w="100%" overflow="hidden">
				<Box p="8" w="100%" bg="white" borderRadius="3px">
					<Center>
						<Image src={Logo} boxSize="160px" loading="eager" />
					</Center>
					<Center mt="16px">
						<Text fontFamily="Moul" fontWeight={currentLang === 'en' ? 'bold' : '400'} textAlign="center" fontSize="20px" color="primary.dark">
							{t('INSURANCE REGULATOR of CAMBODIA')}
						</Text>
					</Center>
					<Formik
						initialValues={{
							username: '',
							password: '',
						}}
						validationSchema={loginSchema}
						onSubmit={(values) => {
							signIn(values);
						}}
					>
						{({ errors, touched }) => (
							<Form>
								<Box mt="8">
									<Field name="username">
										{({ field, meta }) => (
											<>
												<Input {...field} id="username" size="lg" placeholder={t(`Username`)} fontWeight="500" />
												{meta.touched && meta.error && (
													<Text id="error-message-username" color="red.400" fontSize="12px">
														{t('Username')} {t('is')} {t(meta.error)}
													</Text>
												)}
											</>
										)}
									</Field>

									<Field name="password">
										{({ field, meta }) => (
											<>
												<Input {...field} id="password" mt="3" type="password" placeholder={t(`Password`)} size="lg" fontWeight="500" />
												{meta.touched && meta.error && (
													<Text id="error-message-password" color="red.400" fontSize="12px">
														{t('Password')} {t('is')} {t(meta.error)}
													</Text>
												)}
											</>
										)}
									</Field>

									<Flex textAlign="right" mt="6" justifyContent="space-between" alignItems="center" className="loginButtonContainer">
										<Button
											id="login-btn"
											variant="solid"
											size="md"
											h="42px"
											w="100%"
											bg="primary.dark"
											_hover={{ bg: 'primary.dark' }}
											color="white"
											// borderRadius="31px"
											type="submit"
											isLoading={loading}
											loadingText={t('LOGIN')}
											spinnerPlacement="start"
											mb="2"
										>
											{t(`LOGIN`)}
										</Button>
										<Box w="2"></Box>
										<Button
											variant="outline"
											size="md"
											h="42px"
											w="100%"
											mb="2"
											colorScheme="blue"
											isLoading={loadingCamdigikey}
											loadingText={t('Loading')}
											spinnerPlacement="start"
											onClick={() => loginWithCamDigiKey()}
											leftIcon={<Image src={CamdigiKeyLogo} w="20px" h="20px" />}
										>
											{t(`LOGIN WITH CAMDIGIKEY`)}
										</Button>
									</Flex>
								</Box>
							</Form>
						)}
					</Formik>
				</Box>
			</Box>
		</Box>
	);
}

export default Login;

const styles = {
	container: {
		minHeight: `calc(100vh - 234px)`,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#ecf0f1',
	},
};
