import { Text, Flex, Tooltip } from '@chakra-ui/react';
import { checkSelectedActiveRow } from '../../../utils/market-development';
import { useTranslation } from 'react-i18next';
import useStore from '../../../store';

export default function SpreadSheetProductList({ title = null, type = null, label = null, labelMarginTop = '0px', products = [], active = null }) {
	const { t } = useTranslation();
	const { currentLang: lang } = useStore((state) => state.currentLang);

	return (
		<>
			<div className="product-list">
				{title && (
					<>
						<Flex alignItems="center" mt={labelMarginTop} w="400px" h="50px" borderLeft="1px solid hsl(2deg, 0%, 91%)" pl={2} pr={2}>
							<Tooltip label={t(label)}>
								<Text color="orange.500" fontSize="2xl" fontWeight="bold">
									{t(title)}
								</Text>
							</Tooltip>
						</Flex>
					</>
				)}
				<>
					{label && (
						<>
							<Flex alignItems="center" mt={labelMarginTop} w="400px" h="30px" borderLeft="1px solid hsl(2deg, 0%, 91%)" pl={2} pr={2}>
								<Tooltip label={t(label)}>
									<Text color="orange.500" fontSize="md" fontWeight="semibold">
										{t(label)}
									</Text>
								</Tooltip>
							</Flex>
						</>
					)}
				</>
				{products?.map((item, index) => (
					<Flex
						key={`product-line-item-${index}`}
						className={checkSelectedActiveRow(active, index, type) ? 'active-row' : ''}
						alignItems="center"
						h="30px"
						border="1px solid hsl(2deg, 0%, 91%)"
						backgroundColor="#f7fafc"
						borderBottomWidth={index === products?.length - 1 ? 1 : 0}
						pl={2}
						pr={2}
					>
						<Tooltip label={lang === 'kh' ? item?.nameKh : item?.nameEn}>
							<Text ml="4px" color="#0173BB" fontWeight="bold" isTruncated>
								{lang === 'kh' ? item?.nameKh : item?.nameEn}
							</Text>
						</Tooltip>
					</Flex>
				))}
			</div>
		</>
	);
}
