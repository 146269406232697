import moment from 'moment';
import c from '../../../constant';

function titleRow(data, title) {
	const row = data.reduce(function (pre, cur) {
		Object.values(cur).forEach(function (e, j) {
			if (j === 0) {
				pre[0] = '';
			} else if (j === 1) {
				pre[1] = title;
			} else {
				pre[j] = '';
			}
		});
		return pre;
	}, {});
	return [row];
}

function totalRow(data, title) {
	const row = data.reduce(function (pre, cur) {
		Object.values(cur).forEach(function (e, j) {
			if (j === 0) {
				pre[0] = '';
			} else if (j === 1) {
				pre[1] = title;
			} else {
				pre[j] = (pre[j] || 0) + e;
			}
		});
		return pre;
	}, {});
	return [row];
}

function fileName(reportType = null, companyType = null, month = null, year = null) {
	moment.locale('en-gb');
	const m = moment(month, 'M').format('MMMM');
	const y = moment(year, 'Y').format('YYYY');
	const d = moment().format(c.fileNameDateFormat);
	return `Monthly ${reportType} Report - ${companyType} ${m} ${y} - Generated At ${d}`;
}

function excelSale({ headline = null, title = null, saleProducts = [], numColumns = 0, total = null, displayProductLine = true, lang = 'kh' }) {
	const rows = saleProducts?.reduce((pre, cur, i) => {
		let lPre = pre;
		let lCur = {};
		lCur[0] = i + 1;
		lCur[1] = displayProductLine
			? lang === 'kh'
				? cur?.productLine?.nameKh
				: cur?.productLine?.nameEn
			: lang === 'kh'
			? cur?.productType?.nameKh
			: cur?.productType?.nameEn;
		let c = 0;
		while (c < numColumns) {
			lCur[c + 2] = cur?.productValues[c]?.value;
			c = c + 1;
		}
		lPre.push(lCur);
		return lPre;
	}, []);

	let data = [...rows];

	if (title) {
		data = [...titleRow(rows, title), ...data];
	}

	if (headline) {
		data = [...titleRow(rows, headline), ...data];
	}

	if (total) {
		data = [...data, ...totalRow(rows, total)];
	}

	return data;
}

function excelClaim({ headline = null, title = null, claimProducts = [], displayProductLine = true, numColumns = 0, total = null, lang = 'kh' }) {
	const rows = claimProducts?.reduce((pre, cur, i) => {
		let lPre = pre;
		let lCur = {};
		lCur[0] = i + 1;
		lCur[1] = displayProductLine
			? lang === 'kh'
				? cur?.productLine?.nameKh
				: cur?.productLine?.nameEn
			: lang === 'kh'
			? cur?.productType?.nameKh
			: cur?.productType?.nameEn;
		let c = 0;
		while (c < numColumns) {
			lCur[c + 2] = cur?.productValues[c]?.value;
			c = c + 1;
		}
		lPre.push(lCur);
		return lPre;
	}, []);

	let data = [...rows];

	if (title) {
		data = [...titleRow(rows, title), ...data];
	}

	if (headline) {
		data = [...titleRow(rows, headline), ...data];
	}

	if (total) {
		data = [...data, ...totalRow(rows, total)];
	}

	return data;
}

function excelDistributionChannel({
	headline = null,
	title = null,
	displayProductLine = true,
	distributionProvinces = [],
	numColumns = 0,
	total = null,
	lang = 'kh',
}) {
	const rows = distributionProvinces?.reduce((pre, cur, i) => {
		let lPre = pre;
		let lCur = {};
		lCur[0] = i + 1;
		lCur[1] = distributionProvinces
			? lang === 'kh'
				? cur?.province?.name
				: cur?.province?.latinName
			: lang === 'kh'
			? cur?.productType?.name
			: cur?.productType?.latinName;
		let c = 0;
		while (c < numColumns) {
			lCur[c + 2] = cur?.provinceValues[c]?.value;
			c = c + 1;
		}
		lPre.push(lCur);
		return lPre;
	}, []);

	let data = [...rows];

	if (title) {
		data = [...titleRow(rows, title), ...data];
	}

	if (headline) {
		data = [...titleRow(rows, headline), ...data];
	}

	if (total) {
		data = [...data, ...totalRow(rows, total)];
	}

	return data;
}

export { titleRow, totalRow, fileName, excelSale, excelClaim, excelDistributionChannel };
