/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Badge,
	Box,
	Button,
	Center,
	Checkbox,
	Container,
	Flex,
	SimpleGrid,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';
import { faFileExcel, faFilePdf, faPencilRuler, faPlus, faPrint, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import LeaveRequestFilter from '../../components/Filter/LeaveRequestFilter';
import LeaveReportModal from '../../components/Modal/LeaveReportModal';
import PrintLayout from '../../components/Print/PrintLayout';
import LoadingResponsive from '../../components/Table/LoadingResponsive';
import datePickerFromToToDate from '../../utils/datePickerFromToToDate';
import useStore from '../../store';
import moment from 'moment';
import c from '../../constant';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './style.css';

const QUERY_DATA = gql`
	query get(
		$limit: Float
		$offset: Float
		$number: Float
		$name: String
		$fromDate: DateTime
		$toDate: DateTime
		$type: TypeOfLeave
		$read: Boolean
		$status: String
	) {
		getLeaves(
			pagerInput: { limit: $limit, offset: $offset }
			number: $number
			name: $name
			fromDate: $fromDate
			toDate: $toDate
			type: $type
			read: $read
			status: $status
		) {
			leaves {
				id
				no
				from
				to
				comeback
				purpose
				amount
				createdBy
				createdAt
				from
				to
				approveByOffice
				approveByOfficeBy
				approveByOfficeAt
				approveByDepartment
				approveByDepartmentBy
				approveByDepartmentAt
				approveByDirectorGeneral
				approveByDirectorGeneralBy
				approveByDirectorGeneralAt
				user {
					username
					fullName
					fullNameKhmer
					avatar
					department
				}
				userOffice {
					username
					fullName
					fullNameKhmer
				}
				userDepartment {
					username
					fullName
					fullNameKhmer
				}
				userDG {
					username
					fullName
					fullNameKhmer
				}
				notification {
					id
					read
				}
			}
			totalPages
			totalRows
		}
	}
`;

const QUERY_COUNT = gql`
	query get {
		countUnreadLeaveRequest
	}
`;

function Report() {
	const location = useLocation();
	const history = useHistory();
	const urlParams = new URLSearchParams(location.search);
	const currentPage = urlParams.get('page') ? urlParams.get('page') : 1;
	const number = urlParams.get('number') ? urlParams.get('number') : null;
	const name = urlParams.get('name') ? urlParams.get('name') : null;
	const type = urlParams.get('type') ? urlParams.get('type') : null;
	const fromDate = urlParams.get('fromDate') ? urlParams.get('fromDate') : null;
	const toDate = urlParams.get('toDate') ? urlParams.get('toDate') : null;
	const read = urlParams.get('read') ? urlParams.get('read') : null;
	const status = urlParams.get('status') ? urlParams.get('status') : null;
	const modalFilter = useDisclosure();
	const modalLeaveReport = useDisclosure();
	const { t } = useTranslation();
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const { currentUser } = useStore((state) => state.currentUser);
	const [rowLimit, setRowLimit] = useState(10);
	const [loadCount] = useLazyQuery(QUERY_COUNT);
	const [loadData, { loading, data }] = useLazyQuery(QUERY_DATA);

	moment.locale(lang === 'kh' ? 'km' : 'en-gb');

	useEffect(() => {
		window.scrollTo(0, 0);
		loadData({
			variables: {
				offset: currentPage ? (currentPage - 1) * rowLimit : 0,
				limit: rowLimit,
				number,
				name,
				fromDate: datePickerFromToToDate(fromDate),
				toDate: datePickerFromToToDate(toDate),
				type,
				read,
				status: status ? status : 'in progress',
			},
		});
		loadCount();
	}, []);

	useEffect(() => {
		if (currentPage) {
			window.scrollTo(0, 0);
			loadData({
				variables: {
					offset: currentPage ? (currentPage - 1) * rowLimit : 0,
					limit: rowLimit,
					number: parseInt(number),
					name,
					fromDate: datePickerFromToToDate(fromDate),
					toDate: datePickerFromToToDate(toDate),
					type,
					read: read === 'false' ? false : read === 'true' ? true : null,
					status: status ? status : 'in progress',
				},
			});
		}
	}, [rowLimit, currentPage, number, name, fromDate, toDate, type, read, status]);

	function handlePageClick(data) {
		let selected = data.selected;
		const page = selected + 1;

		let url = `?page=${page}`;

		if (status) {
			url += `&status=${status}`;
		}

		if (number) {
			url += `&number=${number}`;
		}

		if (name) {
			url += `&name=${name}`;
		}

		if (fromDate) {
			url += `&fromDate=${fromDate}`;
		}

		if (toDate) {
			url += `&toDate=${toDate}`;
		}

		if (type) {
			url += `&type=${type}`;
		}

		if (read !== null) {
			const unreadOnly = read ? read : '';
			if (unreadOnly) {
				url += `&read=false`;
			}
		}

		history.push(`/request-leave${url}`);
	}

	function handleStatusChange(statusValue) {
		const page = 1;

		let url = `?page=${page}`;

		if (statusValue) {
			url += `&status=${statusValue}`;
		}

		if (number) {
			url += `&number=${number}`;
		}

		if (name) {
			url += `&name=${name}`;
		}

		if (fromDate) {
			url += `&fromDate=${fromDate}`;
		}

		if (toDate) {
			url += `&toDate=${toDate}`;
		}

		if (type) {
			url += `&type=${type}`;
		}

		if (read !== null) {
			const unreadOnly = read ? read : '';
			if (unreadOnly) {
				url += `&read=false`;
			}
		}

		history.push(`/request-leave${url}`);
	}

	function panelTabs({ name, value }) {
		let active = status ? status : 'in progress';
		return (
			<Flex
				className="panel-tab"
				userSelect="none"
				minW="100px"
				onClick={() => handleStatusChange(value)}
				cursor="pointer"
				mb="-2px"
				_hover={{ boxShadow: `inset 0 -2px 0 0 ${value === active ? '#2980b9' : '#bdc3c7'}` }}
				boxShadow={value === active ? 'inset 0 -2px 0 0 #2980b9' : ''}
				pt="16px"
				pb="16px"
				pl="12px"
				pr="12px"
				alignItems="center"
				justifyContent="center"
			>
				<Text>{t(name)}</Text>
			</Flex>
		);
	}

	function getApproveStatus(approve, date, user, deps) {
		if (!deps?.length || !deps.includes(false)) {
			let res = null;
			switch (approve) {
				case null:
					res = <Badge colorScheme="yellow">{t('In progress')}</Badge>;
					break;
				case true:
					res = <Badge colorScheme="green">{t('Approved Leave')}</Badge>;
					break;
				default:
					res = <Badge colorScheme="red">{t('Rejected Leave')}</Badge>;
			}

			if (date) {
				res = <Tooltip label={`${moment(date).format('LLLL')} ${t('By')} ${getDisplayName(user)}`}>{res}</Tooltip>;
			}

			return res;
		}
		return <Badge>{t('N/A')}</Badge>;
	}

	function callbackFilter(e) {
		history.push(`/request-leave${e}`);
	}

	function getDisplayName(user) {
		return lang === 'kh' ? user?.fullNameKhmer || user?.fullName || user?.username : user?.fullName || user?.fullNameKhmer || user?.username;
	}

	return (
		<PrintLayout>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'Request Leave',
							path: '#',
						},
					]}
				/>
				<Center className="show-only-printing">
					<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
						របាយការណ៍សុំច្បាប់
					</Text>
				</Center>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex
							className="hidden-print responsive-header-for-table"
							bg="#FAFAFA"
							p="16px"
							pt="0"
							pb="0"
							mb="16px"
							alignItems="center"
							justifyContent="space-between"
							borderBottom="1px solid #dbdbdb"
						>
							<Flex className="panel-tab-container" minW="303px">
								{panelTabs({ name: 'In progress', value: 'in progress' })}
								{panelTabs({ name: 'Done', value: 'done' })}
								{panelTabs({ name: 'All', value: 'all' })}
								{currentUser?.canViewLeaveRequestReport && panelTabs({ name: 'IRC leave report', value: 'irc-leave-report' })}
							</Flex>
							<Box className="mobile-header-menu" mt="2px" w="100%" display="none">
								<Accordion w="100%" allowMultiple>
									<AccordionItem>
										<h2>
											<AccordionButton>
												<Box as="span" flex="1" textAlign="left" fontWeight="bold">
													{t('Menu')}
												</Box>
												<AccordionIcon />
											</AccordionButton>
										</h2>
										<AccordionPanel p={4}>
											<SimpleGrid columns={[2, null, 3]} spacingX={4} spacingY={4}>
												<Button
													width="100%"
													onClick={() => window?.print()}
													leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
													colorScheme="gray"
													variant="solid"
													size="sm"
													borderRadius={c.borderRadius}
													border="1px solid #bdc3c7"
												>
													{t('Print')}
												</Button>
												{(currentUser?.role === 'admin' || currentUser?.canViewLeaveRequestReport) && (
													<Button
														width="100%"
														onClick={() => history.push('/leave-adjustment?page=1')}
														leftIcon={<FontAwesomeIcon icon={faPencilRuler} style={{ fontSize: 16 }} />}
														colorScheme="gray"
														variant="solid"
														size="sm"
														borderRadius={c.borderRadius}
														border="1px solid #bdc3c7"
													>
														{t('Leave Adjustment')}
													</Button>
												)}
												{(currentUser?.role === 'admin' || currentUser?.canViewLeaveRequestReport) && (
													<Button
														width="100%"
														onClick={modalLeaveReport?.onOpen}
														leftIcon={<FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 16 }} />}
														colorScheme="gray"
														variant="solid"
														size="sm"
														borderRadius={c.borderRadius}
														border="1px solid #bdc3c7"
													>
														{t('Export')}
													</Button>
												)}
												{currentUser?.canViewLeaveRequestReport && (
													<Link to={`/request-leave/pdf-report?page=1&status=irc-leave-report`}>
														<Button
															width="100%"
															leftIcon={<FontAwesomeIcon icon={faFilePdf} style={{ fontSize: 16 }} />}
															colorScheme="gray"
															variant="solid"
															size="sm"
															borderRadius={c.borderRadius}
															border="1px solid #bdc3c7"
														>
															{t('Export')}
														</Button>
													</Link>
												)}
												{!currentUser?.canNotApplyForLeave && currentUser?.role !== 'admin' && (
													<Link to={`/request-leave/add`}>
														<Button
															width="100%"
															leftIcon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: 16 }} />}
															colorScheme="gray"
															variant="solid"
															size="sm"
															borderRadius={c.borderRadius}
															border="1px solid #bdc3c7"
														>
															{t('Create New Leave')}
														</Button>
													</Link>
												)}
												<Button
													width="100%"
													onClick={modalFilter?.onOpen}
													leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
													colorScheme={`${number || name || fromDate || toDate || read || type ? 'yellow' : 'gray'}`}
													variant="solid"
													size="sm"
													borderRadius={c.borderRadius}
													border="1px solid #bdc3c7}"
												>
													{t('Filter')}
												</Button>
											</SimpleGrid>
										</AccordionPanel>
									</AccordionItem>
								</Accordion>
							</Box>
							<Flex className="header-menu" alignItems="center" justifyContent="flex-end" minW="361px">
								<Button
									mr="2"
									onClick={() => window?.print()}
									leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
									colorScheme="gray"
									variant="solid"
									size="sm"
									borderRadius={c.borderRadius}
									border="1px solid #bdc3c7"
								>
									{t('Print')}
								</Button>
								{(currentUser?.role === 'admin' || currentUser?.canViewLeaveRequestReport) && (
									<Button
										mr="2"
										onClick={() => history.push('/leave-adjustment?page=1')}
										leftIcon={<FontAwesomeIcon icon={faPencilRuler} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t('Leave Adjustment')}
									</Button>
								)}
								{(currentUser?.role === 'admin' || currentUser?.canViewLeaveRequestReport) && (
									<Button
										mr="2"
										onClick={modalLeaveReport?.onOpen}
										leftIcon={<FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t('Export')}
									</Button>
								)}
								{currentUser?.canViewLeaveRequestReport && (
									<Link to={`/request-leave/pdf-report?page=1&status=irc-leave-report`}>
										<Button
											mr="2"
											leftIcon={<FontAwesomeIcon icon={faFilePdf} style={{ fontSize: 16 }} />}
											colorScheme="gray"
											variant="solid"
											size="sm"
											borderRadius={c.borderRadius}
											border="1px solid #bdc3c7"
										>
											{t('Export')}
										</Button>
									</Link>
								)}
								{!currentUser?.canNotApplyForLeave && currentUser?.role !== 'admin' && (
									<Link to={`/request-leave/add`}>
										<Button
											mr="2"
											leftIcon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: 16 }} />}
											colorScheme="gray"
											variant="solid"
											size="sm"
											borderRadius={c.borderRadius}
											border="1px solid #bdc3c7"
										>
											{t('Create New Leave')}
										</Button>
									</Link>
								)}
								<Button
									onClick={modalFilter?.onOpen}
									leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
									colorScheme={`${number || name || fromDate || toDate || read || type ? 'yellow' : 'gray'}`}
									variant="solid"
									size="sm"
									borderRadius={c.borderRadius}
									border="1px solid #bdc3c7}"
								>
									{t('Filter')}
								</Button>
							</Flex>
						</Flex>

						<Box p="16px">
							<Table variant="simple" className="table-company-proposal-list responsive-table-container">
								<Thead>
									<Tr className="td-as-th">
										<Th align="left">{t('Number')}</Th>
										<Th align="left">{t('Name')}</Th>
										<Th align="left">{t('Purpose')}</Th>
										<Th align="left">{t('Leave amount (Days)')}</Th>
										<Th align="left">{t('Leave from date')}</Th>
										<Th align="left">{t('Leave to date')}</Th>
										<Th align="right">{t('Approved By Office')}</Th>
										<Th align="right">{t('Approved Leave By Department')}</Th>
										<Th align="right">{t('Approved Leave By DG')}</Th>
									</Tr>
								</Thead>
								<Tbody>
									{loading && <LoadingResponsive column={9} />}
									{data?.getLeaves?.leaves.length <= 0 && (
										<Tr>
											<center>
												<Text>{t('No Data')}</Text>
											</center>
										</Tr>
									)}
									{data?.getLeaves?.leaves?.map((item, index) => {
										return (
											<Tr
												key={index}
												cursor="pointer"
												_hover={{ bg: 'gray.200' }}
												onClick={() => history.push(`/request-leave/detail?id=${item.id}`)}
												bg={item?.notification?.read === false ? 'gray.100' : 'white'}
											>
												<Td>{item?.no}</Td>
												<Td>{getDisplayName(item?.user)}</Td>
												<Td>
													<Tooltip label={item?.purpose}>
														<Text fontWeight="500" maxW="400px" noOfLines={[6, 6, 4, 4, 4]}>
															{item?.purpose}
														</Text>
													</Tooltip>
												</Td>
												<Td>{item.amount}</Td>
												<Td>{moment(item.from).format('LLLL')}</Td>
												<Td>{moment(item.to).format('LLLL')}</Td>
												<Td>
													<Flex justifyContent="flex-end" alignItems="center">
														{item?.approveByOffice === null && item?.approveByDepartment !== null
															? '-'
															: getApproveStatus(item?.approveByOffice, item?.approveByOfficeAt, item?.userOffice, [])}
													</Flex>
												</Td>
												<Td>
													<Flex justifyContent="flex-end" alignItems="center">
														{getApproveStatus(item?.approveByDepartment, item?.approveByDepartmentAt, item?.userDepartment, [
															item?.approveByOffice,
														])}
													</Flex>
												</Td>
												<Td>
													<Flex justifyContent="flex-end" alignItems="center">
														{getApproveStatus(item?.approveByDirectorGeneral, item?.approveByDirectorGeneralAt, item?.userDG, [
															item?.approveByOffice,
															item?.approveByDepartment,
														])}
													</Flex>
												</Td>
											</Tr>
										);
									})}
								</Tbody>
								<tfoot className="responsive-footer">
									<Tr className="td-as-th">
										<Th align="left">{t('Number')}</Th>
										<Th align="left">{t('Name')}</Th>
										<Th align="left">{t('Purpose')}</Th>
										<Th align="left">{t('Leave amount (Days)')}</Th>
										<Th align="left">{t('Leave from date')}</Th>
										<Th align="left">{t('Leave to date')}</Th>
										<Th align="right">{t('Approved By Office')}</Th>
										<Th align="right">{t('Approved Leave By Department')}</Th>
										<Th align="right">{t('Approved Leave By DG')}</Th>
									</Tr>
								</tfoot>
							</Table>
							<Flex justifyContent="flex-start" className="show-only-printing">
								{t('Total Rows')}: {data?.getLeaves?.totalRows}
							</Flex>
							<Flex justifyContent="flex-end" className="hidden-print">
								{t('Total Rows')}: {data?.getLeaves?.totalRows}
							</Flex>
							{data?.getLeaves?.totalPages > 1 && (
								<Flex justifyContent="flex-end" w="100%" mt="4">
									<ReactPaginate
										previousLabel={<ChevronLeftIcon fontSize="20px" />}
										nextLabel={<ChevronRightIcon fontSize="20px" />}
										breakLabel={'...'}
										breakClassName={'break-me'}
										pageCount={data?.getLeaves?.totalPages}
										marginPagesDisplayed={2}
										pageRangeDisplayed={3}
										onPageChange={handlePageClick}
										containerClassName={'pagination'}
										activeClassName={'active'}
										initialPage={(currentPage - 1) | 0}
									/>
								</Flex>
							)}
							<Flex className="hidden-print" justifyContent="flex-end" mt="6">
								<Text mr="2">{t('Show more rows')}</Text>
								<Checkbox
									isChecked={rowLimit > 10}
									onChange={() => {
										handlePageClick({ selected: 0 });
										if (rowLimit > 10) {
											setRowLimit(10);
										} else {
											setRowLimit(300);
										}
									}}
								/>
							</Flex>
						</Box>
					</Box>
				</Center>
			</Container>
			<LeaveRequestFilter
				callback={callbackFilter}
				isOpen={modalFilter?.isOpen}
				onClose={modalFilter?.onClose}
				number={number}
				name={name}
				type={type}
				fromDate={fromDate}
				toDate={toDate}
				read={read}
				status={status}
			/>
			{modalLeaveReport?.isOpen && <LeaveReportModal isOpen={true} onClose={modalLeaveReport?.onClose} />}
		</PrintLayout>
	);
}

export default Report;
