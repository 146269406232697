/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, Input, Button, useToast, InputRightElement, InputGroup, FormLabel } from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { gql, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import RadioFormField from '../../components/form/RadioFormField';
import TextFormField from '../../components/form/TextFormField';
import CheckListFormField from '../../components/form/CheckListFormFieldNested';
import SelectSearchFormField from '../../components/form/SelectSearchFormField';
import useStore from '../../store';
import c from '../../constant';
import * as Yup from 'yup';

const CONVERT_USER_TO_EMPLOYEE = gql`
	mutation convert($username: String!) {
		convertUserToEmployeeByUsername(username: $username)
	}
`;

const SIGNUP_MUTATION = gql`
	mutation signUp(
		$username: String!
		$password: String!
		$role: String
		$companyReportType: String
		$department: String
		$office: String
		$fullName: String
		$fullNameKhmer: String
		$canAssignUserToCompanyProposal: Boolean
		$canAssignUserToCompanyReport: Boolean
		$canUploadDoneFileToCompanyProposal: Boolean
		$canViewListReportToCompanyProposal: Boolean
		$canAutoreassignProposal: Boolean
		$financialReportTypes: String
		$canUpdateCompanyLicensing: Boolean
		$canUpdateCompanyGovernance: Boolean
		$canUpdateCompanyContact: Boolean
		$canApproveRejectLeaveRequest: Boolean
		$canViewLeaveRequestReport: Boolean
		$canNotApplyForLeave: Boolean
		$canReviewAgentRequest: Boolean
		$canApproveRejectDeactivateAgent: Boolean
		$canFinalApproveRejectAgent: Boolean
		$canUploadDoneFileToComplaint: Boolean
		$canAssignUserToComplaint: Boolean
		$position: String
		$paymentAgentRegistration: Boolean
		$paymentPublicService: Boolean
		$paymentLicense: Boolean
		$canUpdateLicense: Boolean
		$paymentFine: Boolean
		$canUpdateFine: Boolean
		$canCreateIssuedLetter: Boolean
		$canUpdateIssuedLetter: Boolean
		$canDeleteIssuedLetter: Boolean
		$mdd: JSON
		$ews: JSON
	) {
		signUp(
			userInput: { username: $username, password: $password }
			role: $role
			companyReportType: $companyReportType
			department: $department
			office: $office
			fullName: $fullName
			fullNameKhmer: $fullNameKhmer
			canAssignUserToCompanyProposal: $canAssignUserToCompanyProposal
			canUploadDoneFileToCompanyProposal: $canUploadDoneFileToCompanyProposal
			canViewListReportToCompanyProposal: $canViewListReportToCompanyProposal
			canAutoreassignProposal: $canAutoreassignProposal
			financialReportTypes: $financialReportTypes
			canUpdateCompanyLicensing: $canUpdateCompanyLicensing
			canUpdateCompanyGovernance: $canUpdateCompanyGovernance
			canUpdateCompanyContact: $canUpdateCompanyContact
			canAssignUserToCompanyReport: $canAssignUserToCompanyReport
			canApproveRejectLeaveRequest: $canApproveRejectLeaveRequest
			canViewLeaveRequestReport: $canViewLeaveRequestReport
			canNotApplyForLeave: $canNotApplyForLeave
			canReviewAgentRequest: $canReviewAgentRequest
			canApproveRejectDeactivateAgent: $canApproveRejectDeactivateAgent
			canFinalApproveRejectAgent: $canFinalApproveRejectAgent
			canUploadDoneFileToComplaint: $canUploadDoneFileToComplaint
			canAssignUserToComplaint: $canAssignUserToComplaint
			position: $position
			paymentAgentRegistration: $paymentAgentRegistration
			paymentPublicService: $paymentPublicService
			paymentLicense: $paymentLicense
			canUpdateLicense: $canUpdateLicense
			paymentFine: $paymentFine
			canUpdateFine: $canUpdateFine
			canCreateIssuedLetter: $canCreateIssuedLetter
			canUpdateIssuedLetter: $canUpdateIssuedLetter
			canDeleteIssuedLetter: $canDeleteIssuedLetter
			mdd: $mdd
			ews: $ews
		)
	}
`;

const signUpSchema = Yup.object().shape({
	fullName: Yup.string().required('Required'),
	fullNameKhmer: Yup.string().required('Required'),
	username: Yup.string().required('Required'),
	password: Yup.string().min(8).required('Required'),
	role: Yup.array().min(1, 'Required').required('Required'),
	companyReportType: Yup.array(),
	companyProposalPermission: Yup.array(),
	companyReportPermission: Yup.array(),
	complaintPermission: Yup.array(),
	department: Yup.array(),
	office: Yup.string(),
	financialReportTypes: Yup.array(),
	companyPermission: Yup.array(),
	requestLeavePermission: Yup.array(),
	agentPermission: Yup.array(),
	paymentPermission: Yup.array(),
	issuedLetter: Yup.array(),
	mdd: Yup.array(),
	ews: Yup.array(),
	position: Yup.object().nullable(),
});

function Create() {
	const history = useHistory();
	const toast = useToast();
	const { t } = useTranslation();
	const [tmpRole, setTmpRole] = useState([]);
	const [departmentCallback, setDepartmentCallback] = useState(null);
	const [tmpReportType, setTmpReportType] = useState([]);
	const [signUpUser, { loading, error, data }] = useMutation(SIGNUP_MUTATION);
	const [username, setUsername] = useState('');
	const [convertUserToEmployee] = useMutation(CONVERT_USER_TO_EMPLOYEE);
	const { currentLang } = useStore((state) => state.currentLang);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	function signUp(value) {
		setUsername(value.username);
		const variables = {
			fullName: value.fullName,
			fullNameKhmer: value.fullNameKhmer,
			username: value.username,
			password: value.password,
			role: arrayRoleToString(value.role),
			companyReportType: arrayRoleToString(value.companyReportType),
			department: arrayRoleToString(value.department),
			office: value.office,
			canAssignUserToCompanyProposal: value.companyProposalPermission?.includes('canAssignUser') ? true : false,
			canUploadDoneFileToCompanyProposal: value.companyProposalPermission?.includes('canUploadDoneFile') ? true : false,
			canViewListReportToCompanyProposal: value.companyProposalPermission?.includes('canViewListReport') ? true : false,
			canAutoreassignProposal: value.companyProposalPermission?.includes('canAutoreassignProposal') ? true : false,
			canAssignUserToCompanyReport: value.companyReportPermission?.includes('canAssignUser') ? true : false,
			canUploadDoneFileToComplaint: value.complaintPermission?.includes('canUploadDoneFile'),
			canAssignUserToComplaint: value.complaintPermission?.includes('canAssignComplaint'),
			financialReportTypes: arrayRoleToString(value.financialReportTypes),

			canUpdateCompanyLicensing: arrayRoleToString(value.companyPermission).includes('canUpdateLicensing') ? true : false,
			canUpdateCompanyGovernance: arrayRoleToString(value.companyPermission).includes('canUpdateGovernance') ? true : false,
			canUpdateCompanyContact: arrayRoleToString(value.companyPermission).includes('canUpdateContact') ? true : false,

			canApproveRejectLeaveRequest: value.requestLeavePermission?.includes('canApproveRejectLeaveRequest') ? true : false,
			canViewLeaveRequestReport: value.requestLeavePermission?.includes('canViewLeaveRequestReport') ? true : false,
			canNotApplyForLeave: value.requestLeavePermission?.includes('canNotApplyForLeave') ? true : false,

			canReviewAgentRequest: value.agentPermission?.includes('canReviewAgentRequest') ? true : false,
			canApproveRejectDeactivateAgent: value.agentPermission?.includes('canApproveRejectDeactivateAgent') ? true : false,
			canFinalApproveRejectAgent: value.agentPermission?.includes('canFinalApproveRejectAgent') ? true : false,

			paymentAgentRegistration: value.paymentPermission?.includes('paymentAgentRegistration') ? true : false,
			paymentPublicService: value.paymentPermission?.includes('paymentPublicService') ? true : false,

			paymentLicense: value.paymentPermission?.includes('paymentLicense') ? true : false,
			canUpdateLicense: value.paymentPermission?.includes('canUpdateLicense') ? true : false,
			paymentFine: value.paymentPermission?.includes('paymentFine') ? true : false,
			canUpdateFine: value.paymentPermission?.includes('canUpdateFine') ? true : false,

			canCreateIssuedLetter: value?.issuedLetter?.includes('canCreateIssuedLetter'),
			canUpdateIssuedLetter: value?.issuedLetter?.includes('canUpdateIssuedLetter'),
			canDeleteIssuedLetter: value?.issuedLetter?.includes('canDeleteIssuedLetter'),

			mdd: value?.mdd || [],

			ews: value?.ewsPermission || [],

			position: value?.position?.value ? value?.position?.value?.toString() : '',
		};

		signUpUser({ variables });
	}

	function arrayRoleToString(role) {
		let result = '';
		if (role) {
			role?.forEach((item, index) => {
				if (index === 0) {
					result = item;
				} else {
					result += `,${item}`;
				}
			});
		}
		return result;
	}

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	useEffect(() => {
		if (data) {
			convertUserToEmployee({
				variables: { username },
			});
			if (data?.signUp) {
				toast({
					title: t('User created successfully'),
					status: 'success',
					isClosable: true,
					position: 'top-right',
				});
				history.push('/users?page=1');
			}
		}
	}, [data]);

	function getRadioRoles() {
		const roles = [];
		c?.roles?.forEach((r) => {
			roles.push({ label: r.label, value: r.role });
		});
		return roles;
	}

	function checkForRole(role) {
		let res = false;
		tmpRole?.forEach((item) => {
			if (item === role) {
				res = true;
			}
		});

		return res;
	}

	function checkForReportType(type) {
		let res = false;
		tmpReportType.forEach((item) => {
			if (item === type) {
				res = true;
			}
		});
		return res;
	}

	function getOfficeDataFromDepartment(departmentData) {
		if (departmentData?.length === 1) {
			const dp = departmentData?.length > 0 ? departmentData[0] : null;
			let res = c.departments.filter((e) => e.value === dp);
			if (res?.length > 0) {
				return res[0]?.offices;
			} else {
				return [];
			}
		} else {
			return [];
		}
	}

	function getPositionOptions(data) {
		let res = [];
		data?.forEach((item) => {
			res.push({ value: item?.value, label: currentLang === 'en' ? item?.label : item?.labelKh });
		});
		return res;
	}

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: t('Users'),
							path: '/users?page=1',
						},
						{
							name: t('Create New User'),
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
							<Text fontSize="x-large" fontWeight="bold">
								{t('Create New User')}
							</Text>
						</Flex>

						<Box p="16px" maxW="480px">
							<Formik
								initialValues={{
									username: '',
									password: '',
									role: '',
									companyReportType: '',
									department: '',
									office: '',
									position: '',
								}}
								validationSchema={signUpSchema}
								onSubmit={(values) => {
									signUp(values);
								}}
							>
								{(props) => (
									<Form>
										<Box mt="3">
											<TextFormField {...props} label="Full Name In Latin" name="fullName" required />

											<TextFormField {...props} label="Full Name In Khmer" name="fullNameKhmer" required />

											<Field name="username">
												{({ field, meta }) => (
													<>
														<FormLabel>
															{t('Username')}
															<span style={{ color: 'red' }}>*</span>
														</FormLabel>
														<Input {...field} id="username" size="lg" fontWeight="500" autoComplete="off" />
														{meta.touched && meta.error && (
															<Text id="error-message-username" color="red.400" fontSize="12px">
																{t('Username')} {t('is')} {t(meta.error)}
															</Text>
														)}
													</>
												)}
											</Field>

											<Field name="password">
												{({ field, meta, form }) => (
													<>
														<FormLabel mt="4">
															{t('Password')}
															<span style={{ color: 'red' }}>*</span>
														</FormLabel>
														<InputGroup size="md">
															<Input {...field} id="password" size="lg" fontWeight="500" autoComplete="off" />
															<InputRightElement width="4.5rem">
																<Button
																	mt="8px"
																	mr="6px"
																	h="100%"
																	size="sm"
																	onClick={() => {
																		form.setFieldValue('password', generatePassword(), true);
																	}}
																>
																	{t('Generate')}
																</Button>
															</InputRightElement>
														</InputGroup>

														{meta.touched && meta.error && (
															<Text id="error-message-password" color="red.400" fontSize="12px">
																{t('Password')} {t('is')} {t(meta.error)}
															</Text>
														)}
													</>
												)}
											</Field>

											<CheckListFormField
												label="Department"
												name="department"
												list={c.departments}
												callBack={(e) => {
													setDepartmentCallback(e);
												}}
											/>

											{departmentCallback?.length === 1 && (
												<RadioFormField label="Office" name="office" radios={getOfficeDataFromDepartment(departmentCallback)} />
											)}

											<SelectSearchFormField
												{...props}
												name="position"
												label="Position"
												placeholder="Position"
												options={getPositionOptions(c?.position)}
												t={t}
											/>

											<CheckListFormField
												label="Role"
												name="role"
												list={getRadioRoles()}
												required
												callBack={(e) => setTmpRole(e)}
												nested={[
													{
														index: 3,
														child: (
															<>
																{checkForRole('complaint') && (
																	<CheckListFormField
																		label="Complaint Permission"
																		name="complaintPermission"
																		list={[
																			{
																				label: 'Can Assign',
																				value: 'canAssignComplaint',
																			},
																			{
																				label: 'Can upload done file',
																				value: 'canUploadDoneFile',
																			},
																		]}
																	/>
																)}
															</>
														),
													},
													{
														index: 4,
														child: (
															<>
																{checkForRole('companySubmissionReport') && (
																	<CheckListFormField
																		label="Company Report Types"
																		name="companyReportType"
																		list={c.companyReportTypes}
																		callBack={(e) => setTmpReportType(e)}
																		nested={[
																			{
																				index: 0,
																				child: (
																					<>
																						{checkForReportType('FINANCIAL_REPORT') && (
																							<CheckListFormField
																								label="Financial Report Types"
																								name="financialReportTypes"
																								list={c.financialReportTypes}
																							/>
																						)}
																					</>
																				),
																			},
																		]}
																	/>
																)}
															</>
														),
													},
													{
														index: 4,
														child: (
															<>
																{checkForRole('companySubmissionReport') && (
																	<CheckListFormField
																		label="Company Report Permission"
																		name="companyReportPermission"
																		list={[
																			{
																				label: 'Can Assign User',
																				value: 'canAssignUser',
																			},
																		]}
																	/>
																)}
															</>
														),
													},
													{
														index: 5,
														child: (
															<>
																{checkForRole('companySubmissionProposal') && (
																	<CheckListFormField
																		label="Proposal Permission"
																		name="companyProposalPermission"
																		list={[
																			{
																				label: 'Can Assign User',
																				value: 'canAssignUser',
																			},
																			{
																				label: 'Can upload done file',
																				value: 'canUploadDoneFile',
																			},
																			{
																				label: 'Can View List Report',
																				value: 'canViewListReport',
																			},
																			{
																				label: 'Can Auto-reassign Proposals',
																				value: 'canAutoreassignProposal',
																			},
																		]}
																	/>
																)}
															</>
														),
													},
													{
														index: 6,
														child: (
															<>
																{checkForRole('companyList') && (
																	<CheckListFormField
																		label="Company Permission"
																		name="companyPermission"
																		list={[
																			{
																				label: 'Can Update Company Licensing',
																				value: 'canUpdateLicensing',
																			},
																			{
																				label: 'Can Update Company Governance',
																				value: 'canUpdateGovernance',
																			},
																			{
																				label: 'Can Update Company Contact',
																				value: 'canUpdateContact',
																			},
																		]}
																	/>
																)}
															</>
														),
													},
													{
														index: 7,
														child: (
															<>
																{checkForRole('requestLeave') && (
																	<CheckListFormField
																		label="Request Leave Permission"
																		name="requestLeavePermission"
																		list={[
																			{
																				label: 'Can Approve/Reject',
																				value: 'canApproveRejectLeaveRequest',
																			},
																			{
																				label: 'Can View Report',
																				value: 'canViewLeaveRequestReport',
																			},
																			{
																				label: 'Can not apply for leave',
																				value: 'canNotApplyForLeave',
																			},
																		]}
																	/>
																)}
															</>
														),
													},
													{
														index: 8,
														child: (
															<>
																{checkForRole('agent') && (
																	<CheckListFormField
																		label="Agent Permission"
																		name="agentPermission"
																		list={[
																			{
																				label: 'Can Review Request',
																				value: 'canReviewAgentRequest',
																			},
																			{
																				label: 'Can Approve/Reject/Deactivate',
																				value: 'canApproveRejectDeactivateAgent',
																			},
																			{
																				label: 'Can Final Approve/Reject/Deactivate',
																				value: 'canFinalApproveRejectAgent',
																			},
																		]}
																	/>
																)}
															</>
														),
													},
													{
														index: 10,
														child: (
															<>
																{checkForRole('payment') && (
																	<CheckListFormField
																		label="Payment Permission"
																		name="paymentPermission"
																		list={[
																			{
																				label: 'Agent Registration',
																				value: 'paymentAgentRegistration',
																			},
																			{
																				label: 'Public Service',
																				value: 'paymentPublicService',
																			},
																			{
																				label: 'View License',
																				value: 'paymentLicense',
																			},
																			{
																				label: 'Can Update License',
																				value: 'canUpdateLicense',
																			},
																			{
																				label: 'View Fines',
																				value: 'paymentFine',
																			},
																			{
																				label: 'Can Update Fines',
																				value: 'canUpdateFine',
																			},
																		]}
																	/>
																)}
															</>
														),
													},
													{
														index: 11,
														child: (
															<>
																{checkForRole('issuedLetter') && (
																	<CheckListFormField
																		label="Permission"
																		name="issuedLetter"
																		list={[
																			{
																				label: 'Create',
																				value: 'canCreateIssuedLetter',
																			},
																			{
																				label: 'Edit',
																				value: 'canUpdateIssuedLetter',
																			},
																			{
																				label: 'Delete',
																				value: 'canDeleteIssuedLetter',
																			},
																		]}
																	/>
																)}
															</>
														),
													},
													{
														index: 14,
														child: (
															<>
																{checkForRole('mdd') && (
																	<CheckListFormField
																		label="Permission"
																		name="mdd"
																		list={[
																			{
																				label: 'General Monthly Report',
																				value: 'mddgmr',
																			},
																			{
																				label: 'Life Monthly Report',
																				value: 'mddlmr',
																			},
																			{
																				label: 'Micro Monthly Report',
																				value: 'mddmmr',
																			},
																		]}
																	/>
																)}
															</>
														),
													},
													{
														index: 16,
														child: (
															<>
																{checkForRole('ews') && (
																	<CheckListFormField
																		label="Permission"
																		name="ewsPermission"
																		list={[
																			{
																				label: 'Can view statement of general insurance',
																				value: 'ewsCanViewStatementGeneralInsurance',
																			},
																			{
																				label: 'Can view statement of life insurance',
																				value: 'ewsCanViewStatementLifeInsurance',
																			},
																			{
																				label: 'Can view statement of micro insurance',
																				value: 'ewsCanViewStatementMicroInsurance',
																			},
																			{
																				label: 'Can Assign User',
																				value: 'ewsCanAssignUser',
																			},
																		]}
																	/>
																)}
															</>
														),
													},
												]}
											/>
											<Flex textAlign="right" mt="6" justifyContent="space-between" alignItems="center">
												<Button
													_hover={{ bg: 'primary.dark' }}
													id="login-btn"
													variant="solid"
													size="md"
													h="42px"
													w="128px"
													bg="primary.dark"
													color="white"
													type="submit"
													isLoading={loading}
													loadingText={t('CREATE')}
													spinnerPlacement="start"
												>
													{t(`CREATE`)}
												</Button>
											</Flex>
										</Box>
									</Form>
								)}
							</Formik>
						</Box>
					</Box>
				</Center>
			</Container>
		</>
	);
}

export default Create;

const generatePassword = (length = 8, wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$') =>
	Array.from(crypto.getRandomValues(new Uint32Array(length)))
		.map((x) => wishlist[x % wishlist.length])
		.join('');
