/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { excelClaim, fileName } from '../../Miscellaneous/export';
import ReactExport from 'react-export-excel';
import c from '../../../../constant';
import useStore from '../../../../store';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ExportExcel({ month = null, year = null, numColumns = 0, claimItem = null, ...props }) {
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const { currentLang } = useStore((state) => state.currentLang);

	useEffect(() => {
		setData(excelData());
	}, [claimItem]);

	function excelData() {
		if (claimItem) {
			const claims = excelClaim({
				claimProducts: claimItem?.claimProducts?.filter((el) => el?.productLine?.type === 'Life'),
				numColumns,
				lang: currentLang,
			});
			return [{ name: 'Claim', data: [...claims] }];
		}
		return [];
	}

	return (
		<>
			<Box {...props}>
				<ExcelFile
					filename={fileName('Claim', 'Life', month, year)}
					element={
						<>
							<Button
								isLoading={false}
								isDisabled={false}
								onClick={() => null}
								leftIcon={<FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 16 }} />}
								colorScheme="gray"
								variant="solid"
								size="sm"
								borderRadius={c.borderRadius}
								border="1px solid #bdc3c7"
							>
								{t('Export')}
							</Button>
						</>
					}
				>
					{data?.map((el, i) => {
						return (
							<ExcelSheet key={i} data={el?.data} name={t(el?.name)}>
								<ExcelColumn label={t('Ordinal')} value="0" />
								<ExcelColumn label={t('Product Line')} value="1" />
								<ExcelColumn label={currentLang === 'kh' ? 'ចំនួន ((១)_សំណងដែលបានទូទាត់)' : 'Number	((1)_Claim Paid)'} value="2" />
								<ExcelColumn label={currentLang === 'kh' ? 'ទឹកប្រាក់ ((១)_សំណងដែលបានទូទាត់)' : 'Amount	((1)_Claim Paid)'} value="3" />
								<ExcelColumn label={currentLang === 'kh' ? 'ចំនួន ((២)_សំណងមិនសង)' : 'Number ((2)_Claim Denied)'} value="4" />
								<ExcelColumn label={currentLang === 'kh' ? 'ទឹកប្រាក់ ((២)_សំណងមិនសង)' : 'Amount ((2)_Claim Denied)'} value="5" />
								<ExcelColumn
									label={currentLang === 'kh' ? 'ចំនួន ((៣)_សំណងមិនទាន់ទូទាត់ចុងខែ)' : 'Number ((3)_Outstanding Claims)'}
									value="6"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? 'ទឹកប្រាក់ ((៣)_សំណងមិនទាន់ទូទាត់ចុងខែ)' : 'Amount ((3)_Outstanding Claims)'}
									value="7"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? 'ចំនួន ((៤)_បម្រែបម្រួលនៃសំណងមិនទាន់ទូទាត់)' : 'Number ((4)_Change in Outstanding Claims)'}
									value="8"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh' ? 'ទឹកប្រាក់ ((៤)_បម្រែបម្រួលនៃសំណងមិនទាន់ទូទាត់)' : 'Amount ((4)_Change in Outstanding Claims)'
									}
									value="9"
								/>
								<ExcelColumn label={currentLang === 'kh' ? 'ចំនួន ((១+២+៤)_សំណងកើតឡើង)' : 'Number ((1+2+4)_Claim Incurred)'} value="10" />
								<ExcelColumn label={currentLang === 'kh' ? 'ទឹកប្រាក់ ((១+២+៤)_សំណងកើតឡើង)' : 'Amount ((1+2+4)_Claim Incurred)'} value="11" />
							</ExcelSheet>
						);
					})}
				</ExcelFile>
			</Box>
		</>
	);
}
