import { Badge } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function ReportType({ type = null }) {
	const { t } = useTranslation();
	console.log(type);

	return (
		<>
			{type === 'MONTHLY' && (
				<>
					<Badge colorScheme="green">{t('MONTHLY')}</Badge>
				</>
			)}
			{type === 'QUARTERLY' && (
				<>
					<Badge colorScheme="yellow">{t('QUARTERLY')}</Badge>
				</>
			)}
			{type === 'SEMESTERLY' && (
				<>
					<Badge colorScheme="purple">{t('SEMESTERLY')}</Badge>
				</>
			)}
			{type === 'ANNUALLY' && (
				<>
					<Badge colorScheme="red">{t('ANNUALLY')}</Badge>
				</>
			)}
		</>
	);
}

export default ReportType;
