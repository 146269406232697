/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Button, Box, Flex, useToast } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import TextFormField from '../../form/TextFormField';
import c from '../../../constant';
import * as Yup from 'yup';

const Q_GET = gql`
	query get($id: String!) {
		getProductType(id: $id) {
			id
			productLine {
				id
				nameKh
				nameEn
				createdAt
				type
			}
			nameKh
			nameEn
			order
		}
	}
`;

const M_EDIT = gql`
	mutation edit($id: String!, $nameKh: String!, $nameEn: String!, $order: Float!) {
		editProductType(id: $id, nameKh: $nameKh, nameEn: $nameEn, order: $order)
	}
`;

const validationSchema = Yup.object().shape({
	nameKh: Yup.string().required('Required'),
	nameEn: Yup.string().required('Required'),
	order: Yup.number().required('Required'),
});

function EditProductLine({ id = null, isOpen = false, onClose = () => null, callback = () => null }) {
	const toast = useToast();
	const { t } = useTranslation();
	const [getProductType, { data: dataGet }] = useLazyQuery(Q_GET);
	const [editProductType, { loading, data: dataEdit, error }] = useMutation(M_EDIT);

	useEffect(() => {
		if (id) {
			const variables = { id };
			getProductType({ variables });
		}
	}, [id]);

	useEffect(() => {
		if (dataEdit) {
			toast({
				title: t('All tasks are completed'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			callback();
			onClose();
		}
	}, [dataEdit]);

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	const onSubmit = (values) => {
		const variables = { id, nameKh: values?.nameKh, nameEn: values?.nameEn, order: values?.order };
		editProductType({ variables });
	};

	return (
		<>
			<Modal
				closeOnEsc={loading ? false : true}
				closeOnOverlayClick={loading ? false : true}
				borderRadius={c.borderRadius}
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius}>
					<ModalHeader>{t('Edit Product Type')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{dataGet?.getProductType && (
							<>
								<Formik
									initialValues={{
										nameKh: dataGet?.getProductType?.nameKh,
										nameEn: dataGet?.getProductType?.nameEn,
										order: dataGet?.getProductType?.order,
									}}
									validationSchema={validationSchema}
									onSubmit={onSubmit}
								>
									{(props) => {
										return (
											<Box pb="4">
												<Form>
													<TextFormField {...props} label="Khmer Name" name="nameKh" required />
													<TextFormField {...props} label="English Name" name="nameEn" required />
													<TextFormField {...props} label="Order" name="order" type="number" required />
													<Flex mt="32px" justifyContent="flex-end">
														<Button
															id="login-btn"
															variant="solid"
															size="md"
															h="42px"
															w="128px"
															type="submit"
															borderRadius={c.borderRadius}
															isLoading={loading}
															isDisabled={loading}
															color="#3498db"
														>
															{t('Edit')}
														</Button>
													</Flex>
												</Form>
											</Box>
										);
									}}
								</Formik>
							</>
						)}
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default EditProductLine;
