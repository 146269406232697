/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { fileName, excelSale } from '../../Miscellaneous/export';
import ReactExport from 'react-export-excel';
import c from '../../../../constant';
import useStore from '../../../../store';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ExportExcel({ month = null, year = null, numColumns = 0, saleItems = [], ...props }) {
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const { currentLang } = useStore((state) => state.currentLang);

	useEffect(() => {
		setData(
			saleItems.reduce((pre, cur) => {
				let localPre = pre;
				let localCur = excelData(cur?.saleReportType, cur?.saleProducts);
				localPre.push(localCur);
				return localPre;
			}, [])
		);
	}, [saleItems]);

	function excelData(saleReportType = null, saleProducts = []) {
		const generalNewPolicies = excelSale({
			headline: t('New Policy'),
			title: t('General Micro Insurance Business'),
			saleProducts: saleProducts?.filter((el) => el?.productLine?.type === 'General' && el?.policyType === 'New') || [],
			numColumns,
			total: t('Total'),
			lang: currentLang,
		});
		const lifeNewPolicies = excelSale({
			title: t('Life Micro Insurance Business'),
			saleProducts: saleProducts?.filter((el) => el?.productLine?.type === 'Life' && el?.policyType === 'New') || [],
			numColumns,
			total: t('Total'),
			lang: currentLang,
		});
		const generalRenewalPolicies = excelSale({
			headline: t('Renewal Policy'),
			title: t('General Micro Insurance Business'),
			saleProducts: saleProducts?.filter((el) => el?.productLine?.type === 'General' && el?.policyType === 'Renewal') || [],
			numColumns,
			total: t('Total'),
			lang: currentLang,
		});
		const lifeRenewalPolicies = excelSale({
			title: t('Life Micro Insurance Business'),
			saleProducts: saleProducts?.filter((el) => el?.productLine?.type === 'Life' && el?.policyType === 'Renewal') || [],
			numColumns,
			total: t('Total'),
			lang: currentLang,
		});
		return { name: saleReportType, data: [...generalNewPolicies, ...lifeNewPolicies, ...generalRenewalPolicies, ...lifeRenewalPolicies] };
	}

	return (
		<>
			<Box {...props}>
				<ExcelFile
					filename={fileName('Sale', 'Micro', month, year)}
					element={
						<>
							<Button
								isLoading={false}
								isDisabled={false}
								onClick={() => null}
								leftIcon={<FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 16 }} />}
								colorScheme="gray"
								variant="solid"
								size="sm"
								borderRadius={c.borderRadius}
								border="1px solid #bdc3c7"
							>
								{t('Export')}
							</Button>
						</>
					}
				>
					{data?.map((el, i) => {
						return (
							<ExcelSheet key={i} data={el?.data} name={t(el?.name)}>
								<ExcelColumn label={t('Ordinal')} value="0" />
								<ExcelColumn label={t('Product Line')} value="1" />
								<ExcelColumn
									label={currentLang === 'kh' ? 'បុព្វលាភធានារ៉ាប់រងដុល (បណ្ណសន្យារ៉ាប់រង)' : 'Gross Premium (Policies)'}
									value="2"
								/>
								<ExcelColumn label={currentLang === 'kh' ? 'ទឹកប្រាក់ធានារ៉ាប់រង (បណ្ណសន្យារ៉ាប់រង)' : 'Sum Insured (Policies)'} value="3" />
								<ExcelColumn
									label={currentLang === 'kh' ? 'ចំនួនបណ្ណសន្យារ៉ាប់រង (បណ្ណសន្យារ៉ាប់រង)' : 'Number of Policies (Policies)'}
									value="4"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? 'ចំនួនដែលត្រូវបានធានា_ប្រុស (បណ្ណសន្យារ៉ាប់រង)' : 'Number of Insured_Male (Policies)'}
									value="5"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? 'ចំនួនដែលត្រូវបានធានា_ស្រី (បណ្ណសន្យារ៉ាប់រង)' : 'Number of Insured_Female (Policies)'}
									value="6"
								/>
								<ExcelColumn
									label={
										currentLang === 'kh' ? 'ចំនួនដែលត្រូវបានធានា_ឯកតាផ្សេង (បណ្ណសន្យារ៉ាប់រង)' : 'Number of Insured_Other Unit (Policies)'
									}
									value="7"
								/>
								<ExcelColumn label={currentLang === 'kh' ? 'ចំនួន (បណ្ណសន្យារ៉ាប់រងលុបចោល/បញ្ចប់)' : 'Number (Cancelled Policies)'} value="8" />
								<ExcelColumn
									label={
										currentLang === 'kh' ? 'បុព្វលាភធានារ៉ាប់រងដុល (បណ្ណសន្យារ៉ាប់រងលុបចោល/បញ្ចប់)' : 'Gross Premium (Cancelled Policies)'
									}
									value="9"
								/>
							</ExcelSheet>
						);
					})}
				</ExcelFile>
			</Box>
		</>
	);
}
