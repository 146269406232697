/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Avatar, Flex, List, ListItem, Text } from '@chakra-ui/react';
import { faCalendarMinus, faClipboardCheck, faComment, faFileAlt, faPlusSquare, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/km';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import IRCBotIcon from '../../assets/images/bot/bot1.jpeg';
import c from '../../constant';
import useStore from '../../store';
import './style.css';

const limit = 15;
const QUERY_NOTIFICATIONS = gql`
query getNotifications($offset:Float){
  getNotifications(pagerInput:{
    offset:$offset
    limit:${limit}
  }){
    totalPages
    notifications{
      id
      category
      openId
      openType
      locationId
      createdAt
      title
      description
      read
    viewType
        viewData
      createdBy{
        id
        username
        fullName
        fullNameKhmer
        avatar
      }
    }
  }
}
`;

const READ_NOTIFICATION = gql`
	mutation read($id: String!) {
		readNotificationById(id: $id)
	}
`;

function NotificationList({ isOpen = true, onClose = () => null }) {
	const history = useHistory();
	const { t } = useTranslation();
	const [currentPage, setCurrentPage] = useState(0);
	const [loadNotifications, { error, loading, data }] = useLazyQuery(QUERY_NOTIFICATIONS);
	const [readNotification] = useMutation(READ_NOTIFICATION);
	const { currentLang } = useStore((state) => state.currentLang);
	moment.locale(currentLang === 'kh' ? 'km' : 'en-gb');

	const { countUnreadNotifications } = useStore((state) => state.countUnreadNotifications);
	const setCountUnreadNotifications = useStore((state) => state.setCountUnreadNotifications);
	const [tabActive, setTabActive] = useState('all');

	useEffect(() => {
		if (isOpen) {
			loadNotifications();
		}
	}, [isOpen]);

	useEffect(() => {
		loadNotificationsWithVar();
	}, [currentPage]);

	function loadNotificationsWithVar() {
		loadNotifications({
			variables: {
				offset: currentPage ? (currentPage - 1) * limit : 0,
			},
		});
	}

	function handlePageClick(data) {
		let selected = data.selected < 0 ? 0 : data.selected;
		const page = selected + 1;
		setCurrentPage(page);
	}

	function getDisplayName(user) {
		if (user) {
			let displayName = user?.username;
			if (currentLang === 'kh') {
				displayName = user?.fullNameKhmer ? user?.fullNameKhmer : user?.fullName ? user?.fullName : user?.username;
			} else {
				displayName = user?.fullName ? user?.fullName : user?.fullNameKhmer ? user?.fullNameKhmer : user?.username;
			}
			return displayName;
		} else {
			return currentLang === 'kh' ? 'នធក' : 'IRC';
		}
	}

	function getCategoryLabel(category) {
		switch (category) {
			case 'COMMENT':
				return 'commented';
			case 'EDIT_COMMENT':
				return 'edited comment';
			case 'COMPLAINT':
				return 'settled';
			case 'COMPANY_PROPOSAL':
				return 'update status';
			default:
				break;
		}
	}

	function getOpenTypeLabel(openType) {
		switch (openType) {
			case 'COMPANY_SUBMISSION_REPORT':
				return 'company report';
			case 'COMPANY_SUBMISSION_PROPOSAL':
				return 'proposal';
			case 'COMPANY_AUDIT_PROPOSAL':
				return 'proposal';
			case 'COMPLAINT_ON_INSURANCE':
				return 'complaint on insurance';
			case 'COMPLAINT':
				return 'complaint';
			case 'LEAVE_REQUEST':
				return 'leave request';
			case 'LEAVE_ADJUSTMENT':
				return 'leave adjustment';
			case 'AGENT':
				return 'agent';
			default:
				break;
		}
	}

	function openItem(item) {
		switch (item?.openType) {
			case 'COMPANY_SUBMISSION_REPORT':
				history.push(`/company-submission-report/detail?id=${item?.openId}`);
				break;
			case 'COMPANY_SUBMISSION_EWS_REPORT':
				history.push('/early-warning-system/detail?id=' + item?.openId);
				break;
			case 'COMPANY_SUBMISSION_MDD_REPORT':
				if (item?.viewData?.id && item?.viewData?.reportType && item?.viewData?.period && item?.viewData?.reportId) {
					const path = {
						monthly_sale_report: 'monthly-sale-report',
						monthly_claim_report: 'monthly-claim-report',
						monthly_distribution_channel_report: 'monthly-distribution-channel-report',
					};
					const params =
						'?id=' +
						item?.viewData?.id +
						'&reportType=' +
						item?.viewData?.reportType +
						'&period=' +
						item?.viewData?.period +
						'&reportId=' +
						item?.viewData?.reportId;
					history.push('/market-development-data/' + path[item?.viewData?.reportId] + '/detail' + params);
				}
				break;
			case 'COMPANY_SUBMISSION_PROPOSAL':
				history.push(`/company-submission-proposal/detail?id=${item?.openId}`);
				break;
			case 'COMPANY_AUDIT_PROPOSAL':
				history.push(`/audit-company/detail?id=${item?.openId}`);
				break;
			case 'COMPLAINT_ON_INSURANCE':
				history.push(`/complaint-on-insurance/detail?id=${item?.openId}`);
				break;
			case 'COMPLAINT':
				history.push(`/complaint/detail?id=${item?.openId}`);
				break;
			case 'LEAVE_REQUEST':
				history.push(`/request-leave/detail?id=${item?.openId}`);
				break;
			case 'LEAVE_ADJUSTMENT':
				history.push(`/leave-adjustment/detail?id=${item?.openId}`);
				break;
			case 'NEW_AGENT_REGISTRATION':
				history.push(`/agent/detail?id=${item?.openId}`);
				break;
			case 'AGENT_RENEWAL':
				history.push(`/agent/detail?id=${item?.openId}`);
				break;
			case 'AGENT':
				history.push(`/agent/detail?id=${item?.openId}`);
				break;
			default:
				break;
		}
		readNotification({ variables: { id: item.id } });
		if (item?.read === false) {
			setCountUnreadNotifications(countUnreadNotifications - 1);
		}
		onClose();
	}

	function getIcon(category) {
		switch (category) {
			case 'COMMENT':
				return iconByNameAndBGColor(faComment, '#3498db');
			case 'EDIT_COMMENT':
				return iconByNameAndBGColor(faComment, '#e74c3c');
			case 'COMPLAINT':
				return iconByNameAndBGColor(faClipboardCheck, '#1abc9c');
			case 'COMPANY_PROPOSAL':
				return iconByNameAndBGColor(faFileAlt, '#e67e22');
			case 'NEW_COMPANY_REPORT':
				return iconByNameAndBGColor(faPlusSquare, '#bdc3c7');
			case 'NEW_COMPLAINT':
				return iconByNameAndBGColor(faPlusSquare, '#c0392b');
			case 'NEW_LEAVE_REQUEST':
				return iconByNameAndBGColor(faCalendarMinus, '#d35400');
			case 'NEW_AGENT_REGISTRATION':
				return iconByNameAndBGColor(faUserAlt, '#27ae60');
			case 'AGENT_RENEWAL':
				return iconByNameAndBGColor(faUserAlt, '#e74c3c');

			default:
				break;
		}
	}

	function iconByNameAndBGColor(icon, bg) {
		return (
			<Flex mt="30px" ml="-14px" w="24px" h="24px" justifyContent="center" alignItems="center" zIndex="20" bg={bg} p="3px" borderRadius="100%">
				<FontAwesomeIcon icon={icon} color="white" style={{ fontSize: 12 }} />
			</Flex>
		);
	}

	function checkReturnOnDesc(type) {
		let res = false;

		if (type === 'LEAVE_REQUEST') {
			res = true;
		}

		if (type === 'LEAVE_ADJUSTMENT') {
			res = true;
		}

		if (type === 'AGENT') {
			res = true;
		}

		return res;
	}

	function getDesctiptionInfo(description) {
		let tmpDesc = description.split(' #');
		return `${t(tmpDesc[0])} ${tmpDesc[1] ? '#' + tmpDesc[1] : ''}`;
	}

	return (
		<Flex direction="column" w="100%" minH={`calc(~"100vh - 62px")`} bg="rgba(236, 240, 241,.4)">
			{loading && <Text m="4">{t('Loading')}</Text>}
			{error && (
				<Text m="4" color="red">
					{t('Notification error')}
				</Text>
			)}

			{/* <Flex m="8px" alignItem="center">
                <TabsComponent 
                    menu={[
                        {label:"ALL", labelKH:"ទាំងអស់",value:"all", icon:<IoListOutline className="tabIcon"/>},
                        {label:"AGENT", labelKH:"ភ្នាក់ងារ", value:"agent", icon:<IoPersonOutline className="tabIcon"/>},
                        {label:"COMMENT", labelKH:"មតិយោបល់", value:"comment", icon:<IoChatboxOutline className="tabIcon"/>},
                    ]}
                    active={tabActive}
                    onTabChange={e=>setTabActive(e)}
                />
            </Flex> */}

			<List spacing={1} pb="32px">
				{data?.getNotifications?.notifications?.map((note, index) => {
					return (
						<ListItem
							onClick={() => openItem(note)}
							key={`n-${index}`}
							bg={note.read ? 'white' : 'rgba(189, 195, 199,.3)'}
							cursor="pointer"
							_hover={{ bg: 'rgba(236, 240, 241,1)' }}
						>
							<Flex pr="2">
								<Flex alignItems="center" p="2">
									<Avatar
										mb="2px"
										size="md"
										name={getDisplayName(note?.createdBy)}
										src={
											note?.createdBy
												? note?.createdBy?.avatar?.icon
													? c.baseFilePath + note?.createdBy?.avatar?.icon
													: null
												: IRCBotIcon
										}
									/>
									{getIcon(note?.category)}
								</Flex>
								<Flex w="100%" direction="column" pt="1" pb="1" pr="1">
									<Text noOfLines={3} ml="1">
										<span style={{ fontWeight: 'bold' }}>{getDisplayName(note?.createdBy)}</span>
										<span style={{ marginLeft: 8, marginRight: 8 }}>{t(getCategoryLabel(note.category))} :</span>"
										{getDesctiptionInfo(note.description)}"
										{checkReturnOnDesc(note.openType) ? ` ${t('on a')} ${t(getOpenTypeLabel(note.openType))}` : ''}.
									</Text>
									<Text ml="1" color={note?.read ? '' : '#2980b9'} fontSize={12} fontWeight={note?.read ? '500' : 'bold'}>
										{moment(note.createdAt).fromNow()}
									</Text>
								</Flex>
							</Flex>
						</ListItem>
					);
				})}

				<ListItem>
					<Flex justifyContent="flex-end" w="100%" alignItems="center" pr="10px" h="100px">
						{data?.getNotifications?.totalPages > 1 && (
							<ReactPaginate
								previousLabel={<ChevronLeftIcon fontSize="20px" />}
								nextLabel={<ChevronRightIcon fontSize="20px" />}
								breakLabel={'...'}
								breakClassName={'break-me'}
								pageCount={data?.getNotifications?.totalPages}
								marginPagesDisplayed={1}
								pageRangeDisplayed={1}
								onPageChange={handlePageClick}
								containerClassName={'pagination'}
								activeClassName={'active'}
								initialPage={(currentPage - 1) | 0}
							/>
						)}
					</Flex>
				</ListItem>
			</List>
		</Flex>
	);
}

export default NotificationList;
