import create from 'zustand';

const useStore = create((set) => ({
	branchValidityAlertCount: 0,
	currentUser: {},
	currentLang: 'en',
	countUnreadNotifications: 0,
	fileViewerLoadedPercentage: 0,
	unreadReportCount: {},

	setCurrentUser: (currentUser) => set((state) => ({ currentUser: { ...state.currentUser, currentUser } })),
	setCurrentLang: (currentLang) => set((state) => ({ currentLang: { ...state.currentLang, currentLang } })),
	setCountUnreadNotifications: (countUnreadNotifications) =>
		set((state) => ({ countUnreadNotifications: { ...state.countUnreadNotifications, countUnreadNotifications } })),
	setFileViewerLoadedPercentage: (fileViewerLoadedPercentage) =>
		set((state) => ({ fileViewerLoadedPercentage: { ...state.fileViewerLoadedPercentage, fileViewerLoadedPercentage } })),
	setBranchValidityAlertCount: (branchValidityAlertCount) => {
		set((state) => ({ branchValidityAlertCount: { ...state.branchValidityAlertCount, branchValidityAlertCount } }));
	},
	setUnreadReportCount: (unreadReportCount) => set((state) => ({ unreadReportCount: { ...state.unreadReportCount, unreadReportCount } })),
	
	readReport: (reportId) => set((state) => {
		let temp = {
			...state.unreadReportCount?.unreadReportCount
		}
		temp[reportId] = {
			...state.unreadReportCount?.unreadReportCount[reportId], 
			count:state.unreadReportCount?.unreadReportCount[reportId]?.count>0?
			state.unreadReportCount?.unreadReportCount[reportId]?.count-1:0
		}
		return({ 
			countUnreadNotifications: {countUnreadNotifications:state?.countUnreadNotifications?.countUnreadNotifications>0?state?.countUnreadNotifications?.countUnreadNotifications-1:0},
			unreadReportCount: {unreadReportCount:temp}
		})
	}),


	testVal : 99,
	testVal1 : 999,
	decrese: () => set((state) => {
		return({ 
			testVal: state.testVal - 1,
			testVal1: state.testVal1 - 1
		})
	}),
}));

export default useStore;
