/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
	Badge,
	Box,
	Button,
	Center,
	Container,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	Text,
	Textarea,
	Tooltip,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { faCheck, faPrint, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Loading from '../../components/Loading';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import ViewComment from '../../components/Comment/ViewComment';
import DownloadAndViewButton from '../../components/Files/DownloadAndViewButton';
import PrintLayout from '../../components/Print/PrintLayout';
import c from '../../constant';
import useStore from '../../store';
import './style.css';

const leftLabelWidth = '175px';
const marginTopRow = 1;
const paddingTopRow = 1;
const paddingBottomRow = 1;
const QUERY_DETAIL = gql`
	query get($id: String!) {
		getLeaveById(id: $id) {
			id
			purpose
			no
			amount
			createdAt
			type
			from
			to
			comeback
			approveByOffice
			approveByOfficeBy
			approveByOfficeAt
			approveByDepartment
			approveByDepartmentBy
			approveByDepartmentAt
			approveByDirectorGeneral
			approveByDirectorGeneralBy
			approveByDirectorGeneralAt
			attachmentFile
			attachmentFileSize
			rejectReason
			user {
				username
				fullName
				fullNameKhmer
				department
			}
			userOffice {
				username
				fullName
				fullNameKhmer
			}
			userDepartment {
				username
				fullName
				fullNameKhmer
			}
			userDG {
				username
				fullName
				fullNameKhmer
			}
			notification {
				id
				read
			}
			reviewingBy
		}
	}
`;

const APPROVE_MUTATION = gql`
	mutation approve($id: String!) {
		approveLeaveRequest(id: $id)
	}
`;

const REJECT_MUTATION = gql`
	mutation reject($id: String!, $reason: String!) {
		rejectLeaveRequest(id: $id, reason: $reason)
	}
`;

const MUTATION_READ_NOTIFICATION = gql`
	mutation read($id: String!) {
		readNotificationById(id: $id)
	}
`;

function ReportDetail() {
	const { t } = useTranslation();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const { currentLang } = useStore((state) => state.currentLang);
	moment.locale(currentLang === 'kh' ? 'km' : 'en-gb');
	const [loadDetail, { error, loading, data }] = useLazyQuery(QUERY_DETAIL);
	const { currentUser } = useStore((state) => state.currentUser);
	const toast = useToast();
	const { countUnreadNotifications } = useStore((state) => state.countUnreadNotifications);
	const setCountUnreadNotifications = useStore((state) => state.setCountUnreadNotifications);
	const [approveLeave, { error: errorApproveLeave, loading: loadingApproveLeave, data: dataApproveLeave }] = useMutation(APPROVE_MUTATION);
	const [rejectLeave, { error: errorRejectLeave, loading: loadingRejectLeave, data: dataRejectLeave }] = useMutation(REJECT_MUTATION);
	const [readNotification, {}] = useMutation(MUTATION_READ_NOTIFICATION);

	const [rejectReason, setRejectReason] = useState('');
	const { isOpen, onOpen, onClose } = useDisclosure();

	useEffect(() => {
		window.scrollTo(0, 0);
		loadDetail({
			variables: {
				id,
			},
		});
	}, []);

	useEffect(() => {
		if (id) {
			loadDetail({
				variables: {
					id,
				},
			});
		}
	}, [id]);

	useEffect(() => {
		if (data?.getLeaveById?.notification?.read === false) {
			setCountUnreadNotifications(countUnreadNotifications - 1);
			readNotification({ variables: { id: data?.getLeaveById?.notification?.id } });
		}
	}, [data]);

	useEffect(() => {
		if (dataRejectLeave) {
			onClose();
		}
	}, [dataRejectLeave]);

	function getDisplayName(user) {
		let result = user?.username;
		if (currentLang === 'kh') {
			if ((result = user?.fullNameKhmer)) {
				result = user?.fullNameKhmer;
			} else {
				if (user?.fullName) {
					result = user?.fullName;
				} else {
					result = user?.username;
				}
			}
		} else {
			if ((result = user?.fullName)) {
				result = user?.fullName;
			} else {
				if (user?.fullNameKhmer) {
					result = user?.fullNameKhmer;
				} else {
					result = user?.username;
				}
			}
		}

		return result;
	}

	function reportType(type) {
		return type === 'MONTHLY' ? (
			<Badge colorScheme="green">{t('MONTHLY')}</Badge>
		) : type === 'QUARTERLY' ? (
			<Badge colorScheme="yellow">{t('QUARTERLY')}</Badge>
		) : (
			<Badge colorScheme="red">{t('ANNUALLY')}</Badge>
		);
	}

	function getLeaveType(type) {
		let res = '';
		leaveType.map((item) => {
			if (item.value === type) {
				res = item.label;
			}
		});
		return res;
	}

	function getApproveStatus(approve, date, user, deps) {
		if (!deps?.length || !deps.includes(false)) {
			let res;
			switch (approve) {
				case null:
					res = <Badge colorScheme="yellow">{t('In progress')}</Badge>;
					break;
				case true:
					res = <Badge colorScheme="green">{t('Approved Leave')}</Badge>;
					break;
				default:
					res = <Badge colorScheme="red">{t('Rejected Leave')}</Badge>;
			}

			if (date) {
				res = <Tooltip label={`${moment(date).format('LLLL')} ${t('By')} ${getDisplayName(user)}`}>{res}</Tooltip>;
			}
			return res;
		}
		return <Badge>{t('N/A')}</Badge>;
	}

	useEffect(() => {
		if (errorApproveLeave) {
			if (errorApproveLeave?.graphQLErrors?.length > 0) {
				errorApproveLeave?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'top-right',
					})
				);
			} else {
				toast({
					title: t(errorApproveLeave?.message ? errorApproveLeave?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				});
			}
		}
	}, [errorApproveLeave]);

	useEffect(() => {
		if (dataApproveLeave?.approveLeaveRequest) {
			toast({
				title: t('Leave request approved successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadDetail({
				variables: {
					id,
				},
			});
		}
	}, [dataApproveLeave]);

	useEffect(() => {
		if (errorRejectLeave) {
			if (errorRejectLeave?.graphQLErrors?.length > 0) {
				errorRejectLeave?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'top-right',
					})
				);
			} else {
				toast({
					title: t(errorRejectLeave?.message ? errorRejectLeave?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				});
			}
		}
	}, [errorRejectLeave]);

	useEffect(() => {
		if (dataRejectLeave?.rejectLeaveRequest) {
			toast({
				title: t('Leave request rejected successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadDetail({
				variables: {
					id,
				},
			});
		}
	}, [dataRejectLeave]);

	function getDepartmentName(dp) {
		let label = 'N/A';
		c.departments.map((item) => {
			if (item.value === dp) {
				label = item.label;
			}
		});
		return label;
	}

	function checkIfDeputyDirector(department) {
		const dp = department ? department?.split(',') : null;
		if (dp?.length > 1) {
			return true;
		} else {
			return false;
		}
	}

	return (
		<>
			<PrintLayout>
				<>
					<Container maxW="container.xl" mt="16px" mb="16px">
						<BreadcrumbComponent
							list={[
								{
									name: 'Request Leave',
									path: '/request-leave?page=1',
								},
								{
									name: 'DETAIL',
									path: '#',
								},
							]}
						/>
						<Center mt="5">
							<Box
								w="100%"
								bg="white"
								borderRadius={c.borderRadius}
								border="1px solid #dbdbdb"
								boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
								pb="32px"
							>
								<Flex
									alignItems="center"
									justifyContent="space-between"
									bg="#FAFAFA"
									p="16px"
									pt="8px"
									pb="8px"
									mb="0px"
									borderBottom="1px solid #dbdbdb"
								>
									<Flex justifyContent="center" alignItems="center">
										<Text fontSize="x-large" fontWeight="bold">
											{t('Request Leave')}
										</Text>
									</Flex>
									<Flex className="hidden-print">
										{data?.getLeaveById?.reviewingBy && (
											<>
												{((currentUser?.role === 'admin' && data?.getLeaveById?.approveByDirectorGeneral === null) ||
													(currentUser?.canApproveRejectLeaveRequest &&
														((data?.getLeaveById?.approveByDepartment === null &&
															(currentUser?.position === '3' || currentUser?.position === '4')) ||
															(data?.getLeaveById?.approveByOffice === null &&
																(currentUser?.position === '5' || currentUser?.position === '6'))) &&
														!checkIfDeputyDirector(data?.getLeaveById?.user?.department))) && (
													<>
														<Button
															mr={2}
															onClick={() => approveLeave({ variables: { id } })}
															leftIcon={<FontAwesomeIcon icon={faCheck} style={{ fontSize: 16 }} />}
															colorScheme="green"
															variant="solid"
															size="sm"
															borderRadius={c.borderRadius}
															border="1px solid #bdc3c7"
															display={['none', 'inherit', 'inherit']}
														>
															{t('Approve Leave')}
														</Button>
														<Button
															mr={2}
															onClick={onOpen}
															leftIcon={<FontAwesomeIcon icon={faTimes} style={{ fontSize: 16 }} />}
															colorScheme="red"
															variant="solid"
															size="sm"
															borderRadius={c.borderRadius}
															border="1px solid #bdc3c7"
															display={['none', 'inherit', 'inherit']}
														>
															{t('Reject Leave')}
														</Button>
													</>
												)}
											</>
										)}
										<Button
											onClick={() => window?.print()}
											leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
											colorScheme="gray"
											variant="solid"
											size="sm"
											borderRadius={c.borderRadius}
											border="1px solid #bdc3c7"
										>
											{t('Print')}
										</Button>
									</Flex>
								</Flex>
								{data?.getLeaveById?.reviewingBy && (
									<>
										{((currentUser?.role === 'admin' && data?.getLeaveById?.approveByDirectorGeneral === null) ||
											(currentUser?.canApproveRejectLeaveRequest &&
												((data?.getLeaveById?.approveByDepartment === null &&
													(currentUser?.position === '3' || currentUser?.position === '4')) ||
													(data?.getLeaveById?.approveByOffice === null &&
														(currentUser?.position === '5' || currentUser?.position === '6'))) &&
												!checkIfDeputyDirector(data?.getLeaveById?.user?.department))) && (
											<Box
												width="100%"
												className="hidden-print"
												display={['inherit', 'none', 'none']}
												alignItems="center"
												justifyContent="space-between"
												bg="#FAFAFA"
												p="16px"
												pt="16px"
												pb="16px"
												mb="0px"
												borderBottom="1px solid #dbdbdb"
											>
												<SimpleGrid columns={2} spacingX={4}>
													<Button
														width="100%"
														onClick={() => approveLeave({ variables: { id } })}
														leftIcon={<FontAwesomeIcon icon={faCheck} style={{ fontSize: 16 }} />}
														colorScheme="green"
														variant="solid"
														size="sm"
														borderRadius={c.borderRadius}
														border="1px solid #bdc3c7"
													>
														{t('Approve Leave')}
													</Button>
													<Button
														width="100%"
														onClick={onOpen}
														leftIcon={<FontAwesomeIcon icon={faTimes} style={{ fontSize: 16 }} />}
														colorScheme="red"
														variant="solid"
														size="sm"
														borderRadius={c.borderRadius}
														border="1px solid #bdc3c7"
													>
														{t('Reject Leave')}
													</Button>
												</SimpleGrid>
											</Box>
										)}
									</>
								)}

								{loading && <Loading />}

								{!error && data?.getLeaveById?.id && (
									<Box p="16px" className="leave-detail-container">
										<Flex pt={paddingTopRow} pb={paddingBottomRow}>
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Number')}
											</Text>
											<Text ml="4">{data?.getLeaveById?.no}</Text>
										</Flex>
										<Flex pt={paddingTopRow} pb={paddingBottomRow}>
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Department')}
											</Text>
											<Text ml="4">{t(getDepartmentName(data?.getLeaveById?.user?.department))}</Text>
										</Flex>

										<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Name')}
											</Text>
											<Text ml="4">{getDisplayName(data?.getLeaveById?.user)}</Text>
										</Flex>
										<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Type')}
											</Text>
											<Text ml="4">{t(getLeaveType(data?.getLeaveById?.type))}</Text>
										</Flex>
										<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Created At')}
											</Text>
											<Text ml="4">{moment(data?.getLeaveById?.createdAt).format('LLLL')}</Text>
										</Flex>
										<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Leave amount (Days)')}
											</Text>
											<Text ml="4">{data?.getLeaveById?.amount}</Text>
										</Flex>
										<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Leave from date')}
											</Text>
											<Text ml="4">{moment(data?.getLeaveById?.from).format('LLLL')}</Text>
										</Flex>
										<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Leave to date')}
											</Text>
											<Text ml="4">{moment(data?.getLeaveById?.to).format('LLLL')}</Text>
										</Flex>
										<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Leave comeback date')}
											</Text>
											<Text ml="4">{moment(data?.getLeaveById?.comeback).format('LLLL')}</Text>
										</Flex>

										<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Purpose')}
											</Text>
											<Text ml="4">
												<pre
													style={{
														whiteSpace: 'pre-wrap',
														paddingBottom: 16,
														fontSize: 14,
														fontFamily: 'Rajdhani, Hanuman, sans-serif, Moul !important',
													}}
												>
													{data?.getLeaveById?.purpose}
												</pre>
											</Text>
										</Flex>

										{data?.getLeaveById?.attachmentFile && (
											<Flex alignItems="center" mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
												<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
													{t('Attachment File')}
												</Text>
												<DownloadAndViewButton url={data?.getLeaveById?.attachmentFile} size={data?.getLeaveById?.attachmentFileSize} />
											</Flex>
										)}

										<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Approved By Office')}
											</Text>
											<Box ml="4">
												{data?.getLeaveById?.approveByOffice === null && data?.getLeaveById?.approveByDepartment !== null
													? '-'
													: getApproveStatus(
															data?.getLeaveById?.approveByOffice,
															data?.getLeaveById?.approveByOfficeAt,
															data?.getLeaveById?.userOffice,
															[]
													  )}
											</Box>
										</Flex>

										<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Approved By Department')}
											</Text>
											<Box ml="4">
												{getApproveStatus(
													data?.getLeaveById?.approveByDepartment,
													data?.getLeaveById?.approveByDepartmentAt,
													data?.getLeaveById?.userDepartment,
													[data?.getLeaveById?.approveByOffice]
												)}
											</Box>
										</Flex>

										<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Approved By DG')}
											</Text>
											<Box ml="4">
												{getApproveStatus(
													data?.getLeaveById?.approveByDirectorGeneral,
													data?.getLeaveById?.approveByDirectorGeneralAt,
													data?.getLeaveById?.userDG,
													[data?.getLeaveById?.approveByOffice, data?.getLeaveById?.approveByDepartment]
												)}
											</Box>
										</Flex>

										{data?.getLeaveById?.rejectReason && (
											<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
												<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
													{t('Reject Reason')}
												</Text>
												<Text ml="4">
													<pre
														style={{
															whiteSpace: 'pre-wrap',
															paddingBottom: 16,
															fontSize: 14,
															fontFamily: 'Rajdhani, Hanuman, sans-serif, Moul !important',
														}}
													>
														{data?.getLeaveById?.rejectReason}
													</pre>
												</Text>
											</Flex>
										)}
									</Box>
								)}
							</Box>
						</Center>
						{!error && data?.getLeaveById?.id && <ViewComment id={id} type="LEAVE_REQUEST" />}
					</Container>
				</>
			</PrintLayout>

			<>
				<Modal isOpen={isOpen} onClose={onClose}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>{t('Reason')}</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<Textarea onChange={(e) => setRejectReason(e.currentTarget.value)} />
						</ModalBody>

						<ModalFooter>
							<Button
								colorScheme="red"
								onClick={() => {
									if (!rejectReason) {
										alert('please input reject reason');
									} else {
										rejectLeave({ variables: { id, reason: rejectReason } });
									}
								}}
								isLoading={loadingRejectLeave}
							>
								{t('Reject Leave')}
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			</>
		</>
	);
}

export default ReportDetail;

const leaveType = [
	{ label: 'Annual leave', value: 'ANNUAL_LEAVE' },
	{ label: 'Short leave', value: 'SHORT_LEAVE' },
	{ label: 'Maternity leave', value: 'MATERNITY_LEAVE' },
	{ label: 'Sick leave', value: 'SICK_LEAVE' },
	{ label: 'Personal leave', value: 'PERSONAL_LEAVE' },
	{ label: 'Work from home', value: 'WORK_FROM_HOME' },
];
