/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Button, Box, Flex, useToast } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import TextFormField from '../../form/TextFormField';
import SelectValueFormField from '../../form/SelectValueFormField';
import useStore from '../../../store';
import c from '../../../constant';
import * as Yup from 'yup';

const Q_PRODUCT_LINES = gql`
	query get($pagerInput: PagerInput!) {
		getProductLines(pagerInput: $pagerInput) {
			list {
				id
				nameKh
				nameEn
				createdAt
				type
			}
			totalPages
			totalRows
		}
	}
`;

const M_CREATE = gql`
	mutation create($productLineId: String!, $nameKh: String!, $nameEn: String!, $order: Float!) {
		createProductType(productLineId: $productLineId, nameKh: $nameKh, nameEn: $nameEn, order: $order)
	}
`;

const validationSchema = Yup.object().shape({
	productLineId: Yup.string().required('Required'),
	nameKh: Yup.string().required('Required'),
	nameEn: Yup.string().required('Required'),
	order: Yup.number().required('Required'),
});

function NewProductType({ isOpen = false, onClose = () => null, callback = () => null }) {
	const toast = useToast();
	const { t } = useTranslation();
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const [getProductLines, { data: dataGet }] = useLazyQuery(Q_PRODUCT_LINES);
	const [createProductType, { loading, data, error }] = useMutation(M_CREATE);

	useEffect(() => {
		getProductLines({ variables: { pagerInput: { offset: 0, limit: 300 } } });
	}, []);

	useEffect(() => {
		if (data) {
			toast({
				title: t('All tasks are completed'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			callback();
			onClose();
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	const onSubmit = (values) => {
		const variables = {
			productLineId: values?.productLineId,
			nameKh: values?.nameKh,
			nameEn: values?.nameEn,
			order: values?.order,
		};
		createProductType({ variables });
	};

	return (
		<>
			<Modal
				closeOnEsc={loading ? false : true}
				closeOnOverlayClick={loading ? false : true}
				borderRadius={c.borderRadius}
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius}>
					<ModalHeader>{t('New Product Type')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Formik
							initialValues={{
								productLineId: '',
								nameKh: '',
								nameEn: '',
								order: '',
							}}
							validationSchema={validationSchema}
							onSubmit={onSubmit}
						>
							{(props) => {
								return (
									<Box pb="4">
										<Form>
											<SelectValueFormField
												{...props}
												label="Product Line"
												name="productLineId"
												placeholder={t('Product Line')}
												options={dataGet?.getProductLines?.list?.reduce((p, c) => {
													let options = p;
													options.push({ label: lang === 'kh' ? c?.nameKh : c?.nameEn, value: c?.id });
													return options;
												}, [])}
												required
											/>
											<TextFormField {...props} label="Khmer Name" name="nameKh" required />
											<TextFormField {...props} label="English Name" name="nameEn" required />
											<TextFormField {...props} label="Order" name="order" type="number" required />
											<Flex mt="32px" justifyContent="flex-end">
												<Button
													id="login-btn"
													variant="solid"
													size="md"
													h="42px"
													w="128px"
													type="submit"
													borderRadius={c.borderRadius}
													isLoading={loading}
													isDisabled={loading}
													color="#3498db"
												>
													{t('Create')}
												</Button>
											</Flex>
										</Form>
									</Box>
								);
							}}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default NewProductType;
