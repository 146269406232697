/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@chakra-ui/react';
import { checkSelectedActiveColumn } from '../../../../utils/market-development';
import Spreadsheet from 'react-spreadsheet';
import SpreadSheetProductList from '../../Miscellaneous/spreadsheet-product-list';
import SpreadSheetTotal from '../../Miscellaneous/spreadsheet-total';

const Table = ({ saleProducts = null, numColumn = 24 }) => {
	const { t } = useTranslation();
	const [inforceData, setInforceData] = useState(
		saleProducts?.filter((el) => el?.businessType === 'Inforce')?.map((el) => el?.productValues?.map((el) => ({ value: el?.value, readOnly: true })))
	);
	const [newData, setNewData] = useState(
		saleProducts?.filter((el) => el?.businessType === 'New')?.map((el) => el?.productValues?.map((el) => ({ value: el?.value, readOnly: true })))
	);
	const [active, setActive] = useState(null);

	return (
		<Box className="spreadsheet_container">
			<div className="product-title-cell">{t('Product Line')}</div>
			<table className="spreadsheet_custom_header">
				<thead>
					<tr style={{ height: '65px' }}>
						<td colSpan="4" className="background-light-silver">
							{t('Number of Policies')}
						</td>
						<td colSpan="4">{t('Number of Insured')}</td>
						<td colSpan="4" className="background-light-silver">
							{t('Sum Insured')}
						</td>
						<td colSpan="4">{t('Gross Premium')}</td>
						<td colSpan="4" className="background-light-silver">
							{t('Flat Cancellation')}
						</td>
						<td colSpan="4">{t('Surrender Policies')}</td>
					</tr>
					<tr style={{ height: '32px' }}>
						<td colSpan="2" className="background-light-silver">
							&gt; 5,000USD
						</td>
						<td colSpan="2" className="background-light-silver">
							≤ 5,000USD
						</td>
						<td colSpan="2">&gt; 5,000USD</td>
						<td colSpan="2">≤ 5,000USD</td>
						<td colSpan="2" className="background-light-silver">
							&gt; 5,000USD
						</td>
						<td colSpan="2" className="background-light-silver">
							≤ 5,000USD
						</td>
						<td colSpan="2">&gt; 5,000USD</td>
						<td colSpan="2">≤ 5,000USD</td>
						<td colSpan="2" className="background-light-silver">
							{t('Number of Policies')}
						</td>
						<td colSpan="2" className="background-light-silver">
							{t('Refunded Gross Premium')}
						</td>
						<td colSpan="2">{t('Number of Policies')}</td>
						<td colSpan="2">{t('Refunded Gross Premium')}</td>
					</tr>
					<tr style={{ height: '53px', maxHeight: '53px' }}>
						<td className={checkSelectedActiveColumn(active, 0) ? 'active-column' : 'background-light-silver'}>{t('Single Premium')}</td>
						<td className={checkSelectedActiveColumn(active, 1) ? 'active-column' : 'background-light-silver'}>{t('Regular Premium')}</td>
						<td className={checkSelectedActiveColumn(active, 2) ? 'active-column' : 'background-light-silver'}>{t('Single Premium')}</td>
						<td className={checkSelectedActiveColumn(active, 3) ? 'active-column' : 'background-light-silver'}>{t('Regular Premium')}</td>
						<td className={checkSelectedActiveColumn(active, 4) ? 'active-column' : ''}>{t('Female')}</td>
						<td className={checkSelectedActiveColumn(active, 5) ? 'active-column' : ''}>{t('Male')}</td>
						<td className={checkSelectedActiveColumn(active, 6) ? 'active-column' : ''}>{t('Female')}</td>
						<td className={checkSelectedActiveColumn(active, 7) ? 'active-column' : ''}>{t('Male')}</td>
						<td className={checkSelectedActiveColumn(active, 8) ? 'active-column' : 'background-light-silver'}>{t('Single Premium')}</td>
						<td className={checkSelectedActiveColumn(active, 9) ? 'active-column' : 'background-light-silver'}>{t('Regular Premium')}</td>
						<td className={checkSelectedActiveColumn(active, 10) ? 'active-column' : 'background-light-silver'}>{t('Single Premium')}</td>
						<td className={checkSelectedActiveColumn(active, 11) ? 'active-column' : 'background-light-silver'}>{t('Regular Premium')}</td>
						<td className={checkSelectedActiveColumn(active, 12) ? 'active-column' : ''}>{t('Single Premium')}</td>
						<td className={checkSelectedActiveColumn(active, 13) ? 'active-column' : ''}>{t('Regular Premium')}</td>
						<td className={checkSelectedActiveColumn(active, 14) ? 'active-column' : ''}>{t('Single Premium')}</td>
						<td className={checkSelectedActiveColumn(active, 15) ? 'active-column' : ''}>{t('Regular Premium')}</td>
						<td className={checkSelectedActiveColumn(active, 16) ? 'active-column' : 'background-light-silver'}>&gt; 5,000USD</td>
						<td className={checkSelectedActiveColumn(active, 17) ? 'active-column' : 'background-light-silver'}>≤ 5,000USD</td>
						<td className={checkSelectedActiveColumn(active, 18) ? 'active-column' : 'background-light-silver'}>&gt; 5,000USD</td>
						<td className={checkSelectedActiveColumn(active, 19) ? 'active-column' : 'background-light-silver'}>≤ 5,000USD</td>
						<td className={checkSelectedActiveColumn(active, 20) ? 'active-column' : ''}>&gt; 5,000USD</td>
						<td className={checkSelectedActiveColumn(active, 21) ? 'active-column' : ''}>≤ 5,000USD</td>
						<td className={checkSelectedActiveColumn(active, 22) ? 'active-column' : ''}>&gt; 5,000USD</td>
						<td className={checkSelectedActiveColumn(active, 23) ? 'active-column' : ''}>≤ 5,000USD</td>
					</tr>
				</thead>
			</table>
			<div>
				<Flex>
					<SpreadSheetProductList
						type="Inforce"
						label="Renewal Policy"
						labelMarginTop="0px"
						products={saleProducts?.filter((el) => el?.businessType === 'Inforce')?.map((e) => e?.productLine)}
						active={active}
					/>
					<Box>
						<Flex alignItems="center" mt="0px" h="30px" borderRight="1px solid hsl(2deg, 0%, 91%)" pl={2} pr={2} />
						<Spreadsheet
							data={inforceData}
							onChange={(e) => {
								let localData = e?.reduce((p, c) => {
									let lp = p;
									let lc = c?.slice(0, numColumn);
									lp.push(lc);
									return lp;
								}, []);
								setInforceData(
									localData?.slice(0, saleProducts?.filter((el) => el?.businessType === 'Inforce')?.map((e) => e?.productLine)?.length)
								);
							}}
							onActivate={(e) => setActive({ ...e, t: 'Inforce' })}
							hideRowIndicators
							hideColumnIndicators
						/>
					</Box>
				</Flex>
				<SpreadSheetTotal data={inforceData} numColumn={numColumn} />
			</div>
			<div>
				<Flex>
					<SpreadSheetProductList
						type="New"
						label="New Policy"
						labelMarginTop="0px"
						products={saleProducts?.filter((el) => el?.businessType === 'New')?.map((e) => e?.productLine)}
						active={active}
					/>
					<Box>
						<Flex alignItems="center" mt="0px" h="30px" borderRight="1px solid hsl(2deg, 0%, 91%)" pl={2} pr={2} />
						<Spreadsheet
							data={newData}
							onChange={(e) => {
								let localData = e?.reduce((p, c) => {
									let lp = p;
									let lc = c?.slice(0, numColumn);
									lp.push(lc);
									return lp;
								}, []);
								setNewData(localData?.slice(0, saleProducts?.filter((el) => el?.businessType === 'New')?.map((e) => e?.productLine)?.length));
							}}
							onActivate={(e) => setActive({ ...e, t: 'New' })}
							hideRowIndicators
							hideColumnIndicators
						/>
					</Box>
				</Flex>
				<SpreadSheetTotal data={newData} numColumn={numColumn} />
			</div>
		</Box>
	);
};

export default Table;
