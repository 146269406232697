import { Box, Text, Flex, Tooltip } from '@chakra-ui/react';
import { checkSelectedActiveRow } from '../../../utils/market-development';
import useStore from '../../../store';

export default function EWSLabels({ type = null, labels = [], active = null }) {
	const { currentLang: locale } = useStore((state) => state?.currentLang);

	function getFontWeight(level) {
		if (level === 0) {
			return 'bold';
		} else if (level === 1) {
			return 'bold';
		} else if (level === 2) {
			return 'bold';
		} else {
			return 'bold';
		}
	}

	return (
		<div className="ews-template-label">
			<Box w="400px" position="absolute" zIndex={100}>
				{labels?.map((label, i) => {
					return (
						<Flex
							key={`label-item-${i}`}
							className={checkSelectedActiveRow(active, i, type) ? 'active-row' : ''}
							alignItems="center"
							h="30px"
							border="1px solid hsl(2deg, 0%, 91%)"
							backgroundColor={label?.readOnly ? '#f7fafc' : '#FFFFFF'}
							borderBottomWidth={i === labels?.length - 1 ? 1 : 0}
							pl={label?.level * 2}
							pr={2}
						>
							<Tooltip label={locale === 'kh' ? label?.titleKhmer : label?.titleEnglish}>
								<Text ml={4} fontSize="sm" fontWeight={label?.readOnly ? getFontWeight(label?.level) : 'normal'} color="#2c3e50" noOfLines={1}>
									{locale === 'kh' ? label?.titleKhmer : label?.titleEnglish}
								</Text>
							</Tooltip>
						</Flex>
					);
				})}
			</Box>
		</div>
	);
}
