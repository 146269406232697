/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, forwardRef } from 'react';
import { Box, Flex, useToast, Button, useDisclosure, Divider, Badge, SimpleGrid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { CompanyBadge } from '../../../Other';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import ExcelLife from '../Excel/ExcelLife';
import SpreadSheetTable from './table';
import SpreadSheetKeyValuePair from '../../Miscellaneous/spreadsheet-key-value-pair';
import UserDislayNameById from '../../../../components/UserDislayNameById';
import EditMonthlyReportModal from '../../../Modal/EditMonthlyReportModal';
import Loading from '../../../Loading';
import CompanyName from '../../../CompanyName';
import LabelDate from '../../../LabelDate';
import c from '../../../../constant';

const Q_DETAIL = gql`
	query get($id: String!) {
		getMonthlyClaimReportById(id: $id) {
			id
			reportType
			month
			year
			createdAt
			updatedAt
			company {
				id
				name
				nameKhmer
				logo
				type
			}
			isEditing
			editingUpdatedAt
			editingUpdatedBy
			editingDueDate
			editingDescription
			penalties {
				id
				active
			}
			claimItems {
				claimProducts {
					productLine {
						id
						nameKh
						nameEn
						type
					}
					productValues {
						id
						claimType
						claimValueType
						value
					}
				}
			}
		}
	}
`;

const Page = forwardRef(({ id = null }, ref) => {
	const toast = useToast();
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [month, setMonth] = useState(null);
	const [year, setYear] = useState(null);
	const [createdAt, setCreatedAt] = useState(null);
	const [updatedAt, setUpdatedAt] = useState(null);
	const [isPenalized, setPenalized] = useState(false);
	const [company, setCompany] = useState(null);
	const [claimItem, setClaimItem] = useState(null);
	const [getDetail, { data: dataGet, loading: loadingGet, error: errorGet }] = useLazyQuery(Q_DETAIL, { fetchPolicy: 'no-cache' });

	useEffect(() => {
		loadDetail();
	}, [id]);

	useEffect(() => {
		if (dataGet?.getMonthlyClaimReportById) {
			setMonth(parseInt(dataGet?.getMonthlyClaimReportById?.month) + 1);
			setYear(parseInt(dataGet?.getMonthlyClaimReportById?.year));
			setCreatedAt(dataGet?.getMonthlyClaimReportById?.createdAt);
			setUpdatedAt(dataGet?.getMonthlyClaimReportById?.updatedAt);
			setPenalized(dataGet?.getMonthlyClaimReportById?.penalties?.length > 0);
			setCompany(dataGet?.getMonthlyClaimReportById?.company);
			setClaimItem(dataGet?.getMonthlyClaimReportById?.claimItems[0]);
		}
	}, [dataGet]);

	useEffect(() => {
		if (errorGet) {
			if (errorGet?.graphQLErrors?.length > 0) {
				errorGet?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorGet?.message ? errorGet?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorGet]);

	function loadDetail() {
		id && getDetail({ variables: { id } });
	}

	return (
		<>
			{loadingGet ? (
				<>
					<Loading />
				</>
			) : (
				<>
					{month && year && createdAt && company && claimItem && (
						<>
							<Flex pl={4} pr={4}>
								<SimpleGrid columns={[1, dataGet?.getMonthlyClaimReportById?.isEditing ? 2 : 1]} w="100%" spacingX={4} spacingY={4}>
									<Flex w="100%" direction="column" border="1px dotted #dbdbdb" borderRadius={c.borderRadius} pl={4} pr={4} pt={4} pb={4}>
										<>
											{isPenalized && (
												<Box>
													<Badge colorScheme="red" variant="solid">
														{t('Penalized')}
													</Badge>
												</Box>
											)}
										</>
										<SpreadSheetKeyValuePair label="Company" value={<CompanyName company={company} />} />
										<SpreadSheetKeyValuePair label="Type" value={<CompanyBadge type={company?.type} />} />
										<SpreadSheetKeyValuePair label="Monthly" value={<LabelDate input={month} inputFormat="M" outputFormat="MMMM" />} />
										<SpreadSheetKeyValuePair label="Year" value={<LabelDate input={year} inputFormat="Y" outputFormat="YYYY" />} />
										<SpreadSheetKeyValuePair label="Submitted At" value={<LabelDate input={createdAt} outputFormat={c.dateTimeFormat} />} />
										<>
											{updatedAt && (
												<>
													<SpreadSheetKeyValuePair
														label="Updated at"
														value={<LabelDate input={updatedAt} outputFormat={c.dateTimeFormat} />}
													/>
												</>
											)}
										</>
										<Flex mt={2}>
											<>
												{!dataGet?.getMonthlyClaimReportById?.isEditing && (
													<Button
														onMouseDown={onOpen}
														isLoading={false}
														isDisabled={false}
														onClick={() => null}
														leftIcon={<FontAwesomeIcon icon={faUserEdit} style={{ fontSize: 16 }} />}
														colorScheme="gray"
														variant="solid"
														size="sm"
														borderRadius={c.borderRadius}
														border="1px solid #bdc3c7"
														mr={2}
													>
														{t('Allow Editing')}
													</Button>
												)}
											</>
											<Flex>
												<ExcelLife month={month} year={year} numColumns={10} claimItem={claimItem} />
											</Flex>
										</Flex>
									</Flex>
									{dataGet?.getMonthlyClaimReportById?.isEditing && (
										<Flex direction="column" border="1px dotted #dbdbdb" borderRadius={c.borderRadius} pl={4} pr={4} pt={4} pb={4}>
											<SpreadSheetKeyValuePair
												label="Allow Editing"
												value={
													<LabelDate input={dataGet?.getMonthlyClaimReportById?.editingUpdatedAt} outputFormat={c.dateTimeFormat} />
												}
											/>
											<SpreadSheetKeyValuePair
												label="By"
												value={<UserDislayNameById id={dataGet?.getMonthlyClaimReportById?.editingUpdatedBy} />}
											/>
											<SpreadSheetKeyValuePair
												label="Due Date"
												value={<LabelDate input={dataGet?.getMonthlyClaimReportById?.editingDueDate} outputFormat={c.dateTimeFormat} />}
											/>
											<SpreadSheetKeyValuePair label="Description" value={dataGet?.getMonthlyClaimReportById?.editingDescription} />
											<Box mt={2}>
												<Button
													onMouseDown={onOpen}
													isLoading={false}
													isDisabled={false}
													onClick={() => null}
													leftIcon={<FontAwesomeIcon icon={faUserEdit} style={{ fontSize: 16 }} />}
													colorScheme="red"
													variant="solid"
													size="sm"
													borderRadius={c.borderRadius}
													border="1px solid #bdc3c7"
													mr={2}
												>
													{t('Stop Allowing Editing')}
												</Button>
											</Box>
										</Flex>
									)}
								</SimpleGrid>
							</Flex>
							<Box pl={4} pr={4} pb={4} pt={4}>
								<SpreadSheetTable claimProducts={claimItem?.claimProducts} />
							</Box>
						</>
					)}
				</>
			)}
			{isOpen && (
				<EditMonthlyReportModal
					id={id}
					type="Claim"
					isEditable={dataGet?.getMonthlyClaimReportById?.isEditing}
					editableDueDate={dataGet?.getMonthlyClaimReportById?.editingDueDate}
					editableDescription={dataGet?.getMonthlyClaimReportById?.editingDescription}
					onClose={onClose}
					onCallback={loadDetail}
				/>
			)}
		</>
	);
});

export default Page;
