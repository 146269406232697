import { EWSTypeCurrency, sumOrError } from './ews';
import { isNumeric } from './market-development';

const splociNumColumn = 2;
const readyOnlys = [2, 8, 10, 11, 18, 19, 25, 26, 32, 33, 37, 38, 43, 44, 45, 47, 48, 49, 54, 55, 56];
const sumOnlys = [2, 8, 10, 18, 25, 32, 37, 43, 45, 47, 49, 54, 56];

function cleanSPLOCICells(labels) {
	const cells = labels?.map((label, i) => {
		if (readyOnlys?.includes(i)) {
			if (sumOnlys?.includes(i)) {
				return [
					{ value: '0', readOnly: true },
					{ value: '0', readOnly: true },
				];
			} else {
				return [
					{ value: '', readOnly: true },
					{ value: '', readOnly: true },
				];
			}
		} else {
			return [
				{ value: '', readOnly: false },
				{ value: '', readOnly: false },
			];
		}
	});
	return cells;
}

function detailSPLOCICells({ code = null, labels = [], detailLabels = [], readOnly = false }) {
	const localCells = labels?.map((label, i) => {
		if (readyOnlys?.includes(i)) {
			if (sumOnlys?.includes(i)) {
				return [
					{ value: '0', readOnly: true },
					{ value: '0', readOnly: true },
				];
			} else {
				return [
					{ value: '', readOnly: true },
					{ value: '', readOnly: true },
				];
			}
		} else {
			const detailLabel = detailLabels?.find((el) => el?.templateLabel?.code === label?.code);
			if (detailLabel) {
				return [
					{ value: detailLabel?.values?.find((value) => value?.currency === EWSTypeCurrency.USD)?.value || 0, readOnly },
					{ value: detailLabel?.values?.find((value) => value?.currency === EWSTypeCurrency.KHR)?.value || 0, readOnly },
				];
			} else {
				return [
					{ value: '', readOnly },
					{ value: '', readOnly },
				];
			}
		}
	});

	const { cells } = calculateSPLOCICells({ code, cells: localCells, numLabel: labels?.length, readOnly });

	return cells;
}

function calculateSPLOCICells({ code, cells, numLabel, readOnly = false }) {
	// Gross premium income
	let gpiSums = Array.from({ length: 2 }, (item, i) => i + 0);
	let gpiUSD = 0;
	let gpiKHR = 0;

	// Net premium earned
	let npeSums = Array.from({ length: 5 }, (item, i) => i + 3);
	let npeUSD = 0;
	let npeKHR = 0;

	// Total operating income
	let toiSums = Array.from({ length: 1 }, (item, i) => i + 9);
	let toiUSD = 0;
	let toiKHR = 0;

	// Net claims and benefits expense
	let ncbeSums = Array.from({ length: 6 }, (item, i) => i + 12);
	let ncbeUSD = 0;
	let ncbeKHR = 0;

	// Total operating expenses
	let toeSums = Array.from({ length: 5 }, (item, i) => i + 20);
	let toeUSD = 0;
	let toeKHR = 0;

	// Total financial income
	let tfiSums = Array.from({ length: 5 }, (item, i) => i + 27);
	let tfiUSD = 0;
	let tfiKHR = 0;

	// Total finance costs
	let tfcSums = Array.from({ length: 3 }, (item, i) => i + 34);
	let tfcUSD = 0;
	let tfcKHR = 0;

	// Total other gain loss
	let toglSums = Array.from({ length: 4 }, (item, i) => i + 39);
	let toglUSD = 0;
	let toglKHR = 0;

	// Profit after tax
	let patSums = Array.from({ length: 1 }, (item, i) => i + 46);
	let patUSD = 0;
	let patKHR = 0;

	// Total other comprehensive income
	let tociSums = Array.from({ length: 4 }, (item, i) => i + 50);
	let tociUSD = 0;
	let tociKHR = 0;

	let localCells = cells?.map((cols, i) => {
		if (readyOnlys?.includes(i)) {
			if (i === 2) {
				return [
					{ value: sumOrError([gpiUSD]), readOnly: true },
					{ value: sumOrError([gpiKHR]), readOnly: true },
				];
			} else if (i === 8) {
				return [
					{
						value: sumOrError([npeUSD, gpiUSD]),
						readOnly: true,
					},
					{
						value: sumOrError([npeKHR, gpiKHR]),
						readOnly: true,
					},
				];
			} else if (i === 10) {
				return [
					{
						value: sumOrError([toiUSD, npeUSD, gpiUSD]),
						readOnly: true,
					},
					{
						value: sumOrError([toiKHR, npeKHR, gpiKHR]),
						readOnly: true,
					},
				];
			} else if (i === 18) {
				return [
					{
						value: sumOrError([ncbeUSD]),
						readOnly: true,
					},
					{
						value: sumOrError([ncbeKHR]),
						readOnly: true,
					},
				];
			} else if (i === 25) {
				return [
					{
						value: sumOrError([toeUSD]),
						readOnly: true,
					},
					{
						value: sumOrError([toeKHR]),
						readOnly: true,
					},
				];
			} else if (i === 32) {
				return [
					{
						value: sumOrError([tfiUSD]),
						readOnly: true,
					},
					{
						value: sumOrError([tfiKHR]),
						readOnly: true,
					},
				];
			} else if (i === 37) {
				return [
					{
						value: sumOrError([tfcUSD]),
						readOnly: true,
					},
					{
						value: sumOrError([tfcKHR]),
						readOnly: true,
					},
				];
			} else if (i === 43) {
				return [
					{
						value: sumOrError([toglUSD]),
						readOnly: true,
					},
					{
						value: sumOrError([toglKHR]),
						readOnly: true,
					},
				];
			} else if (i === 45) {
				return [
					{
						value: sumOrError([toiUSD, npeUSD, gpiUSD, ncbeUSD, toeUSD, tfiUSD, tfcUSD, toglUSD]),
						readOnly: true,
					},
					{
						value: sumOrError([toiKHR, npeKHR, gpiKHR, ncbeKHR, toeKHR, tfiKHR, tfcKHR, toglKHR]),
						readOnly: true,
					},
				];
			} else if (i === 47) {
				return [
					{
						value: sumOrError([toiUSD, npeUSD, gpiUSD, ncbeUSD, toeUSD, tfiUSD, tfcUSD, toglUSD, patUSD]),
						readOnly: true,
					},
					{
						value: sumOrError([toiKHR, npeKHR, gpiKHR, ncbeKHR, toeKHR, tfiKHR, tfcKHR, toglKHR, patKHR]),
						readOnly: true,
					},
				];
			} else if (i === 54) {
				return [
					{
						value: sumOrError([tociUSD]),
						readOnly: true,
					},
					{
						value: sumOrError([tociKHR]),
						readOnly: true,
					},
				];
			} else if (i === 56) {
				return [
					{
						value: sumOrError([toiUSD, npeUSD, gpiUSD, ncbeUSD, toeUSD, tfiUSD, tfcUSD, toglUSD, patUSD, tociUSD]),
						readOnly: true,
					},
					{
						value: sumOrError([toiKHR, npeKHR, gpiKHR, ncbeKHR, toeKHR, tfiKHR, tfcKHR, toglKHR, patKHR, tociKHR]),
						readOnly: true,
					},
				];
			} else {
				return [
					{ value: '', readOnly: true },
					{ value: '', readOnly: true },
				];
			}
		} else {
			if (gpiSums?.includes(i)) {
				gpiUSD = gpiUSD + parseFloat(cols[0]?.value || 0);
				gpiKHR = gpiKHR + parseFloat(cols[1]?.value || 0);
			} else if (npeSums?.includes(i)) {
				npeUSD = npeUSD + parseFloat(cols[0]?.value || 0);
				npeKHR = npeKHR + parseFloat(cols[1]?.value || 0);
			} else if (toiSums?.includes(i)) {
				toiUSD = toiUSD + parseFloat(cols[0]?.value || 0);
				toiKHR = toiKHR + parseFloat(cols[1]?.value || 0);
			} else if (ncbeSums?.includes(i)) {
				ncbeUSD = ncbeUSD + parseFloat(cols[0]?.value || 0);
				ncbeKHR = ncbeKHR + parseFloat(cols[1]?.value || 0);
			} else if (toeSums?.includes(i)) {
				toeUSD = toeUSD + parseFloat(cols[0]?.value || 0);
				toeKHR = toeKHR + parseFloat(cols[1]?.value || 0);
			} else if (tfiSums?.includes(i)) {
				tfiUSD = tfiUSD + parseFloat(cols[0]?.value || 0);
				tfiKHR = tfiKHR + parseFloat(cols[1]?.value || 0);
			} else if (tfcSums?.includes(i)) {
				tfcUSD = tfcUSD + parseFloat(cols[0]?.value || 0);
				tfcKHR = tfcKHR + parseFloat(cols[1]?.value || 0);
			} else if (toglSums?.includes(i)) {
				toglUSD = toglUSD + parseFloat(cols[0]?.value || 0);
				toglKHR = toglKHR + parseFloat(cols[1]?.value || 0);
			} else if (patSums?.includes(i)) {
				patUSD = patUSD + parseFloat(cols[0]?.value || 0);
				patKHR = patKHR + parseFloat(cols[1]?.value || 0);
			} else if (tociSums?.includes(i)) {
				tociUSD = tociUSD + parseFloat(cols[0]?.value || 0);
				tociKHR = tociKHR + parseFloat(cols[1]?.value || 0);
			}
			return [
				{ value: cols[0]?.value, readOnly },
				{ value: cols[1]?.value, readOnly },
			];
		}
	});
	localCells = localCells.slice(0, numLabel);
	return { code, cells: localCells };
}

function validateSPLOCICells({ cells }) {
	const localCells = cells?.reduce((iPre, iCur) => {
		let localIPre = iPre;
		localIPre.push(
			...iCur?.reduce((jPre, jCur) => {
				let localJPre = jPre;
				if (!jCur?.readOnly && !isNumeric(jCur?.value)) {
					localJPre.push(jCur);
				}
				return localJPre;
			}, [])
		);
		return localIPre;
	}, []);
	return localCells?.length === 0;
}

export { splociNumColumn, cleanSPLOCICells, detailSPLOCICells, calculateSPLOCICells, validateSPLOCICells };
