import moment from 'moment';
import c from '../../../constant';

function titleRow(data, title) {
	const row = data.reduce(function (pre, cur) {
		Object.values(cur).forEach(function (e, j) {
			if (j === 0) {
				pre[0] = '';
			} else if (j === 1) {
				pre[1] = title;
			} else {
				pre[j] = '';
			}
		});
		return pre;
	}, {});
	return [row];
}

function totalRow(data, title) {
	const row = data.reduce(function (pre, cur) {
		Object.values(cur).forEach(function (e, j) {
			if (j === 0) {
				pre[0] = '';
			} else if (j === 1) {
				pre[1] = title;
			} else {
				pre[j] = (pre[j] || 0) + e;
			}
		});
		return pre;
	}, {});
	return [row];
}

function fileName(reportType = null, companyType = null, month = null, year = null) {
	moment.locale('en-gb');
	const m = moment(month, 'M').format('MMMM');
	const y = moment(year, 'Y').format('YYYY');
	const d = moment().format(c.fileNameDateFormat);
	return `Quarterly ${reportType} Report - Generated At ${d}`;
}

function excelEarlyWarning({ headline = null, title = null, objects = [], total = null }) {
	const rows = objects?.reduce((pre, cur, i) => {
		let lPre = pre;
		let lCur = {};
		lCur[0] = i + 1;
		lCur[1] = cur?.objects;
		lPre.push(lCur);
		return lPre;
	}, []);

	let data = [...rows];

	if (title) {
		data = [...titleRow(rows, title), ...data];
	}

	if (headline) {
		data = [...titleRow(rows, headline), ...data];
	}

	if (total) {
		data = [...data, ...totalRow(rows, total)];
	}

	return data;
}

export { titleRow, totalRow, fileName, excelEarlyWarning };
