/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@chakra-ui/react';
import { checkSelectedActiveColumn } from '../../../../utils/market-development';
import Spreadsheet from 'react-spreadsheet';
import SpreadSheetProductList from '../../Miscellaneous/spreadsheet-product-list';
import SpreadSheetTotal from '../../Miscellaneous/spreadsheet-total';

const Table = ({ saleProducts = null, numColumn = 8 }) => {
	const { t } = useTranslation();
	const [newGeneralData, setNewGeneralData] = useState(
		saleProducts
			?.filter((el) => el?.productLine?.type === 'General' && el?.policyType === 'New')
			?.map((el) => el?.productValues?.map((el) => ({ value: el?.value, readOnly: true })))
	);
	const [newLifeData, setNewLifeData] = useState(
		saleProducts
			?.filter((el) => el?.productLine?.type === 'Life' && el?.policyType === 'New')
			?.map((el) => el?.productValues?.map((el) => ({ value: el?.value, readOnly: true })))
	);
	const [renewalGeneralData, setRenewalGeneralData] = useState(
		saleProducts
			?.filter((el) => el?.productLine?.type === 'General' && el?.policyType === 'Renewal')
			?.map((el) => el?.productValues?.map((el) => ({ value: el?.value, readOnly: true })))
	);
	const [renewalLifeData, setRenewalLifeData] = useState(
		saleProducts
			?.filter((el) => el?.productLine?.type === 'Life' && el?.policyType === 'Renewal')
			?.map((el) => el?.productValues?.map((el) => ({ value: el?.value, readOnly: true })))
	);
	const [active, setActive] = useState(null);

	return (
		<Box className="spreadsheet_container">
			<div className="product-title-cell">{t('Product Line')}</div>
			<table className="spreadsheet_custom_header">
				<thead>
					<tr style={{ height: '62px' }}>
						<td colSpan="6" className="background-light-green">
							{t('Policies')}
						</td>
						<td colSpan="2" className="background-light-orange">
							{t('Cancelled Policies')}
						</td>
					</tr>
					<tr style={{ height: '58px' }}>
						<td rowSpan={2} className={checkSelectedActiveColumn(active, 0) ? 'active-column' : 'background-light-green'}>
							{t('Gross Premium')}
						</td>
						<td rowSpan={2} className={checkSelectedActiveColumn(active, 1) ? 'active-column' : 'background-light-green'}>
							{t('Sum Insured')}
						</td>
						<td rowSpan={2} className={checkSelectedActiveColumn(active, 2) ? 'active-column' : 'background-light-green'}>
							{t('Number of Policies')}
						</td>
						<td colSpan={3} className="background-light-green">
							{t('Number of Insured')}
						</td>
						<td rowSpan={2} className={checkSelectedActiveColumn(active, 6) ? 'active-column' : 'background-light-orange'}>
							{t('Number of Policies')}
						</td>
						<td rowSpan={2} className={checkSelectedActiveColumn(active, 7) ? 'active-column' : 'background-light-orange'}>
							{t('Gross Premium')}
						</td>
					</tr>
					<tr style={{ height: '30px' }}>
						<td className={checkSelectedActiveColumn(active, 3) ? 'active-column' : 'background-light-green'}>{t('Male')}</td>
						<td className={checkSelectedActiveColumn(active, 4) ? 'active-column' : 'background-light-green'}>{t('Female')}</td>
						<td className={checkSelectedActiveColumn(active, 5) ? 'active-column' : 'background-light-green'}>{t('Other Unit')}</td>
					</tr>
				</thead>
			</table>
			<div>
				<Flex>
					<SpreadSheetProductList
						title="New Policy"
						type="t1"
						label="General Micro Insurance Business"
						labelMarginTop="0px"
						products={saleProducts?.filter((el) => el?.productLine?.type === 'General' && el?.policyType === 'New')?.map((e) => e?.productLine)}
						active={active}
					/>
					<Box>
						<Flex alignItems="center" mt="0px" h="80px" borderRight="1px solid hsl(2deg, 0%, 91%)" pl={2} pr={2} />
						<Spreadsheet
							data={newGeneralData}
							onChange={(e) => {
								let localData = newGeneralData?.reduce((p, c) => {
									let lp = p;
									let lc = c?.slice(0, numColumn);
									lp.push(lc);
									return lp;
								}, []);
								setNewGeneralData(localData?.slice(0, newGeneralData?.length));
							}}
							onActivate={(e) => setActive({ ...e, t: 't1' })}
							hideRowIndicators
							hideColumnIndicators
						/>
					</Box>
				</Flex>
				<SpreadSheetTotal data={newGeneralData} numColumn={numColumn} />
			</div>
			<div>
				<Flex>
					<SpreadSheetProductList
						type="t2"
						label="Life Micro Insurance Business"
						labelMarginTop="0px"
						products={saleProducts?.filter((el) => el?.productLine?.type === 'Life' && el?.policyType === 'New')?.map((e) => e?.productLine)}
						active={active}
					/>
					<Box>
						<Flex alignItems="center" mt="0px" h="30px" borderRight="1px solid hsl(2deg, 0%, 91%)" pl={2} pr={2} />
						<Spreadsheet
							data={newLifeData}
							onChange={(e) => {
								let localData = newLifeData?.reduce((p, c) => {
									let lp = p;
									let lc = c?.slice(0, numColumn);
									lp.push(lc);
									return lp;
								}, []);
								setNewLifeData(localData?.slice(0, newLifeData?.length));
							}}
							onActivate={(e) => setActive({ ...e, t: 't2' })}
							hideRowIndicators
							hideColumnIndicators
						/>
					</Box>
				</Flex>
				<SpreadSheetTotal data={newLifeData} numColumn={numColumn} />
			</div>
			<div>
				<Flex>
					<SpreadSheetProductList
						title="Renewal Policy"
						type="t3"
						label="General Micro Insurance Business"
						labelMarginTop="0px"
						products={saleProducts?.filter((el) => el?.productLine?.type === 'General' && el?.policyType === 'Renewal')?.map((e) => e?.productLine)}
						active={active}
					/>
					<Box>
						<Flex alignItems="center" mt="0px" h="80px" borderRight="1px solid hsl(2deg, 0%, 91%)" pl={2} pr={2} />
						<Spreadsheet
							data={renewalGeneralData}
							onChange={(e) => {
								let localData = renewalGeneralData?.reduce((p, c) => {
									let lp = p;
									let lc = c?.slice(0, numColumn);
									lp.push(lc);
									return lp;
								}, []);
								setRenewalGeneralData(localData?.slice(0, renewalGeneralData?.length));
							}}
							onActivate={(e) => setActive({ ...e, t: 't3' })}
							hideRowIndicators
							hideColumnIndicators
						/>
					</Box>
				</Flex>
				<SpreadSheetTotal data={renewalGeneralData} numColumn={numColumn} />
			</div>
			<div>
				<Flex>
					<SpreadSheetProductList
						type="t4"
						label="Life Micro Insurance Business"
						labelMarginTop="0px"
						products={saleProducts?.filter((el) => el?.productLine?.type === 'Life' && el?.policyType === 'Renewal')?.map((e) => e?.productLine)}
						active={active}
					/>
					<Box>
						<Flex alignItems="center" mt="0px" h="30px" borderRight="1px solid hsl(2deg, 0%, 91%)" pl={2} pr={2} />
						<Spreadsheet
							data={renewalLifeData}
							onChange={(e) => {
								let localData = renewalLifeData?.reduce((p, c) => {
									let lp = p;
									let lc = c?.slice(0, numColumn);
									lp.push(lc);
									return lp;
								}, []);
								setRenewalLifeData(localData?.slice(0, renewalLifeData?.length));
							}}
							onActivate={(e) => setActive({ ...e, t: 't4' })}
							hideRowIndicators
							hideColumnIndicators
						/>
					</Box>
				</Flex>
				<SpreadSheetTotal data={renewalLifeData} numColumn={numColumn} />
			</div>
		</Box>
	);
};

export default Table;
