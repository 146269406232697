/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { gql, useLazyQuery } from '@apollo/client';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { excelDistributionChannel, fileName } from '../../Miscellaneous/export';
import ReactExport from 'react-export-excel';
import c from '../../../../constant';
import useStore from '../../../../store';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Q_DETAIL = gql`
	query get($id: String!) {
		getMonthlyDistributionReportById(id: $id) {
			id
			reportType
			month
			year
			createdAt
			updatedAt
			isEditing
			editingUpdatedAt
			editingUpdatedBy
			editingDueDate
			editingDescription
			penalties {
				id
				active
			}
			distributionItems {
				id
				productLine {
					id
					nameKh
					nameEn
					type
				}
				distributionProvinces {
					id
					province {
						id
						code
						name
						latinName
					}
					provinceValues {
						id
						distributionType
						distributionValueType
						value
					}
				}
			}
		}
	}
`;

export default function ExportExcelGeneral({ id = null, month = null, year = null, numColumns = 0, ...props }) {
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [fetchData, { data: dataGet }] = useLazyQuery(Q_DETAIL, { fetchPolicy: 'no-cache' });
	const { currentLang } = useStore((state) => state.currentLang);

	useEffect(() => {
		id && fetchData({ variables: { id } });
	}, [id]);

	useEffect(() => {
		dataGet && setData(excelData(dataGet?.getMonthlyDistributionReportById?.distributionItems));
	}, [dataGet]);

	function excelData(distributionItems) {
		const distributions = distributionItems.reduce((pre, cur) => {
			let localPre = pre;
			let localCur = excelDistributionChannel({ distributionProvinces: cur?.distributionProvinces, numColumns, total: t('Total'), lang: currentLang });
			localPre.push({ name: cur?.productLine?.nameKh, data: localCur });
			return localPre;
		}, []);
		return distributions;
	}

	return (
		<>
			<Box {...props}>
				<ExcelFile
					filename={fileName('Distribution Channel', 'Micro', month, year)}
					element={
						<>
							<Button
								isLoading={false}
								isDisabled={false}
								onClick={() => null}
								leftIcon={<FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 16 }} />}
								colorScheme="gray"
								variant="solid"
								size="sm"
								borderRadius={c.borderRadius}
								border="1px solid #bdc3c7"
							>
								{t('Export')}
							</Button>
						</>
					}
				>
					{data?.map((el, i) => {
						return (
							<ExcelSheet key={i} data={el?.data} name={t(el?.name)}>
								<ExcelColumn label={t('Ordinal')} value="0" />
								<ExcelColumn label={t('Product Line')} value="1" />
								<ExcelColumn label={currentLang === 'kh' ? 'ជើងសា (ចំនួនបណ្ណសន្យារ៉ាប់រង)' : 'Brokers (Number of Policies)'} value="2" />
								<ExcelColumn
									label={currentLang === 'kh' ? 'ភ្នាក់ងារ (ចំនួនបណ្ណសន្យារ៉ាប់រង)' : 'Individual Agent (Number of Policies)'}
									value="3"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? 'ភ្នាក់ងារដៃគូរ (ចំនួនបណ្ណសន្យារ៉ាប់រង)' : 'Corporate Agent (Number of Policies)'}
									value="4"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? 'ប៊េងកាសួរេន (ចំនួនបណ្ណសន្យារ៉ាប់រង)' : 'Bancassurance (Number of Policies)'}
									value="5"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? 'លក់តាមប្រព័ន្ធឌីជីថល (ចំនួនបណ្ណសន្យារ៉ាប់រង)' : 'Digital Sale (Number of Policies)'}
									value="6"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? 'បុគ្គលិកលក់ (ចំនួនបណ្ណសន្យារ៉ាប់រង)' : 'Sale Staff (Number of Policies)'}
									value="7"
								/>
								<ExcelColumn label={currentLang === 'kh' ? 'ចំនួនដែលត្រូវបានធានា' : 'Number of Insured'} value="8" />
								<ExcelColumn label={currentLang === 'kh' ? 'ជើងសា (បុព្វលាភធានារ៉ាប់រងដុល)' : 'Brokers (Gross Premium)'} value="9" />
								<ExcelColumn
									label={currentLang === 'kh' ? 'ភ្នាក់ងារ (បុព្វលាភធានារ៉ាប់រងដុល)' : 'Individual Agent (Gross Premium)'}
									value="10"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? 'ភ្នាក់ងារដៃគូរ (បុព្វលាភធានារ៉ាប់រងដុល)' : 'Corporate Agent	(Gross Premium)'}
									value="11"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? 'ប៊េងកាសួរេន (បុព្វលាភធានារ៉ាប់រងដុល)' : 'Bancassurance (Gross Premium)'}
									value="12"
								/>
								<ExcelColumn
									label={currentLang === 'kh' ? 'លក់តាមប្រព័ន្ធឌីជីថល (បុព្វលាភធានារ៉ាប់រងដុល)' : 'Digital Sale (Gross Premium)'}
									value="13"
								/>
								<ExcelColumn label={currentLang === 'kh' ? 'បុគ្គលិកលក់ (បុព្វលាភធានារ៉ាប់រងដុល)' : 'Sale Staff (Gross Premium)'} value="14" />
								<ExcelColumn label={currentLang === 'kh' ? 'ចំនួន (សំណងកើតឡើង)' : 'Number (Claim Incurred)'} value="15" />
								<ExcelColumn label={currentLang === 'kh' ? 'ទឹកប្រាក់ (សំណងកើតឡើង)' : 'Amount (Claim Incurred)'} value="16" />
								<ExcelColumn label={currentLang === 'kh' ? 'ចំនួន (សំណងដែលបានទូទាត់)' : 'Number	(Claim Paid)'} value="17" />
								<ExcelColumn label={currentLang === 'kh' ? 'ទឹកប្រាក់ (សំណងដែលបានទូទាត់)' : 'Amount	(Claim Paid)'} value="18" />
								<ExcelColumn label={currentLang === 'kh' ? 'ចំនួន (សំណងមិនសង)' : 'Number (Claim Denied)'} value="19" />
								<ExcelColumn label={currentLang === 'kh' ? 'ទឹកប្រាក់ (សំណងមិនសង)' : 'Amount (Claim Denied)'} value="20" />
								<ExcelColumn label={currentLang === 'kh' ? 'ចំនួន (សំណងមិនទាន់ទូទាត់ចុងខែ)' : 'Number (Outstanding Claims)'} value="21" />
								<ExcelColumn label={currentLang === 'kh' ? 'ទឹកប្រាក់ (សំណងមិនទាន់ទូទាត់ចុងខែ)' : 'Amount (Outstanding Claims)'} value="22" />
							</ExcelSheet>
						);
					})}
				</ExcelFile>
			</Box>
		</>
	);
}
