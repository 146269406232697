/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import { checkSelectedActiveColumn } from '../../../../utils/market-development';
import Loading from '../../../Loading';
import Spreadsheet from 'react-spreadsheet';
import SpreadSheetProvinceList from '../../Miscellaneous/spreadsheet-province-list';
import SpreadSheetTotal from '../../Miscellaneous/spreadsheet-total';

const Q_ITEM = gql`
	query get($distributionItemId: Float!) {
		getMonthlyDistributionReportItem(distributionItemId: $distributionItemId) {
			id
			productLine {
				id
				nameKh
				nameEn
				type
			}
			distributionProvinces {
				id
				province {
					id
					code
					name
					latinName
				}
				provinceValues {
					id
					distributionType
					distributionValueType
					value
				}
			}
		}
	}
`;

const Table = ({ distributionItemId = null, numColumn = 21 }) => {
	const { t } = useTranslation();
	const [provinces, setProvinces] = useState([]);
	const [provinceValues, setProvinceValues] = useState([]);
	const [active, setActive] = useState(null);
	const [fetchData, { loading, data }] = useLazyQuery(Q_ITEM, { fetchPolicy: 'no-cache' });

	useEffect(loadDetail, [distributionItemId]);

	useEffect(() => {
		if (data) {
			setProvinces(data?.getMonthlyDistributionReportItem?.distributionProvinces?.map((el) => el?.province));
			setProvinceValues(
				data?.getMonthlyDistributionReportItem?.distributionProvinces?.map((el) =>
					el?.provinceValues?.map((el) => ({ value: el?.value, readOnly: true }))
				)
			);
		}
	}, [data]);

	function loadDetail() {
		distributionItemId && fetchData({ variables: { distributionItemId } });
	}

	return (
		<>
			{loading && <Loading />}
			{!loading && provinceValues?.length > 0 && (
				<Box className="spreadsheet_container">
					<div className="product-title-cell">{t('Province/City')}</div>
					<table className="spreadsheet_custom_header">
						<thead>
							<tr style={{ height: '82px' }}>
								<td colSpan="6" className="background-light-green">
									{t('Number of Policies')}
								</td>
								<td rowSpan="2" className={checkSelectedActiveColumn(active, 6) ? 'active-column' : 'background-light-green'}>
									{t('Number of Insured')}
								</td>
								<td colSpan="6" className="background-light-ocean">
									{t('Gross Premium')}
								</td>
								<td colSpan="2" className="background-light-orange">
									{t('Claim Incurred')}
								</td>
								<td colSpan="2" className="background-light-orange">
									{t('Claim Paid')}
								</td>
								<td colSpan="2" className="background-light-orange">
									{t('Claim Denied')}
								</td>
								<td colSpan="2" className="background-light-orange">
									{t('Outstanding Claims')}
								</td>
							</tr>
							<tr style={{ height: '68px' }}>
								<td className={checkSelectedActiveColumn(active, 0) ? 'active-column' : 'background-light-green'}>
									<>{t('Brokers')}</>
								</td>
								<td className={checkSelectedActiveColumn(active, 1) ? 'active-column' : 'background-light-green'}>
									<>{t('Individual Agent')}</>
								</td>
								<td className={checkSelectedActiveColumn(active, 2) ? 'active-column' : 'background-light-green'}>
									<>{t('Corporate Agent')}</>
								</td>
								<td className={checkSelectedActiveColumn(active, 3) ? 'active-column' : 'background-light-green'}>
									<>{t('Bancassurance')}</>
								</td>
								<td className={checkSelectedActiveColumn(active, 4) ? 'active-column' : 'background-light-green'}>
									<>{t('Digital Sale')}</>
								</td>
								<td className={checkSelectedActiveColumn(active, 5) ? 'active-column' : 'background-light-green'}>
									<>{t('Sale Staff')}</>
								</td>
								<td className={checkSelectedActiveColumn(active, 7) ? 'active-column' : 'background-light-ocean'}>
									<>{t('Brokers')}</>
								</td>
								<td className={checkSelectedActiveColumn(active, 8) ? 'active-column' : 'background-light-ocean'}>
									<>{t('Individual Agent')}</>
								</td>
								<td className={checkSelectedActiveColumn(active, 9) ? 'active-column' : 'background-light-ocean'}>
									<>{t('Corporate Agent')}</>
								</td>
								<td className={checkSelectedActiveColumn(active, 10) ? 'active-column' : 'background-light-ocean'}>
									<>{t('Bancassurance')}</>
								</td>
								<td className={checkSelectedActiveColumn(active, 11) ? 'active-column' : 'background-light-ocean'}>
									<>{t('Digital Sale')}</>
								</td>
								<td className={checkSelectedActiveColumn(active, 12) ? 'active-column' : 'background-light-ocean'}>
									<>{t('Sale Staff')}</>
								</td>
								<td className={checkSelectedActiveColumn(active, 13) ? 'active-column' : 'background-light-orange'}>
									<>{t('number')}</>
								</td>
								<td className={checkSelectedActiveColumn(active, 14) ? 'active-column' : 'background-light-orange'}>
									<>{t('amount1')}</>
								</td>
								<td className={checkSelectedActiveColumn(active, 15) ? 'active-column' : 'background-light-orange'}>
									<>{t('number')}</>
								</td>
								<td className={checkSelectedActiveColumn(active, 16) ? 'active-column' : 'background-light-orange'}>
									<>{t('amount1')}</>
								</td>
								<td className={checkSelectedActiveColumn(active, 17) ? 'active-column' : 'background-light-orange'}>
									<>{t('number')}</>
								</td>
								<td className={checkSelectedActiveColumn(active, 18) ? 'active-column' : 'background-light-orange'}>
									<>{t('amount1')}</>
								</td>
								<td className={checkSelectedActiveColumn(active, 19) ? 'active-column' : 'background-light-orange'}>
									<>{t('number')}</>
								</td>
								<td className={checkSelectedActiveColumn(active, 20) ? 'active-column' : 'background-light-orange'}>
									<>{t('amount1')}</>
								</td>
							</tr>
						</thead>
					</table>
					<div>
						<Flex>
							<SpreadSheetProvinceList type="General" label={null} labelMarginTop="0px" provinces={provinces} active={active} />
							<Box>
								<Flex alignItems="center" mt="0px" h="0px" borderRight="1px solid hsl(2deg, 0%, 91%)" pl={2} pr={2} />
								<Spreadsheet
									data={provinceValues}
									onChange={(e) => {
										let localData = provinceValues?.reduce((p, c) => {
											let lp = p;
											let lc = c?.slice(0, numColumn);
											lp.push(lc);
											return lp;
										}, []);
										setProvinceValues(localData?.slice(0, provinceValues?.length));
									}}
									onActivate={(e) => setActive({ ...e, t: 'General' })}
									hideRowIndicators
									hideColumnIndicators
								/>
							</Box>
						</Flex>
						<SpreadSheetTotal data={provinceValues} numColumn={numColumn} />
					</div>
				</Box>
			)}
		</>
	);
};

export default Table;
