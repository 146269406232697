import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import DownloadAndViewButton from '../../../components/Files/DownloadAndViewButton';
import LabelDetail from '../../../components/LabelDetail';
import c from '../../../constant';

export default function AttachmentFile({ attachmentFile = null }) {
	const { t } = useTranslation();

	return (
		<>
			<Flex flex={1} border="1px dotted #dbdbdb" borderRadius={c.borderRadius} pl={4} pr={4} pt={4} pb={4}>
				<Box width="100%">
					<Flex justifyContent="flex-start" alignItems="center">
						<Text fontWeight="bold" fontSize="md" color="gray.600">
							{t('Attachment')}
						</Text>
					</Flex>
					<SimpleGrid columns={[1, null, 2]} spacingX={8} mt={2}>
						<LabelDetail
							mb={1}
							mt={0}
							label={null}
							labelColor="gray.600"
							childDetail={<DownloadAndViewButton url={attachmentFile?.url} size={attachmentFile?.size} marginLeft={0} />}
						/>
					</SimpleGrid>
				</Box>
			</Flex>
		</>
	);
}
